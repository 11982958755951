import "./index.css";

const CustomSelect = ({ options, name, id, handleChange }) => {
    return (
        <select name={name} id={id} onChange={handleChange} className="select-wrapper">
            {options.map((item) => (
                <option value={item}>{item.display}</option>
            ))}
        </select>
    );
};

export default CustomSelect;
