import React from "react";
import { useState, useEffect, useRef } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MoreInfo from "@mui/icons-material/MoreVert";

const ScheduleFeatureTableBody = ({
    item,
    handleTotalUsersCount,
    openStopModal,
    setOpenStopModal,
    // showPopover,
    // setShowPopover,
    handleStopModalClose,
    handleStopModalOpen,
    stopItem,
    setStopItem,
    setStopEmailID,
    setStopReason,
    hanldeCampaignStop,
    stopEmailID,
    stopReason,
}) => {
    // const [stopItem, setStopItem] = useState();

    // POPOVER
    const [showPopover, setShowPopover] = useState(false);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowPopover(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    const handleStopModal = () => {
        handleStopModalOpen();
    };

    const handleShowPopper = (item) => {
        setShowPopover(!showPopover);
        setStopItem(item);
    };

    return (
        <>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} style={{ position: "relative" }}>
                <TableCell align="left">{item?.hypothesis_id}</TableCell>
                <TableCell width="110px" align="center">
                    {item?.campaign_name}
                </TableCell>
                <TableCell width="110px" align="center">
                    {item?.channel}
                </TableCell>
                <TableCell width="110px" align="center">
                    {item?.intent}
                </TableCell>

                <TableCell align="center">{item?.schedule_date}</TableCell>
                <TableCell align="center">{item?.schedule_time}</TableCell>
                <TableCell align="center">
                    <button className="goal-success-btn" onClick={() => handleTotalUsersCount(item)}>
                        Get Data
                    </button>
                </TableCell>
                <TableCell align="center">
                    {item?.status === "RESCINDED" || item?.status === "PROCESSED" ? (
                        <span style={{ color: "#B3B0B0" }}>{item?.status}</span>
                    ) : (
                        <span>{item?.status}</span>
                    )}
                    {item?.status === "RESCINDED" || item?.status === "PROCESSED" ? (
                        <MoreInfo style={{ paddingLeft: "8px", fontSize: "14px", color: "#B3B0B0" }} />
                    ) : (
                        <MoreInfo
                            style={{ cursor: "pointer", paddingLeft: "8px", fontSize: "14px" }}
                            onClick={() => handleShowPopper(item)}
                        />
                    )}
                </TableCell>

                <TableCell align="center" ref={ref}>
                    {showPopover && (
                        <div className="popover-menu" onClick={handleStopModal}>
                            Stop
                        </div>
                    )}
                </TableCell>
            </TableRow>
        </>
    );
};

export default ScheduleFeatureTableBody;
