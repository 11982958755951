import React from "react";
import "./index.css";
import embibeLogo from "../../../assets/embibeMobileLogo.svg";

const getTime = () => {
    var d = new Date(); // for now
    let hr = d.getHours(); // => 9
    let min = d.getMinutes(); // =>  30
    d.getSeconds();

    return `${hr}:${min}`;
};

const PNPreview = ({ pushNTitle, pushNBody, pnImageLink, previewItem }) => {
    return (
        <>
            {pushNBody !== "" || pnImageLink !== "" || pushNTitle !== "" ? (
                <div className="pn-preview-container">
                    <div className="pn-preview-body-container">
                        <div className="pn-preview-header">
                            <div className="pn-preview-logo">
                                <img src={embibeLogo} />
                            </div>
                            <div className="pn-preview-embibe">Embibe</div>
                            <div className="pn-preview-time">{getTime()}</div>
                        </div>
                        <div className="pn-preview-title">{pushNTitle || previewItem?.message_details?.title}</div>
                        <div className="pn-preview-body">{pushNBody || previewItem?.message_details?.body}</div>
                        {(pnImageLink || previewItem?.message_details?.image_link) && (
                            <div className="pn-preview-image">
                                <img src={pnImageLink || previewItem?.message_details?.image_link} />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default PNPreview;
