export const statusOptions = [
    {
        label: "Registered",
        name: "select_status",
        value: "REGISTERED",
    },
    {
        label: "Approved",
        name: "select_status",
        value: "APPROVED",
    },
];

export const templateTypeOptions = [
    {
        label: "PROMOTIONAL ",
        name: "template_type",
        value: "PROMOTIONAL",
    },
    {
        label: "TRANSACTIONAL ",
        name: "template_type",
        value: "TRANSACTIONAL",
    },
    {
        label: "INFORMATIONAL ",
        name: "template_type",
        value: "INFORMATIONAL",
    },
];
export const ymBotAccounts = [
    { label: "MB ", name: "MB", value: "MB" },
    { label: "Embibe For Promotion ", name: "EMBIBE_FOR_PROMOTION", value: "EMBIBE_FOR_PROMOTION" },
];
export const localeOptions = [
    {
        label: "EN",
        name: "locale",
        value: "EN",
    },
    {
        label: "HI",
        name: "locale",
        value: "HI",
    },
    {
        label: "BN",
        name: "locale",
        value: "BN",
    },
    {
        label: "MR",
        name: "locale",
        value: "MR",
    },
    {
        label: "TE",
        name: "locale",
        value: "TE",
    },
    {
        label: "GU",
        name: "locale",
        value: "GU",
    },
    {
        label: "KN",
        name: "locale",
        value: "KN",
    },
    {
        label: "OR",
        name: "locale",
        value: "OR",
    },
    {
        label: "PA",
        name: "locale",
        value: "PA",
    },
    {
        label: "ML",
        name: "locale",
        value: "ML",
    },
    {
        label: "AS",
        name: "locale",
        value: "AS",
    },
    {
        label: "TA",
        name: "locale",
        value: "TA",
    },
];

export const formatOptions = [
    {
        label: "Text ",
        name: "format",
        value: "TEXT",
    },
    {
        label: "HTML ",
        name: "format",
        value: "HTML",
    },
];

export const vendorNameOptions = [
    {
        label: "Gupshup ",
        name: "gupshup",
        value: "GUPSHUP",
    },
    {
        label: "YM ",
        name: "vendor_name",
        value: "YM",
    },
];

export const notificationTypeOptions = [
    {
        label: "Plain Text ",
        name: "notification_type",
        value: "notification_type_plainText",
    },
    {
        label: "Image ",
        name: "notification_type",
        value: "notification_type_image",
    },
];

export const pnNotificationTypes = [
    {
        label: "App Only",
        name: "pn_notification_type",
        value: "APP",
    },
    {
        label: "Web Only",
        name: "pn_notification_type",
        value: "WEB",
    },
    {
        label: "Both",
        name: "pn_notification_type",
        value: "BOTH",
    },
    {
        label: "IOS Only",
        name: "pn_notification_type",
        value: "IOS",
    },
    {
        label: "Android And Web",
        name: "pn_notification_type",
        value: "ANDROID_AND_WEB",
    },
];
export const pnNotificationTypeApp = [
    {
        label: "App Only",
        name: "pn_notification_type",
        value: "APP",
    },
];
export const pnNotificationTypeWeb = [
    {
        label: "Web Only",
        name: "pn_notification_type",
        value: "WEB",
    },
];
export const inAppPriorityStatus = [
    {
        label: "HIGH",
        name: "in_app_priority_status",
        value: "HIGH",
    },
    {
        label: "MEDIUM",
        name: "in_app_priority_status",
        value: "MEDIUM",
    },
];
export const platformSelectType = [
    {
        label: "Web",
        name: "platform_select_type",
        value: "WEB",
    },
    {
        label: "App",
        name: "platform_select_type",
        value: "APP",
    },
];
export const inAppPopupNotificationType = [
    {
        label: "Cover",
        name: "in_app_popup_notification_type",
        value: "COVER",
    },
    {
        label: "Half Interstitial",
        name: "in_app_popup_notification_type",
        value: "HALF_INTERSTITIAL",
    },
    {
        label: "Interstitial",
        name: "in_app_popup_notification_type",
        value: "INTERSTITIAL",
    },
    {
        label: "Header",
        name: "in_app_popup_notification_type",
        value: "HEADER",
    },
    {
        label: "Footer",
        name: "in_app_popup_notification_type",
        value: "FOOTER",
    },
    {
        label: "Alert",
        name: "in_app_popup_notification_type",
        value: "ALERT",
    },
];
export const webPopupNotificationType = [
    {
        label: "Box",
        name: "web_popup_notification_type",
        value: "BOX",
    },
    {
        label: "Interstitial",
        name: "web_popup_notification_type",
        value: "INTERSTITIAL",
    },
];

export const inAppPriorityTags = [
    {
        label: "Environment",
        name: "in_app_tags",
        value: "Environment",
    },
    {
        label: "Module/Category",
        name: "in_app_tags",
        value: "Module/Category",
    },
    {
        label: "Feature/Information",
        name: "in_app_tags",
        value: "Feature/Information",
    },
];
