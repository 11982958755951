import TextField from "@mui/material/TextField";
import "./index.css";

const FormSubmitBtn = ({
    id,
    label,
    variant,
    value,
    placeholder,
    handleChange,
    multiline,
    rows,
    name,
    inputDisabled,
    helperText,
    type,
    isError,
    style,
    isRequired,
    buttonText,
    margin,
    disabledSubmit,
    handleClick,
}) => {
    return (
        <div>
            <input
                id={id}
                // onChange={handleChange}
                value={value}
                label={label}
                placeholder={placeholder}
                margin="normal"
                className="form-submit-button"
                type={type}
                // className={variant}
                disabled={disabledSubmit ? "disabled" : ""}
                onClick={handleClick}
                // error={isError === true ? true : false}
                required={isRequired ? true : false}
                // inputDisable={disabled}
                // error
                // helperText={isError === true ? "Please fill" : ""}
            />
        </div>
    );
};

export default FormSubmitBtn;
