import React from "react";
import "./index.css";
import Select from "react-select";

const emailList = [
    "simi.james@embibe.com",
    "vignesh.c@embibe.com",
    "vishanth.v@embibe.com",
    "kenneth.noronha@embibe.com",
    "alka.chandra@embibe.com",
    "ankitk.ksingh@embibe.com",
    "bhargav.patel@embibe.com",
    "hiteshvishal_thinkjs@embibe.com",
];

const StopEmailSelect = ({ setStopEmailID }) => {
    const emalOptions = emailList.map((item) => ({ value: item, label: item }));

    const handleEmailInput = (option) => {
        setStopEmailID(option.value);
    };
    return (
        <>
            <Select style={{ width: "100%" }} options={emalOptions} onChange={handleEmailInput} />
        </>
    );
};

export default StopEmailSelect;
