import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/Button";
import InputField from "../../../../components/InputField";
import FormSubmitBtn from "../../../../components/SubmitForm";
import StopEmailSelect from "./StopEmailSelect";

const StopRescindForm = ({
    handleStopModalClose,
    stopItem,
    setStopEmailID,
    setStopReason,
    hanldeCampaignStop,
    stopEmailID,
    stopReason,
}) => {
    return (
        <>
            <div className="stop-form-header">
                <span style={{ fontSize: "18px" }}>Stop Campaign</span>
                <span className="stop-form-header-close" onClick={handleStopModalClose}>
                    x
                </span>
            </div>
            <div className="stop-form-sub-header">
                <span style={{ fontSize: "15px" }}>This campaign will no longer be sent to the selected segment.</span>
                <br /> <span style={{ fontSize: "15px" }}>This action cannot be undone.</span>
            </div>

            <StopEmailSelect setStopEmailID={setStopEmailID} />

            <div className="stop-form-textarea-label">Reason for stopping this campaign *</div>
            <textarea
                type="textarea"
                rows="6"
                cols="92"
                className="stop-form-textarea"
                // minlength="60"
                required
                onChange={(e) => setStopReason(e.target.value)}
            />

            <div className="stop-form-btn-wrapper">
                <CustomButton buttonText="Cancel" variant="outlined" color="error" handleClick={handleStopModalClose} />

                {/* <FormSubmitBtn type="submit" value="STOP" /> */}
                <CustomButton
                    handleClick={hanldeCampaignStop}
                    buttonText="Apply"
                    variant="outlined"
                    disabled={!stopEmailID || !stopReason || stopReason?.length < 60 ? true : false}
                />
            </div>
        </>
    );
};

export default StopRescindForm;
