import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const UserViewDropdown = ({ userViewSelected, userViewDropdownData, setUserViewSelected }) => {
    return (
        <div className="user-view-input-wrapper">
            <div>User View</div>
            <div>
                <FormControl fullWidth>
                    <Select
                        id="create-template"
                        name="userView"
                        value={userViewSelected}
                        onChange={(e) => setUserViewSelected(e.target.value)}
                    >
                        {userViewDropdownData?.map((item) => (
                            <MenuItem value={item.user_view_id}>{item.user_view_name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default UserViewDropdown;
