import React from "react";
import "./index.css";

const MobilePhoneTemplate = ({ children }) => {
    return (
        <div class="laptop">
            <div class="screen">
                <div class="lcd">{children}</div>
                <div class="glass_frame"></div>
                <div class="screen_reflection"></div>
            </div>
            <div class="keyboard top"></div>
            <div class="keyboard bottom"></div>
        </div>
    );
};

export default MobilePhoneTemplate;
