import { Link } from "react-router-dom";
import studentApp from "../../assets/studentApp.svg";
import parentApp from "../../assets/parentApp.svg";
import lensApp from "../../assets/lensApp.svg";
import schoolApp from "../../assets/schoolApp.svg";
import experimentApp from "../../assets/experiments.svg";
import classroomApp from "../../assets/classroomApp.svg";
import mahendras from "../../assets/mahendrasLogo.svg";
import lensUP from "../../assets/UP Lens logo .png";
import "./index.css";
import { ROUTE } from "../../utils/constants/routeConstants";
import SelectApp from "./SelectApp";
import Signout from "../../pages/Login/logout";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const NavBar = () => {
    const value = useSelector((state) => state.value);
    const location = useLocation();
    const navList = [
        { id: "home", label: "Home", route: ROUTE.HOME },
        { id: "campaign", label: "Campaign", route: ROUTE.CAMPIAGN },
        { id: "testUserList", label: "Test User List", route: ROUTE.TEST_USER_LIST },
        { id: "template", label: "Template", route: ROUTE.TEMPLATE },
        { id: "userView", label: "User View", route: ROUTE.USER_VIEW },
        { id: "deepLink", label: "Deep Link", route: ROUTE.DEEPLINK },
    ];

    const mahendrasNavList = [
        { id: "home", label: "Home", route: ROUTE.HOME },
        { id: "campaign", label: "Campaign", route: ROUTE.CAMPIAGN },
        { id: "testUserList", label: "Test User List", route: ROUTE.TEST_USER_LIST },
        { id: "template", label: "Template", route: ROUTE.TEMPLATE },
        { id: "userView", label: "User View", route: ROUTE.USER_VIEW },
    ];

    const combinedNavList =
        value === "MAHENDRAS" || value === "UP_LENS" || value === "NCERT_LENS" || value === "MTG"
            ? [...mahendrasNavList]
            : [...navList];

    const getImageSource = () => {
        if (value === "STUDENT") {
            return studentApp;
        } else if (value === "LENS") {
            return lensApp;
        } else if (value === "PARENT") {
            return parentApp;
        } else if (value === "EXPERIMENT") {
            return experimentApp;
        } else if (value === "SCHOOL") {
            return schoolApp;
        } else if (value === "CLASSROOM") {
            return classroomApp;
        } else if (value === "MAHENDRAS") {
            return mahendras;
        } else if (value === "UP_LENS") {
            return lensUP;
        } else {
            return studentApp;
        }
    };
    const imageSource = getImageSource();
    const platformText =
        value === "STUDENT"
            ? "Student"
            : value === "LENS"
            ? "Lens"
            : value === "PARENT"
            ? "Parent"
            : value === "EXPERIMENT"
            ? "VLE"
            : value === "SCHOOL"
            ? "School"
            : value === "MAHENDRAS"
            ? "Mahendras"
            : value === "CLASSROOM"
            ? "Classroom"
            : value === "UP_LENS"
            ? "UP Lens"
            : value === "NCERT_LENS"
            ? "NCERT Lens"
            : value === "MTG"
            ? "MTG Lens"
            : "";

    return (
        <div
            className={
                value === "STUDENT"
                    ? "navbar-container std"
                    : value === "LENS"
                    ? "navbar-container lns"
                    : value === "PARENT"
                    ? "navbar-container prt"
                    : value === "EXPERIMENT"
                    ? "navbar-container vle"
                    : value === "SCHOOL"
                    ? "navbar-container sch"
                    : value === "CLASSROOM"
                    ? "navbar-container cls"
                    : value === "MAHENDRAS"
                    ? "navbar-container"
                    : value === "UP_LENS"
                    ? "navbar-container upl"
                    : "navbar-container"
            }
        >
            <div className="navbar-links-wrapper">
                <div>
                    <h1 className="navbar-logo">
                        Emtap
                        <sub style={{ fontSize: "x-small" }}>{platformText}</sub>
                    </h1>
                </div>
                <Routes>
                    <Route path={ROUTE.HOME} element={<SelectApp />} />
                    <Route path={ROUTE.CAMPIAGN} element={<SelectApp />} />
                    <Route path={ROUTE.TEST_USER_LIST} element={<SelectApp />} />
                    <Route path={ROUTE.TEMPLATE} element={<SelectApp />} />
                    <Route path={ROUTE.USER_VIEW} element={<SelectApp />} />
                    <Route path={ROUTE.INCIDENT_VIEW} element={<SelectApp />} />
                    <Route path={ROUTE.SERVICES} element={<SelectApp />} />
                    <Route path={ROUTE.FEATURES} element={<SelectApp />} />
                    <Route path={ROUTE.REPORTS} element={<SelectApp />} />
                    <Route path={ROUTE.DEEPLINK} element={<SelectApp />} />
                    <Route path={ROUTE.LOGIN} element={<SelectApp />} />
                </Routes>
                {combinedNavList.map((item) => (
                    <Link
                        key={item.route}
                        to={item.route}
                        className={location.pathname === item.route ? "navbar-link active" : "navbar-link"}
                    >
                        {item.label}
                    </Link>
                ))}

                <div className={value === "MAHENDRAS" ? "mahendras-logo" : "platform-logo"}>
                    <img src={imageSource} alt="Emtap's Platform Logo" />
                    <Signout />
                </div>
            </div>
        </div>
    );
};

export default NavBar;
