import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { dateHelper } from "../../utils/helpers/dateHelper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { getTotalUsersCount, getUserViewName } from "../../features/userView/userViewSlice";
import { useDispatch, useSelector } from "react-redux";
import UserViewDetails from "./UserViewDetails";
import Modal from "@mui/material/Modal";
import { getSingleTemplate } from "../../features/templatesView/templatesViewSlice";
import TemplatePreview from "../TemplateComponents/TemplatePreview";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    // overflow: "scroll",
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: "14px 18px",
    // textAlign: "center",
    // color: theme.palette.text.secondary,
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography style={{ padding: "12px 14px" }} variant="body2">
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
}

const SuccessCriteriaDetails = ({ successCriteria }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Typography onClick={handleClick} style={{ cursor: "pointer" }}>
                {successCriteria?.slice(0, 2)?.toString()}
                {successCriteria?.length > 2 && " ..."}
            </Typography>
            {successCriteria?.length > 2 && (
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Typography sx={{ p: 1 }}>
                        <ul>
                            {successCriteria?.map((item) => (
                                <li>{item}</li>
                            ))}
                        </ul>
                    </Typography>
                </Popover>
            )}
        </>
    );
};

const CampaignDetails = ({ handleClose, rowDetails }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const dispatch = useDispatch();
    const previewItem = useSelector((state) => state?.templatesView?.singleTemplateData);

    // TEMPLATE INFO DETAILS
    const [templateOpen, setTemplateOpen] = useState(false);
    const handleTemplateClose = () => setTemplateOpen(false);
    // const [previewItem, setPreviewItem] = useState();

    const handleTemplateDetails = (tempDetails) => {
        dispatch(getSingleTemplate(tempDetails?.template_id));
        // setPreviewItem(tempDetails);
        setTemplateOpen(true);
    };

    // USER VIEW DETAILS INFO
    const [userViewOpen, setuserViewOpen] = useState(false);
    const handleUserViewClose = () => setuserViewOpen(false);

    const handleUserViewDetails = () => {
        dispatch(getUserViewName(rowDetails?.campaign?.user_view_id));
        dispatch(getTotalUsersCount(rowDetails?.campaign?.user_view_id));
        setuserViewOpen(true);
    };

    return (
        <>
            <div className="test-user-modal-header">
                <span>Campaign Details</span>
                <span onClick={handleClose} style={{ cursor: "pointer" }}>
                    <CloseIcon />
                </span>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Item>
                        <Grid container spacing={2}>
                            <Grid item lg={3}>
                                Hypothesis ID :
                            </Grid>
                            <Grid item lg={9}>
                                {rowDetails?.hypothesis_id}
                            </Grid>

                            <Grid item lg={3}>
                                Action :
                            </Grid>
                            <Grid item lg={9}>
                                {rowDetails?.action}
                            </Grid>

                            <Grid item lg={3}>
                                Intent :
                            </Grid>
                            <Grid item lg={9}>
                                {rowDetails?.intent}
                            </Grid>

                            <Grid item lg={3}>
                                Campaign Name :
                            </Grid>
                            <Grid item lg={9}>
                                {rowDetails?.campaign_name}
                            </Grid>

                            <Grid item lg={3}>
                                Created At :
                            </Grid>
                            <Grid item lg={9}>
                                {dateHelper(rowDetails?.created_at)}
                            </Grid>

                            <Grid item lg={3}>
                                Created_by :
                            </Grid>
                            <Grid item lg={9}>
                                {rowDetails?.created_by}
                            </Grid>

                            {rowDetails?.success_criteria && (
                                <>
                                    <Grid item lg={3}>
                                        Success Criteria :
                                    </Grid>

                                    <Grid item lg={9}>
                                        <SuccessCriteriaDetails successCriteria={rowDetails?.success_criteria} />
                                    </Grid>
                                </>
                            )}
                            <Grid item lg={3}>
                                OverRide Limit :
                            </Grid>
                            <Grid item lg={9}>
                                {rowDetails?.override_limit === true ? "Yes" : "No"}
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>

                <Grid item xs={6}>
                    <Item>
                        {rowDetails?.campaign?.user_view_id !== undefined ? (
                            <Grid container spacing={2}>
                                <Grid item lg={3}>
                                    User View ID :
                                </Grid>
                                <Grid item lg={9}>
                                    <div className="campaign-details-info">
                                        <span> {rowDetails?.campaign?.user_view_id}</span>
                                        <InfoIcon
                                            className="campaign-info-icon"
                                            onClick={() => handleUserViewDetails(rowDetails)}
                                        />
                                    </div>
                                </Grid>

                                <Grid item lg={3}>
                                    schedule_type :
                                </Grid>
                                <Grid item lg={9}>
                                    {rowDetails?.campaign?.schedule?.schedule_type}
                                </Grid>

                                {rowDetails?.campaign?.schedule?.start_date && (
                                    <>
                                        <Grid item lg={3}>
                                            Start Date :
                                        </Grid>

                                        <Grid item lg={9}>
                                            {rowDetails?.campaign?.schedule?.start_date}
                                        </Grid>
                                    </>
                                )}

                                {rowDetails?.campaign?.schedule?.end_date && (
                                    <>
                                        <Grid item lg={3}>
                                            End Date :
                                        </Grid>

                                        <Grid item lg={9}>
                                            {rowDetails?.campaign?.schedule?.end_date}
                                        </Grid>
                                    </>
                                )}

                                {rowDetails?.campaign?.schedule?.trigger_time && (
                                    <>
                                        <Grid item lg={3}>
                                            Trigger Time :
                                        </Grid>

                                        <Grid item lg={9}>
                                            {rowDetails?.campaign?.schedule?.trigger_time}
                                        </Grid>
                                    </>
                                )}

                                {rowDetails?.campaign?.schedule?.selections && (
                                    <>
                                        <Grid item lg={3}>
                                            Selections :
                                        </Grid>
                                        <Grid item lg={9}>
                                            {rowDetails?.campaign?.schedule?.selections.toString()}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        ) : (
                            <>No data found</>
                        )}
                    </Item>
                </Grid>

                <Grid item xs={12}>
                    <Item>
                        <Grid container spacing={2}>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        {rowDetails?.linked_templates?.map((item, index) => (
                                            <Tab label={`LT ${index + 1}`} />
                                        ))}
                                    </Tabs>
                                </Box>
                                {rowDetails?.linked_templates?.map((item, index) => (
                                    <TabPanel value={value} index={index}>
                                        <Grid container spacing={2}>
                                            <Grid container xs={6}>
                                                <Grid item lg={3} p={1}>
                                                    Template ID :
                                                </Grid>
                                                <Grid item lg={9} p={1}>
                                                    <div className="campaign-details-info">
                                                        <span>{item?.template_id}</span>
                                                        <InfoIcon
                                                            className="campaign-info-icon"
                                                            onClick={() => handleTemplateDetails(item)}
                                                        />
                                                    </div>
                                                </Grid>

                                                <Grid item lg={3} p={1}>
                                                    Fact Type :
                                                </Grid>
                                                <Grid item lg={9} p={1}>
                                                    {item?.fact_type}
                                                </Grid>

                                                <Grid item lg={3} p={1}>
                                                    Weightage :
                                                </Grid>
                                                <Grid item lg={9} p={1}>
                                                    {item?.weightage}
                                                </Grid>

                                                <Grid item lg={3} p={1}>
                                                    Priority :
                                                </Grid>
                                                <Grid item lg={9} p={1}>
                                                    {item?.priority}
                                                </Grid>
                                            </Grid>
                                            <Grid container xs={6}>
                                                {item?.custom_deep_link_details?.screen_name && (
                                                    <>
                                                        <Grid item lg={3}>
                                                            Screen Name :
                                                        </Grid>
                                                        <Grid item lg={9}>
                                                            {item?.custom_deep_link_details?.screen_name}
                                                        </Grid>
                                                    </>
                                                )}

                                                {item?.custom_deep_link_details?.desktop_url && (
                                                    <>
                                                        <Grid item lg={3}>
                                                            Desktop URL :
                                                        </Grid>
                                                        <Grid item lg={9}>
                                                            {item?.custom_deep_link_details?.desktop_url}
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.custom_deep_link_details?.tags && (
                                                    <>
                                                        <Grid item lg={3}>
                                                            Tags :
                                                        </Grid>
                                                        <Grid item lg={9}>
                                                            {item?.custom_deep_link_details?.tags}
                                                        </Grid>
                                                    </>
                                                )}
                                                {item?.custom_deep_link_details?.screen_name && (
                                                    <>
                                                        <Grid item lg={3}>
                                                            Custom Deep Link :
                                                        </Grid>
                                                        <Grid item lg={9}>
                                                            {JSON.stringify(
                                                                item?.custom_deep_link_details?.custom_data
                                                            )}
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                ))}
                            </Box>
                        </Grid>
                    </Item>
                </Grid>
            </Grid>

            {/* USER VIEW DETAILS MODAL */}
            <Modal
                open={userViewOpen}
                onClose={handleUserViewClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="search-modal-header">
                        <span>User View Details</span>
                        <span onClick={handleUserViewClose}>x</span>
                    </div>
                    <UserViewDetails />
                </Box>
            </Modal>

            {/* TEMPLATE DETAILS MODAL */}
            <TemplatePreview
                open={templateOpen}
                setOpen={setTemplateOpen}
                handlePreviewClose={handleTemplateClose}
                previewItem={previewItem}
                templateDetails="temp"
            />
        </>
    );
};

export default CampaignDetails;
