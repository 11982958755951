import React, { useState } from "react";
import InputField from "../../../../components/InputField";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../../components/Button";

const EditPriorityAndweightage = ({
    item,
    handlePriorityChange,
    handleWeightageChange,
    handleClose,
    handleUpdateData,
}) => {
    return (
        <>
            <div>
                <div style={{ float: "right", cursor: "pointer" }}>
                    <CloseIcon onClick={handleClose} />
                </div>
                <div>
                    <div style={{ padding: "8px 0" }}>
                        <b>Name</b> : {item?.template_name}
                    </div>
                    <div style={{ padding: "8px 0" }}>Channel : {item?.channel}</div>
                    <div style={{ padding: "8px 0" }}>Priority : {item?.priority}</div>
                    <div style={{ padding: "8px 0" }}>Weightage : {item?.weightage}</div>
                </div>
                <InputField
                    id="outlined-helperText"
                    label="Priority"
                    variant="outlined"
                    type="number"
                    handleChange={handlePriorityChange}
                    // value={newPriority}
                />
                <InputField
                    id="outlined-helperText"
                    label="Weightage"
                    variant="outlined"
                    type="number"
                    handleChange={handleWeightageChange}
                    //  value={weightage}
                />
                <div style={{ marginTop: "36px", textAlign: "center" }}>
                    <CustomButton buttonText="Update" variant="outlined" handleClick={() => handleUpdateData(item)} />
                </div>
            </div>
        </>
    );
};

export default EditPriorityAndweightage;
