import { toast } from "react-toastify";

export const toastError = (error) => {
    const errorCode = error?.response?.status;
    if (errorCode.toString().startsWith("4")) {
        const { message, message_code } = error.response.data;
        // toast.error(`${message_code}`);
        toast.error(`${message}`);
    } else if (errorCode.toString().startsWith("5")) {
        toast.error("Something went wrong");
    }
};

export const toastSuccess = () => {
    toast.success("Successfully Submitted");
};

export const toastSuccessDelete = () => {
    toast.success("Successfully Deleted");
};
