import React from "react";
import "./index.css";
import ShareIcon from "@mui/icons-material/IosShareOutlined";

const WAPreview = ({ waBody, waImageLink, waCtaLink, previewItem }) => {
    return (
        <>
            {waBody !== "" || waImageLink !== "" ? (
                <div className="wa-preview-container">
                    <div className="wa-preview-body">
                        <div className="wa-img-body">
                            {(waImageLink || previewItem?.message_details?.image_link) && (
                                <div className="wa-preview-image">
                                    <img src={waImageLink || previewItem?.message_details?.image_link} />
                                </div>
                            )}
                            <div className="wa-body">{waBody || previewItem?.message_details?.body}</div>
                        </div>
                        {(waCtaLink || previewItem?.default_template_variables_values?.cta_link) && (
                            <div className="wa-cta">
                                <a href={waCtaLink} target="_blank">
                                    <ShareIcon className="wa-cta-icon" />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default WAPreview;
