import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import testUserListService from "./testUserListService";

const initialState = {
    testUserList: [],
    testUserData: [],
    testUserMsg: [],
    isTestUserLoadingList: false,
    isTestUserLoading: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

export const getTestUserList = createAsyncThunk("getTestUserList/get", async (application,thunkAPI) => {
    try {
        return await testUserListService.getTestUserList(application);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const addTestUserList = createAsyncThunk("addTestUserList/post", async (data, thunkAPI) => {
    try {
        return await testUserListService.addTestUserListAPI(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteTestUserList = createAsyncThunk("deleteTestUserList/delete", async (id, thunkAPI) => {
    try {
        return await testUserListService.deleteTestUserListAPI(id);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getTestUser = createAsyncThunk("getTestUser/get", async (id, thunkAPI) => {
    try {
        return await testUserListService.getTestUserAPI(id);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const addTestUser = createAsyncThunk("addTestUser/post", async (id, data, thunkAPI) => {
    try {
        return await testUserListService.addTestUserAPI(id, data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteTestUser = createAsyncThunk("deleteTestUser/delete", async (id, profileID, thunkAPI) => {
    try {
        return await testUserListService.deleteTestUserAPI(id, profileID);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const testUserListSlice = createSlice({
    name: "testUserList",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
        resetTestUserData: (state) => {
            state.testUserData = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTestUserList.pending, (state) => {
                state.isTestUserLoadingList = true;
            })
            .addCase(getTestUserList.fulfilled, (state, action) => {
                state.isTestUserLoadingList = false;
                state.isSuccess = true;
                state.testUserList = action.payload;
            })
            .addCase(getTestUserList.rejected, (state, action) => {
                state.isTestUserLoadingList = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(addTestUserList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addTestUserList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.testUserList.content = [...state?.testUserList?.content, action.payload];
            })
            .addCase(addTestUserList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(deleteTestUserList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteTestUserList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.testUserList.content = state.testUserList?.content.filter(
                    (item) => item.users_list_id !== action.payload.resource_id
                );
            })
            .addCase(deleteTestUserList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getTestUser.pending, (state) => {
                state.isTestUserLoading = true;
            })
            .addCase(getTestUser.fulfilled, (state, action) => {
                state.isTestUserLoading = false;
                state.isSuccess = true;
                state.testUserData = action.payload;
            })
            .addCase(getTestUser.rejected, (state, action) => {
                state.isTestUserLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(addTestUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addTestUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.testUserMsg = action.payload;
            })
            .addCase(addTestUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(deleteTestUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteTestUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                // state.testUserData.content = state.testUserData?.filter(
                //     (item) => item.profile_id !== action.payload.resource_id
                // );
            })
            .addCase(deleteTestUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});

export const { reset, resetTestUserData } = testUserListSlice.actions;
export default testUserListSlice.reducer;
