import React, { useState } from "react";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Signout = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleLogout = async () => {
        setIsLoading(true);
        localStorage.clear();
        sessionStorage.clear();
        toast.success("Logged Out");
        navigate("/");
        setTimeout(() => {
            document.location.reload();
        }, 1000);
        setIsLoading(false);
    };

    return (
        <>
            <Button
                variant="text"
                onClick={handleLogout}
                sx={{ color: "#fff" }}
                className="logout-btn"
                disabled={isLoading}
            >
                <LogoutIcon />
                {isLoading ? "Loading..." : "Logout"}
            </Button>
        </>
    );
};

export default Signout;
