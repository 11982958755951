import { useState, useEffect } from "react";
import InputField from "../../../../components/InputField";
import { useSelector } from "react-redux";
import { isTimeValid } from "../helper/TimeCheckHelper";

const TriggerTime = ({ userViewEditDetails, handleUserViewChange, isOnce }) => {
    const [triggerTime, setTriggerTime] = useState();
    const [startDate, setstartDate] = useState();
    const { dates } = useSelector((state) => state?.userView);

    useEffect(() => {
        dates.startDate && setstartDate(dates.startDate);
    }, [dates]);

    useEffect(() => {
        if (userViewEditDetails?.schedule?.trigger_time) {
            setTriggerTime(userViewEditDetails?.schedule?.trigger_time);
        }
    }, []);

    const handleTriggerTime = (e) => {
        setTriggerTime(e.target.value);
        handleUserViewChange(e);
    };

    const handleTimeChange = (e) => {
        isOnce ? isTimeValid(e, startDate, handleUserViewChange) : handleUserViewChange(e);
    };

    return (
        <div className="user-view-input-wrapper">
            <div>Time</div>
            {userViewEditDetails?.schedule?.start_date ? (
                <InputField
                    id="outlined-helperText"
                    variant="outlined"
                    name="time"
                    type="time"
                    value={triggerTime ?? ""}
                    handleChange={(e) => handleTriggerTime(e)}
                />
            ) : (
                <InputField
                    id="outlined-helperText"
                    variant="outlined"
                    name="time"
                    type="time"
                    handleChange={(e) => handleTimeChange(e)}
                />
            )}
        </div>
    );
};

TriggerTime.defaultProps = {
    isOnce: false,
};

export default TriggerTime;
