import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import createCampaignService from "./createCampaignService";

const initialState = {
    hypothesisID: "",
    createdHypothesisData: {},
    allHypothesisData: [],
    clonedData: {},
    searchedCampaignData: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

export const createCampaignID = createAsyncThunk("createCampaign/create", async (createCampaignData, thunkAPI) => {
    try {
        return await createCampaignService.createCampaignHypothesisID(createCampaignData);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getAllHypothesis = createAsyncThunk("getAllHypothesis/get", async (data, thunkAPI) => {
    try {
        return await createCampaignService.getAllHypothesisAPI(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const updateIntentAction = createAsyncThunk("updateIntentAction/put", async (id, data, thunkAPI) => {
    try {
        return await createCampaignService.updateActionIntentAPI(id, data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// CLONE HYPOTHESIS
export const cloneHypothesis = createAsyncThunk("cloneHypothesis/put", async (id, thunkAPI) => {
    try {
        return await createCampaignService.cloneHypothesisAPI(id);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// SEARCH CAMPAIGN
export const searchCampaign = createAsyncThunk("searchCampaignAPI/get", async (data, thunkAPI) => {
    try {
        return await createCampaignService.searchCampaignAPI(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const createCampaignSlice = createSlice({
    name: "createCampaign",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
            state.hypothesisID = "";
        },
        resetHypotheisData: (state) => {
            state.createdHypothesisData = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCampaignID.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createCampaignID.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.hypothesisID = action.payload.hypothesis_id;
                state.createdHypothesisData = action.payload;
            })
            .addCase(createCampaignID.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getAllHypothesis.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllHypothesis.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.allHypothesisData = action.payload;
            })
            .addCase(getAllHypothesis.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(cloneHypothesis.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(cloneHypothesis.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.clonedData = action.payload;
            })
            .addCase(cloneHypothesis.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(searchCampaign.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(searchCampaign.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.searchedCampaignData = action.payload;
            })
            .addCase(searchCampaign.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});

export const { reset, resetHypotheisData } = createCampaignSlice.actions;
export default createCampaignSlice.reducer;
