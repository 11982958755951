import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReachData } from "../../../../features/home/homeSlice";
import Spinner from "../../../../components/Spinner";

const Reach = () => {
    const dispatch = useDispatch();
    const reachData = useSelector((state) => state?.home?.reachData);
    const isReachLoading = useSelector((state) => state?.home?.isReachLoading);
    const reach = reachData?.map((item) => item.reach);
    const totalReach = reach.reduce((acc, curr) => acc + curr, 0);
    const noWebReach = totalReach - reach[2];
    const noMpushReach = totalReach - reach[1];
    // 0:"WA"
    // 1:"PN_USERS"
    // 2:"PN_DEVICES"
    // 3:"EM"
    // 4:"SMS"

    const value = useSelector((state) => state.value);
    useEffect(() => {
        dispatch(getReachData());
    }, []);

    return (
        <>
            <h2 className="home-reach-header">Embibe Platform Reachability</h2>
            <div className="boxContainer">
                {isReachLoading ? (
                    <div className="spinner-container-reach">
                        <Spinner />
                    </div>
                ) : (
                    <table className="tableMain">
                        <tbody>
                            <tr
                                className={
                                    value === "STUDENT"
                                        ? "header stdTable"
                                        : value === "LENS"
                                        ? "header lnsTable"
                                        : value === "PARENT"
                                        ? "header prtTable"
                                        : value === "EXPERIMENT"
                                        ? "header vleTable"
                                        : value === "SCHOOL"
                                        ? "header schTable"
                                        : value === "CLASSROOM"
                                        ? "header clsTable"
                                        : value === "MAHENDRAS"
                                        ? "header mahTable"
                                        : value === "UP_LENS"
                                        ? "header uplTable"
                                        : "header"
                                }
                            >
                                <td className="tableContent">Overall</td>
                                {value !== "SCHOOL" && <td className="tableContent">M-push</td>}
                                {value !== "PARENT" &&
                                    value !== "LENS" &&
                                    value !== "EXPERIMENT" &&
                                    value !== "UP_LENS" && <td className="tableContent">Web-push</td>}
                                <td className="tableContent">WhatsApp</td>
                                <td className="tableContent">Email</td>
                                <td className="tableContent">SMS</td>
                            </tr>
                            <tr>
                                <td className="tableContent">
                                    {value === "SCHOOL"
                                        ? noMpushReach
                                        : value === "PARENT"
                                        ? noWebReach
                                        : value === "LENS"
                                        ? noWebReach
                                        : value === "UP_LENS"
                                        ? noWebReach
                                        : value === "EXPERIMENT"
                                        ? noWebReach
                                        : totalReach}
                                </td>
                                {value !== "SCHOOL" && <td className="tableContent">{reach[1]}</td>}
                                {value !== "PARENT" &&
                                    value !== "LENS" &&
                                    value !== "EXPERIMENT" &&
                                    value !== "UP_LENS" && <td className="tableContent">{reach[2]}</td>}

                                <td className="tableContent">{reach[0]}</td>
                                <td className="tableContent">{reach[3]}</td>
                                <td className="tableContent">{reach[4]}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );
};

export default Reach;

//reach table (FYI further changes would be done)
