import { useState, useEffect } from "react";
import CustomButton from "../../components/Button";
import CreateTemplate from "./components/CreateTemplate";
import SearchTemplate from "./components/SearchTemplate";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./index.css";
import TemplateTable from "./components/TemplateTable.js";
import { useDispatch, useSelector } from "react-redux";
import {
    linkTemplate,
    deleteTemplate,
    updateTemplate,
    reset,
    resetSearchData,
    getAllLinkedTemps,
    getFactType,
    getFactTypeData,
    getFactTypeVars,
} from "../../features/searchTemplate/searchTemplateSlice";
// import EmailTemplate from "./components/CreateTemplate/components/Email";
import EmailTemplate from "../TemplateComponents/EmailTemplate";
// import SMSTemplate from "./components/CreateTemplate/components/SMS";
import SMSTemplate from "../TemplateComponents/SMSTemplate";
// import WhatsAppTemplate from "./components/CreateTemplate/components/WhatsApp";
import WhatsAppTemplate from "../TemplateComponents/WATemplate";
// import PushNotificationTemplate from "./components/CreateTemplate/components/m-Push";
import PushNotificationTemplate from "../TemplateComponents/PushNTemplate";
import { useNavigate } from "react-router-dom";
import { getUserViews } from "../../features/userView/userViewSlice";
import InputField from "../../components/InputField";
import Popper from "@mui/material/Popper";
import BackArrow from "@mui/icons-material/ArrowBackIos";
import { Link, useParams } from "react-router-dom";
import { getHypothesis } from "../../features/searchTemplate/searchTemplateSlice";
import { getAllHypothesis } from "../../features/createCampaign/createCampaignSlice";
import InAppNotification from "../TemplateComponents/InAppNotification";
import InAppPopupNotification from "../TemplateComponents/InAppPopupNotification.js";

const templateBtnSelected = {
    backgroundColor: "rgb(41, 8, 102)",
    color: "#fff",
    border: "none",
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
};

const Template = () => {
    const [templateType, setTemplateType] = useState();

    const hypothesisID = localStorage.getItem("hypothesisID");
    const editHypothesisID = localStorage.getItem("editHypothesisID");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const value = useSelector((state) => state.value);
    // MODAL OPEN/CLOSE FUNCTIONS
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
        dispatch(reset());
        dispatch(resetSearchData());
        // window.location.reload();
        dispatch(getHypothesis(hypothesisID || editHypothesisID));
    };

    const [priority, setPriority] = useState();
    const [weightage, setWeightage] = useState();

    const allLinkedTemplates = useSelector((state) => state?.template?.linkedTemplates);
    // const allLinkedTemplates = useSelector((state) => state?.template?.allLinkedTemplates);
    // const allLinkedTemplates = JSON.parse(localStorage.getItem("linkedState"));
    useEffect(() => {
        if (hypothesisID) {
            dispatch(getHypothesis(hypothesisID));
        }
    }, [hypothesisID]);

    // ******************************************** SEARCH TEMPLATE

    //ADD TEMPLATE TO THE TABLE
    const handleAddTemplate = (id, factTypeSelected, successCb, errorCb) => {
        const storageDeepLinkData = localStorage.getItem("deepLinkData");
        const deepLinkData = JSON.parse(storageDeepLinkData);
        const hypothesisID = localStorage.getItem("hypothesisID");
        const editHypothesisID = localStorage.getItem("editHypothesisID");
        let data = {
            priority: 1,
            weightage: 100,
            template_id: id,
            fact_type: factTypeSelected,
            custom_deep_link_details: {
                screen_name: deepLinkData?.screenName,
                custom_data: deepLinkData?.customDataJSON,
                tags: deepLinkData?.tags,
                desktop_url: deepLinkData?.desktopUrl,
                deep_link: deepLinkData?.deep_link,
            },
            application: value,
        };
        let linkTemplateData;

        if (editHypothesisID) {
            linkTemplateData = {
                hypothesisID: editHypothesisID,
                data,
                successCb,
                errorCb,
            };
            dispatch(linkTemplate(linkTemplateData));
            localStorage.removeItem("deepLinkData");
        } else {
            linkTemplateData = {
                hypothesisID,
                data,
                successCb,
                errorCb,
            };
            dispatch(linkTemplate(linkTemplateData));
            localStorage.removeItem("deepLinkData");
        }
    };

    // UPDATE PRIORITY AND WEIGHTAGE IN THE TEMPLATE ROW
    const [isSuccess, setIsSuccess] = useState();
    const successCb = (val) => {
        setIsSuccess(val);
    };

    const updateTemplateData = (item) => {
        let data = {
            priority: priority?.id === item?.id ? parseInt(priority?.priority) : item?.priority,
            weightage: weightage?.id === item?.id ? parseInt(weightage?.weightage) : item?.weightage,
            template_id: item?.template_id,
            fact_type: item?.fact_type,
            custom_deep_link_details: {
                screen_name: item?.custom_deep_link_details?.screen_name,
                custom_data: item?.custom_deep_link_details?.custom_data,
                tags: item?.custom_deep_link_details?.tags,
                desktop_url: item?.custom_deep_link_details?.desktop_url,
            },
        };

        let updateTempId = item?.id;

        if (editHypothesisID) {
            const updateTemplateData = {
                hypothesisID: editHypothesisID,
                updateTempId,
                data,
                successCb,
            };
            dispatch(updateTemplate(updateTemplateData));
            // setTimeout(() => {
            //     if (isSuccess === 200) {
            //         dispatch(getHypothesis(hypothesisID || editHypothesisID));
            //         window.location.reload();
            //     }
            // }, 1000);
        } else {
            const updateTemplateData = {
                hypothesisID,
                updateTempId,
                data,
                successCb,
            };
            dispatch(updateTemplate(updateTemplateData));
            // setTimeout(() => {
            //     if (isSuccess === 200) {
            //         dispatch(getHypothesis(hypothesisID || editHypothesisID));
            //         window.location.reload();
            //     }
            // }, 1000);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (isSuccess === 200) {
                dispatch(getHypothesis(hypothesisID || editHypothesisID));
                window.location.reload();
            }
        }, 1000);
    }, [isSuccess]);

    //******************************************************* DELETE TEMPLATE ROW
    const deleteTemplateRow = (id) => {
        if (editHypothesisID) {
            let deleteData = { hypothesisID: editHypothesisID, id };
            dispatch(deleteTemplate(deleteData));
            // window.location.reload();
        } else {
            let deleteData = { hypothesisID, id };
            dispatch(deleteTemplate(deleteData));
            // window.location.reload();
        }
    };

    // ******************************************** CREATE TEMPLATE
    const [createTempChannel, setCreateTempChannel] = useState("");

    const handleCreateTempChannel = (event) => {
        setCreateTempChannel(event.target.value);
    };

    const handleCreateModalClose = () => {
        handleClose();
        setCreateTempChannel("");
        localStorage.removeItem("createdTempData");
    };

    // PROCEED NEXT BUTTON CLICK
    const handleProceedNext = () => {
        navigate("/campaign-details");
        // dispatch(getUserViews());
    };

    // GETTING AND SETTING THE FACT TYPES AND FACT TYPE VARS
    const factTypes = useSelector((state) => state?.template?.factTypes);
    const factTypeVars = useSelector((state) => state?.template?.factTypeVars);
    useEffect(() => {
        dispatch(getFactType(value));
        dispatch(getFactTypeVars(value));
        // localStorage.setItem("factTypes", JSON.stringify(factTypes));
        // localStorage.setItem("factTypeVars", JSON.stringify(factTypeVars));
    }, [value]);

    localStorage.setItem("factTypes", JSON.stringify(factTypes));
    localStorage.setItem("factTypeVars", JSON.stringify(factTypeVars));

    let hypothesisId = localStorage.getItem("editHypothesisID");
    useEffect(() => {
        if (hypothesisId) {
            dispatch(getHypothesis(hypothesisId));
        }
    }, []);

    return (
        <div className="template-wrapper">
            <Link to="/create-campaign">
                <BackArrow />
            </Link>
            <div className="template-button-wrapper">
                {/* SEARCH AND CREATE BUTTONS */}
                <CustomButton
                    buttonText="Search Template"
                    variant="outlined"
                    style={templateType === "search" ? templateBtnSelected : {}}
                    handleClick={() => {
                        setTemplateType("search");
                        setOpen(true);
                    }}
                />
                <CustomButton
                    buttonText="Create Template"
                    variant="outlined"
                    style={templateType === "create" ? templateBtnSelected : {}}
                    handleClick={() => {
                        setTemplateType("create");
                        setOpen(true);
                    }}
                />
            </div>

            {/* SHOWING THE TEMPLATES IN A TABLE */}
            <TemplateTable
                allLinkedTemplates={allLinkedTemplates}
                updateTemplateData={updateTemplateData}
                deleteTemplateRow={deleteTemplateRow}
                setWeightage={setWeightage}
                setPriority={setPriority}
            />

            {/* SEARCH TEMPLATE MODAL */}
            {templateType === "search" && (
                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="search-modal-header">
                            <span>Search Template</span>
                            <span onClick={handleClose}>x</span>
                        </div>
                        <SearchTemplate handleAddTemplate={handleAddTemplate} />
                    </Box>
                </Modal>
            )}

            {/* CREATE TEMPLATE MODAL */}
            {templateType === "create" && (
                <Modal
                    open={open}
                    onClose={handleCreateModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="search-modal-header">
                            <span>Create Template</span>
                            <span onClick={handleCreateModalClose}>x</span>
                        </div>
                        <CreateTemplate
                            handleCreateTempChannel={handleCreateTempChannel}
                            createTempChannel={createTempChannel}
                        />
                        {createTempChannel === "EM" && (
                            // <EmailTemplate handleClose={handleClose} setCreateTempChannel={setCreateTempChannel} />
                            <EmailTemplate handleClose={handleClose} setCreateTempChannel={setCreateTempChannel} />
                        )}
                        {createTempChannel === "SMS" && (
                            // <SMSTemplate handleClose={handleClose} setCreateTempChannel={setCreateTempChannel} />
                            <SMSTemplate handleClose={handleClose} setCreateTempChannel={setCreateTempChannel} />
                        )}
                        {createTempChannel === "WA" && (
                            // <WhatsAppTemplate handleClose={handleClose} setCreateTempChannel={setCreateTempChannel} />
                            <WhatsAppTemplate handleClose={handleClose} setCreateTempChannel={setCreateTempChannel} />
                        )}
                        {createTempChannel === "PN" && (
                            // <PushNotificationTemplate
                            //     handleClose={handleClose}
                            //     setCreateTempChannel={setCreateTempChannel}
                            // />
                            <PushNotificationTemplate
                                handleClose={handleClose}
                                setCreateTempChannel={setCreateTempChannel}
                            />
                        )}
                        {createTempChannel === "IA" && (
                            // <PushNotificationTemplate
                            //     handleClose={handleClose}
                            //     setCreateTempChannel={setCreateTempChannel}
                            // />
                            <InAppNotification handleClose={handleClose} setCreateTempChannel={setCreateTempChannel} />
                        )}
                        {createTempChannel === "IA_POPUP" && (
                            <InAppPopupNotification
                                handleClose={handleClose}
                                setCreateTempChannel={setCreateTempChannel}
                            />
                        )}
                    </Box>
                </Modal>
            )}

            <div className="template-btn-next">
                <CustomButton
                    buttonText="Proceed to Next"
                    variant="outlined"
                    disabled={allLinkedTemplates?.length === 0 ? true : false}
                    handleClick={handleProceedNext}
                />
            </div>
        </div>
    );
};

export default Template;
