import React from "react";
import "./index.css";

const SMSPreview = ({ smsBody, previewItem }) => {
    return (
        <>
            {smsBody !== "" ? (
                <div className="sms-preview-container">
                    <div className="sms-preview-body">{smsBody || previewItem?.message_details?.body}</div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default SMSPreview;
