import React from "react";
import InputField from "../InputField";
import CustomButton from "../Button";
import { useState } from "react";
import { createImageLink } from "../../features/searchTemplate/searchTemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export const ImgUpload = ({ imageLink, setImageLink, channelType, templateData }) => {
    const [openUploadImageInput, setopenUploadImageInput] = useState(false);

    const { imageDetails } = useSelector((state) => state?.template);
    const dispatch = useDispatch();

    useEffect(() => {
        setImageLink(imageDetails.imageUrl ? imageDetails.imageUrl : templateData?.message_details?.image_link);
    }, [imageDetails]);

    const handleUploadImage = (e) => {
        let formData = new FormData(); //formdata object
        formData.append("file", e.target.files[0]);

        let data = {
            formData,
            channelType,
        };
        dispatch(createImageLink(data));
    };

    return (
        <div>
            Upload Image
            <div style={{ marginBottom: "16px", marginTop: "8px" }}>
                <CustomButton
                    buttonText="Upload Image"
                    variant="contained"
                    handleClick={() => setopenUploadImageInput(true)}
                    // disabled={item?.channel === "EM" ? true : false}
                />

                {openUploadImageInput && (
                    <input type="file" style={{ marginLeft: "10px" }} onChange={handleUploadImage} />
                )}
            </div>
            <InputField
                id="outlined-helperText"
                label="Image Link"
                variant="outlined"
                handleChange={(e) => setImageLink(e.target.value)}
                value={imageLink}
                // isRequired={true}
            />
        </div>
    );
};
