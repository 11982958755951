import Reach from "./components/Reach/Reach";
import Overall from "./components/Overall/Overall";
import CampaignWise from "./components/CampaignWise/CampaignWise";
import ScheduleFeature from "./components/ScheduleFeature";
import "./index.css";
import { useSelector } from "react-redux";
import appConfig from "../../config/app.config";

const Home = () => {
    const value = useSelector((state) => state.value);
    return (
        <div className="home-container">
            {appConfig[value].PLATFORM_REACHABLITY_ENABLED === true ? <Reach /> : <></>}
            <Overall />
            <CampaignWise />
            <ScheduleFeature />
        </div>
    );
};

export default Home;
