import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import TemplateTableRow from "./TemplateTableRow";
import TablePagination from "@mui/material/TablePagination";

const TemplateTable = ({ allLinkedTemplates, setWeightage, setPriority, updateTemplateData, deleteTemplateRow }) => {
    // PAGINATION
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // PAGE NUMBER CHANGE - pages 1,2,...9,10
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // NO OF ROWS PER PAGE COUNT - 5, 10, 25
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    return (
        <>
            <TableContainer sx={{ maxHeight: 440 }} style={{ marginTop: "54px" }}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead className="template-table-head">
                        <TableRow>
                            <TableCell>Template</TableCell>
                            <TableCell>Channel</TableCell>
                            <TableCell>Priority</TableCell>
                            <TableCell>Weightage</TableCell>
                            <TableCell>Unlink</TableCell>
                            {/* {showUpdateBtn && <TableCell align="center">Update</TableCell>} */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allLinkedTemplates
                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            ?.map((item) => (
                                <TemplateTableRow
                                    key={item?.id}
                                    item={item}
                                    setWeightage={setWeightage}
                                    setPriority={setPriority}
                                    updateTemplateData={updateTemplateData}
                                    deleteTemplateRow={deleteTemplateRow}
                                    showUpdateBtn={showUpdateBtn}
                                    setShowUpdateBtn={setShowUpdateBtn}
                                />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {allLinkedTemplates?.length > 0 ? (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={allLinkedTemplates?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ marginBottom: "50px" }}
                />
            ) : (
                ""
            )}
        </>
    );
};

export default TemplateTable;
