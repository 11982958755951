import axios from "axios";
import { toastError } from "../../utils/helpers/toastError";
import { BASE_URL } from "../../utils/constants/apiBaseURL.constants";

// GET TEST USER LIST
const getTestUserList = async (application) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/users-list?application=${application}&page=0&pageSize=1000`);
        if (response?.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// ADD TEST USER LIST
const addTestUserListAPI = async ({ data, successCb }) => {
    try {
        const response = await axios.post(`${BASE_URL}/user-cohort-ms/users-list`, data);
        if (response?.status === 201) {
            successCb(response?.status);
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// DELETE TEST USER LIST
const deleteTestUserListAPI = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/user-cohort-ms/users-list/${id}`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET TEST USER
const getTestUserAPI = async (id) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/users-list/${id}/users`);
        if (response?.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// ADD TEST USER
const addTestUserAPI = async ({ id, data, successCb }) => {
    try {
        const response = await axios.post(`${BASE_URL}/user-cohort-ms/users-list/${id}/users`, data);
        if (response?.status === 200) {
            successCb(response.data.payload);
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// DELETE TEST USER
const deleteTestUserAPI = async ({ userId, payload }) => {
    try {
        const response = await axios.delete(`${BASE_URL}/user-cohort-ms/users-list/${userId}/users`, { data: payload });
        if (response?.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

const testUserListService = {
    getTestUserList,
    addTestUserListAPI,
    deleteTestUserListAPI,
    getTestUserAPI,
    addTestUserAPI,
    deleteTestUserAPI,
};
export default testUserListService;
