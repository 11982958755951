import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import searchTemplateService from "./searchTemplateService";

const initialState = {
    searchedTemplateContent: {},
    singlelinkedTemplate: {},
    allLinkedTemplates: [],
    linkedTemplates: [],
    updatedTemplate: {},
    createdTemplateData: {},
    hypohesisData: {},
    factTypes: {},
    factTypeData: {},
    factTypeVars: {},
    hypothesisDataCampaignTbl: {},
    isCreateTemplateSuccess: "",
    isGetHypothesisSuccess: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
    imageDetails: {},
    message: "",
};

// SEARCH TEMPLATE
export const searchTemplateContent = createAsyncThunk("searchTemplate/get", async (data, thunkAPI) => {
    try {
        return await searchTemplateService.searchTemplateContent(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// LINK A SINGLE TEMPLATE
export const linkTemplate = createAsyncThunk("linkTemplate/post", async (hypothesisID, templateData, thunkAPI) => {
    try {
        return await searchTemplateService.linkSearchTemplate(hypothesisID, templateData);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// UPDATE TEMPLATE
export const updateTemplate = createAsyncThunk("updateTemplate/put", async (hypothesisID, templateID, thunkAPI) => {
    try {
        return await searchTemplateService.updateTemplate(hypothesisID, templateID);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// DELETE A SINGLE TEMPLATE
export const deleteTemplate = createAsyncThunk("deleteTemplate/delete", async (hypothesisID, templateID, thunkAPI) => {
    try {
        return await searchTemplateService.deleteTemplateAPI(hypothesisID, templateID);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// CREATE TEMPLATE
export const createTemplate = createAsyncThunk("createTemplate/post", async (templateData, thunkAPI) => {
    try {
        return await searchTemplateService.createTemplateAPI(templateData);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// GET SINGLE HYPOTHESIS DATA
export const getHypothesis = createAsyncThunk("getHypothesis/get", async (id, thunkAPI) => {
    try {
        return await searchTemplateService.getHypothesisAPI(id);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
// GET SINGLE HYPOTHESIS DATA FOR CAMPAIGN WISE TABLE
export const getHypothesisDataCampaignTbl = createAsyncThunk(
    "getHypothesisDataCampaignTbl/get",
    async (id, thunkAPI) => {
        try {
            return await searchTemplateService.getHypothesisAPI(id);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// GET FACT TYPE
export const getFactType = createAsyncThunk("getFactType/get", async (application,thunkAPI) => {
    try {
        return await searchTemplateService.getFactTypeAPI(application);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// GET FACT TYPE DATA
export const getFactTypeData = createAsyncThunk("getFactTypeData/get", async (factType, thunkAPI) => {
    try {
        return await searchTemplateService.getFactTypeDataAPI(factType);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// EDIT TEMPLATE
export const editTemplate = createAsyncThunk("editTemplate/get", async (templateId, data, thunkAPI) => {
    try {
        return await searchTemplateService.editTemplateAPI(templateId, data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// GET ALL FACT TYPE VARS
export const getFactTypeVars = createAsyncThunk("getFactTypeVars/get", async (application,thunkAPI) => {
    try {
        return await searchTemplateService.getFactTypeVarsAPI(application);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// //GET IMAGE LINK FROM IMAGE
export const createImageLink = createAsyncThunk("createImageLink/post", async (data, thunkAPI) => {
    try {
        return await searchTemplateService.createImageLinkAPI(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// SEARCH TEMPLATE SLICE
export const searchTemplateSlice = createSlice({
    name: "searchTemplate",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
            state.templateContent = [];
            state.singlelinkedTemplate = {};
            state.createdTemplateData = {};
        },
        resetSearchData: (state) => {
            state.searchedTemplateContent = {};
        },
        resetLinkedTempData: (state) => {
            state.allLinkedTemplates = [];
        },
        resetHypothesisData: (state) => {
            state.hypohesisData = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchTemplateContent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(searchTemplateContent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.searchedTemplateContent = action.payload;
            })
            .addCase(searchTemplateContent.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getHypothesis.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getHypothesis.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isGetHypothesisSuccess = true;
                state.hypohesisData = action.payload;
                state.linkedTemplates = action.payload.linked_templates;
            })
            .addCase(getHypothesis.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            // .addCase(linkTemplate.pending, (state) => {
            //     state.isLoading = true;
            // })
            // .addCase(linkTemplate.fulfilled, (state, action) => {
            //     state.isLoading = false;
            //     state.isSuccess = true;
            //     state.singlelinkedTemplate = action.payload;
            //     state.allLinkedTemplates.push(state.singlelinkedTemplate);
            //     // localStorage.setItem("linkedState", JSON.stringify(state.allLinkedTemplates));
            // })
            // .addCase(linkTemplate.rejected, (state, action) => {
            //     state.isLoading = false;
            //     state.isError = true;
            //     state.message = action.payload;
            // })
            .addCase(updateTemplate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateTemplate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.updatedTemplate = action.payload;
                const index = state.allLinkedTemplates.findIndex((temp) => temp.id === action.payload.id);

                // if (index >= 0) {
                //     const tempArr = [...state.linkedTemplates];
                //     tempArr[index] = action.payload;
                //     state.linkedTemplates = tempArr;
                // }
            })
            .addCase(updateTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(deleteTemplate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteTemplate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.linkedTemplates = state.linkedTemplates.filter((item) => item.id !== action.payload.resource_id);
            })
            .addCase(deleteTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(createTemplate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createTemplate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isCreateTemplateSuccess = action.payload.success;
                state.createdTemplateData = action.payload.payload;
            })
            .addCase(createTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })

            .addCase(getFactType.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFactType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.factTypes = action.payload;
            })
            .addCase(getFactType.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getFactTypeData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFactTypeData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.factTypeData = action.payload;
            })
            .addCase(getFactTypeData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(editTemplate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editTemplate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                // state.factTypeData = action.payload;
            })
            .addCase(editTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getFactTypeVars.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFactTypeVars.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.factTypeVars = action.payload;
                // state.factTypeData = action.payload;
            })
            .addCase(getFactTypeVars.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(createImageLink.pending, (state) => {})
            .addCase(createImageLink.fulfilled, (state, action) => {
                state.imageDetails.imageUrl = action.payload.path;
            })
            .addCase(createImageLink.rejected, (state, action) => {
                state.isError = true;
                state.message = action.message;
            })
            .addCase(getHypothesisDataCampaignTbl.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getHypothesisDataCampaignTbl.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.hypothesisDataCampaignTbl = action.payload;
            })
            .addCase(getHypothesisDataCampaignTbl.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});

export const { reset, resetSearchData, resetLinkedTempData, resetHypothesisData } = searchTemplateSlice.actions;
export default searchTemplateSlice.reducer;
