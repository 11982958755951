import React, { useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import appConfig from "../../config/app.config";

const SuccessCriteriaMultiSelect = ({ setSuccessCriteriaList, successCriteriaList }) => {
    const editSuccessCriteria = useSelector((state) => state?.template?.hypohesisData?.success_criteria);
    const [scList, setSCList] = useState(JSON.parse(localStorage.getItem("SCList")) ?? editSuccessCriteria ?? []);
    const defaultList = scList?.map((item) => ({ value: item, label: item }));
    const editDefaultList = editSuccessCriteria?.map((item) => ({ value: item, label: item }));
    const value = useSelector((state) => state.value);
    const eventsList = appConfig[value].SUCCESS_CRITERIA.map((item) => ({ value: item, label: item }));
    const handleSCMultiSelect = (options) => {
        const arr = options?.map((item) => item.value);
        setSuccessCriteriaList(arr);
        localStorage.setItem("SCList", JSON.stringify(arr));
    };

    const newHypothesis = localStorage.getItem("newHypothesis");
    let editHypothesisID = localStorage.getItem("editHypothesisID");

    return (
        <div className="success-criteria-multi-select">
            <div>Success Criteria</div>

            {!newHypothesis && editDefaultList?.length > 0 && (
                <>
                    <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={eventsList}
                        onChange={handleSCMultiSelect}
                        defaultValue={editDefaultList || defaultList}
                    />
                </>
            )}

            {editHypothesisID !== null && editDefaultList?.length === 0 && (
                <>
                    <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={eventsList}
                        onChange={handleSCMultiSelect}
                        defaultValue={defaultList || editDefaultList}
                    />
                </>
            )}

            {newHypothesis && (
                <>
                    <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={eventsList}
                        onChange={handleSCMultiSelect}
                        defaultValue={defaultList}
                    />
                </>
            )}
        </div>
    );
};

export default SuccessCriteriaMultiSelect;
