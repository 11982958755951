import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CustomCheckbox = ({ handleChange, label, name, value, isChecked }) => {
    return (
        <div>
            <FormControlLabel
                control={<Checkbox />}
                name={name}
                value={value}
                checked={isChecked}
                onChange={handleChange}
                label={label}
            />
        </div>
    );
};

export default CustomCheckbox;
