import React, { useState, useEffect } from "react";
import Select from "react-select";

const SingleSelect = ({ options, handleSingleSelect, defaultValue }) => {
    const optionsList = options.map((item) => ({ value: item.value, label: item.label }));
    const [defaultValueList, setDefaultValueList] = useState();

    useEffect(() => {
        if (defaultValue === "APP") {
            setDefaultValueList("App Only");
        } else if (defaultValue === "WEB") {
            setDefaultValueList("Web Only");
        } else if (defaultValue === "BOTH") {
            setDefaultValueList("Both");
        }
    }, [defaultValue]);

    return (
        <>
            <div style={{ margin: "14px 0" }}>Select PN Type</div>
            <Select
                closeMenuOnSelect={false}
                options={optionsList}
                onChange={handleSingleSelect}
                placeholder={defaultValueList}
                // defaultValue={defaultValueList ? defaultValueList : "Select"}
            />
        </>
    );
};

export default SingleSelect;
