import React from "react";
import { useState, useEffect } from "react";
import InputField from "../../../components/InputField";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: "14px 18px",
    // textAlign: "center",
    // color: theme.palette.text.secondary,
}));

const closeBtnStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "12px",
    cursor: "pointer",
};

const EditDefaultVars = ({
    templateData,
    setCustomDataJSON,
    setCustomVarKey,
    limit,
    label = "Add Default Variables",
    Namekey = "key",
    Valuekey = "value",
}) => {
    const inputArr = [""];
    const [customDataKey, setCustomDataKey] = useState(
        Namekey === "Name"
            ? templateData?.message_details?.ctas?.map((cta) => cta.name) || inputArr
            : templateData?.default_template_variables_values
            ? Object.keys(templateData.default_template_variables_values)
            : inputArr
    );

    const [customDataVal, setCustomDataVal] = useState(
        Valuekey === "Deeplink"
            ? templateData?.message_details?.ctas?.map((cta) => cta.deep_link) || inputArr
            : templateData?.default_template_variables_values
            ? Object.values(templateData.default_template_variables_values)
            : inputArr
    );

    useEffect(() => {
        var newObj = {};
        for (var i = 0; i < customDataKey?.length; i++) {
            newObj[customDataKey[i]] = customDataVal[i];
        }
        setCustomDataJSON(newObj);
        setCustomVarKey(customDataKey);
    }, [customDataKey, customDataVal]);

    const addRow = () => {
        if (limit && customDataKey.length >= limit) return;
        setCustomDataKey((s) => {
            return [...s, ""];
        });

        setCustomDataVal((s) => {
            return [...s, ""];
        });
    };

    const removeRow = (index) => {
        const listKey = [...customDataKey];
        listKey.splice(index, 1);
        setCustomDataKey(listKey);

        const listVal = [...customDataVal];
        listVal.splice(index, 1);
        setCustomDataVal(listVal);

        // let result = Object.keys(customDataJSON).map((key) => [key, customDataJSON[key]]);
        // const dataList = [...result];
        // dataList.splice(index, 1);

        // setCustomDataJSON(dataList);
    };

    const handleCustomKeyChange = (e) => {
        let newArr = [];
        const index = e.target.id;
        setCustomDataKey((item) => {
            newArr = item?.slice();
            newArr[index] = e.target.value;

            return newArr;
        });
    };

    const handleCustomValChange = (e) => {
        let newArr = [];
        const index = e.target.id;
        setCustomDataVal((item) => {
            newArr = item?.slice();
            newArr[index] = e.target.value;
            return newArr;
        });
    };

    return (
        <div>
            <div className="custom-deep-custom-header">
                <p>{label}</p>
                <span className="custom-data-add-btn" onClick={addRow}>
                    <AddIcon />
                </span>
            </div>

            <div style={{ marginBottom: "36px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Item>
                            {customDataKey?.map((item, i) => {
                                return (
                                    <div>
                                        <InputField
                                            inputLabel={Namekey}
                                            isRequired={true}
                                            id={i}
                                            variant="outlined"
                                            handleChange={(e) => handleCustomKeyChange(e, "key")}
                                            value={item !== null && item !== undefined ? item : ""}
                                        />
                                    </div>
                                );
                            })}
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            {customDataVal?.map((item, i) => {
                                return (
                                    <Grid container spacing={2}>
                                        <Grid item xs={10}>
                                            <InputField
                                                inputLabel={Valuekey}
                                                isRequired={true}
                                                id={i}
                                                variant="outlined"
                                                handleChange={(e) => handleCustomValChange(e, "val")}
                                                value={item !== null && item !== undefined ? item : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={2} style={closeBtnStyle}>
                                            <span onClick={() => removeRow(i)}>
                                                <CloseIcon />
                                            </span>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Item>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default EditDefaultVars;
