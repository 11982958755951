import axios from "axios";
import { BASE_URL } from "../../utils/constants/apiBaseURL.constants";
import { toastError, toastSuccess } from "../../utils/helpers/toastError";

// GET USER VIEW
const getUserViews = async ({ page, rowsPerPage, application }) => {
    try {
        // const response = await axios(`${BASE_URL}/user-cohort-ms/user-view?pageNo=0&pageSize=1000`);
        const response = await axios(`${BASE_URL}/user-cohort-ms/user-view?application=${application}&pageNo=${page}&pageSize=${rowsPerPage}`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// USER VIEW CAMPAIGN DETAILS
const userViewCampaignDetails = async ({ hypothesisID, data }) => {
    try {
        const response = await axios.patch(
            `${BASE_URL}/user-cohort-ms/hypothesis/${hypothesisID}/campaign-details
    `,
            data
        );
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// USER LIST
const userList = async (application) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/users-list?application=${application}&page=0&pageSize=1000`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// USER VIEW TRIGGER
const userViewTrigger = async (hypothesisID) => {
    try {
        const response = await axios.post(`${BASE_URL}/user-cohort-ms/hypothesis/trigger`, hypothesisID);
        if (response?.status === 200) {
            toastSuccess();
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET ALL LINKED HYPOTHESIS
const getAllLinkedHypothesis = async ({ hypothesisID }) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/hypothesis/${hypothesisID}`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET USER VIEW NAME USING USER VIEW ID
const getUserViewNameAPI = async (userViewId) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/user-view/${userViewId}`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// RESCIND/STOP VIEW NAME USING HYPOTHESIS ID
const rescindUserViewAPI = async (hypothesisID) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/hypothesis/${hypothesisID}/rescind`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET TOTAL USERS COUNT
const getTotalUsersCount = async (userViewId) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/user-view/${userViewId}/count`);
        if (response?.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// SEARCH USER VIEW
const searchUserView = async ({ serachText = "", page, rowsPerPage,application }) => {
    try {
        const response = await axios(
            `${BASE_URL}/user-cohort-ms/user-view/search?name=${serachText}&application=${application}&pageNo=${page}&pageSize=${rowsPerPage}`
        );
        if (response?.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

const userViewService = {
    getUserViews,
    userViewCampaignDetails,
    userList,
    userViewTrigger,
    getAllLinkedHypothesis,
    getUserViewNameAPI,
    rescindUserViewAPI,
    getTotalUsersCount,
    searchUserView,
};
export default userViewService;
