import { configureStore } from "@reduxjs/toolkit";
import createCampaignReducer from "../features/createCampaign/createCampaignSlice";
import searchTemplateReducer from "../features/searchTemplate/searchTemplateSlice";
import userViewReducer from "../features/userView/userViewSlice";
import testUserListReducer from "../features/testUserList/testUserListSlice";
import templatesViewReducer from "../features/templatesView/templatesViewSlice";
import homereducer from "../features/home/homeSlice";
import valueReducer from "../features/apptype/appTypeSlice";

export const store = configureStore({
    reducer: {
        createCampaign: createCampaignReducer,
        template: searchTemplateReducer,
        userView: userViewReducer,
        testUserList: testUserListReducer,
        templatesView: templatesViewReducer,
        home: homereducer,
        value: valueReducer,
    },
});
