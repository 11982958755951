import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css";
import QRImage from "../../assets/no-image.png";

async function getDeeplink(requestData, value) {
    const valueToApplicationMap = {
        STUDENT: `${process.env.REACT_APP_STUDENT_DEEPLINK}`,
        LENS: `${process.env.REACT_APP_LENS_DEEPLINK}`,
        PARENT: `${process.env.REACT_APP_PARENT_DEEPLINK}`,
        EXPERIMENT: `${process.env.REACT_APP_EXPERIMENT_DEEPLINK}`,
        SCHOOL: `${process.env.REACT_APP_SCHOOL_DEEPLINK}`,
        CLASSROOM: `${process.env.REACT_APP_CLASSROOM_DEEPLINK}`,
    };
    const branch_key = valueToApplicationMap[value] || null;
    var raw = JSON.stringify({
        branch_key,
        data: requestData,
    });

    var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
    };

    const response = await fetch("https://api2.branch.io/v1/url", requestOptions);
    const myJson = await response.json();
    return myJson;
}

async function getQRcode(branch_url) {
    const qrCodeURL = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${branch_url}`;

    return qrCodeURL;
}
const DeeplinkMiniSite = () => {
    const value = useSelector((state) => state.value);

    const [channel, setChannel] = useState("");
    const [feature, setFeature] = useState("");
    const [campaign, setCampaign] = useState("");
    const [screenName, setScreenName] = useState("");
    const [desktopUrl, setDesktopUrl] = useState("");
    const [customData, setCustomData] = useState("");
    const [tags, setTags] = useState("");
    const [deeplink, setDeeplink] = useState("");
    const [qrImage, setQrImage] = useState("");
    const [errors, setErrors] = useState({});

    const [errorImageSrc, setErrorImageSrc] = useState(QRImage);

    const handleImageError = () => {
        setQrImage(errorImageSrc);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = checkInputs();
        if (Object.keys(validationErrors).length === 0) {
            const tagArr = tags && tags.split(",");
            const requestData = {
                screen_name: screenName,
                "~channel": channel,
                "~feature": feature,
                "~campaign": campaign,
                $desktop_url: desktopUrl,
                "~tags": tagArr,
                custom_object: JSON.parse(customData),
            };
            try {
                const response = await getDeeplink(requestData, value);
                const branchUrl = response?.url;
                setDeeplink(branchUrl);
                const qrCodeURL = await getQRcode(branchUrl);
                setQrImage(qrCodeURL);
            } catch (error) {
                console.error("Error getting deeplink:", error);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const checkInputs = () => {
        const validationErrors = {};

        if (channel.trim() === "") {
            validationErrors.channel = "Channel cannot be blank";
        }

        if (feature.trim() === "") {
            validationErrors.feature = "Feature cannot be blank";
        }

        if (campaign.trim() === "") {
            validationErrors.campaign = "Campaign cannot be blank";
        }

        if (screenName.trim() === "") {
            validationErrors.screenName = "Screen Name cannot be blank";
        }

        if (desktopUrl.trim() === "") {
            validationErrors.desktopUrl = "Desktop URL cannot be blank";
        }

        setErrors(validationErrors);
        return validationErrors;
    };

    return (
        <div className="container">
            <div className="test-user-list-title">Create a deeplink</div>
            <form onSubmit={handleSubmit} className="form">
                <div className={`form-control ${errors.channel && "error"}`}>
                    <label htmlFor="channel">Channel</label>
                    <input
                        type="text"
                        placeholder="channel"
                        id="channel"
                        value={channel}
                        onChange={(e) => setChannel(e.target.value)}
                    />
                    {errors.channel && <small>{errors.channel}</small>}
                </div>
                <div className={`form-control ${errors.feature && "error"}`}>
                    <label htmlFor="feature">Feature</label>
                    <input
                        type="text"
                        placeholder="feature"
                        id="feature"
                        value={feature}
                        onChange={(e) => setFeature(e.target.value)}
                    />
                    {errors.feature && <small>{errors.feature}</small>}
                </div>
                <div className={`form-control ${errors.campaign && "error"}`}>
                    <label htmlFor="campaign">Campaign</label>
                    <input
                        type="text"
                        placeholder="campaign"
                        id="campaign"
                        value={campaign}
                        onChange={(e) => setCampaign(e.target.value)}
                    />
                    {errors.campaign && <small>{errors.campaign}</small>}
                </div>
                <div className={`form-control ${errors.campaign && "error"}`}>
                    <label htmlFor="screenName">ScreenName</label>
                    <input
                        type="text"
                        placeholder="screenName"
                        id="screenName"
                        value={screenName}
                        onChange={(e) => setScreenName(e.target.value)}
                    />
                    {errors.screenName && <small>{errors.screenName}</small>}
                </div>
                <div className={`form-control ${errors.desktopUrl && "error"}`}>
                    <label htmlFor="desktopUrl">DesktopUrl</label>
                    <input
                        type="text"
                        placeholder="desktopUrl"
                        id="desktopUrl"
                        value={desktopUrl}
                        onChange={(e) => setDesktopUrl(e.target.value)}
                    />
                    {errors.desktopUrl && <small>{errors.desktopUrl}</small>}
                </div>
                <div className={`form-control ${errors.customData && "error"}`}>
                    <label htmlFor="customData">CustomData</label>
                    <input
                        type="text"
                        placeholder="customData"
                        id="customData"
                        value={customData}
                        onChange={(e) => setCustomData(e.target.value)}
                    />
                    {errors.customData && <small>{errors.customData}</small>}
                </div>
                <div className={`form-control ${errors.tags && "error"}`}>
                    <label htmlFor="tags">Tags</label>
                    <input
                        type="text"
                        placeholder="tags"
                        id="tags"
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                    />
                    {errors.tags && <small>{errors.tags}</small>}
                </div>
                <div className="form-control">
                    <label htmlFor="deep_link">Deeplink</label>
                    <input type="text" disabled placeholder="final deeplink" id="deep_link" value={deeplink} />
                </div>
                <div className="form-control">
                    <img id="QRimage" src={qrImage} onError={handleImageError} alt="QR Code" width="200" height="200" />
                </div>
                <div className="form-control">
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
};
export default DeeplinkMiniSite;
