import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, TextField, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setValue } from "../../features/apptype/appTypeSlice";
import { login } from "../../features/home/homeSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import createEncryptor from "./encryptions";

const Authentication = ({ setToken }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [pass, setPass] = useState("");
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const loginUser = data.get("email").trim();
        const loginPassword = data.get("password").trim();
        if (!loginUser) {
            setUsername("ERROR");
            return;
        }
        if (!loginPassword) {
            setPass("ERROR");
            return;
        }
        const result = {
            email: loginUser,
            password: loginPassword,
        };
        setUsername("");
        setPass("");
        setIsLoading(true);
        dispatch(login(result))
            .then((response) => {
                if (response && response.error) {
                    toast.info("Please Enter the Valid Credentials");
                    throw new Error("Couldn't login");
                } else {
                    const encryptedApplicationAccess = createEncryptor(`${process.env.REACT_APP_SALTS}`).crypt(
                        response.payload.applications.toString()
                    );
                    localStorage.setItem("applicationAccessProvided", encryptedApplicationAccess);
                    const userOrganization = response.payload.type;
                    localStorage.setItem("userOrganization", userOrganization);
                    dispatch(setValue(response.payload.applications[0]));
                    const encryptToken = createEncryptor(`${process.env.REACT_APP_SALTS}`).crypt(
                        process.env.REACT_APP_TOKENS
                    );
                    toast.success("Logged In");
                    setToken(encryptToken);
                    navigate("/home");
                }
            })
            .catch((error) => {
                console.error(error);
            });
        setIsLoading(false);
    };
    return (
        <React.Fragment>
            <Container maxWidth="sm">
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                        mt: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        boxShadow: "3",
                        height: "60vh",
                        borderRadius: "10px",
                        padding: "4rem",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ mb: 10 }}>
                        Sign in
                    </Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    {username === "ERROR" ? <p className="errormsg">UserName is required</p> : <></>}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    {pass === "ERROR" ? <p className="errormsg">Password is required</p> : <></>}
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoading}
                        sx={{
                            mt: 6,
                            mb: 2,
                            paddingLeft: 8,
                            paddingRight: 8,
                            borderRadius: "15px",
                        }}
                    >
                        sign in
                    </Button>
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default Authentication;
Authentication.propTypes = {
    setToken: PropTypes.func.isRequired,
};
