import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { getTemplates, resetSingleTemplate } from "../../features/templatesView/templatesViewSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { dateHelper } from "../../utils/helpers/dateHelper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getFactTypeVars } from "../../features/searchTemplate/searchTemplateSlice";
import Spinner from "../../components/Spinner";
import SearchIcon from "@mui/icons-material/Search";
import InputField from "../../components/InputField";
import useDebounce from "../../utils/helpers/useDebounce";
import { searchTemplate } from "../../features/templatesView/templatesViewSlice";
import { clearLocalStorageExcept } from "../../utils/helpers/localStorageClear";

const TemplatesViewTableRow = ({ row, handleTemplateEdit }) => {
    return (
        <TableRow tabIndex={-1} key={row?.created_at}>
            <TableCell>{row?.template_name}</TableCell>
            <TableCell>{row?.channel}</TableCell>
            <TableCell>{dateHelper(row?.created_at)}</TableCell>
            <TableCell onClick={() => handleTemplateEdit(row?.template_id)}>
                <VisibilityIcon className="campaign-edit-icon" />
            </TableCell>
        </TableRow>
    );
};

const TemplatesView = () => {
    // localStorage.clear();
    clearLocalStorageExcept(["applicationAccessProvided", "token", "userOrganization"]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const allTemplates = useSelector((state) => state?.templatesView?.allTemplatesData?.content);
    const isAllTemplateLoading = useSelector((state) => state?.templatesView?.isAllTemplateLoading);
    const value = useSelector((state) => state.value);

    useEffect(() => {
        // dispatch(getTemplates());
        dispatch(resetSingleTemplate());
    }, []);

    // GETTING AND SETTING THE FACT TYPES
    const factTypeVars = useSelector((state) => state?.template?.factTypeVars);

    useEffect(() => {
        dispatch(getFactTypeVars(value));
        // localStorage.setItem("factTypeVars", JSON.stringify(factTypeVars));//---> change
    }, [value]);

    localStorage.setItem("factTypeVars", JSON.stringify(factTypeVars));
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleTemplateEdit = (id) => {
        localStorage.setItem("editTemplateId", id);
        navigate(`/create-template`);
    };

    // SEARCH TEMPLATES
    const [searchTerm, setSearchTerm] = useState();
    const debouncedTerm = useDebounce(searchTerm, 500);
    const searchedContent = useSelector((state) => state?.templatesView?.searchedTemplateData?.content);

    const allTemplatesCount = useSelector((state) => state?.templatesView?.allTemplatesData?.totalElements);
    const searchedTempCount = useSelector((state) => state?.templatesView?.searchedTemplateData?.totalElements);

    let userViewArr = [];
    userViewArr = searchTerm !== undefined ? searchedContent : allTemplates;
    useEffect(() => {
        if (debouncedTerm === undefined) {
            const data = {
                page: page,
                rowsPerPage,
                application: value,
            };
            dispatch(getTemplates(data));
        } else {
            const data = {
                serachText: debouncedTerm,
                page: page,
                rowsPerPage,
                application: value,
            };
            dispatch(searchTemplate(data));
            setPage(0);
        }
    }, [debouncedTerm, page, rowsPerPage, value]);

    useEffect(() => {
        handleChangePage("", page);
    }, [page, rowsPerPage]);

    return (
        <div className="campaign-container">
            <div className="test-user-list-title">All Templates</div>

            <div className="user-view-search">
                <InputField
                    id="outlined-search"
                    label="Search Template"
                    // type="search"
                    variant="outlined"
                    handleChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm ?? ""}
                />

                <span className="search-icon">
                    <SearchIcon />
                </span>
            </div>
            {isAllTemplateLoading ? (
                <div className="spinner-container-table">
                    <Spinner />
                </div>
            ) : (
                <>
                    <TableContainer>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <TableHead className="template-table-head">
                                <TableRow>
                                    <TableCell>Template Name</TableCell>
                                    <TableCell>Channel</TableCell>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>View</TableCell>
                                </TableRow>
                            </TableHead>

                            {userViewArr?.length > 0 ? (
                                <TableBody>
                                    {userViewArr
                                        // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        ?.map((row, index) => (
                                            <TemplatesViewTableRow
                                                row={row}
                                                handleTemplateEdit={handleTemplateEdit}
                                                value={value}
                                            />
                                        ))}
                                </TableBody>
                            ) : userViewArr?.length === 0 ? (
                                <div className="user-view-no-data">No data found</div>
                            ) : (
                                <div className="spinner-container-table">
                                    <Spinner />
                                </div>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={debouncedTerm === undefined ? allTemplatesCount : searchedTempCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}

            <div className="test-user-list-add-btn" onClick={() => navigate("/create-template")}>
                <AddIcon />
            </div>
        </div>
    );
};

export default TemplatesView;
