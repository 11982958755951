import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TestUserListTableRow from "./TestUserListTableRow";
import TestUserTableRow from "./TestUserTableRow";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";

const TestUserTable = ({ headerList, bodyList, setDeleteID, setOpenDelete, tableName }) => {
    // PAGINATION
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // PAGE NUMBER CHANGE - pages 1,2,...9,10
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // NO OF ROWS PER PAGE COUNT - 5, 10, 25
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };
    const value = useSelector((state) => state.value);
    return (
        <>
            <TableContainer style={{ marginTop: "54px" }}>
                <Table sx={{ minWidth: "100%", maxWidth: "100%" }} aria-label="simple table">
                    <TableHead className="template-table-head">
                        <TableRow>
                            {headerList?.map((item) => (
                                <TableCell align={item.align} key={item.id}>
                                    {item?.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {bodyList?.length > 0 ? (
                    <TableBody>
                        {tableName === "test-user-list" &&
                            bodyList
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map((item) => (
                                    <TestUserListTableRow
                                        key={item?.users_list_id}
                                        item={item}
                                        setDeleteID={setDeleteID}
                                        setOpenDelete={setOpenDelete}
                                    />
                                ))}
                        {tableName === "test-user" &&
                            bodyList
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map((item) => (
                                    <TestUserTableRow
                                        key={item?.users_list_id}
                                        item={item}
                                        setDeleteID={setDeleteID}
                                        setOpenDelete={setOpenDelete}
                                    />
                                ))}
                    </TableBody>):(
                    <TableBody>
                    <div className="user-view-no-data">No data found</div>
                    </TableBody>
                    )}
                </Table>
            </TableContainer>

          
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={bodyList?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ marginBottom: "50px" }}
                />
        </>
    );
};

export default TestUserTable;
