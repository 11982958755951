import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllHypothesis,
    cloneHypothesis,
    resetHypotheisData,
    searchCampaign,
} from "../../features/createCampaign/createCampaignSlice";
import { resetHypothesisData } from "../../features/searchTemplate/searchTemplateSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { dateHelper } from "../../utils/helpers/dateHelper";
import EditIcon from "@mui/icons-material/EditOutlined";
import CloneIcon from "@mui/icons-material/CopyAllOutlined";
import Tooltip from "@mui/material/Tooltip";
import Spinner from "../../components/Spinner";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CampaignDetails from "./CampaignDetails";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import useDebounce from "../../utils/helpers/useDebounce";
import SearchIcon from "@mui/icons-material/Search";
import InputField from "../../components/InputField";
import { clearLocalStorageExcept } from "../../utils/helpers/localStorageClear";

// Use the function with the key you want to exclude

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    height: 680,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
};

const CampaignTableRow = ({ row, handleCampaignEdit, handleClone, handleCampaignDetails }) => {
    return (
        <TableRow tabIndex={-1} key={row?.created_at}>
            <TableCell>{row?.hypothesis_id}</TableCell>
            <TableCell sx={{ maxWidth: "200px", overflow: "clip" }}>{row?.intent}</TableCell>
            <TableCell sx={{ maxWidth: "200px", overflow: "clip" }}>{row?.action}</TableCell>
            <TableCell sx={{ maxWidth: "200px", overflow: "clip" }}>{row?.campaign_name}</TableCell>
            <TableCell>{dateHelper(row?.created_at)}</TableCell>
            <TableCell onClick={() => handleCampaignDetails(row)}>
                <InfoIcon className="campaign-edit-icon" />
            </TableCell>
            {row?.status === "DATA_CAPTURING" ? (
                <TableCell onClick={() => handleCampaignEdit(row?.hypothesis_id)}>
                    <EditIcon className="campaign-edit-icon" />
                </TableCell>
            ) : (
                <TableCell>
                    <Tooltip
                        title="The status is not DATA CAPTURING"
                        // style={{ fontSize: 18 }}
                        placement="top-start"
                        arrow
                    >
                        <EditIcon className="campaign-edit-icon-grey" />
                    </Tooltip>
                </TableCell>
            )}
            <TableCell onClick={() => handleClone(row?.hypothesis_id)}>
                <CloneIcon className="campaign-edit-icon" />
            </TableCell>
        </TableRow>
    );
};

const Campaign = () => {
    // localStorage.clear();
    clearLocalStorageExcept(["applicationAccessProvided", "token", "userOrganization"]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const allHypothesisData = useSelector((state) => state?.createCampaign?.allHypothesisData?.content);
    const allHypothesis = useSelector((state) => state?.createCampaign?.allHypothesisData);
    const isLoading = useSelector((state) => state?.createCampaign?.isLoading);

    useEffect(() => {
        // const data = {
        //     page: page,
        //     rowsPerPage,
        // };
        // dispatch(getAllHypothesis(data));
        // dispatch(getAllHypothesis());
        dispatch(resetHypothesisData());
        dispatch(resetHypotheisData());
    }, [page, rowsPerPage]);

    // PAGE NUMBER CHANGE - pages 1,2,...9,10
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // NO OF ROWS PER PAGE COUNT - 5, 10, 25
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // CAMPAIGN EDIT
    const handleCampaignEdit = (id) => {
        localStorage.setItem("editHypothesisID", id);
        navigate(`/create-campaign`);
        // dispatch(getHypothesis(id));
    };

    // CLONE HYPOTHESIS
    const handleClone = (id) => {
        dispatch(cloneHypothesis(id));
        window.location.reload();
    };

    // MODAL OPEN/CLOSE FUNCTIONS
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [rowDetails, setRowDetails] = useState();

    // CAMPAIGN DETAILS
    const handleCampaignDetails = (rowDetails) => {
        setOpen(true);
        setRowDetails(rowDetails);
    };

    const handleCreateCampaign = () => {
        navigate("/create-campaign");
        localStorage.setItem("newHypothesis", true);
    };
    // SEARCH CAMPAIGN
    const [searchTerm, setSearchTerm] = useState();
    const debouncedTerm = useDebounce(searchTerm, 500);
    const searchedContent = useSelector((state) => state?.createCampaign?.searchedCampaignData?.content);

    const allCampaignsCount = useSelector((state) => state?.createCampaign?.allHypothesisData?.totalElements);
    const searchedCampCount = useSelector((state) => state?.createCampaign?.searchedCampaignData?.totalElements);

    let userViewArr = [];
    userViewArr = searchTerm !== undefined ? searchedContent : allHypothesisData;
    const value = useSelector((state) => state.value);
    useEffect(() => {
        if (debouncedTerm === undefined) {
            const data = {
                page: page,
                rowsPerPage,
                application: value,
            };
            dispatch(getAllHypothesis(data));
        } else {
            const data = {
                serachText: debouncedTerm,
                page: page,
                rowsPerPage,
                application: value,
            };
            dispatch(searchCampaign(data));
            setPage(0);
        }
    }, [debouncedTerm, page, rowsPerPage, value]);

    useEffect(() => {
        handleChangePage("", page);
    }, [page, rowsPerPage]);

    return (
        <div className="campaign-container">
            <div className="test-user-list-title">All Hypothesis</div>

            <div className="user-view-search">
                <InputField
                    id="outlined-search"
                    label="Search Campaign"
                    // type="search"
                    variant="outlined"
                    handleChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm ?? ""}
                />

                <span className="search-icon">
                    <SearchIcon />
                </span>
            </div>

            {isLoading ? (
                <div className="spinner-container-table">
                    <Spinner />
                </div>
            ) : (
                <>
                    <TableContainer>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <TableHead className="template-table-head">
                                <TableRow>
                                    <TableCell>Hypothesis ID</TableCell>
                                    <TableCell>Intent</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Campaign Name</TableCell>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Info</TableCell>
                                    <TableCell>Edit</TableCell>
                                    <TableCell>Clone</TableCell>
                                </TableRow>
                            </TableHead>
                            {userViewArr?.length > 0 ? (
                                <TableBody>
                                    {userViewArr
                                        // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        ?.map((row, index) => {
                                            return (
                                                <CampaignTableRow
                                                    row={row}
                                                    handleCampaignEdit={handleCampaignEdit}
                                                    handleClone={handleClone}
                                                    handleCampaignDetails={handleCampaignDetails}
                                                />
                                            );
                                        })}
                                </TableBody>
                            ) : userViewArr?.length === 0 ? (
                                <div className="user-view-no-data">No data found</div>
                            ) : (
                                <div className="spinner-container-table">
                                    <Spinner />
                                </div>
                            )}
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={debouncedTerm === undefined ? allCampaignsCount : searchedCampCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        style={{ marginBottom: "100px" }}
                    />
                </>
            )}

            <div className="test-user-list-add-btn" onClick={handleCreateCampaign}>
                <AddIcon />
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CampaignDetails handleClose={handleClose} rowDetails={rowDetails} />
                </Box>
            </Modal>
        </div>
    );
};

export default Campaign;
