import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login/authentication";
import useToken from "./pages/Login/useToken";
import Home from "./pages/Home";
import CreateCampaign from "./pages/Campaign/CreateCampaign";
import NavBar from "./components/NavBar";
import Template from "./pages/Template";
import UserView from "./pages/UserView";
import "react-toastify/dist/ReactToastify.css";
import TestUserList from "./pages/TestUserList";
import TestUser from "./pages/TestUserList/TestUser";
import Campaign from "./pages/Campaign";
import TemplatesView from "./pages/TemplatesView";
import CreateTemplateView from "./pages/TemplatesView/components/CreateTemplateView";
import UserViewPage from "./pages/UserViewPage";
import DeeplinkMiniSite from "./pages/Deeplink/index";
import { ROUTE } from "./utils/constants/routeConstants";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import createEncryptor from "./pages/Login/encryptions";
function App() {
    const THEME = createTheme({
        typography: {
            allVariants: {
                fontFamily: "Poppins",
            },
        },
    });

    const isTokenValid = (token) => {
        try {
            const decryptToken = createEncryptor(process.env.REACT_APP_SALTS).decrypt(token);
            return decryptToken === process.env.REACT_APP_TOKENS || token === process.env.REACT_APP_TOKENS;
        } catch (error) {
            console.error("Error decrypting token:", error);
            return false;
        }
    };
    const { token, setToken } = useToken();
    if (isTokenValid(token)) {
        return (
            <>
                <BrowserRouter>
                    <ThemeProvider theme={THEME}>
                        <div className="app-container">
                            <NavBar />
                            <Routes>
                                <Route path={ROUTE.LOGIN} element={<Home />} />
                                <Route path={ROUTE.HOME} element={<Home />} />
                                <Route path={ROUTE.CAMPIAGN} element={<Campaign />} />
                                <Route path={ROUTE.CREATE_CAMPAIGN} element={<CreateCampaign />} />
                                <Route path={ROUTE.LINKED_TEMPLATE} element={<Template />} />
                                <Route path={ROUTE.CAMPAIGN_DETAILS} element={<UserView />} />
                                <Route path={ROUTE.TEST_USER_LIST} element={<TestUserList />} />
                                <Route path={ROUTE.TEST_USER} element={<TestUser />} />
                                <Route path={ROUTE.TEMPLATE} element={<TemplatesView />} />
                                <Route path={ROUTE.CREATE_TEMPLATE} element={<CreateTemplateView />} />
                                <Route path={ROUTE.USER_VIEW} element={<UserViewPage />} />
                                <Route path={ROUTE.DEEPLINK} element={<DeeplinkMiniSite />} />
                                <Route path="*" element={<Navigate to={ROUTE.HOME} />} />
                            </Routes>
                        </div>
                    </ThemeProvider>
                </BrowserRouter>
                <ToastContainer />
            </>
        );
    }
    return (
        <>
            <BrowserRouter>
                <ThemeProvider theme={THEME}>
                    <div className="app-container">
                        <Login setToken={setToken} />
                    </div>
                </ThemeProvider>
            </BrowserRouter>
            <ToastContainer />
        </>
    );
}

export default App;
