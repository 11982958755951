import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import templatesViewService from "./templatesViewService";

const initialState = {
    allTemplatesData: [],
    singleTemplateData: {},
    isAllTemplateLoading: false,
    searchedTemplateData: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// GET ALL TEMPLATES
export const getTemplates = createAsyncThunk("getTemplates/get", async (data, thunkAPI) => {
    try {
        return await templatesViewService.getTemplatesAPI(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// GET SINGLE TEMPLATE DATA
export const getSingleTemplate = createAsyncThunk("getSingleTemplate/get", async (templateId, thunkAPI) => {
    try {
        return await templatesViewService.getSingleTemplatesAPI(templateId);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// SEARCH TEMPLATE
export const searchTemplate = createAsyncThunk("searchTemplateAPI/get", async (data, thunkAPI) => {
    try {
        return await templatesViewService.searchTemplateAPI(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// TEMPLATES VIEW SLICE
export const templatesViewSlice = createSlice({
    name: "templatesView",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
        resetSingleTemplate: (state) => {
            state.singleTemplateData = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTemplates.pending, (state) => {
                state.isAllTemplateLoading = true;
            })
            .addCase(getTemplates.fulfilled, (state, action) => {
                state.isAllTemplateLoading = false;
                state.isSuccess = true;
                state.allTemplatesData = action.payload;
            })
            .addCase(getTemplates.rejected, (state, action) => {
                state.isAllTemplateLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getSingleTemplate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSingleTemplate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.singleTemplateData = action.payload;
            })
            .addCase(getSingleTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(searchTemplate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(searchTemplate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.searchedTemplateData = action.payload;
            })
            .addCase(searchTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});

export const { reset, resetSingleTemplate } = templatesViewSlice.actions;
export default templatesViewSlice.reducer;
