export const NOTIFICATION_TAGS = [
    {
        label: "Trending for Your Exam",
        name: "inAppTags_Options",
        value: "Trending for Your Exam",
    },
    {
        label: "Mb Recommends",
        name: "inAppTags_Options",
        value: "Mb Recommends",
    },
    {
        label: "Embibe Live Assignment",
        name: "inAppTags_Options",
        value: "Embibe Live Assignment",
    },
    {
        label: "Assignment from School",
        name: "inAppTags_Options",
        value: "Assignment from School",
    },
    {
        label: "Assignment from Parent",
        name: "inAppTags_Options",
        value: "Assignment from Parent",
    },
    {
        label: "Invite Your Friend",
        name: "inAppTags_Options",
        value: "Invite Your Friend",
    },
    {
        label: "Invite Your Parent",
        name: "inAppTags_Options",
        value: "Invite Your Parent",
    },
    {
        label: "Subscription",
        name: "inAppTags_Options",
        value: "Subscription",
    },
    {
        label: "Exam Info",
        name: "inAppTags_Options",
        value: "Exam Info",
    },
    {
        label: "Create Your Own Test",
        name: "inAppTags_Options",
        value: "Create Your Own Test",
    },
    {
        label: "Test Feedback",
        name: "inAppTags_Options",
        value: "Test Feedback",
    },
    {
        label: "Embibe Live",
        name: "inAppTags_Options",
        value: "Embibe Live",
    },
    {
        label: "Current Affairs",
        name: "inAppTags_Options",
        value: "Current Affairsl",
    },
    {
        label: "My Timeline",
        name: "inAppTags_Options",
        value: "My Timeline",
    },
    {
        label: "Revision List",
        name: "inAppTags_Options",
        value: "Revision List",
    },
    {
        label: "Points To Remember",
        name: "inAppTags_Options",
        value: "Points To Remember",
    },
    {
        label: "Bookmarks",
        name: "inAppTags_Options",
        value: "Bookmarks",
    },
    {
        label: "Embium Coins",
        name: "inAppTags_Options",
        value: "Embium Coins",
    },
    {
        label: "Embibe Exclusive Video",
        name: "inAppTags_Options",
        value: "Embibe Exclusive Video",
    },
    {
        label: "Learn",
        name: "inAppTags_Options",
        value: "Learn",
    },
    {
        label: "Practice",
        name: "inAppTags_Options",
        value: "Practice",
    },
    {
        label: "Test",
        name: "inAppTags_Options",
        value: "Test",
    },
    {
        label: "Achieve",
        name: "inAppTags_Options",
        value: "Achieve",
    },
    {
        label: "Rewards",
        name: "inAppTags_Options",
        value: "Rewards",
    },
    {
        label: "Doubt Resolution",
        name: "inAppTags_Options",
        value: "Doubt Resolution",
    },
    {
        label: "Live Class",
        name: "inAppTags_Options",
        value: "Live Class",
    },
    {
        label: "Free Trial",
        name: "inAppTags_Options",
        value: "Free Trial",
    },
];
export const MAHENDRAS_NOTIFICATION_TAGS = [
    {
        label: "Mb Recommends",
        name: "inAppTags_Options",
        value: "Mb Recommends",
    },
    {
        label: "Embibe Live Assignment",
        name: "inAppTags_Options",
        value: "Embibe Live Assignment",
    },
    {
        label: "Exam Info",
        name: "inAppTags_Options",
        value: "Exam Info",
    },
    {
        label: "Create Your Own Test",
        name: "inAppTags_Options",
        value: "Create Your Own Test",
    },
    {
        label: "Test Feedback",
        name: "inAppTags_Options",
        value: "Test Feedback",
    },
    {
        label: "Embibe Live",
        name: "inAppTags_Options",
        value: "Embibe Live",
    },
    {
        label: "My Timeline",
        name: "inAppTags_Options",
        value: "My Timeline",
    },
    {
        label: "Revision List",
        name: "inAppTags_Options",
        value: "Revision List",
    },
    {
        label: "Points To Remember",
        name: "inAppTags_Options",
        value: "Points To Remember",
    },
    {
        label: "Bookmarks",
        name: "inAppTags_Options",
        value: "Bookmarks",
    },
    {
        label: "Learn",
        name: "inAppTags_Options",
        value: "Learn",
    },
    {
        label: "Practice",
        name: "inAppTags_Options",
        value: "Practice",
    },
    {
        label: "Test",
        name: "inAppTags_Options",
        value: "Test",
    },
    {
        label: "Achieve",
        name: "inAppTags_Options",
        value: "Achieve",
    },
    {
        label: "Doubt Resolution",
        name: "inAppTags_Options",
        value: "Doubt Resolution",
    },
    {
        label: "Live Class",
        name: "inAppTags_Options",
        value: "Live Class",
    },
];
export const MTG_NOTIFICATION_TAGS = [];
export const NOTIFICATION_TYPES = [
    {
        label: "Alert Embibe Live",
        name: "in_app_notification_type",
        value: "alert_embibe_live",
    },
    {
        label: "Discover Achieve",
        name: "in_app_notification_type",
        value: "discover_achieve",
    },
    {
        label: "Discover Book",
        name: "in_app_notification_type",
        value: "discover_book",
    },
    {
        label: "Discover DR",
        name: "in_app_notification_type",
        value: "discover_dr",
    },
    {
        label: "Discover Learn",
        name: "in_app_notification_type",
        value: "discover_learn",
    },
    {
        label: "Discover Live Class",
        name: "in_app_notification_type",
        value: "discover_live_class",
    },
    {
        label: "Discover Practice",
        name: "in_app_notification_type",
        value: "discover_practice",
    },
    {
        label: "Discover Question",
        name: "in_app_notification_type",
        value: "discover_question",
    },
    {
        label: "Discover Test",
        name: "in_app_notification_type",
        value: "discover_test",
    },
    {
        label: "Discover Video",
        name: "in_app_notification_type",
        value: "discover_video",
    },
    {
        label: "DiscoverMb Book",
        name: "in_app_notification_type",
        value: "discover_mb_book",
    },
    {
        label: "DiscoverMb Question",
        name: "in_app_notification_type",
        value: "discover_mb_question",
    },
    {
        label: "DiscoverMb Test",
        name: "in_app_notification_type",
        value: "discover_mb_test",
    },
    {
        label: "DiscoverMb Video",
        name: "in_app_notification_type",
        value: "discover_mb_video",
    },
    {
        label: "Reminder-Pre-class Activity",
        name: "in_app_notification_type",
        value: "reminder_pre_class_activity",
    },
    {
        label: "Reminder-Post-class Activity",
        name: "in_app_notification_type",
        value: "reminder_post_class_activity",
    },
    {
        label: "Reminder-School",
        name: "in_app_notification_type",
        value: "reminder_school",
    },
    {
        label: "Reminder-Parent",
        name: "in_app_notification_type",
        value: "reminder_parent",
    },
    {
        label: "Reminder-Free Trial Ends Soon",
        name: "in_app_notification_type",
        value: "reminder_free_trial_ends_soon",
    },
    {
        label: "Reminder-Subscription Ends Soon",
        name: "in_app_notification_type",
        value: "reminder_subscription_ends_soon",
    },
    {
        label: "Informative",
        name: "in_app_notification_type",
        value: "informative",
    },
    {
        label: "Informative-Join Class",
        name: "in_app_notification_type",
        value: "informative_join_class",
    },
    {
        label: "Informative-Class Cancelled",
        name: "in_app_notification_type",
        value: "informative_class_cancelled",
    },
    {
        label: "Informative-Class Rescheduled",
        name: "in_app_notification_type",
        value: "informative_class_rescheduled",
    },
    {
        label: "Alert Embibe Live-Missed Class",
        name: "in_app_notification_type",
        value: "alert_embibe_live_missed_class",
    },
    {
        label: "Reminder-My Timeline",
        name: "in_app_notification_type",
        value: "reminder_my_timeline",
    },
    {
        label: "Informative-Class Rescheduled",
        name: "in_app_notification_type",
        value: "informative_class_rescheduled",
    },
    {
        label: "Reminder-Revision List",
        name: "in_app_notification_type",
        value: "reminder_revision_list",
    },
    {
        label: "Informative Exclusive Video",
        name: "in_app_notification_type",
        value: "informative_exclusive_video",
    },
    {
        label: "Referral",
        name: "in_app_notification_type",
        value: "referral",
    },
    {
        label: "Reminder-Points To Remember",
        name: "in_app_notification_type",
        value: "reminder_points_to_remember",
    },
    {
        label: "Alert Embibe Live-Bookmarks",
        name: "in_app_notification_type",
        value: "alert_embibe_live_bookmarks",
    },
    {
        label: "Discover Live Class-Free Trial",
        name: "in_app_notification_type",
        value: "discover_live_class_free_trial",
    },
    {
        label: "Discover Live Class-Subscription",
        name: "in_app_notification_type",
        value: "discover_live_class_subscription",
    },
];
export const MAHENDRAS_NOTIFICATION_TYPES = [
    {
        label: "Discover DR",
        name: "in_app_notification_type",
        value: "discover_dr",
    },
    {
        label: "Discover Learn",
        name: "in_app_notification_type",
        value: "discover_learn",
    },
    {
        label: "Discover Live Class",
        name: "in_app_notification_type",
        value: "discover_live_class",
    },
    {
        label: "Discover Practice",
        name: "in_app_notification_type",
        value: "discover_practice",
    },
    {
        label: "DiscoverMb Video",
        name: "in_app_notification_type",
        value: "discover_mb_video",
    },
    {
        label: "DiscoverMb Book",
        name: "in_app_notification_type",
        value: "discover_mb_book",
    },
    {
        label: "DiscoverMb Question",
        name: "in_app_notification_type",
        value: "discover_mb_question",
    },
    {
        label: "DiscoverMb Test",
        name: "in_app_notification_type",
        value: "discover_mb_test",
    },
    {
        label: "Reminder",
        name: "in_app_notification_type",
        value: "Reminder",
    },
    {
        label: "Informative",
        name: "in_app_notification_type",
        value: "informative",
    },
    {
        label: "Alert Embibe Live",
        name: "in_app_notification_type",
        value: "alert_embibe_live",
    },
    {
        label: "Discover Achieve",
        name: "in_app_notification_type",
        value: "discover_achieve",
    },
];
export const MTG_NOTIFICATION_TYPES = [];
export const EMBIBE_COMMON_EMAIL_LIST = [
    "simi.james@embibe.com",
    "vishanth.v@embibe.com",
    "kenneth.noronha@embibe.com",
    "alka.chandra@embibe.com",
    "ankitk.ksingh@embibe.com",
    "bhargav.patel@embibe.com",
    "hiteshvishal_thinkjs@embibe.com",
    "sukanya@embibe.com",
    "dipit.kochar@embibe.com",
];

export const MAHENDRAS_EMAIL_LIST = [
    "head.digital@mahendras.org",
    "vp.anujsinghsengar@mahendras.org",
    "techsupport@mahendras.org",
];
export const MTG_EMAIL_LIST = ["sukanya@embibe.com", "dipit.kochar@embibe.com"];
export const CHANNEL = [
    {
        label: "Email",
        name: "embibe_Channel",
        value: "EM",
    },
    {
        label: "SMS",
        name: "embibe_Channel",
        value: "SMS",
    },
    {
        label: "WhatsApp",
        name: "embibe_Channel",
        value: "WA",
    },
    {
        label: "m-Push",
        name: "embibe_Channel",
        value: "PN",
    },
    {
        label: "In App",
        name: "embibe_Channel",
        value: "IA",
    },
    {
        label: "In App Popup",
        name: "embibe_Channel",
        value: "IA_POPUP",
    },
];

export const MAHENDRAS_CHANNEL = [
    {
        label: "m-Push",
        name: "embibe_Channel",
        value: "PN",
    },
    {
        label: "In App",
        name: "embibe_Channel",
        value: "IA",
    },
];
export const MTG_CHANNEL = [
    {
        label: "m-Push",
        name: "embibe_Channel",
        value: "PN",
    },
];
