import TextField from "@mui/material/TextField";
import "./index.css";

const InputField = ({
    id,
    label,
    variant,
    value,
    placeholder,
    handleChange,
    multiline,
    rows,
    name,
    inputDisabled,
    helperText,
    type,
    isError,
    style,
    isRequired,
    inputLabel,
    handleKeyChange,
    inputProps,
}) => {
    return (
        <div style={style ? { width: style } : { width: "100%" }}>
            <label className="input-label">{inputLabel}</label>
            <TextField
                id={id}
                onChange={handleChange}
                onKeyPress={handleKeyChange}
                value={value}
                label={label}
                variant={variant}
                name={name}
                placeholder={placeholder}
                margin="normal"
                style={{ width: "100%" , borderColor:"#7352cda1"}}
                multiline={multiline}
                rows={rows ? rows : ""}
                disabled={inputDisabled === true ? true : false}
                type={type}
                error={isError === true ? true : false}
                required={isRequired ? true : false}
                // inputDisable={disabled}
                // error
                helperText={isError === true ? "Please fill" : ""}
                inputProps={inputProps}
            />
        </div>
    );
};

export default InputField;
