import { useState } from "react";
import createEncryptor from "./encryptions";
export default function useToken() {
    const getToken = () => {
        const userToken = localStorage.getItem("token");
        if (userToken) {
            const decryptToken = createEncryptor(process.env.REACT_APP_SALTS).decrypt(userToken);
            return decryptToken;
        } else {
            return userToken;
        }
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        localStorage.setItem("token", userToken);
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token,
    };
}
