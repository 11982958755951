import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import appConfig from "../../../../config/app.config";
import "./index.css";

const CreateTemplate = ({ createTempChannel, handleCreateTempChannel, isTemplateView, channel }) => {
    const editTemplateId = localStorage.getItem("editTemplateId");
    const value = useSelector((state) => state.value);

    return (
        <>
            <div className="create-template-wrapper">
                <div className="create-template-channel">Channel</div>
                <FormControl fullWidth disabled={editTemplateId ? true : false}>
                    <Select
                        id="create-template"
                        value={createTempChannel ? createTempChannel : channel}
                        onChange={handleCreateTempChannel}
                    >
                        {appConfig[value].ENABLED_CHANNELS.map((channel) => (
                            <MenuItem value={channel.value} key={channel.value}>
                                {channel.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>
    );
};

export default CreateTemplate;
