import { useState, useEffect } from "react";
import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import WAPreview from "./WAPreview/WAPreview";
import PNPreview from "./PNPreview/PNPreview";
import SMSPreview from "./SMSPreview/SMSPreview";
import MobilePhoneTemplate from "./MobilePhoneTemplate";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { dateHelper } from "../../utils/helpers/dateHelper";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 780,
    height: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    // overflow: "scroll",
    display: "flex",
    justifyContent: "center",
};

const templateContainer = {
    margin: "50px 60px",
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: "14px 18px",
    // textAlign: "center",
    // color: theme.palette.text.secondary,
}));

const TemplatePreview = ({
    open,
    setOpen,
    handlePreviewClose,
    templateType,
    waBody,
    waImageLink,
    previewItem,
    waCtaLink,
    pushNBody,
    pushNTitle,
    smsBody,
    pnImageLink,
    templateDetails,
}) => {
    return (
        <div>
            <Modal
                open={open}
                onClose={handlePreviewClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {previewItem?.channel !== "EM" && (
                        <MobilePhoneTemplate templateType={templateType} previewItem={previewItem}>
                            {(templateType === "WA" || previewItem?.channel === "WA") && (
                                <WAPreview
                                    waBody={waBody}
                                    waImageLink={waImageLink}
                                    waCtaLink={waCtaLink}
                                    previewItem={previewItem}
                                />
                            )}
                            {(templateType === "PN" || previewItem?.channel === "PN") && (
                                <PNPreview
                                    pushNBody={pushNBody}
                                    pushNTitle={pushNTitle}
                                    pnImageLink={pnImageLink}
                                    previewItem={previewItem}
                                />
                            )}
                            {(templateType === "SMS" || previewItem?.channel === "SMS") && (
                                <SMSPreview smsBody={smsBody} previewItem={previewItem} />
                            )}
                        </MobilePhoneTemplate>
                    )}
                    {templateDetails === "temp" ? (
                        <div style={templateContainer}>
                            <div>Template Details</div>
                            <ul>
                                <li>Channel : {previewItem?.channel}</li>
                                <li>Created at : {dateHelper(previewItem?.created_at)}</li>
                                <li>Template Name : {previewItem?.template_name}</li>
                                <li>Template Type : {previewItem?.template_type}</li>
                                <li>Template ID : {previewItem?.template_id}</li>
                                <li>Status : {previewItem?.status}</li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default TemplatePreview;
