import axios from "axios";
import { toastError, toastSuccess, toastSuccessDelete } from "../../utils/helpers/toastError";
import { BASE_URL } from "../../utils/constants/apiBaseURL.constants";

// SEARCH THE TEMPLATES
const searchTemplateContent = async ({ serachText = "", page, rowsPerPage, application }) => {
    try {
        const response = await axios(
            `${BASE_URL}/user-cohort-ms/template/search?name=${serachText}&pageNo=${page}&pageSize=${rowsPerPage}&application=${application}`
        );
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// LINK A SINGLE TEMPLATE
const linkSearchTemplate = async ({ hypothesisID, data, successCb, errorCb }) => {
    try {
        const response = await axios.post(`${BASE_URL}/user-cohort-ms/hypothesis/${hypothesisID}/link-template`, data);
        if (response?.status === 200) {
            toastSuccess();
            successCb(response?.status);
            return response.data.payload;
        }
    } catch (error) {
        errorCb(error?.response?.data?.message_code);
        toastError(error);
        return Promise.reject(error);
    }
};

// UPDATE TEMPLATES
const updateTemplate = async ({ hypothesisID, updateTempId, data, successCb }) => {
    try {
        const response = await axios.put(
            `${BASE_URL}/user-cohort-ms/hypothesis/${hypothesisID}/link-template/${updateTempId}
      `,
            data
        );
        if (response?.status === 200) {
            successCb(response?.status);
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// DELETE TEMPLATE
const deleteTemplateAPI = async ({ hypothesisID, id }) => {
    try {
        const response = await axios.delete(
            `${BASE_URL}/user-cohort-ms/hypothesis/${hypothesisID}/link-template/${id}
    `
        );
        if (response?.status === 200) {
            toastSuccessDelete();
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// CREATE TEMPLATE
const createTemplateAPI = async (createData) => {
    try {
        const response = await axios.post(`${BASE_URL}/notification-ms-v2/template`, createData);
        if (response?.status === 200) {
            return response.data;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET SINGLE HYPOTHESIS DATA
const getHypothesisAPI = async (id) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/hypothesis/${id}`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET FACT TYPE
const getFactTypeAPI = async (application) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/fact-type?application=${application}`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET FACT TYPE DATA
const getFactTypeDataAPI = async (factType) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/fact-type/${factType}`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// EDIT TEMPLATE
const editTemplateAPI = async ({ templateId, data }) => {
    try {
        const response = await axios.put(`${BASE_URL}/notification-ms-v2/template/${templateId}`, data);
        // if (response?.status === 200) {
        //     toastSuccess();
        // }
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET ALL FACT TYPE VARIABLES
const getFactTypeVarsAPI = async (application) => {
    try {
        const response = await axios(`${BASE_URL}/user-cohort-ms/fact-type/all/variables?application=${application}`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

const createImageLinkAPI = async ({ formData, channelType }) => {
    try {
        const response = await axios.post(`${BASE_URL}/user-cohort-ms/upload?channel=${channelType}`, formData);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

const searchTemplateService = {
    searchTemplateContent,
    linkSearchTemplate,
    deleteTemplateAPI,
    updateTemplate,
    createTemplateAPI,
    getHypothesisAPI,
    getFactTypeAPI,
    getFactTypeDataAPI,
    editTemplateAPI,
    getFactTypeVarsAPI,
    createImageLinkAPI,
};
export default searchTemplateService;
