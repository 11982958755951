import { useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";

function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}

const TemplateTitle = ({ setMentions, setTitle, title }) => {
    const [value, setValue] = useState(title ? title : "");
    // const [mentions, setMentions] = useState();

    // const factTypeVariables = useSelector((state) => state?.template?.factTypeData?.variables);
    // const factTypeVars = useSelector((state) => state?.template?.factTypeVars);
    const factTypeVars = JSON.parse(localStorage.getItem("factTypeVars"));

    // FORMATTING THE ARRAY TO GET ARRAY OF OBJECTS WITH KEYS - ID AND DISPLAY
    const format = (array) => {
        const items = array?.map((display, index) => ({ id: index + 1, display }));
        return items;
    };
    let mentionData = format(factTypeVars);

    const handleChange = (event, newValue, newPlainTextValue, mentions) => {
        setValue(event.target.value);
        setTitle(event.target.value);
        localStorage.setItem("title", event.target.value);
        const mentionedVars = [];
        mentions.map((item) => mentionedVars.push(item.display));
        const tempMentions = removeDuplicates(mentionedVars);
        setMentions(tempMentions);
    };

    return (
        <div>
            <div style={{ margin: "16px 0" }}>Title *</div>
            <div className="template-title">
                <MentionsInput onChange={handleChange} value={value} className="mentions">
                    <Mention trigger="@" markup="{{__display__}}" data={mentionData} className="mentions__mention" />
                </MentionsInput>
            </div>
        </div>
    );
};

export default TemplateTitle;
