import { useState } from "react";
import EditNote from "../../../../assets/editNote.svg";
// import CloseIcon from "../../../../assets/closeIcon.svg";
import InputField from "../../../../components/InputField";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";

const Weightage = ({
    id,
    setWeightage,
    weightage,
    isWeightageEdit,
    setIsWeightageEdit,
    showUpdateBtn,
    setShowUpdateBtn,
}) => {
    const [error, setError] = useState(false);
    const toggleFrom = () => {
        setIsWeightageEdit(!isWeightageEdit);
        setError(false);
        setShowUpdateBtn(!showUpdateBtn);
    };

    const handleWeightageChange = (e) => {
        setWeightage({ id: id, weightage: e.target.value });
        if (e.target.value > 100) {
            toast.error("Weightage should be less than or equal to 100");
        }
    };

    return (
        <div className="template-table-cell-priority">
            {isWeightageEdit ? (
                <div className="template-table-input">
                    <InputField
                        id="outlined-helperText"
                        label="Weightage"
                        variant="outlined"
                        type="number"
                        handleChange={handleWeightageChange}
                        //  value={weightage}
                    />
                </div>
            ) : (
                <div>{weightage}%</div>
            )}

            {isWeightageEdit ? (
                <div className="template-table-close-icon" onClick={toggleFrom}>
                    <CloseIcon />
                </div>
            ) : (
                <div className="template-table-edit-icon" onClick={toggleFrom}>
                    <EditIcon fontSize="22px" />
                </div>
            )}
        </div>
    );
};

export default Weightage;
