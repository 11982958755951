import InputField from "../../../../components/InputField";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../../components/Button";

const DateRangePicker = ({ handleSave, handleClose, startDate, setStartDate, endData, setEndDate }) => {
    const handleStartDate = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    };

    return (
        <>
            <div className="test-user-modal-header">
                <span>Select Date Range</span>
                <span onClick={handleClose} style={{ cursor: "pointer" }}>
                    <CloseIcon />
                </span>
            </div>

            <div className="home-date-picker">
                <InputField
                    id="outlined-helperText"
                    variant="outlined"
                    name="startDate"
                    type="date"
                    inputLabel="Start Date"
                    value={startDate ?? ""}
                    handleChange={handleStartDate}
                />

                <InputField
                    id="outlined-helperText"
                    variant="outlined"
                    name="startDate"
                    type="date"
                    inputLabel="End Date"
                    value={endData ?? ""}
                    handleChange={handleEndDate}
                />
            </div>

            <div className="home-date-btn">
                <CustomButton handleClick={handleSave} buttonText="Apply" variant="outlined" />
            </div>
        </>
    );
};

export default DateRangePicker;
