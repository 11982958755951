import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import { getTestUser } from "../../../features/testUserList/testUserListSlice";
import { useDispatch, useSelector } from "react-redux";

const TestUserListTableRow = ({ item, setDeleteID, setOpenDelete }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDeleteClick = (id) => {
        setOpenDelete(true);
        setDeleteID(id);
    };

    const handleTableRowClick = () => {
        navigate(`/test-user/${item.users_list_id}/${item?.name}`);
    };
    return (
        <TableRow
            key={item?.users_list_id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            style={{ cursor: "pointer" }}
        >
            <TableCell onClick={handleTableRowClick}>{item?.name}</TableCell>
            <TableCell>
                <div className="template-table-close-icon" onClick={() => handleDeleteClick(item.users_list_id)}>
                    <DeleteIcon />
                </div>
            </TableCell>
        </TableRow>
    );
};

export default TestUserListTableRow;
