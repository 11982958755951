function clearLocalStorageExcept(excludedKeys) {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (!excludedKeys.includes(key)) {
            localStorage.removeItem(key);
        }
    }
}

export { clearLocalStorageExcept };
