import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userViewService from "./userViewService";

const initialState = {
    userViews: {},
    campaignDetails: {},
    userList: {},
    userTriggerData: {},
    allLinkedHypothesis: {},
    userViewName: {},
    totalUsersCount: "",
    searchedContent: {},
    isUserviewLoading: false,
    isUserviewNameLoading: false,
    isUserViewError: false,
    isUserViewSuccess: false,
    isUserViewErrorMsg: "",
    isError: false,
    isSuccess: false,
    isLoading: false,
    errors: {},
    message: "",
    dates: {},
};

export const getUserViews = createAsyncThunk("userViews/get", async (data, thunkAPI) => {
    try {
        return await userViewService.getUserViews(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const userViewCampaignDetails = createAsyncThunk(
    "userViewCampaignDetails/patch",
    async (hypothesisID, data, thunkAPI) => {
        try {
            return await userViewService.userViewCampaignDetails(hypothesisID, data);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const userListAPI = createAsyncThunk("userList/patch", async (application,thunkAPI) => {
    try {
        return await userViewService.userList(application);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const userViewTriggerExecute = createAsyncThunk(
    "userViewTriggerExecute/post",
    async (hypothesisID, thunkAPI) => {
        try {
            return await userViewService.userViewTrigger(hypothesisID);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getAllLinkedHypothesis = createAsyncThunk("allHypothesis/get", async (hypothesisID, thunkAPI) => {
    try {
        return await userViewService.getAllLinkedHypothesis(hypothesisID);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getUserViewName = createAsyncThunk("userViewName/get", async (userViewID, thunkAPI) => {
    try {
        return await userViewService.getUserViewNameAPI(userViewID);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const rescindUserView = createAsyncThunk("rescindUserView/delete", async (hypothesisID, thunkAPI) => {
    try {
        return await userViewService.rescindUserViewAPI(hypothesisID);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getTotalUsersCount = createAsyncThunk("totalUsersCount/get", async (userViewId, thunkAPI) => {
    try {
        return await userViewService.getTotalUsersCount(userViewId);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// SEARCH USER VIEW
export const searchUserView = createAsyncThunk("serachUserVeiew/get", async (data, thunkAPI) => {
    try {
        return await userViewService.searchUserView(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

//STORE START DATE IN REDUX
export const getStartDate = createAsyncThunk("startDate/get", async (data) => {
    return data;
});

export const userViewSlice = createSlice({
    name: "userView",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
        resetUserViewData: (state) => {
            state.userViewName = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserViews.pending, (state) => {
                state.isUserviewLoading = true;
            })
            .addCase(getUserViews.fulfilled, (state, action) => {
                state.isUserviewLoading = false;
                state.isSuccess = true;
                state.userViews = action.payload;
            })
            .addCase(getUserViews.rejected, (state, action) => {
                state.isUserviewLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(userViewCampaignDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(userViewCampaignDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.campaignDetails = action.payload;
            })
            .addCase(userViewCampaignDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(userListAPI.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(userListAPI.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.userList = action.payload;
            })
            .addCase(userListAPI.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(userViewTriggerExecute.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(userViewTriggerExecute.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.errors.handleExecuteError = false;
                state.userTriggerData = action.payload;
            })
            .addCase(userViewTriggerExecute.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errors.handleExecuteError = true;
                state.message = action.payload;
            })
            .addCase(getAllLinkedHypothesis.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllLinkedHypothesis.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.allLinkedHypothesis = action.payload;
            })
            .addCase(getAllLinkedHypothesis.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getUserViewName.pending, (state) => {
                state.isUserviewNameLoading = true;
            })
            .addCase(getUserViewName.fulfilled, (state, action) => {
                state.isUserviewNameLoading = false;
                state.isUserViewSuccess = true;
                state.userViewName = action.payload;
            })
            .addCase(getUserViewName.rejected, (state, action) => {
                state.isUserviewNameLoading = false;
                state.isUserViewError = true;
                state.isUserViewErrorMsg = action.payload;
            })
            .addCase(rescindUserView.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(rescindUserView.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                // state.userViewName = action.payload;
            })
            .addCase(rescindUserView.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getTotalUsersCount.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTotalUsersCount.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.totalUsersCount = action.payload;
            })
            .addCase(getTotalUsersCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(searchUserView.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(searchUserView.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.searchedContent = action.payload;
            })
            .addCase(searchUserView.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getStartDate.fulfilled, (state, action) => {
                state.dates.startDate = action.payload;
            });
    },
});

export const { reset, resetUserViewData } = userViewSlice.actions;
export default userViewSlice.reducer;
