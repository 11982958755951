import {
    NOTIFICATION_TAGS,
    NOTIFICATION_TYPES,
    MAHENDRAS_EMAIL_LIST,
    CHANNEL,
    MAHENDRAS_CHANNEL,
    MTG_CHANNEL,
    MAHENDRAS_NOTIFICATION_TAGS,
    MAHENDRAS_NOTIFICATION_TYPES,
    MTG_NOTIFICATION_TYPES,
    MTG_NOTIFICATION_TAGS,
    MTG_EMAIL_LIST,
} from "./config.constants";
const appConfig = {
    STUDENT: {
        EMAIL_LIST: [],
        SUCCESS_CRITERIA: [
            "LVDP_Restart",
            "LVDP_Stop",
            "LVDP_Play_Buffer_Stop",
            "LVDP_Play_Buffer",
            "LVDP_Seek",
            "LVDP_Start",
            "PQ_Continue_Click",
            "PQ_Practice_Started",
            "PQ_Check_Answer_Click",
            "PQ_Select_Answer_Click",
            "PQ_Load_Stop",
            "PQ_End_Practice_Click",
            "PQ_End_Practice_Confirm",
            "TINS_Read_Instruction_Click",
            "TQ_Select_Answer_Click",
            "TQ_Save_And_Next_Click",
            "TINS_Start_Test_Click",
            "TQ_Submit_Test_Click",
            "TQ_Submit_Test_Confirm",
            "TFB_Questionwise_Analysis_Click",
            "TFB_Questionwise_Analysis_Question_Click",
            "TFB_Strong_And_Weak_Analysis_Click",
            "TFB_Chapterwise_Analysis_Click",
            "UH_Favourite_Book_Click",
            "UH_RL_Apply_Filter",
            "LVDP_Resume",
            "LVDP_End",
            "AC_Create_Journey_Tile_Click",
            "AL_Enter_To_Achieve_Click",
            "UH_Add_Favourite_Book_Click",
            "TINS_Instructions_Tab_Click",
            "PQ_Timer_Color",
            "TQ_Previous_Click",
            "TQ_Submit_Test_Cancel",
            "LVDP_Quality_Click",
            "LVDP_Forward",
            "LVDP_Skip_Introduction",
            "TFB_Grade_Click",
            "TS_View_Test_Feedback_Click",
            "TFB_Achieve_Click",
            "UH_Favourite_Books_Added",
            "TFB_Your_Top_Skill_Analysis_Click",
            "UH_Manage_Favourite_Book_Click",
            "TFB_Negative_Behaviour_Analysis_Click",
            "TFB_Average_Time_Spent_Per_Question_Click",
            "LVDP_Update_Quality",
            "LVDP_Bookmark_Video",
            "LVDP_Download_Click",
            "PQ_Bookmark_Question",
            "PQ_Hint_Click",
            "PQ_SWU_Textbox_Click",
            "PQ_SWU_Micro_Hint_Click",
            "PQ_SWU_Textbox_Input_Text",
            "PQ_Hint_Close_Click",
            "PQ_Full_Solution_Click",
            "PQ_SWU_Reveal_Answer_Click",
            "PQ_FS_PCON_Video_Click",
            "LVDP_Rewind",
            "PQ_Solve_With_Us_Click",
            "TFB_Topicwise_Analysis_Click",
            "LVDP_Speed_Click",
            "LVDP_Update_Speed",
            "LVDP_Pause",
            "LVDP_Play",
            "TQ_Mark_For_Review_Click",
            "TQ_Attempt_Overview_Click",
            "LVSum_Bookmark_Video_Click",
            "UH_RL_Question_Accordion_Click",
            "UH_User_Home_Hero_Banner_Click",
            "LVDP_Continue",
            "TQ_View_Instructions_Click",
            "TQ_VP_Select_Question_Click",
            "TQ_View_Paper_Click",
            "TFB_Chapterwise_Analysis_Chapter_Click",
            "TFB_Questionwise_Analysis_Question_PCON_Video_Click",
            "TFB_Chapterwise_Analysis_Learn_Topic_Click",
            "TQ_VP_Filter_By_Attempt_Type_Click",
            "TFB_Negative_Behaviour_Video_Click",
            "TFB_Topicwise_Analysis_Learn_Topic_Click",
            "PQ_SWU_Next_Step_Click",
            "PQ_End_Practice_Cancel",
            "UH_RL_Topic_Accordion_Click",
            "PQ_Textbox_Click",
            "PQ_Textbox_Input_Text",
            "APAJ_See_Your_Journey_Click",
            "UH_RL_Chapter_Click",
            "UH_RL_Chapter_Question_Click",
            "UH_RL_Topic_Click",
            "UH_RL_Topic_Video_Click",
            "UH_RL_Learn_Topic_Click",
            "UH_RL_Question_PCON_Video_Click",
            "TQ_Input_Answer_Click",
            "TFB_Positive_Behaviour_Analysis_Click",
            "UH_TL_Practice_Question_SCON_Video_Click",
            "UH_TL_Practice_Question_PCON_Video_Click",
            "TQ_View_Summary_Click",
            "PQ_Session_Summary_Click",
            "PQ_Session_Summary_Close_Click",
            "PQ_Hide_Solution_Click",
            "PQ_FS_SCON_Video_Click",
            "TFB_Positive_Behaviour_Video_Click",
            "PQ_SWU_Step_Tab_Click",
            "TFB_Strong_And_Weak_Analysis_Learn_Topic_Click",
            "PH_Practice_Hero_Banner_Click",
            "TQ_Section_Tab_Click",
            "TFB_Questionwise_Analysis_Question_SCON_Video_Click",
            "LH_Learn_Hero_Banner_Click",
            "UH_RL_Question_SCON_Video_Click",
            "TH_Test_Hero_Banner_Click",
            "UH_RL_Practice_All_Questions_Click",
            "Chatbot_Click",
            "SI_Mobile_Email_Input",
            "SI_Sign_In_Success",
            "WL_Sign_Out_Success",
            "LH_Edit_Profile_Click",
            "PE_Select_Goal_Click",
            "PE_Goal_Next",
            "PE_Exam_Next",
            "PE_Select_Exam_Click",
            "PE_Language_Next",
            "PE_Profile_Updated",
            "LH_Subject_Filter_Click",
            "AL_Enter_To_Achieve_Click",
            "UH_Parent_Assignment_Click",
            "TH_Create_Your_Own_Test_Click",
            "TSum_Start_Test_Click",
            "TSum_View_Test_Feedback_Click",
            "PH_Big_Book_Click",
            "LH_Subject_Embibe_Explainer_Video_Click",
            "Search_Manual_Search",
            "Search_Results_Loaded",
            "UH_Edit_Profile_Click",
            "UH_Downloaded_Video_Click",
            "APAJ_View_Feedback_Click",
            "APAJ_Certified_Achievement_Feedback_Click",
            "LRECOM_Video_Click",
            "LSum_Points_To_Remember_Click",
            "PSum_Behaviour_Video_Click",
            "PSum_Topic_Click",
            "PSum_Book_Click",
            "BBTOC_Topic_Practice_Click",
            "PH_Subject_Book_Click",
            "AH_EM_Subject_Card_Click",
            "UH_Test_Taken_Click",
            "UH_Practice_All_Bookmarked_Questions",
            "PE_Edit_Goals_Click",
            "LH_Big_Book_Click",
            "LH_Embibe_Explainer_Video_Click",
            "LH_Subject_Book_Click",
            "LRECOM_AutoPlay_Video",
            "APAJ_View_Journey_Details_Click",
            "AH_Achieve_Journey_Tab_Click",
            "SU_Existing_User_SignIn_Click",
            "SU_Sign_Up_Proceed_Click",
            "SU_Account_Register_Success",
            "PH_Edit_Profile_Click",
            "LS_Sign_Up_Click",
            "UH_Invite_Parent_Click",
            "WL_Add_Profile_Click",
            "PA_Input_Name",
            "PA_Proceed_Click",
            "PA_Select_Goal_Click",
            "PA_Goal_Next",
            "PA_Language_Next",
            "PA_Exam_Next",
            "PA_New_Profile_Added",
            "PA_Select_Exam_Click",
            "UH_Parent_Invited",
            "LVSum_Download_Video_Click",
            "LH_Achieve_Ad_Click",
            "Search_Apply_Filter",
            "TH_Full_Test_Click",
            "GS_SignUP_Complete",
            "LH_Live_Class_Unregister_Success",
            "LH_Live_Class_Register_Now_Click",
            "LH_Live_Class_Registration_Success",
            "WL_Video_Quality_Click",
            "WL_Update_Video_Quality",
            "PA_Select_Language_Click",
            "TH_Custom_Test_Click",
            "MU_Search_Click",
            "PSum_Practice_Chapter_Click",
            "LH_Book_Click",
            "BTOC_Chapter_Click",
            "LH_Subject_Chapter_Click",
            "BTOC_Topic_Click",
            "APT_Select_Target_Date",
            "APT_PAJ_Start_Journey_Click",
            "APAJ_View_Feedback_&_Start_Click",
            "APAJ_Start_PAJ_Click",
            "AH_Explore_Mastery_Tab_Click",
            "LH_Boclips_Video_Click",
            "CYOT_Quick_Test_Creation_Start",
            "CYOT_Create_Custom_Test_Click",
            "ELC_Leave_Live_Class",
            "UH_ELC_Live_Class_Register_Now_Click",
            "UH_ELC_Live_Class_Registration_Success",
            "UH_ELC_Join_Live_Class_Click",
            "UH_ELC_Watch_Past_Live_Class_Click",
            "UH_ELC_Live_Class_Click",
            "LH_Join_Live_Class_Click",
            "LH_Live_Class_Click",
            "LH_Explore_Live_Class_Click",
            "ELCSum_Pre_Class_Video_Click",
            "ELCSum_Prerequisite_Video_Click",
            "ELCSum_Points_To_Remember_Click",
            "ELCSum_Live_Class_Register_Now_Click",
            "ELCSum_Live_Class_Registration_Success",
            "ELCSum_Join_Live_Class_Click",
            "ELCSum_Watch_Past_Live_Class_Click",
            "ELCSum_Live_Class_Click",
            "ELCSum_Recap_Video_Click",
            "ELCSum_Assignment_Click",
            "ELCSum_Test_Click",
            "ELCSum_View_Performance_Report_Click",
            "ELCSum_Live_Class_Unregister_Success",
            "ELC_Join_Live_Class_Click",
            "ELC_Watch_Past_Live_Class_Click",
            "ELC_Open_Question",
            "ELC_Close_Question",
            "ELC_Attempt_Question",
            "ELC_Live_Class_Ended",
            "ELC_Feedback_Submitted",
            "ELC_Post_Class_Activity_Click",
            "ELC_Video_Start",
            "ELC_Video_Play_Buffer",
            "ELC_Video_Play",
            "ELC_Video_Pause",
            "ELC_Video_Seek",
            "ELC_Video_End",
            "APAJ_SS_Video_Click",
            "TFB_Score_Click",
            "AL_Watch_Introduction_Video_Click",
            "TQ_VP_View_Options_Click",
            "UH_RL_Practice_Chapter_Click",
            "UH_TL_Practice_Question_Card_Click",
            "LS_Log_In_Click",
            "LS_Read_TOS_Click",
            "SI_Get_OTP_Click",
            "SI_Sign_In_Password_Click",
            "SI_Sign_In_OTP_Click",
            "SI_New_Sign_Up_Click",
            "SI_Input_Password",
            "SI_Sign_In_Proceed_Click",
            "SI_Forgot_Password_Click",
            "SI_Input_OTP",
            "SI_Resend_OTP_Click",
            "SI_OTP_Proceed_Click",
            "SI_OTP_Verification_Back_Click",
            "FP_Mobile_Email_Input",
            "FP_Get_OTP_Click",
            "FP_Input_OTP",
            "FP_OTP_Proceed_Click",
            "FP_Resend_OTP_Click",
            "FP_New_Password_Input",
            "FP_New_Password_Proceed_Click",
            "FP_OTP_Verification_Back_Click",
            "LS_Accept_TOS",
            "SU_Edit_Avatar_Click",
            "SU_Choose_Avatar",
            "SU_Input_Name",
            "SU_Input_Mobile",
            "SU_Input_Password",
            "SU_Whatsapp_Recommendations_Opt_In",
            "SU_Input_OTP",
            "SU_Resend_OTP_Click",
            "SU_OTP_Verification_Back_Click",
            "SU_OTP_Proceed_Click",
            "GS_Select_Goal_Click",
            "GS_Goal_Next",
            "GS_Back_Click",
            "GS_Select_Exam_Click",
            "GS_Exam_Next",
            "GS_Select_Language_Click",
            "GS_Language_Next",
            "UH_Big_Book_Click",
            "UH_Play_All_Bookmarked_Videos",
            "UH_Bookmarked_Video_Click",
            "UH_Bookmarked_Question_Click",
            "UH_Bookmarked_Question_PCON_Video_Click",
            "UH_Bookmarked_Question_SCON_Video_Click",
            "PASum_Assigned_Practice_Click",
            "PASum_Assigned_Video_Click",
            "PASum_Assigned_Test_Click",
            "UH_Home_Tab_Click",
            "UH_TL_Yearly_Card_Click",
            "UH_TL_Monthly_Card_Click",
            "UH_TL_Weekly_Card_Click",
            "UH_TL_Daily_Card_Click",
            "UH_TL_Hourly_Card_Click",
            "UH_TL_Practice_Card_Click",
            "UH_School_Join_Live_Class_Click",
            "UH_School_Assignment_Click",
            "UH_School_Assigned_Prerequisite_Video_Click",
            "UH_School_Assigned_Recap_Video_Click",
            "UH_School_Assigned_Test_Click",
            "UH_School_Assigned_Lesson_Click",
            "SASum_Assigned_Video_Click",
            "SASum_Assigned_Test_Click",
            "LCSum_Play_Coobo_Click",
            "LCSum_Related_Coobo_Click",
            "Coobo_Loaded",
            "Coobo_Started",
            "Coobo_Exit",
            "Coobo_Slide_Play",
            "Coobo_Slide_Pause",
            "Coobo_Go_To_Next_Slide",
            "Coobo_Go_To_Previous_Slide",
            "Coobo_Slide_Switch",
            "Coobo_Actions",
            "PH_Learn_Ad_View",
            "PH_Learn_Ad_Click",
            "PH_Test_Ad_View",
            "PH_Test_Ad_Click",
            "PH_Achieve_Ad_View",
            "PH_Achieve_Ad_Click",
            "PH_Subject_Filter_Click",
            "PH_Continue_Practice_Click",
            "PH_Book_Click",
            "PH_Trending_Book_For_Exam_Click",
            "PH_Chapter_Click",
            "PH_Subject_Chapter_Click",
            "PSum_Attempt_Quality_Click",
            "PSum_Points_To_Remember_Click",
            "PSum_Test_Click",
            "PSum_Recommended_Video_Click",
            "PSum_Coobo_Click",
            "PSum_Share_Practice_Details_Click",
            "LI_Continue_Practice_Click",
            "LI_Recommended_Video_Click",
            "API_Error",
            "TH_Learn_Ad_View",
            "TH_Learn_Ad_Click",
            "TH_Practice_Ad_View",
            "TH_Practice_Ad_Click",
            "TH_Achieve_Ad_View",
            "TH_Achieve_Ad_Click",
            "TH_Subject_Filter_Click",
            "TH_Edit_Profile_Click",
            "TH_Continue_Test_Click",
            "TH_Chapter_Test_Click",
            "TH_Subject_Chapter_Test_Click",
            "TH_Subject_Full_Test_Click",
            "TH_Finished_Test_Click",
            "TH_Previous_Year_Test_Click",
            "TH_Part_Test_Click",
            "TH_Subject_Part_Test_Click",
            "TH_Trending_Test_For_Exam_Click",
            "TSum_Resume_Test_Click",
            "TSum_Attempt_Quality_Click",
            "TSum_Behaviour_Video_Click",
            "TSum_Recommended_Video_Click",
            "TSum_Recommended_Practice_Click",
            "TSum_Test_Click",
            "TSum_Share_Test_Details_Click",
            "CYOT_Select_Subject",
            "CYOT_Create_Quick_Test_Click",
            "CYOT_Quick_Test_Created",
            "CYOT_Custom_Test_Creation_Start",
            "CYOT_Custom_Test_Created",
            "MU_Profile_Select_Click",
            "MU_LH_Click",
            "MU_PH_Click",
            "MU_TH_Click",
            "MU_UH_Click",
            "MU_AH_Click",
            "AL_Close_Onboarding_Video",
            "AL_Success_Story_Card_Click",
            "APR_Current_Readiness_Tab_Click",
            "APR_Future_Readiness_Tab_Click",
            "APR_Skills_Readiness_Tab_Click",
            "APR_Start_Achieve_Journey_Click",
            "AC_Create_Journey_Tile_Click",
            "AC_Continue_Tile_Resume_Click",
            "APAJ_Continue_Click",
            "APAJ_Extend_Journey_Click",
            "APAJ_Cancel_Journey_Extension",
            "APAJ_Extend_Target_Date",
            "APAJ_Select_Extension_Reason",
            "APAJ_Journey_Extended",
            "APAJ_Start_Certify_Achievement_Test_Click",
            "APAJ_Resume_Certify_Achievement_Test_Click",
            "AH_EM_Chapter_Click",
            "AH_EM_Topics_Click",
            "AH_EM_Topic_Video_Click",
            "AD_Test_Created_Achieve_Home_Click",
            "AD_Select_Subject",
            "AD_Continue_to_Chapter_Click",
            "AD_Select_Chapter",
            "AD_Subject_Tab_Click",
            "AD_Back_To_Subject_Click",
            "AD_Create_Test_Click",
            "AD_Take_Test_Later_Click",
            "AD_Start_Preq_Diagnostic_Test_Click",
            "AD_VFB_Preq_Diagnostic_Test_Click",
            "AT_Continue_To_ADT_Click",
            "AD_Start_Achieve_Diagnostic_Test_Click",
            "AD_Plan_Target_Click",
            "APT_Score_Click",
            "APT_Time_Increase_Click",
            "APT_Time_Decrease_Click",
            "APT_PAJ_Name_Click",
            "APAJ_Skip_Click",
            "APAJ_Milestone_Continue_Journey_Click",
            "APAJ_Achievement_Alert_Continue_Click",
            "APAJ_Summary_Back_Click",
            "APAJ_Summary_Continue_Click",
            "TINS_Take_Later_Click",
            "LVDP_Error",
            "LS_Loader_Video_Play",
            "LS_Loader_Video_End_Play",
            "LS_Cookie_Consent",
            "LS_Top_Navigation_Click",
            "LS_Learn_Ad_View",
            "LS_Practice_Ad_View",
            "LS_Test_Ad_View",
            "LS_Achieve_Ad_View",
            "LS_Track_Ad_View",
            "LS_Assign_Ad_View",
            "LS_Students_Ad_View",
            "LS_App_Install_Click",
            "LS_Footer_Social_Icon_Click",
            "LS_Footer_Options_Click",
            "LS_Footer_NCERT_Books_Click",
            "LS_Footer_NCERT_Solutions_Click",
            "LS_Footer_School_Click",
            "LS_Footer_Exams_Click",
            "LS_Play_Success_Stories",
            "LS_Read_Outcomes_Click",
            "AI_Explore_Knowlege_Graph_Click",
            "AI_Explore_Feature_Click",
            "AI_Read_Article_Click",
            "MyJio_Education_Tab_Loaded",
            "Get_Started_Click",
            "Whatsapp_Recommendations_Opt_In",
            "OBT_Onboarding_Tour_Displayed",
            "OBT_Next_Step_Click",
            "OBT_Previous_Step_Click",
            "OBT_Close_Onboarding_Tour",
            "MF_Sticky_Banner_Explore_Plans_Hover",
            "MF_Sticky_Banner_Explore_Plans_Click",
            "MF_Floater_Explore_Plans_Hover",
            "MF_Floater_Explore_Plans_Click",
            "MF_Floater_Drag",
            "MF_Choose_Plan_Click",
            "MF_Explore_Plans_Feature_Card_Click",
            "MF_Feature_Card_Expand_Click",
            "MF_Explore_Plans_Vertical_Scroll",
            "MF_Explore_Plans_Horizontal_Scroll",
            "MF_Explore_Plans_Awards_Click",
            "MF_Explore_Plans_Patents_Click",
            "MF_Explore_Plan_App_Reviews_Click",
            "MF_Explore_Plan_Choose_Plan_Video_Click",
            "MF_Explore_Plan_News_Media_Click",
            "MF_Explore_Plan_App_Icon_Click",
            "App_Put_In_Background",
            "App_Accessed_From_Background",
            "App_Close",
            "MF_Explore_Plan_Page_Paint_Start",
            "MF_Explore_Plan_Page_Paint_Stop",
            "MF_Checkout_Page_Paint_Start",
            "MF_Checkout_Page_Paint_Stop",
            "MF_Offers_Benefits_Page_Paint_Start",
            "MF_Offers_Benefits_Page_Paint_Stop",
            "MF_Billing_Details_Page_Paint_Start",
            "MF_Billing_Details_Page_Paint_Stop",
            "MF_Invoice_Download_Page_Paint_Start",
            "MF_Invoice_Download_Page_Paint_Stop",
            "MF_CO_Proceed_to_Pay_Success",
            "MF_CO_Proceed_to_Pay_Failed",
            "MF_Payment_Cross_Icon_Click",
            "MF_RP_Payment_Popup_Hidden",
            "MF_RP_Payment_Successful_Popup",
            "MF_Payment_Pending_Popup",
            "MF_RP_Payment_Failure_Popup",
            "MF_Apple_Payment_Successful_Popup",
            "MF_Apple_Payment_Failure_Popup",
            "MF_Apple_Payment_Initiate_Popup",
            "MF_CO_Back_Click",
            "MF_CO_See_How_Click",
            "MF_CO_Plan_Selection",
            "MF_CO_Spin_For_Discounts_Click",
            "MF_CO_Apply_Coupon_Click",
            "MF_CO_Change_Coupon_Click",
            "MF_CO_I_Have_A_Coupon_Click",
            "MF_CO_Autopay_Check_Click",
            "MF_CO_Terms_Services_Click",
            "MF_CO_Proceed_to_Pay_Click",
            "MF_OB_Tap_Spin_Wheel",
            "MF_OB_Input_Coupon_Code",
            "MF_OB_Input_Coupon_Code_Apply",
            "MF_OB_Click_Coupon_Code_Apply",
            "MF_OB_Congrats_Apply_Coupon_Code",
            "MF_OB_Back_Click",
            "MF_Payment_Successful_Popup",
            "MF_Payment_Fail_Retry_Popup",
            "MF_Payment_Pending_Popup",
            "MF_PS_Payment_Status",
            "MF_BD_Student_Input",
            "MF_BD_Parent_Checkbok",
            "MF_BD_User_Type_Checkbox",
            "MF_BD_Billing_Input",
            "MF_BD_Verify_Details_Click",
            "MF_IN_Input_OTP_Click",
            "MF_IN_Resend_OTP_Click",
            "MF_IN_OTP_Validation_Success",
            "MF_IN_Continue_Embibe_Journey_Click",
            "MF_IN_Download_Invoice_Click",
            "MF_IN_See_How_Click",
            "MF_FN_Back_Click",
            "MF_FN_Explore_Plans_Click",
            "MF_Menu_Subscription_Payments",
            "MF_Menu_Challenges_Rewards",
            "MF_Menu_Embium_Discounts",
            "GT_Displayed",
            "GT_Next_Step_Click",
            "GT_Previous_Step_Click",
            "GT_Close_Onboarding_Tour",
        ],
        NOTIFICATION_TAGS: NOTIFICATION_TAGS,
        NOTIFICATION_TYPES: NOTIFICATION_TYPES,
        PLATFORM_REACHABLITY_ENABLED: true,
        ENABLED_CHANNELS: CHANNEL,
        pnNotificationTypes: [
            {
                label: "App Only",
                name: "pn_notification_type",
                value: "APP",
            },
            {
                label: "Web Only",
                name: "pn_notification_type",
                value: "WEB",
            },
            {
                label: "IOS Only",
                name: "pn_notification_type",
                value: "IOS",
            },
            {
                label: "Android And Web",
                name: "pn_notification_type",
                value: "ANDROID_AND_WEB",
            },
            {
                label: "Both",
                name: "pn_notification_type",
                value: "BOTH",
            },
        ],
        TEMPLATE_EDITABLE: true,
    },
    PARENT: {
        EMAIL_LIST: [],
        SUCCESS_CRITERIA: [
            "LS_Get_Involved_Today_Click",
            "LS_Mobile_Input",
            "LS_Continue_Account_Verification_Click",
            "LS_TOS_Click",
            "LS_Whatsapp_Recommendations_Opt_In",
            "LS_Edit_Mobile_Click",
            "LS_Input_OTP",
            "LS_Resend_OTP_Click",
            "LS_OTP_Proceed_Click",
            "LS_OTP_Verification_Back_Click",
            "SI_Sign_In_Success",
            "SU_Sign_Up_Success",
            "API_Error",
            "Whatsapp_Recommendations_Opt_In",
            "MU_Notification_Icon_Click",
            "MU_Profile_Icon_Click",
            "MU_Track_Click",
            "MU_Assign_Click",
            "MU_Achieve_Click",
            "MU_More_Click",
            "MU_School_Click",
            "TC_Connect_Child_Click",
            "TC_Time_Spent_Card_Click",
            "TC_Practice_Question_Card_Click",
            "TC_Practice_Question_PCON_Video_Click",
            "TC_Practice_Qusetion_SCON_Video_Click",
            "ASN_Connect_Child_Click",
            "ASN_Create_Assignment_Click",
            "ASN_Generate_Assignment_Click",
            "ASN_Assignment_Generation_Start",
            "ASN_Assignment_Generated",
            "ASN_Assignment_Review_Next_Click",
            "ASN_Assignment_Finalise_And_Send_Click",
            "ASN_Assignment_Creation_Start",
            "ASN_Assignment_Created",
            "ASN_Create_My_Own_Assignment_Click",
            "ASN_Select_Book_And_Activity_Next_Click",
            "ASN_Select_Content_Next_Click",
            "ASN_Assignment_Click",
            "ASN_School_Assignment_Click",
            "ASUM_Video_Click",
            "ASUM_Practice_Click",
            "ASUM_Test_Click",
            "TFB_Achieve_Click",
            "TFB_Score_Click",
            "TFB_Grade_Click",
            "TFB_Your_Top_Skill_Analysis_Click",
            "TFB_Positive_Behaviour_Analysis_Click",
            "TFB_Negative_Behaviour_Analysis_Click",
            "TFB_Average_Time_Spent_Click",
            "TFB_Chapterwise_Analysis_Click",
            "TFB_Topicwise_Analysis_Click",
            "TFB_Questionwise_Analysis_Click",
            "TFB_Strong_And_Weak_Analysis_Click",
            "TFB_Positive_Behaviour_Video_Click",
            "TFB_Negative_Behaviour_Video_Click",
            "TFB_Chapterwise_Analysis_Accordion_Click",
            "TFB_Chapterwise_Analysis_Chapter_Click",
            "TFB_Topicwise_Analysis_Accordion_Click",
            "TFB_Question_Analysis_Accordion_Click",
            "TFB_Question_Analysis_Question_Click",
            "TFB_Question_Analysis_PCON_Video_Click",
            "TFB_Question_Analysis_SCON_Video_Click",
            "TFB_Strong_Weak_Chap_Analysis_Acc_Click",
            "TFB_Strong_Weak_Analysis_Chapter_Click",
            "TFB_Strong_Weak_Topic_Analysis_Acc_Click",
            "MO_Select_Child_Profile",
            "MO_Language_Settings_Click",
            "MO_Update_Language",
            "MO_Read_TOS_Click",
            "MO_Notifications_Click",
            "MO_Sign_Out_Click",
            "MO_Sign_Out_Cancel",
            "MO_Sign_Out_Success",
            "MO_Manage_Profiles_Click",
            "MO_Add_Child_Profile_Click",
            "MP_Edit_Parent_Profile_Click",
            "MP_Edit_Child_Profile_Click",
            "MP_Add_Child_Profile_Click",
            "EPP_Profile_Updated",
            "ECP_Exam_Category",
            "ECP_Select_Goal",
            "ECP_Select_Exam",
            "ECP_Select_Language",
            "ECP_Profile_Updated",
            "ACP_Create_Child_Account_Click",
            "ACP_Mobile_Email_Input",
            "ACP_Add_Child_Proceed",
            "ACP_Input_Name",
            "ACP_Select_Study_Location",
            "ACP_Input_School_Name",
            "ACP_Select_City",
            "ACP_Select_Gender",
            "ACP_Select_Age",
            "ACP_Select_Exam_Category",
            "ACP_Select_Goal",
            "ACP_Select_Exam",
            "ACP_Select_Subject_And_Books",
            "ACP_Child_Profile_Added",
            "LCP_Connect_Child_Click",
            "LCP_Mobile_Email_Input",
            "LCP_Input_Password",
            "LCP_Link_Using_OTP_Click",
            "LCP_Link_Using_Password_Click",
            "LCP_Link_Child_Proceed_Click",
            "LCP_Get_OTP_Click",
            "LCP_Input_OTP",
            "LCP_Resend_OTP_Click",
            "LCP_Edit_Mobile_Email_Click",
            "LCP_OTP_Proceed_Click",
            "LCP_Select_Child_Profile",
            "LCP_Child_Account_Linked_Success",
            "Install_Student_App_Click",
            "Accept_Notification",
            "Reject_Notification",
            "ACH_Connect_Child_Click",
            "MO_Account_Settings_Click",
            "ACT_Delete_Account_Click",
            "ACT_Delete_Account_Input_OTP",
            "ACT_Delete_Account_Resend_OTP",
            "ACT_Delete_Account_Proceed",
            "ACT_Account_Deleted",
            "MO_Deactivate_Account_Click",
            "ACT_Deactivate_Account_Input_OTP",
            "ACT_Deactivate_Account_Resend_OTP",
            "ACT_Deactivate_Account_Proceed",
            "ACT_Account_Deactivated",
            "ACT_Account_Reactivated",
            "School_Homework_Tab_Click",
            "School_Homework_Click",
        ],
        NOTIFICATION_TAGS: NOTIFICATION_TAGS,
        NOTIFICATION_TYPES: NOTIFICATION_TYPES,
        PLATFORM_REACHABLITY_ENABLED: true,
        ENABLED_CHANNELS: CHANNEL,
        pnNotificationTypes: [
            {
                label: "App Only",
                name: "pn_notification_type",
                value: "APP",
            },
        ],
        TEMPLATE_EDITABLE: true,
    },
    SCHOOL: {
        EMAIL_LIST: [],
        SUCCESS_CRITERIA: [
            "Navigation_Menu_Click",
            "MP_Edit_Profile_Click",
            "MS_Apply_Filter",
            "MST_Class_Click",
            "MC_Class_Click",
            "MC_Add_Book_Click",
            "MC_Book_Added",
            "MC_Book_Removed",
            "MC_Slot_Click",
            "AS_Assign_Test_Click",
            "AS_Assign_Test_Select_Test_Type",
            "AS_Slot_Click",
            "AP_Create_Lesson_Click",
            "AP_Lesson_Created",
            "AP_Lesson_Published",
            "AP_Lesson_Unpublished",
            "AP_Mark_Completed",
            "AP_Edit_Topic_Click",
            "AP_Topic_Updated",
            "AP_Edit_Lesson_Click",
            "AP_Slot_Click",
            "ASH_Assigned_Homework_Click",
            "ASH_Assigned_Homework_Due_Date_Extended",
            "AT_Assigned_Test_Click",
            "AT_Assigned_Test_Deleted",
            "AP_Create_Embibe_Preset_Test_Click",
            "EP_Select_Test_Loaded",
            "EP_Select_Test",
            "EP_Select_Test_Back",
            "EP_Review_Questions_Loaded",
            "EP_Review_Questions",
            "EP_Review_Questions_Back",
            "EP_Review_Instructions_Loaded",
            "EP_Review_Instructions",
            "EP_Review_Instructions_Back",
            "EP_Assign_Test_Loaded",
            "EP_Assign_Test_Click",
            "EP_Assign_Test_Back",
            "EP_Download_Assign_Test_Pdf",
            "EP_Assign_Test_Success",
            "AP_Create_Your_Own_Test_Click",
            "CYOT_ST_Create_Test_Click",
            "CYOT_ST_Confirm_Syllabus_And_Books_Loaded",
            "CYOT_ST_Confirm_Syllabus_And_Books",
            "CYOT_ST_Review_Questions_Loaded",
            "CYOT_ST_Review_Questions",
            "CYOT_ST_Review_Questions_Back",
            "CYOT_ST_Preview_Test_Loaded",
            "CYOT_ST_Preview_Test",
            "CYOT_ST_Preview_Test_Back",
            "CYOT_ST_Download_Assign_Test_Pdf",
            "CYOT_ST_Assign_Test_Loaded",
            "CYOT_ST_Assign_Test_Click",
            "CYOT_ST_Assign_Test_Back",
            "CYOT_ST_Assign_Test_Success",
            "CYOT_MT_Edit_My_Template",
            "CYOT_MT_Delete_My_Template",
            "CYOT_MT_Create_Test_Click",
            "CYOT_MT_Confirm_Syllabus_And_Books_Loaded",
            "CYOT_MT_Confirm_Syllabus_And_Books_Back",
            "CYOT_MT_Confirm_Syllabus_And_Books",
            "CYOT_MT_Review_Questions_Loaded",
            "CYOT_MT_Review_Questions",
            "CYOT_MT_Review_Questions_Back",
            "CYOT_MT_Preview_Test_Loaded",
            "CYOT_MT_Preview_Test",
            "CYOT_MT_Preview_Test_Back",
            "CYOT_MT_Download_Assign_Test_Pdf",
            "CYOT_MT_Assign_Test_Loaded",
            "CYOT_MT_Assign_Test_Click",
            "CYOT_MT_Assign_Test_Back",
            "CYOT_MT_Assign_Test_Success",
            "CYOT_SL_Create_Test_Click",
            "CYOT_SL_Confirm_Syllabus_And_Books_Loaded",
            "CYOT_SL_Confirm_Syllabus_And_Books",
            "CYOT_SL_Review_Questions_Loaded",
            "CYOT_SL_Review_Questions",
            "CYOT_SL_Review_Questions_Back",
            "CYOT_SL_Preview_Test_Loaded",
            "CYOT_SL_Preview_Test",
            "CYOT_SL_Preview_Test_Back",
            "CYOT_SL_Download_Assign_Test_Pdf",
            "CYOT_SL_Assign_Test_Loaded",
            "CYOT_SL_Assign_Test_Click",
            "CYOT_SL_Assign_Test_Back",
            "CYOT_SL_Assign_Test_Success",
            "CYOT_Create_New_Template_Click",
            "CYOT_Template_Created",
            "CYOT_Search_Template_Click",
            "AP_Create_Your_Own_Homework_Click",
            "CYOH_Select_Syllabus_Book_And_Activity_Loaded",
            "CYOH_Select_Syllabus_Book_And_Activity",
            "CYOH_Select_Syllabus_Book_And_Activity_Back",
            "CYOH_Select_Learn_Content_Loaded",
            "CYOH_Select_Learn_Content",
            "CYOH_Select_Learn_Content_Back",
            "CYOH_Select_Practice_Content_Loaded",
            "CYOH_Select_Practice_Content",
            "CYOH_Select_Practice_Content_Back",
            "CYOH_Add_Homework_Details_Loaded",
            "CYOH_Add_Homework_Details",
            "CYOH_Add_Homework_Details_Back",
            "CYOH_Assign_Homework_Loaded",
            "CYOH_Assign_Homework_Click",
            "CYOH_Assign_Homework_Back",
            "CYOH_Assign_Homework_Success",
            "AP_Embibe_Recommended_Homework_Click",
            "ERH_Select_Homework_Type",
            "ERH_Select_Syllabus_Book_And_Activity_Loaded",
            "ERH_Select_Syllabus_Book_And_Activity",
            "ERH_Select_Syllabus_Book_And_Activity_Back",
            "ERH_Add_Homework_Details_Loaded",
            "ERH_Add_Homework_Details",
            "ERH_Add_Homework_Details_Back",
            "ERH_Assign_Homework_Success",
            "Apply_Class_Filter",
            "Outcomes_Panel_Viewed",
            "Mastery_Improvement_View_Details_Click",
            "Mastery_Improvement_Panel_Viewed",
            "Average_Mastery_Improvement_Panel_Click",
            "Syllabus_Coverage_View_Details_Click",
            "Syllabus_Coverage_Panel_Viewed",
            "Syllabus_Coverage_Panel_Click",
            "Syllabus_Coverage_Back_Click",
            "Score_Improvement_View_Details_Click",
            "Score_Improvement_Panel_Viewed",
            "Average_Score_Improvement_Panel_Click",
            "Score_Improvement_Back_Click",
            "Effort_Insights_Panel_Viewed",
            "Behavioural_Improvement_Panel_Viewed",
            "Behavioural_Improvement_View_Details_Click",
            "Average_Behavioural_Improvement_Panel_Click",
            "Behavioural_Improvement_Back_Click",
            "Homework_Completion_View_Details_Click",
            "Homework_Completion_Panel_Viewed",
            "Homework_Completion_Back_Click",
            "Class_Participation_View_Details_Click",
            "Class_Participation_Panel_Viewed",
            "Average_Class_Participation_Panel_Click",
            "Class_Participation_Back_Click",
            "Learning_Gaps_Panel_Viewed",
            "Learning_Gaps_View_Details_Click",
            "Learning_Gaps_Back_Click",
            "Concept_Mastery_Improvement_Panel_Viewed",
            "Concept_Mastery_Improvement_View_Details_Click",
            "Concept_Mastery_Improvement_Back_Click",
            "Average_Test_Scores_Panel_Viewed",
            "Average_Test_Scores_Panel_Click",
            "Studentwise_Analysis_Panel_Viewed",
            "Studentwise_Analysis_View_Details_Click",
            "Studentwise_Analysis_Panel_Click",
            "Studentwise_Analysis_Back_Click",
            "Download_Studentwise_Analysis_Report",
            "Achieve_Analysis_Panel_Viewed",
            "Homework_Analysis_Panel_Viewed",
            "Class_Homework_Analysis_Click",
            "Class_Homework_Analysis_Widget_Click",
            "Class_Homework_Analysis_Back_Click",
            "Test_Analysis_Panel_Viewed",
            "Class_Test_Analysis_Click",
            "Student_Test_Analysis_Click",
            "Class_Test_Analysis_Widget_Click",
            "Class_Test_Analysis_Back_Click",
            "Student_Test_Analysis_Widget_Click",
            "Student_Test_Analysis_Back_Click",
        ],
        NOTIFICATION_TAGS: NOTIFICATION_TAGS,
        NOTIFICATION_TYPES: NOTIFICATION_TYPES,
        PLATFORM_REACHABLITY_ENABLED: true,
        ENABLED_CHANNELS: CHANNEL,
        pnNotificationTypes: [
            {
                label: "Web Only",
                name: "pn_notification_type",
                value: "WEB",
            },
        ],
        TEMPLATE_EDITABLE: true,
    },
    EXPERIMENT: {
        EMAIL_LIST: [],
        SUCCESS_CRITERIA: [
            "SI_Mobile_Email_Input",
            "SI_Get_OTP_Click",
            "SI_Sign_In_Using_Password_Click",
            "SI_Sign_In_Using_OTP_Click",
            "SI_New_Sign_Up_Click",
            "SI_Input_Password",
            "SI_Sign_In_Proceed_Click",
            "SI_Forgot_Password_Click",
            "SI_Read_TOS_Click",
            "SI_Input_OTP",
            "SI_Resend_OTP_Click",
            "SI_OTP_Proceed_Click",
            "SI_OTP_Verification_Back_Click",
            "SI_Sign_In_Success",
            "FP_Mobile_Email_Input",
            "FP_Get_OTP_Click",
            "FP_Input_OTP",
            "FP_OTP_Proceed_Click",
            "FP_Resend_OTP_Click",
            "FP_New_Password_Input",
            "FP_New_Password_Proceed_Click",
            "FP_OTP_Verification_Back_Click",
            "SU_Read_TOS_Click",
            "SU_Edit_Avatar_Click",
            "SU_Choose_Avatar",
            "SU_Existing_User_SignIn_Click",
            "SU_Input_Name",
            "SU_Input_Mobile",
            "SU_Input_Password",
            "SU_Whatsapp_Recommendations_Opt_In",
            "SU_Sign_Up_Proceed_Click",
            "SU_Account_Register_Success",
            "SU_Input_OTP",
            "SU_Resend_OTP_Click",
            "SU_OTP_Verification_Back_Click",
            "SU_OTP_Proceed_Click",
            "GS_Select_Goal_Click",
            "GS_Goal_Next",
            "GS_Select_Exam_Click",
            "GS_Exam_Next",
            "GS_Select_Language_Click",
            "GS_Language_Next",
            "GS_Back_Click",
            "GS_SignUP_Complete",
            "EH_Hero_Banner_Click",
            "EH_Experiment_For_Exam_Click",
            "EH_Subject_Experiment_Click",
            "EH_Video_App_Install_Banner_Click",
            "EH_Edit_Profile_Click",
            "EH_Search_Click",
            "EH_Profile_Icon_Click",
            "ESum_Start_Experiment_Click",
            "ESum_Continue_Experiment_Click",
            "ESum_Prerequisite_Topic_Click",
            "ESum_Related_Experiment_Click",
            "TSum_Experiment_Click",
            "TSum_Video_Click",
            "TSum_Related_Topic_Click",
            "TSum_Prerequisite_Topic_Click",
            "TSum_Video_App_Install_Banner_Click",
            "Video_Resume",
            "Video_Restart",
            "Video_Play_Start",
            "Video_Play_Buffer_Start",
            "Video_Play_Buffer_Stop",
            "Video_Play",
            "Video_Pause",
            "Video_Seek",
            "Video_Rewind",
            "Video_Forward",
            "Video_End",
            "Video_Stop",
            "Video_Continue",
            "Video_Error",
            "Experiment_Module_Selection_Loaded",
            "Experiment_Module_Selection_Exit",
            "Select_Experiment_Module",
            "Start_Experiment_Intoduction_Video",
            "Exit_Experiment_Introduction",
            "Experiment_Loaded",
            "Experiment_Started",
            "Experiment_Actions",
            "Experiment_Previous_Step_Click",
            "Experiment_Next_Step_Click",
            "Experiment_Skip_Step",
            "View_Experiment_Details",
            "Continue_To_Evaluation",
            "Continue_Experiment",
            "Exit_Experiment",
            "Experiment_Evaluation_Loaded",
            "Start_Experiment_Evaluation_Click",
            "Evaluation_Started",
            "Evaluation_View_Question",
            "Evaluation_Previous_Question_Click",
            "Evaluation_Next_Question_Click",
            "Evaluation_Attempt_Question",
            "Evaluation_Skip_Question",
            "Evaluation_View_Question_Solution",
            "Evaluation_View_Experiment_Details",
            "Continue_Experiment_Evaluation",
            "Exit_Evaluation",
            "Evaluation_Submitted",
            "Retake_Evaluation",
            "Search_Results_Loaded",
            "Search_Results_Click",
            "Search_Experiment_Click",
            "PE_Select_Goal_Click",
            "PE_Goal_Next",
            "PE_Select_Exam_Click",
            "PE_Exam_Next",
            "PE_Select_Language_Click",
            "PE_Language_Next",
            "PE_Back_Click",
            "PE_Profile_Updated",
        ],
        NOTIFICATION_TAGS: NOTIFICATION_TAGS,
        NOTIFICATION_TYPES: NOTIFICATION_TYPES,
        PLATFORM_REACHABLITY_ENABLED: true,
        ENABLED_CHANNELS: CHANNEL,
        pnNotificationTypes: [
            {
                label: "App Only",
                name: "pn_notification_type",
                value: "APP",
            },
        ],
        TEMPLATE_EDITABLE: true,
    },
    LENS: {
        EMAIL_LIST: [],
        SUCCESS_CRITERIA: [
            "LS_Lets_Begin_Click",
            "LS_Log_In_Click",
            "LS_Sign_Up_Click",
            "SI_Mobile_Email_Input",
            "SI_Get_OTP_Click",
            "SI_Sign_In_Using_Password_Click",
            "SI_Sign_In_Using_OTP_Click",
            "SI_New_Sign_Up_Click",
            "SI_Input_Password",
            "SI_Sign_In_Proceed_Click",
            "SI_Forgot_Password_Click",
            "SI_Read_TOS_Click",
            "SI_Input_OTP",
            "SI_Resend_OTP_Click",
            "SI_OTP_Proceed_Click",
            "SI_OTP_Verification_Back_Click",
            "SI_Sign_In_Success",
            "FP_Mobile_Email_Input",
            "FP_Get_OTP_Click",
            "FP_Input_OTP",
            "FP_OTP_Proceed_Click",
            "FP_Resend_OTP_Click",
            "FP_New_Password_Input",
            "FP_New_Password_Proceed_Click",
            "FP_OTP_Verification_Back_Click",
            "FP_Read_TOS_Click",
            "SU_Read_TOS_Click",
            "SU_Edit_Avatar_Click",
            "SU_Choose_Avatar",
            "SU_Existing_User_SignIn_Click",
            "SU_Input_Name",
            "SU_Input_Mobile",
            "SU_Input_Password",
            "SU_Whatsapp_Recommendations_Opt_In",
            "SU_Sign_Up_Proceed_Click",
            "SU_Account_Register_Success",
            "SU_Input_OTP",
            "SU_Resend_OTP_Click",
            "SU_OTP_Verification_Back_Click",
            "SU_OTP_Proceed_Click",
            "GS_Select_Goal_Click",
            "GS_Goal_Next",
            "GS_Select_Exam_Click",
            "GS_Exam_Next",
            "GS_Back_Click",
            "GS_SignUP_Complete",
            "GS_Scan_For_Knowledge_Click",
            "API_Error",
            "Image_Captured",
            "Image_Imported",
            "Search_Entities_Click",
            "Image_Scanning_Started",
            "Search_Results_Loaded",
            "Entity_Click",
            "Search_Feature_Discovery_Options_Click",
            "DR_Image_Captured",
            "DR_Image_Imported",
            "DR_Image_Scanning_Started",
            "DR_Search_Results_Loaded",
            "DR_Search_Results_Not_Found_Options_Click",
            "DR_Question_Click",
            "DR_Learning_Video_Click",
            "DR_Student_Video_App_Install_Banner_Click",
            "DR_Practice_Banner_Click",
            "DR_Student_Practice_App_Install_Banner_Click",
            "AR_Asset_Download_Start",
            "AR_Asset_Downloaded",
            "AR_Asset_Actions",
            "AR_Related_Matches_Click",
            "AR_Reset_Asset_Position",
            "AR_Asset_Label_Enabled",
            "AR_Asset_Label_Disabled",
            "AR_Related_Video_Click",
            "AR_Related_Experiment_Click",
            "AR_Report_Issue_Click",
            "AR_Issue_Reported_Successfully",
            "AR_Student_Video_App_Install_Banner_Click",
            "AR_Experiments_App_Install_Banner_Click",
            "Video_Play_Start",
            "Video_Play_Buffer_Start",
            "Video_Play_Buffer_Stop",
            "Video_Play",
            "Video_Pause",
            "Video_Seek",
            "Video_Rewind",
            "Video_Forward",
            "Video_End",
            "Video_Stop",
            "Video_Error",
            "Error",
            "ACT_Edit_Profile_Click",
            "ACT_Sign_Out_Success",
            "ACT_Read_TOS_Click",
            "ACT_Report_Issue_Click",
            "ACT_Delete_Account_Click",
            "ACT_Delete_Account_Input_OTP",
            "ACT_Delete_Account_Resend_OTP",
            "ACT_Delete_Account_Proceed",
            "ACT_Account_Deleted",
            "PE_Select_Goal_Click",
            "PE_Goal_Next",
            "PE_Select_Exam_Click",
            "PE_Exam_Next",
            "PE_Back_Click",
            "PE_Profile_Updated",
        ],
        NOTIFICATION_TAGS: NOTIFICATION_TAGS,
        NOTIFICATION_TYPES: NOTIFICATION_TYPES,
        PLATFORM_REACHABLITY_ENABLED: true,
        ENABLED_CHANNELS: CHANNEL,
        pnNotificationTypes: [
            {
                label: "App Only",
                name: "pn_notification_type",
                value: "APP",
            },
        ],
        TEMPLATE_EDITABLE: true,
    },
    CLASSROOM: {
        EMAIL_LIST: [],
        SUCCESS_CRITERIA: [
            "LVDP_Restart",
            "LVDP_Stop",
            "LVDP_Play_Buffer_Stop",
            "LVDP_Play_Buffer",
            "LVDP_Seek",
            "LVDP_Start",
            "PQ_Continue_Click",
            "PQ_Practice_Started",
            "PQ_Check_Answer_Click",
            "PQ_Select_Answer_Click",
            "PQ_Load_Stop",
            "PQ_End_Practice_Click",
            "PQ_End_Practice_Confirm",
            "TINS_Read_Instruction_Click",
            "TQ_Select_Answer_Click",
            "TQ_Save_And_Next_Click",
            "TINS_Start_Test_Click",
            "TQ_Submit_Test_Click",
            "TQ_Submit_Test_Confirm",
            "TFB_Questionwise_Analysis_Click",
            "TFB_Questionwise_Analysis_Question_Click",
            "TFB_Strong_And_Weak_Analysis_Click",
            "TFB_Chapterwise_Analysis_Click",
            "UH_Favourite_Book_Click",
            "UH_RL_Apply_Filter",
            "LVDP_Resume",
            "LVDP_End",
            "AC_Create_Journey_Tile_Click",
            "AL_Enter_To_Achieve_Click",
            "UH_Add_Favourite_Book_Click",
            "TINS_Instructions_Tab_Click",
            "PQ_Timer_Color",
            "TQ_Previous_Click",
            "TQ_Submit_Test_Cancel",
            "LVDP_Quality_Click",
            "LVDP_Forward",
            "LVDP_Skip_Introduction",
            "TFB_Grade_Click",
            "TS_View_Test_Feedback_Click",
            "TFB_Achieve_Click",
            "UH_Favourite_Books_Added",
            "TFB_Your_Top_Skill_Analysis_Click",
            "UH_Manage_Favourite_Book_Click",
            "TFB_Negative_Behaviour_Analysis_Click",
            "TFB_Average_Time_Spent_Per_Question_Click",
            "LVDP_Update_Quality",
            "LVDP_Bookmark_Video",
            "LVDP_Download_Click",
            "PQ_Bookmark_Question",
            "PQ_Hint_Click",
            "PQ_SWU_Textbox_Click",
            "PQ_SWU_Micro_Hint_Click",
            "PQ_SWU_Textbox_Input_Text",
            "PQ_Hint_Close_Click",
            "PQ_Full_Solution_Click",
            "PQ_SWU_Reveal_Answer_Click",
            "PQ_FS_PCON_Video_Click",
            "LVDP_Rewind",
            "PQ_Solve_With_Us_Click",
            "TFB_Topicwise_Analysis_Click",
            "LVDP_Speed_Click",
            "LVDP_Update_Speed",
            "LVDP_Pause",
            "LVDP_Play",
            "TQ_Mark_For_Review_Click",
            "TQ_Attempt_Overview_Click",
            "LVSum_Bookmark_Video_Click",
            "UH_RL_Question_Accordion_Click",
            "UH_User_Home_Hero_Banner_Click",
            "LVDP_Continue",
            "TQ_View_Instructions_Click",
            "TQ_VP_Select_Question_Click",
            "TQ_View_Paper_Click",
            "TFB_Chapterwise_Analysis_Chapter_Click",
            "TFB_Questionwise_Analysis_Question_PCON_Video_Click",
            "TFB_Chapterwise_Analysis_Learn_Topic_Click",
            "TQ_VP_Filter_By_Attempt_Type_Click",
            "TFB_Negative_Behaviour_Video_Click",
            "TFB_Topicwise_Analysis_Learn_Topic_Click",
            "PQ_SWU_Next_Step_Click",
            "PQ_End_Practice_Cancel",
            "UH_RL_Topic_Accordion_Click",
            "PQ_Textbox_Click",
            "PQ_Textbox_Input_Text",
            "APAJ_See_Your_Journey_Click",
            "UH_RL_Chapter_Click",
            "UH_RL_Chapter_Question_Click",
            "UH_RL_Topic_Click",
            "UH_RL_Topic_Video_Click",
            "UH_RL_Learn_Topic_Click",
            "UH_RL_Question_PCON_Video_Click",
            "TQ_Input_Answer_Click",
            "TFB_Positive_Behaviour_Analysis_Click",
            "UH_TL_Practice_Question_SCON_Video_Click",
            "UH_TL_Practice_Question_PCON_Video_Click",
            "TQ_View_Summary_Click",
            "PQ_Session_Summary_Click",
            "PQ_Session_Summary_Close_Click",
            "PQ_Hide_Solution_Click",
            "PQ_FS_SCON_Video_Click",
            "TFB_Positive_Behaviour_Video_Click",
            "PQ_SWU_Step_Tab_Click",
            "TFB_Strong_And_Weak_Analysis_Learn_Topic_Click",
            "PH_Practice_Hero_Banner_Click",
            "TQ_Section_Tab_Click",
            "TFB_Questionwise_Analysis_Question_SCON_Video_Click",
            "LH_Learn_Hero_Banner_Click",
            "UH_RL_Question_SCON_Video_Click",
            "TH_Test_Hero_Banner_Click",
            "UH_RL_Practice_All_Questions_Click",
            "Chatbot_Click",
            "SI_Mobile_Email_Input",
            "SI_Sign_In_Success",
            "WL_Sign_Out_Success",
            "LH_Edit_Profile_Click",
            "PE_Select_Goal_Click",
            "PE_Goal_Next",
            "PE_Exam_Next",
            "PE_Select_Exam_Click",
            "PE_Language_Next",
            "PE_Profile_Updated",
            "LH_Subject_Filter_Click",
            "AL_Enter_To_Achieve_Click",
            "UH_Parent_Assignment_Click",
            "TH_Create_Your_Own_Test_Click",
            "TSum_Start_Test_Click",
            "TSum_View_Test_Feedback_Click",
            "PH_Big_Book_Click",
            "LH_Subject_Embibe_Explainer_Video_Click",
            "Search_Manual_Search",
            "Search_Results_Loaded",
            "UH_Edit_Profile_Click",
            "UH_Downloaded_Video_Click",
            "APAJ_View_Feedback_Click",
            "APAJ_Certified_Achievement_Feedback_Click",
            "LRECOM_Video_Click",
            "LSum_Points_To_Remember_Click",
            "PSum_Behaviour_Video_Click",
            "PSum_Topic_Click",
            "PSum_Book_Click",
            "BBTOC_Topic_Practice_Click",
            "PH_Subject_Book_Click",
            "AH_EM_Subject_Card_Click",
            "UH_Test_Taken_Click",
            "UH_Practice_All_Bookmarked_Questions",
            "PE_Edit_Goals_Click",
            "LH_Big_Book_Click",
            "LH_Embibe_Explainer_Video_Click",
            "LH_Subject_Book_Click",
            "LRECOM_AutoPlay_Video",
            "APAJ_View_Journey_Details_Click",
            "AH_Achieve_Journey_Tab_Click",
            "SU_Existing_User_SignIn_Click",
            "SU_Sign_Up_Proceed_Click",
            "SU_Account_Register_Success",
            "PH_Edit_Profile_Click",
            "LS_Sign_Up_Click",
            "UH_Invite_Parent_Click",
            "WL_Add_Profile_Click",
            "PA_Input_Name",
            "PA_Proceed_Click",
            "PA_Select_Goal_Click",
            "PA_Goal_Next",
            "PA_Language_Next",
            "PA_Exam_Next",
            "PA_New_Profile_Added",
            "PA_Select_Exam_Click",
            "UH_Parent_Invited",
            "LVSum_Download_Video_Click",
            "LH_Achieve_Ad_Click",
            "Search_Apply_Filter",
            "TH_Full_Test_Click",
            "GS_SignUP_Complete",
            "LH_Live_Class_Unregister_Success",
            "LH_Live_Class_Register_Now_Click",
            "LH_Live_Class_Registration_Success",
            "WL_Video_Quality_Click",
            "WL_Update_Video_Quality",
            "PA_Select_Language_Click",
            "TH_Custom_Test_Click",
            "MU_Search_Click",
            "PSum_Practice_Chapter_Click",
            "LH_Book_Click",
            "BTOC_Chapter_Click",
            "LH_Subject_Chapter_Click",
            "BTOC_Topic_Click",
            "APT_Select_Target_Date",
            "APT_PAJ_Start_Journey_Click",
            "APAJ_View_Feedback_&_Start_Click",
            "APAJ_Start_PAJ_Click",
            "AH_Explore_Mastery_Tab_Click",
            "LH_Boclips_Video_Click",
            "CYOT_Quick_Test_Creation_Start",
            "CYOT_Create_Custom_Test_Click",
            "ELC_Leave_Live_Class",
            "UH_ELC_Live_Class_Register_Now_Click",
            "UH_ELC_Live_Class_Registration_Success",
            "UH_ELC_Join_Live_Class_Click",
            "UH_ELC_Watch_Past_Live_Class_Click",
            "UH_ELC_Live_Class_Click",
            "LH_Join_Live_Class_Click",
            "LH_Live_Class_Click",
            "LH_Explore_Live_Class_Click",
            "ELCSum_Pre_Class_Video_Click",
            "ELCSum_Prerequisite_Video_Click",
            "ELCSum_Points_To_Remember_Click",
            "ELCSum_Live_Class_Register_Now_Click",
            "ELCSum_Live_Class_Registration_Success",
            "ELCSum_Join_Live_Class_Click",
            "ELCSum_Watch_Past_Live_Class_Click",
            "ELCSum_Live_Class_Click",
            "ELCSum_Recap_Video_Click",
            "ELCSum_Assignment_Click",
            "ELCSum_Test_Click",
            "ELCSum_View_Performance_Report_Click",
            "ELCSum_Live_Class_Unregister_Success",
            "ELC_Join_Live_Class_Click",
            "ELC_Watch_Past_Live_Class_Click",
            "ELC_Open_Question",
            "ELC_Close_Question",
            "ELC_Attempt_Question",
            "ELC_Live_Class_Ended",
            "ELC_Feedback_Submitted",
            "ELC_Post_Class_Activity_Click",
            "ELC_Video_Start",
            "ELC_Video_Play_Buffer",
            "ELC_Video_Play",
            "ELC_Video_Pause",
            "ELC_Video_Seek",
            "ELC_Video_End",
            "APAJ_SS_Video_Click",
            "TFB_Score_Click",
            "AL_Watch_Introduction_Video_Click",
            "TQ_VP_View_Options_Click",
            "UH_RL_Practice_Chapter_Click",
            "UH_TL_Practice_Question_Card_Click",
            "LS_Log_In_Click",
            "LS_Read_TOS_Click",
            "SI_Get_OTP_Click",
            "SI_Sign_In_Password_Click",
            "SI_Sign_In_OTP_Click",
            "SI_New_Sign_Up_Click",
            "SI_Input_Password",
            "SI_Sign_In_Proceed_Click",
            "SI_Forgot_Password_Click",
            "SI_Input_OTP",
            "SI_Resend_OTP_Click",
            "SI_OTP_Proceed_Click",
            "SI_OTP_Verification_Back_Click",
            "FP_Mobile_Email_Input",
            "FP_Get_OTP_Click",
            "FP_Input_OTP",
            "FP_OTP_Proceed_Click",
            "FP_Resend_OTP_Click",
            "FP_New_Password_Input",
            "FP_New_Password_Proceed_Click",
            "FP_OTP_Verification_Back_Click",
            "LS_Accept_TOS",
            "SU_Edit_Avatar_Click",
            "SU_Choose_Avatar",
            "SU_Input_Name",
            "SU_Input_Mobile",
            "SU_Input_Password",
            "SU_Whatsapp_Recommendations_Opt_In",
            "SU_Input_OTP",
            "SU_Resend_OTP_Click",
            "SU_OTP_Verification_Back_Click",
            "SU_OTP_Proceed_Click",
            "GS_Select_Goal_Click",
            "GS_Goal_Next",
            "GS_Back_Click",
            "GS_Select_Exam_Click",
            "GS_Exam_Next",
            "GS_Select_Language_Click",
            "GS_Language_Next",
            "UH_Big_Book_Click",
            "UH_Play_All_Bookmarked_Videos",
            "UH_Bookmarked_Video_Click",
            "UH_Bookmarked_Question_Click",
            "UH_Bookmarked_Question_PCON_Video_Click",
            "UH_Bookmarked_Question_SCON_Video_Click",
            "PASum_Assigned_Practice_Click",
            "PASum_Assigned_Video_Click",
            "PASum_Assigned_Test_Click",
            "UH_Home_Tab_Click",
            "UH_TL_Yearly_Card_Click",
            "UH_TL_Monthly_Card_Click",
            "UH_TL_Weekly_Card_Click",
            "UH_TL_Daily_Card_Click",
            "UH_TL_Hourly_Card_Click",
            "UH_TL_Practice_Card_Click",
            "UH_School_Join_Live_Class_Click",
            "UH_School_Assignment_Click",
            "UH_School_Assigned_Prerequisite_Video_Click",
            "UH_School_Assigned_Recap_Video_Click",
            "UH_School_Assigned_Test_Click",
            "UH_School_Assigned_Lesson_Click",
            "SASum_Assigned_Video_Click",
            "SASum_Assigned_Test_Click",
            "LCSum_Play_Coobo_Click",
            "LCSum_Related_Coobo_Click",
            "Coobo_Loaded",
            "Coobo_Started",
            "Coobo_Exit",
            "Coobo_Slide_Play",
            "Coobo_Slide_Pause",
            "Coobo_Go_To_Next_Slide",
            "Coobo_Go_To_Previous_Slide",
            "Coobo_Slide_Switch",
            "Coobo_Actions",
            "PH_Learn_Ad_View",
            "PH_Learn_Ad_Click",
            "PH_Test_Ad_View",
            "PH_Test_Ad_Click",
            "PH_Achieve_Ad_View",
            "PH_Achieve_Ad_Click",
            "PH_Subject_Filter_Click",
            "PH_Continue_Practice_Click",
            "PH_Book_Click",
            "PH_Trending_Book_For_Exam_Click",
            "PH_Chapter_Click",
            "PH_Subject_Chapter_Click",
            "PSum_Attempt_Quality_Click",
            "PSum_Points_To_Remember_Click",
            "PSum_Test_Click",
            "PSum_Recommended_Video_Click",
            "PSum_Coobo_Click",
            "PSum_Share_Practice_Details_Click",
            "LI_Continue_Practice_Click",
            "LI_Recommended_Video_Click",
            "API_Error",
            "TH_Learn_Ad_View",
            "TH_Learn_Ad_Click",
            "TH_Practice_Ad_View",
            "TH_Practice_Ad_Click",
            "TH_Achieve_Ad_View",
            "TH_Achieve_Ad_Click",
            "TH_Subject_Filter_Click",
            "TH_Edit_Profile_Click",
            "TH_Continue_Test_Click",
            "TH_Chapter_Test_Click",
            "TH_Subject_Chapter_Test_Click",
            "TH_Subject_Full_Test_Click",
            "TH_Finished_Test_Click",
            "TH_Previous_Year_Test_Click",
            "TH_Part_Test_Click",
            "TH_Subject_Part_Test_Click",
            "TH_Trending_Test_For_Exam_Click",
            "TSum_Resume_Test_Click",
            "TSum_Attempt_Quality_Click",
            "TSum_Behaviour_Video_Click",
            "TSum_Recommended_Video_Click",
            "TSum_Recommended_Practice_Click",
            "TSum_Test_Click",
            "TSum_Share_Test_Details_Click",
            "CYOT_Select_Subject",
            "CYOT_Create_Quick_Test_Click",
            "CYOT_Quick_Test_Created",
            "CYOT_Custom_Test_Creation_Start",
            "CYOT_Custom_Test_Created",
            "MU_Profile_Select_Click",
            "MU_LH_Click",
            "MU_PH_Click",
            "MU_TH_Click",
            "MU_UH_Click",
            "MU_AH_Click",
            "AL_Close_Onboarding_Video",
            "AL_Success_Story_Card_Click",
            "APR_Current_Readiness_Tab_Click",
            "APR_Future_Readiness_Tab_Click",
            "APR_Skills_Readiness_Tab_Click",
            "APR_Start_Achieve_Journey_Click",
            "AC_Create_Journey_Tile_Click",
            "AC_Continue_Tile_Resume_Click",
            "APAJ_Continue_Click",
            "APAJ_Extend_Journey_Click",
            "APAJ_Cancel_Journey_Extension",
            "APAJ_Extend_Target_Date",
            "APAJ_Select_Extension_Reason",
            "APAJ_Journey_Extended",
            "APAJ_Start_Certify_Achievement_Test_Click",
            "APAJ_Resume_Certify_Achievement_Test_Click",
            "AH_EM_Chapter_Click",
            "AH_EM_Topics_Click",
            "AH_EM_Topic_Video_Click",
            "AD_Test_Created_Achieve_Home_Click",
            "AD_Select_Subject",
            "AD_Continue_to_Chapter_Click",
            "AD_Select_Chapter",
            "AD_Subject_Tab_Click",
            "AD_Back_To_Subject_Click",
            "AD_Create_Test_Click",
            "AD_Take_Test_Later_Click",
            "AD_Start_Preq_Diagnostic_Test_Click",
            "AD_VFB_Preq_Diagnostic_Test_Click",
            "AT_Continue_To_ADT_Click",
            "AD_Start_Achieve_Diagnostic_Test_Click",
            "AD_Plan_Target_Click",
            "APT_Score_Click",
            "APT_Time_Increase_Click",
            "APT_Time_Decrease_Click",
            "APT_PAJ_Name_Click",
            "APAJ_Skip_Click",
            "APAJ_Milestone_Continue_Journey_Click",
            "APAJ_Achievement_Alert_Continue_Click",
            "APAJ_Summary_Back_Click",
            "APAJ_Summary_Continue_Click",
            "TINS_Take_Later_Click",
            "LVDP_Error",
            "LS_Loader_Video_Play",
            "LS_Loader_Video_End_Play",
            "LS_Cookie_Consent",
            "LS_Top_Navigation_Click",
            "LS_Learn_Ad_View",
            "LS_Practice_Ad_View",
            "LS_Test_Ad_View",
            "LS_Achieve_Ad_View",
            "LS_Track_Ad_View",
            "LS_Assign_Ad_View",
            "LS_Students_Ad_View",
            "LS_App_Install_Click",
            "LS_Footer_Social_Icon_Click",
            "LS_Footer_Options_Click",
            "LS_Footer_NCERT_Books_Click",
            "LS_Footer_NCERT_Solutions_Click",
            "LS_Footer_School_Click",
            "LS_Footer_Exams_Click",
            "LS_Play_Success_Stories",
            "LS_Read_Outcomes_Click",
            "AI_Explore_Knowlege_Graph_Click",
            "AI_Explore_Feature_Click",
            "AI_Read_Article_Click",
            "MyJio_Education_Tab_Loaded",
            "Get_Started_Click",
            "Whatsapp_Recommendations_Opt_In",
            "OBT_Onboarding_Tour_Displayed",
            "OBT_Next_Step_Click",
            "OBT_Previous_Step_Click",
            "OBT_Close_Onboarding_Tour",
        ],
        NOTIFICATION_TAGS: NOTIFICATION_TAGS,
        NOTIFICATION_TYPES: NOTIFICATION_TYPES,
        PLATFORM_REACHABLITY_ENABLED: true,
        ENABLED_CHANNELS: CHANNEL,
        pnNotificationTypes: [
            {
                label: "App Only",
                name: "pn_notification_type",
                value: "APP",
            },
        ],
        TEMPLATE_EDITABLE: true,
    },
    UP_LENS: {
        EMAIL_LIST: [],
        SUCCESS_CRITERIA: [
            "LVDP_Restart",
            "LVDP_Stop",
            "LVDP_Play_Buffer_Stop",
            "LVDP_Play_Buffer",
            "LVDP_Seek",
            "LVDP_Start",
            "PQ_Continue_Click",
            "PQ_Practice_Started",
            "PQ_Check_Answer_Click",
            "PQ_Select_Answer_Click",
            "PQ_Load_Stop",
            "PQ_End_Practice_Click",
            "PQ_End_Practice_Confirm",
            "TINS_Read_Instruction_Click",
            "TQ_Select_Answer_Click",
            "TQ_Save_And_Next_Click",
            "TINS_Start_Test_Click",
            "TQ_Submit_Test_Click",
            "TQ_Submit_Test_Confirm",
            "TFB_Questionwise_Analysis_Click",
            "TFB_Questionwise_Analysis_Question_Click",
            "TFB_Strong_And_Weak_Analysis_Click",
            "TFB_Chapterwise_Analysis_Click",
            "UH_Favourite_Book_Click",
            "UH_RL_Apply_Filter",
            "LVDP_Resume",
            "LVDP_End",
            "AC_Create_Journey_Tile_Click",
            "AL_Enter_To_Achieve_Click",
            "UH_Add_Favourite_Book_Click",
            "TINS_Instructions_Tab_Click",
            "PQ_Timer_Color",
            "TQ_Previous_Click",
            "TQ_Submit_Test_Cancel",
            "LVDP_Quality_Click",
            "LVDP_Forward",
            "LVDP_Skip_Introduction",
            "TFB_Grade_Click",
            "TS_View_Test_Feedback_Click",
            "TFB_Achieve_Click",
            "UH_Favourite_Books_Added",
            "TFB_Your_Top_Skill_Analysis_Click",
            "UH_Manage_Favourite_Book_Click",
            "TFB_Negative_Behaviour_Analysis_Click",
            "TFB_Average_Time_Spent_Per_Question_Click",
            "LVDP_Update_Quality",
            "LVDP_Bookmark_Video",
            "LVDP_Download_Click",
            "PQ_Bookmark_Question",
            "PQ_Hint_Click",
            "PQ_SWU_Textbox_Click",
            "PQ_SWU_Micro_Hint_Click",
            "PQ_SWU_Textbox_Input_Text",
            "PQ_Hint_Close_Click",
            "PQ_Full_Solution_Click",
            "PQ_SWU_Reveal_Answer_Click",
            "PQ_FS_PCON_Video_Click",
            "LVDP_Rewind",
            "PQ_Solve_With_Us_Click",
            "TFB_Topicwise_Analysis_Click",
            "LVDP_Speed_Click",
            "LVDP_Update_Speed",
            "LVDP_Pause",
            "LVDP_Play",
            "TQ_Mark_For_Review_Click",
            "TQ_Attempt_Overview_Click",
            "LVSum_Bookmark_Video_Click",
            "UH_RL_Question_Accordion_Click",
            "UH_User_Home_Hero_Banner_Click",
            "LVDP_Continue",
            "TQ_View_Instructions_Click",
            "TQ_VP_Select_Question_Click",
            "TQ_View_Paper_Click",
            "TFB_Chapterwise_Analysis_Chapter_Click",
            "TFB_Questionwise_Analysis_Question_PCON_Video_Click",
            "TFB_Chapterwise_Analysis_Learn_Topic_Click",
            "TQ_VP_Filter_By_Attempt_Type_Click",
            "TFB_Negative_Behaviour_Video_Click",
            "TFB_Topicwise_Analysis_Learn_Topic_Click",
            "PQ_SWU_Next_Step_Click",
            "PQ_End_Practice_Cancel",
            "UH_RL_Topic_Accordion_Click",
            "PQ_Textbox_Click",
            "PQ_Textbox_Input_Text",
            "APAJ_See_Your_Journey_Click",
            "UH_RL_Chapter_Click",
            "UH_RL_Chapter_Question_Click",
            "UH_RL_Topic_Click",
            "UH_RL_Topic_Video_Click",
            "UH_RL_Learn_Topic_Click",
            "UH_RL_Question_PCON_Video_Click",
            "TQ_Input_Answer_Click",
            "TFB_Positive_Behaviour_Analysis_Click",
            "UH_TL_Practice_Question_SCON_Video_Click",
            "UH_TL_Practice_Question_PCON_Video_Click",
            "TQ_View_Summary_Click",
            "PQ_Session_Summary_Click",
            "PQ_Session_Summary_Close_Click",
            "PQ_Hide_Solution_Click",
            "PQ_FS_SCON_Video_Click",
            "TFB_Positive_Behaviour_Video_Click",
            "PQ_SWU_Step_Tab_Click",
            "TFB_Strong_And_Weak_Analysis_Learn_Topic_Click",
            "PH_Practice_Hero_Banner_Click",
            "TQ_Section_Tab_Click",
            "TFB_Questionwise_Analysis_Question_SCON_Video_Click",
            "LH_Learn_Hero_Banner_Click",
            "UH_RL_Question_SCON_Video_Click",
            "TH_Test_Hero_Banner_Click",
            "UH_RL_Practice_All_Questions_Click",
            "Chatbot_Click",
            "SI_Mobile_Email_Input",
            "SI_Sign_In_Success",
            "WL_Sign_Out_Success",
            "LH_Edit_Profile_Click",
            "PE_Select_Goal_Click",
            "PE_Goal_Next",
            "PE_Exam_Next",
            "PE_Select_Exam_Click",
            "PE_Language_Next",
            "PE_Profile_Updated",
            "LH_Subject_Filter_Click",
            "AL_Enter_To_Achieve_Click",
            "UH_Parent_Assignment_Click",
            "TH_Create_Your_Own_Test_Click",
            "TSum_Start_Test_Click",
            "TSum_View_Test_Feedback_Click",
            "PH_Big_Book_Click",
            "LH_Subject_Embibe_Explainer_Video_Click",
            "Search_Manual_Search",
            "Search_Results_Loaded",
            "UH_Edit_Profile_Click",
            "UH_Downloaded_Video_Click",
            "APAJ_View_Feedback_Click",
            "APAJ_Certified_Achievement_Feedback_Click",
            "LRECOM_Video_Click",
            "LSum_Points_To_Remember_Click",
            "PSum_Behaviour_Video_Click",
            "PSum_Topic_Click",
            "PSum_Book_Click",
            "BBTOC_Topic_Practice_Click",
            "PH_Subject_Book_Click",
            "AH_EM_Subject_Card_Click",
            "UH_Test_Taken_Click",
            "UH_Practice_All_Bookmarked_Questions",
            "PE_Edit_Goals_Click",
            "LH_Big_Book_Click",
            "LH_Embibe_Explainer_Video_Click",
            "LH_Subject_Book_Click",
            "LRECOM_AutoPlay_Video",
            "APAJ_View_Journey_Details_Click",
            "AH_Achieve_Journey_Tab_Click",
            "SU_Existing_User_SignIn_Click",
            "SU_Sign_Up_Proceed_Click",
            "SU_Account_Register_Success",
            "PH_Edit_Profile_Click",
            "LS_Sign_Up_Click",
            "UH_Invite_Parent_Click",
            "WL_Add_Profile_Click",
            "PA_Input_Name",
            "PA_Proceed_Click",
            "PA_Select_Goal_Click",
            "PA_Goal_Next",
            "PA_Language_Next",
            "PA_Exam_Next",
            "PA_New_Profile_Added",
            "PA_Select_Exam_Click",
            "UH_Parent_Invited",
            "LVSum_Download_Video_Click",
            "LH_Achieve_Ad_Click",
            "Search_Apply_Filter",
            "TH_Full_Test_Click",
            "GS_SignUP_Complete",
            "LH_Live_Class_Unregister_Success",
            "LH_Live_Class_Register_Now_Click",
            "LH_Live_Class_Registration_Success",
            "WL_Video_Quality_Click",
            "WL_Update_Video_Quality",
            "PA_Select_Language_Click",
            "TH_Custom_Test_Click",
            "MU_Search_Click",
            "PSum_Practice_Chapter_Click",
            "LH_Book_Click",
            "BTOC_Chapter_Click",
            "LH_Subject_Chapter_Click",
            "BTOC_Topic_Click",
            "APT_Select_Target_Date",
            "APT_PAJ_Start_Journey_Click",
            "APAJ_View_Feedback_&_Start_Click",
            "APAJ_Start_PAJ_Click",
            "AH_Explore_Mastery_Tab_Click",
            "LH_Boclips_Video_Click",
            "CYOT_Quick_Test_Creation_Start",
            "CYOT_Create_Custom_Test_Click",
            "ELC_Leave_Live_Class",
            "UH_ELC_Live_Class_Register_Now_Click",
            "UH_ELC_Live_Class_Registration_Success",
            "UH_ELC_Join_Live_Class_Click",
            "UH_ELC_Watch_Past_Live_Class_Click",
            "UH_ELC_Live_Class_Click",
            "LH_Join_Live_Class_Click",
            "LH_Live_Class_Click",
            "LH_Explore_Live_Class_Click",
            "ELCSum_Pre_Class_Video_Click",
            "ELCSum_Prerequisite_Video_Click",
            "ELCSum_Points_To_Remember_Click",
            "ELCSum_Live_Class_Register_Now_Click",
            "ELCSum_Live_Class_Registration_Success",
            "ELCSum_Join_Live_Class_Click",
            "ELCSum_Watch_Past_Live_Class_Click",
            "ELCSum_Live_Class_Click",
            "ELCSum_Recap_Video_Click",
            "ELCSum_Assignment_Click",
            "ELCSum_Test_Click",
            "ELCSum_View_Performance_Report_Click",
            "ELCSum_Live_Class_Unregister_Success",
            "ELC_Join_Live_Class_Click",
            "ELC_Watch_Past_Live_Class_Click",
            "ELC_Open_Question",
            "ELC_Close_Question",
            "ELC_Attempt_Question",
            "ELC_Live_Class_Ended",
            "ELC_Feedback_Submitted",
            "ELC_Post_Class_Activity_Click",
            "ELC_Video_Start",
            "ELC_Video_Play_Buffer",
            "ELC_Video_Play",
            "ELC_Video_Pause",
            "ELC_Video_Seek",
            "ELC_Video_End",
            "APAJ_SS_Video_Click",
            "TFB_Score_Click",
            "AL_Watch_Introduction_Video_Click",
            "TQ_VP_View_Options_Click",
            "UH_RL_Practice_Chapter_Click",
            "UH_TL_Practice_Question_Card_Click",
            "LS_Log_In_Click",
            "LS_Read_TOS_Click",
            "SI_Get_OTP_Click",
            "SI_Sign_In_Password_Click",
            "SI_Sign_In_OTP_Click",
            "SI_New_Sign_Up_Click",
            "SI_Input_Password",
            "SI_Sign_In_Proceed_Click",
            "SI_Forgot_Password_Click",
            "SI_Input_OTP",
            "SI_Resend_OTP_Click",
            "SI_OTP_Proceed_Click",
            "SI_OTP_Verification_Back_Click",
            "FP_Mobile_Email_Input",
            "FP_Get_OTP_Click",
            "FP_Input_OTP",
            "FP_OTP_Proceed_Click",
            "FP_Resend_OTP_Click",
            "FP_New_Password_Input",
            "FP_New_Password_Proceed_Click",
            "FP_OTP_Verification_Back_Click",
            "LS_Accept_TOS",
            "SU_Edit_Avatar_Click",
            "SU_Choose_Avatar",
            "SU_Input_Name",
            "SU_Input_Mobile",
            "SU_Input_Password",
            "SU_Whatsapp_Recommendations_Opt_In",
            "SU_Input_OTP",
            "SU_Resend_OTP_Click",
            "SU_OTP_Verification_Back_Click",
            "SU_OTP_Proceed_Click",
            "GS_Select_Goal_Click",
            "GS_Goal_Next",
            "GS_Back_Click",
            "GS_Select_Exam_Click",
            "GS_Exam_Next",
            "GS_Select_Language_Click",
            "GS_Language_Next",
            "UH_Big_Book_Click",
            "UH_Play_All_Bookmarked_Videos",
            "UH_Bookmarked_Video_Click",
            "UH_Bookmarked_Question_Click",
            "UH_Bookmarked_Question_PCON_Video_Click",
            "UH_Bookmarked_Question_SCON_Video_Click",
            "PASum_Assigned_Practice_Click",
            "PASum_Assigned_Video_Click",
            "PASum_Assigned_Test_Click",
            "UH_Home_Tab_Click",
            "UH_TL_Yearly_Card_Click",
            "UH_TL_Monthly_Card_Click",
            "UH_TL_Weekly_Card_Click",
            "UH_TL_Daily_Card_Click",
            "UH_TL_Hourly_Card_Click",
            "UH_TL_Practice_Card_Click",
            "UH_School_Join_Live_Class_Click",
            "UH_School_Assignment_Click",
            "UH_School_Assigned_Prerequisite_Video_Click",
            "UH_School_Assigned_Recap_Video_Click",
            "UH_School_Assigned_Test_Click",
            "UH_School_Assigned_Lesson_Click",
            "SASum_Assigned_Video_Click",
            "SASum_Assigned_Test_Click",
            "LCSum_Play_Coobo_Click",
            "LCSum_Related_Coobo_Click",
            "Coobo_Loaded",
            "Coobo_Started",
            "Coobo_Exit",
            "Coobo_Slide_Play",
            "Coobo_Slide_Pause",
            "Coobo_Go_To_Next_Slide",
            "Coobo_Go_To_Previous_Slide",
            "Coobo_Slide_Switch",
            "Coobo_Actions",
            "PH_Learn_Ad_View",
            "PH_Learn_Ad_Click",
            "PH_Test_Ad_View",
            "PH_Test_Ad_Click",
            "PH_Achieve_Ad_View",
            "PH_Achieve_Ad_Click",
            "PH_Subject_Filter_Click",
            "PH_Continue_Practice_Click",
            "PH_Book_Click",
            "PH_Trending_Book_For_Exam_Click",
            "PH_Chapter_Click",
            "PH_Subject_Chapter_Click",
            "PSum_Attempt_Quality_Click",
            "PSum_Points_To_Remember_Click",
            "PSum_Test_Click",
            "PSum_Recommended_Video_Click",
            "PSum_Coobo_Click",
            "PSum_Share_Practice_Details_Click",
            "LI_Continue_Practice_Click",
            "LI_Recommended_Video_Click",
            "API_Error",
            "TH_Learn_Ad_View",
            "TH_Learn_Ad_Click",
            "TH_Practice_Ad_View",
            "TH_Practice_Ad_Click",
            "TH_Achieve_Ad_View",
            "TH_Achieve_Ad_Click",
            "TH_Subject_Filter_Click",
            "TH_Edit_Profile_Click",
            "TH_Continue_Test_Click",
            "TH_Chapter_Test_Click",
            "TH_Subject_Chapter_Test_Click",
            "TH_Subject_Full_Test_Click",
            "TH_Finished_Test_Click",
            "TH_Previous_Year_Test_Click",
            "TH_Part_Test_Click",
            "TH_Subject_Part_Test_Click",
            "TH_Trending_Test_For_Exam_Click",
            "TSum_Resume_Test_Click",
            "TSum_Attempt_Quality_Click",
            "TSum_Behaviour_Video_Click",
            "TSum_Recommended_Video_Click",
            "TSum_Recommended_Practice_Click",
            "TSum_Test_Click",
            "TSum_Share_Test_Details_Click",
            "CYOT_Select_Subject",
            "CYOT_Create_Quick_Test_Click",
            "CYOT_Quick_Test_Created",
            "CYOT_Custom_Test_Creation_Start",
            "CYOT_Custom_Test_Created",
            "MU_Profile_Select_Click",
            "MU_LH_Click",
            "MU_PH_Click",
            "MU_TH_Click",
            "MU_UH_Click",
            "MU_AH_Click",
            "AL_Close_Onboarding_Video",
            "AL_Success_Story_Card_Click",
            "APR_Current_Readiness_Tab_Click",
            "APR_Future_Readiness_Tab_Click",
            "APR_Skills_Readiness_Tab_Click",
            "APR_Start_Achieve_Journey_Click",
            "AC_Create_Journey_Tile_Click",
            "AC_Continue_Tile_Resume_Click",
            "APAJ_Continue_Click",
            "APAJ_Extend_Journey_Click",
            "APAJ_Cancel_Journey_Extension",
            "APAJ_Extend_Target_Date",
            "APAJ_Select_Extension_Reason",
            "APAJ_Journey_Extended",
            "APAJ_Start_Certify_Achievement_Test_Click",
            "APAJ_Resume_Certify_Achievement_Test_Click",
            "AH_EM_Chapter_Click",
            "AH_EM_Topics_Click",
            "AH_EM_Topic_Video_Click",
            "AD_Test_Created_Achieve_Home_Click",
            "AD_Select_Subject",
            "AD_Continue_to_Chapter_Click",
            "AD_Select_Chapter",
            "AD_Subject_Tab_Click",
            "AD_Back_To_Subject_Click",
            "AD_Create_Test_Click",
            "AD_Take_Test_Later_Click",
            "AD_Start_Preq_Diagnostic_Test_Click",
            "AD_VFB_Preq_Diagnostic_Test_Click",
            "AT_Continue_To_ADT_Click",
            "AD_Start_Achieve_Diagnostic_Test_Click",
            "AD_Plan_Target_Click",
            "APT_Score_Click",
            "APT_Time_Increase_Click",
            "APT_Time_Decrease_Click",
            "APT_PAJ_Name_Click",
            "APAJ_Skip_Click",
            "APAJ_Milestone_Continue_Journey_Click",
            "APAJ_Achievement_Alert_Continue_Click",
            "APAJ_Summary_Back_Click",
            "APAJ_Summary_Continue_Click",
            "TINS_Take_Later_Click",
            "LVDP_Error",
            "LS_Loader_Video_Play",
            "LS_Loader_Video_End_Play",
            "LS_Cookie_Consent",
            "LS_Top_Navigation_Click",
            "LS_Learn_Ad_View",
            "LS_Practice_Ad_View",
            "LS_Test_Ad_View",
            "LS_Achieve_Ad_View",
            "LS_Track_Ad_View",
            "LS_Assign_Ad_View",
            "LS_Students_Ad_View",
            "LS_App_Install_Click",
            "LS_Footer_Social_Icon_Click",
            "LS_Footer_Options_Click",
            "LS_Footer_NCERT_Books_Click",
            "LS_Footer_NCERT_Solutions_Click",
            "LS_Footer_School_Click",
            "LS_Footer_Exams_Click",
            "LS_Play_Success_Stories",
            "LS_Read_Outcomes_Click",
            "AI_Explore_Knowlege_Graph_Click",
            "AI_Explore_Feature_Click",
            "AI_Read_Article_Click",
            "MyJio_Education_Tab_Loaded",
            "Get_Started_Click",
            "Whatsapp_Recommendations_Opt_In",
            "OBT_Onboarding_Tour_Displayed",
            "OBT_Next_Step_Click",
            "OBT_Previous_Step_Click",
            "OBT_Close_Onboarding_Tour",
        ],
        NOTIFICATION_TAGS: NOTIFICATION_TAGS,
        NOTIFICATION_TYPES: NOTIFICATION_TYPES,
        PLATFORM_REACHABLITY_ENABLED: true,
        ENABLED_CHANNELS: CHANNEL,
        pnNotificationTypes: [
            {
                label: "App Only",
                name: "pn_notification_type",
                value: "APP",
            },
        ],
        TEMPLATE_EDITABLE: true,
    },
    NCERT_LENS: {
        EMAIL_LIST: [],
        SUCCESS_CRITERIA: [
            "LS_Lets_Begin_Click",
            "LS_Log_In_Click",
            "LS_Sign_Up_Click",
            "SI_Mobile_Email_Input",
            "SI_Get_OTP_Click",
            "SI_Sign_In_Using_Password_Click",
            "SI_Sign_In_Using_OTP_Click",
            "SI_New_Sign_Up_Click",
            "SI_Input_Password",
            "SI_Sign_In_Proceed_Click",
            "SI_Forgot_Password_Click",
            "SI_Read_TOS_Click",
            "SI_Input_OTP",
            "SI_Resend_OTP_Click",
            "SI_OTP_Proceed_Click",
            "SI_OTP_Verification_Back_Click",
            "SI_Sign_In_Success",
            "FP_Mobile_Email_Input",
            "FP_Get_OTP_Click",
            "FP_Input_OTP",
            "FP_OTP_Proceed_Click",
            "FP_Resend_OTP_Click",
            "FP_New_Password_Input",
            "FP_New_Password_Proceed_Click",
            "FP_OTP_Verification_Back_Click",
            "FP_Read_TOS_Click",
            "SU_Read_TOS_Click",
            "SU_Edit_Avatar_Click",
            "SU_Choose_Avatar",
            "SU_Existing_User_SignIn_Click",
            "SU_Input_Name",
            "SU_Input_Mobile",
            "SU_Input_Password",
            "SU_Whatsapp_Recommendations_Opt_In",
            "SU_Sign_Up_Proceed_Click",
            "SU_Account_Register_Success",
            "SU_Input_OTP",
            "SU_Resend_OTP_Click",
            "SU_OTP_Verification_Back_Click",
            "SU_OTP_Proceed_Click",
            "GS_Select_Goal_Click",
            "GS_Goal_Next",
            "GS_Select_Exam_Click",
            "GS_Exam_Next",
            "GS_Back_Click",
            "GS_SignUP_Complete",
            "GS_Scan_For_Knowledge_Click",
            "API_Error",
            "Image_Captured",
            "Image_Imported",
            "Search_Entities_Click",
            "Image_Scanning_Started",
            "Search_Results_Loaded",
            "Entity_Click",
            "Search_Feature_Discovery_Options_Click",
            "DR_Image_Captured",
            "DR_Image_Imported",
            "DR_Image_Scanning_Started",
            "DR_Search_Results_Loaded",
            "DR_Search_Results_Not_Found_Options_Click",
            "DR_Question_Click",
            "DR_Learning_Video_Click",
            "DR_Student_Video_App_Install_Banner_Click",
            "DR_Practice_Banner_Click",
            "DR_Student_Practice_App_Install_Banner_Click",
            "AR_Asset_Download_Start",
            "AR_Asset_Downloaded",
            "AR_Asset_Actions",
            "AR_Related_Matches_Click",
            "AR_Reset_Asset_Position",
            "AR_Asset_Label_Enabled",
            "AR_Asset_Label_Disabled",
            "AR_Related_Video_Click",
            "AR_Related_Experiment_Click",
            "AR_Report_Issue_Click",
            "AR_Issue_Reported_Successfully",
            "AR_Student_Video_App_Install_Banner_Click",
            "AR_Experiments_App_Install_Banner_Click",
            "Video_Play_Start",
            "Video_Play_Buffer_Start",
            "Video_Play_Buffer_Stop",
            "Video_Play",
            "Video_Pause",
            "Video_Seek",
            "Video_Rewind",
            "Video_Forward",
            "Video_End",
            "Video_Stop",
            "Video_Error",
            "Error",
            "ACT_Edit_Profile_Click",
            "ACT_Sign_Out_Success",
            "ACT_Read_TOS_Click",
            "ACT_Report_Issue_Click",
            "ACT_Delete_Account_Click",
            "ACT_Delete_Account_Input_OTP",
            "ACT_Delete_Account_Resend_OTP",
            "ACT_Delete_Account_Proceed",
            "ACT_Account_Deleted",
            "PE_Select_Goal_Click",
            "PE_Goal_Next",
            "PE_Select_Exam_Click",
            "PE_Exam_Next",
            "PE_Back_Click",
            "PE_Profile_Updated",
        ],
        NOTIFICATION_TAGS: NOTIFICATION_TAGS,
        NOTIFICATION_TYPES: NOTIFICATION_TYPES,
        PLATFORM_REACHABLITY_ENABLED: true,
        ENABLED_CHANNELS: CHANNEL,
        pnNotificationTypes: [
            {
                label: "App Only",
                name: "pn_notification_type",
                value: "APP",
            },
        ],
        TEMPLATE_EDITABLE: true,
    },
    MAHENDRAS: {
        EMAIL_LIST: MAHENDRAS_EMAIL_LIST,
        SUCCESS_CRITERIA: [
            "LS_Log_In_Click",
            "LS_Sign_Up_Click",
            "LS_Footer_About_Us_Link_Click",
            "LS_Footer_Navigation_Link_Click",
            "LS_Footer_Important_Link_Click",
            "LS_Footer_Social_Link_Click",
            "LS_Read_TOS_Click",
            "LS_Privacy_Statement_Click",
            "LS_Sign_Out_Success",
            "LS_Continue_Journey",
            "SI_Read_TOS_Click",
            "SI_Mobile_Email_Input",
            "SI_Input_Password",
            "SI_Sign_In_Proceed_Click",
            "SI_Forgot_Password_Click",
            "SI_Sign_In_Password_Click",
            "SI_Sign_In_OTP_Click",
            "SI_Get_OTP_Click",
            "SI_Input_OTP",
            "SI_Resend_OTP_Click",
            "SI_OTP_Proceed_Click",
            "SI_OTP_Verification_Back_Click",
            "FP_Mobile_Email_Input",
            "FP_Get_OTP_Click",
            "FP_Input_OTP",
            "FP_OTP_Proceed_Click",
            "FP_Resend_OTP_Click",
            "FP_New_Password_Input",
            "FP_New_Password_Proceed_Click",
            "SI_Sign_In_Success",
            "SU_Read_TOS_Click",
            "SU_Existing_User_SignIn_Click",
            "SU_Input_Name",
            "SU_Input_Mobile",
            "SU_Input_Password",
            "SU_Sign_Up_Proceed_Click",
            "SU_Whatsapp_Recommendations_Opt_In",
            "SU_Account_Register_Success",
            "SU_Input_OTP",
            "SU_Resend_OTP_Click",
            "SU_OTP_Verification_Back_Click",
            "SU_OTP_Proceed_Click",
            "SU_Select_Program_Procced",
            "GS_Select_Goal_Click",
            "GS_Goal_Next",
            "GS_Back_Click",
            "GS_Select_Exam_Click",
            "GS_Exam_Next",
            "GS_Select_Language_Click",
            "GS_Language_Next",
            "GS_SignUP_Complete",
            "SI_Select_Program_Procced",
        ],
        NOTIFICATION_TAGS: MAHENDRAS_NOTIFICATION_TAGS,
        NOTIFICATION_TYPES: MAHENDRAS_NOTIFICATION_TYPES,
        PLATFORM_REACHABLITY_ENABLED: false,
        ENABLED_CHANNELS: MAHENDRAS_CHANNEL,
        pnNotificationTypes: [
            {
                label: "App Only",
                name: "pn_notification_type",
                value: "APP",
            },
            {
                label: "Web Only",
                name: "pn_notification_type",
                value: "WEB",
            },
        ],
        TEMPLATE_EDITABLE: false,
    },
    MTG: {
        EMAIL_LIST: MTG_EMAIL_LIST,
        SUCCESS_CRITERIA: [
            "LS_Lets_Begin_Click",
            "LS_Log_In_Click",
            "LS_Sign_Up_Click",
            "SI_Mobile_Email_Input",
            "SI_Get_OTP_Click",
            "SI_Sign_In_Using_Password_Click",
            "SI_Sign_In_Using_OTP_Click",
            "SI_New_Sign_Up_Click",
            "SI_Input_Password",
            "SI_Sign_In_Proceed_Click",
            "SI_Forgot_Password_Click",
            "SI_Read_TOS_Click",
            "SI_Input_OTP",
            "SI_Resend_OTP_Click",
            "SI_OTP_Proceed_Click",
            "SI_OTP_Verification_Back_Click",
            "SI_Sign_In_Success",
            "FP_Mobile_Email_Input",
            "FP_Get_OTP_Click",
            "FP_Input_OTP",
            "FP_OTP_Proceed_Click",
            "FP_Resend_OTP_Click",
            "FP_New_Password_Input",
            "FP_New_Password_Proceed_Click",
            "FP_OTP_Verification_Back_Click",
            "FP_Read_TOS_Click",
            "SU_Read_TOS_Click",
            "SU_Edit_Avatar_Click",
            "SU_Choose_Avatar",
            "SU_Existing_User_SignIn_Click",
            "SU_Input_Name",
            "SU_Input_Mobile",
            "SU_Input_Password",
            "SU_Whatsapp_Recommendations_Opt_In",
            "SU_Sign_Up_Proceed_Click",
            "SU_Account_Register_Success",
            "SU_Input_OTP",
            "SU_Resend_OTP_Click",
            "SU_OTP_Verification_Back_Click",
            "SU_OTP_Proceed_Click",
            "GS_Select_Goal_Click",
            "GS_Goal_Next",
            "GS_Select_Exam_Click",
            "GS_Exam_Next",
            "GS_Back_Click",
            "GS_SignUP_Complete",
            "GS_Scan_For_Knowledge_Click",
            "API_Error",
            "Image_Captured",
            "Image_Imported",
            "Search_Entities_Click",
            "Image_Scanning_Started",
            "Search_Results_Loaded",
            "Entity_Click",
            "Search_Feature_Discovery_Options_Click",
            "DR_Image_Captured",
            "DR_Image_Imported",
            "DR_Image_Scanning_Started",
            "DR_Search_Results_Loaded",
            "DR_Search_Results_Not_Found_Options_Click",
            "DR_Question_Click",
            "DR_Learning_Video_Click",
            "DR_Student_Video_App_Install_Banner_Click",
            "DR_Practice_Banner_Click",
            "DR_Student_Practice_App_Install_Banner_Click",
            "AR_Asset_Download_Start",
            "AR_Asset_Downloaded",
            "AR_Asset_Actions",
            "AR_Related_Matches_Click",
            "AR_Reset_Asset_Position",
            "AR_Asset_Label_Enabled",
            "AR_Asset_Label_Disabled",
            "AR_Related_Video_Click",
            "AR_Related_Experiment_Click",
            "AR_Report_Issue_Click",
            "AR_Issue_Reported_Successfully",
            "AR_Student_Video_App_Install_Banner_Click",
            "AR_Experiments_App_Install_Banner_Click",
            "Video_Play_Start",
            "Video_Play_Buffer_Start",
            "Video_Play_Buffer_Stop",
            "Video_Play",
            "Video_Pause",
            "Video_Seek",
            "Video_Rewind",
            "Video_Forward",
            "Video_End",
            "Video_Stop",
            "Video_Error",
            "Error",
            "ACT_Edit_Profile_Click",
            "ACT_Sign_Out_Success",
            "ACT_Read_TOS_Click",
            "ACT_Report_Issue_Click",
            "ACT_Delete_Account_Click",
            "ACT_Delete_Account_Input_OTP",
            "ACT_Delete_Account_Resend_OTP",
            "ACT_Delete_Account_Proceed",
            "ACT_Account_Deleted",
            "PE_Select_Goal_Click",
            "PE_Goal_Next",
            "PE_Select_Exam_Click",
            "PE_Exam_Next",
            "PE_Back_Click",
            "PE_Profile_Updated",
        ],
        NOTIFICATION_TAGS: MTG_NOTIFICATION_TAGS,
        NOTIFICATION_TYPES: MTG_NOTIFICATION_TYPES,
        PLATFORM_REACHABLITY_ENABLED: false,
        ENABLED_CHANNELS: MTG_CHANNEL,
        pnNotificationTypes: [
            {
                label: "App Only",
                name: "pn_notification_type",
                value: "APP",
            },
        ],
        TEMPLATE_EDITABLE: true,
    },
};

export default appConfig;
