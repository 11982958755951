import { useState, useEffect } from "react";
import { getFactType } from "../../features/searchTemplate/searchTemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomDropdown from "../CustomDropdown";
import { getFactTypeData } from "../../features/searchTemplate/searchTemplateSlice";

const FactTypeSelect = ({ templateData, setIsFactType, setIsCustomFT }) => {
    const [factType, setFactType] = useState();
    const dispatch = useDispatch();
    localStorage.setItem("factTypeSelected", factType);

    const factTypes = JSON.parse(localStorage.getItem("factTypes"));

    if (factType) {
        dispatch(getFactTypeData(factType));
        setIsFactType(true);
    }

    const customRegex = /[Cc][Uu][Ss][Tt][Oo][Mm]/;
    const handleFTChange = (e) => {
        setFactType(e.target.value);
        if (e.target.value.match(customRegex)) {
            setIsCustomFT(true);
        } else {
            setIsCustomFT(false);
        }
    };

    return (
        <>
            <div style={{ margin: "16px 0" }}>Fact Type *</div>
            <FormControl fullWidth>
                <Select id="create-template" onChange={handleFTChange}>
                    {factTypes?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default FactTypeSelect;
