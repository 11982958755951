import UserViewButtons from "../../components/UserViewButtons";

const Immediate = ({ handleSave, handleTest, handleExecute, disableSaveBtn, disableTestExeBtn, handleEditSave }) => {
    return (
        <div>
            <UserViewButtons
                handleSave={handleSave}
                handleTest={handleTest}
                disableSaveBtn={disableSaveBtn}
                disableTestExeBtn={disableTestExeBtn}
                handleExecute={handleExecute}
                handleEditSave={handleEditSave}
            />
        </div>
    );
};

export default Immediate;
