import { useState, useEffect } from "react";
import CustomDropdown from "../../components/CustomDropdown";
import CustomButton from "../../components/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { userListAPI } from "../../features/userView/userViewSlice";
import Select from "react-select";

const UserListDD = ({ userListData, setUserListSelected }) => {
    const userListDDVal = userListData?.map((item) => ({
        value: item?.users_list_id,
        label: item?.name,
    }));

    const handleUserViewSelect = (option) => {
        setUserListSelected(option);
    };

    return (
        <div className="user-list-test-wrapper">
            <div className="create-template-channel">UserList</div>
            <div>
                <Select closeMenuOnSelect={true} options={userListDDVal} onChange={handleUserViewSelect} />
            </div>
            {/* <FormControl fullWidth>
                <Select id="create-template" name="userListSelected" onChange={(e) => handleTestData(e)}>
                    {userListData?.map((item) => (
                        <MenuItem value={item?.users_list_id}>{item?.name}</MenuItem>
                    ))}
                </Select>
            </FormControl> */}
        </div>
    );
};

const LinkedTemplatestDD = ({ setLinkedTempSelected, allLinkedHypothesis }) => {
    const userListDDVal = allLinkedHypothesis?.map((item) => ({
        value: item?.id,
        label: item?.template_name,
    }));

    const handleUserViewSelect = (option) => {
        setLinkedTempSelected(option);
    };
    return (
        <div className="user-list-test-wrapper">
            <div className="create-template-channel">Linked Templates</div>
            <Select closeMenuOnSelect={true} options={userListDDVal} onChange={handleUserViewSelect} />

            {/* <FormControl fullWidth>
                <Select id="create-template" name="linkedTempSelected" onChange={(e) => handleTestData(e)}>
                    {allLinkedHypothesis?.map((item) => (
                        <MenuItem value={item?.id}>{item?.template_name}</MenuItem>
                    ))}
                </Select>
            </FormControl> */}
        </div>
    );
};

const UserListTest = ({
    handleClose,
    handleTestExecute,
    setUserListTestData,
    setUserListSelected,
    setLinkedTempSelected,
}) => {
    // const [testData, setTestData] = useState();
    const allLinkedHypothesis = useSelector((state) => state?.userView?.allLinkedHypothesis?.linked_templates);
    const userListData = useSelector((state) => state?.userView?.userList?.content);

    return (
        <div style={{ width: "100%" }}>
            <div className="user-test-dropdown">
                <UserListDD userListData={userListData} setUserListSelected={setUserListSelected} />
                <LinkedTemplatestDD
                    allLinkedHypothesis={allLinkedHypothesis}
                    setLinkedTempSelected={setLinkedTempSelected}
                />
            </div>
            <div className="user-test-buttons">
                <CustomButton buttonText="Cancel" variant="outlined" color="error" handleClick={handleClose} />
                <CustomButton buttonText="Execute" variant="outlined" handleClick={handleTestExecute} />
            </div>
        </div>
    );
};

export default UserListTest;
