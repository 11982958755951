import { toast } from "react-toastify";

export const isTimeValid = (e, startDate, handleUserViewChange) => {
    let currentDate = new Date();
    let today = currentDate.toISOString().split("T")[0];

    let h = currentDate.getHours();
    let m = currentDate.getMinutes();

    let currentTime = h + ":" + m;

    if (startDate && startDate === today && e.target.value < currentTime) {
        toast.error("Time cannot be past the Start Date");
    } else {
        handleUserViewChange(e);
    }
};
