import { useState, useEffect } from "react";
import InputField from "../../../../components/InputField";

const EndDate = ({ userViewEditDetails, handleUserViewChange }) => {
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        if (userViewEditDetails?.schedule?.end_date) {
            setEndDate(userViewEditDetails?.schedule?.end_date);
        }
    }, []);

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
        handleUserViewChange(e);
    };

    const date = new Date();
    const today = date.toISOString().split("T")[0];

    return (
        <div className="user-view-input-wrapper">
            <div>End Date</div>
            {userViewEditDetails?.schedule?.end_date ? (
                <InputField
                    id="outlined-helperText"
                    variant="outlined"
                    name="endDate"
                    type="date"
                    value={endDate ?? ""}
                    handleChange={(e) => handleEndDate(e)}
                    inputProps={{
                        min: today,
                    }}
                />
            ) : (
                <InputField
                    id="outlined-helperText"
                    variant="outlined"
                    name="endDate"
                    type="date"
                    handleChange={(e) => handleUserViewChange(e)}
                    inputProps={{
                        min: today,
                    }}
                />
            )}
        </div>
    );
};

export default EndDate;
