import { useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Weightage from "./Weightage";
// import CloseIcon from "../../../../assets/closeIcon.svg";
import Priority from "./Priority";
import CustomButton from "../../../../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import Unlink from "@mui/icons-material/LinkOff";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import EditPriorityAndweightage from "./EditPriorityAndweightage";
import EditIcon from "@mui/icons-material/EditOutlined";
import { toast } from "react-toastify";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 650,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const TemplateTableRow = ({
    item,
    setWeightage,
    setPriority,
    updateTemplateData,
    deleteTemplateRow,
    showUpdateBtn,
    setShowUpdateBtn,
}) => {
    const [isWeightageEdit, setIsWeightageEdit] = useState(false);
    const [isPriorityEdit, setIsPriorityEdit] = useState(false);

    // MODAL OPEN/CLOSE FUNCTIONS
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handlePriorityUpdate = (item) => {
        updateTemplateData(item);
        setIsPriorityEdit(!isPriorityEdit);
    };
    const handleWeightageUpdate = (item) => {
        updateTemplateData(item);
        setIsWeightageEdit(!isWeightageEdit);
    };

    const handleUpdateData = (item) => {
        handlePriorityUpdate(item);
        handleWeightageUpdate(item);
        handleClose();
    };

    const handlePriorityChange = (e) => {
        setPriority({ id: item?.id, priority: e.target.value });
        if (e.target.value > 5) {
            toast.error("Priority should be less than or equal to 5");
        }
    };

    const handleWeightageChange = (e) => {
        setWeightage({ id: item?.id, weightage: e.target.value });
        if (e.target.value > 100) {
            toast.error("Weightage should be less than or equal to 100");
        }
    };

    return (
        <>
            <TableRow key={item?.template_id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                    {item?.template_name}
                </TableCell>
                <TableCell>{item?.channel}</TableCell>
                <TableCell>
                    <div className="template-table-cell-priority">
                        {item?.priority}
                        <div className="template-table-edit-icon" onClick={() => setOpen(true)}>
                            <EditIcon fontSize="22px" />
                        </div>
                    </div>
                </TableCell>
                <TableCell>
                    <div className="template-table-cell-priority">
                        {item?.weightage} %
                        <div className="template-table-edit-icon" onClick={() => setOpen(true)}>
                            <EditIcon fontSize="22px" />
                        </div>
                    </div>
                </TableCell>
                {/* <TableCell>
                    <Priority
                        id={item?.id}
                        priority={item?.priority}
                        setPriority={setPriority}
                        isPriorityEdit={isPriorityEdit}
                        setIsPriorityEdit={setIsPriorityEdit}
                        showUpdateBtn={showUpdateBtn}
                        setShowUpdateBtn={setShowUpdateBtn}
                    />
                </TableCell>
                <TableCell>
                    <Weightage
                        id={item?.id}
                        weightage={item?.weightage}
                        setWeightage={setWeightage}
                        setIsWeightageEdit={setIsWeightageEdit}
                        isWeightageEdit={isWeightageEdit}
                        showUpdateBtn={showUpdateBtn}
                        setShowUpdateBtn={setShowUpdateBtn}
                    />
                </TableCell> */}
                <TableCell>
                    <div className="template-table-close-icon" onClick={() => deleteTemplateRow(item.id)}>
                        <Unlink />
                    </div>
                </TableCell>
                {/* {isPriorityEdit || isWeightageEdit ? (
                    <TableCell>
                        <CustomButton
                            buttonText="Update"
                            variant="outlined"
                            handleClick={() => handleUpdateData(item)}
                        />
                    </TableCell>
                ) : (
                    ""
                )} */}
            </TableRow>

            {/* SHOW EMAIL SELECT */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <EditPriorityAndweightage
                        item={item}
                        handleClose={handleClose}
                        handlePriorityChange={handlePriorityChange}
                        handleWeightageChange={handleWeightageChange}
                        handleUpdateData={handleUpdateData}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default TemplateTableRow;
