import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import homeService from "./homeService";

const initialState = {
    overallData: [],
    campaignWiseData: [],
    reachData: [],
    goalSuccess: [],
    exportCampaignWiseData: {},
    scheduleFeatureData: [],
    isError: false,
    isSuccess: false,
    isReachLoading: false,
    isOverallLoading: false,
    isCampaignLoading: false,
    isScheduleLoading: false,
    message: "",
};

// login
export const login = createAsyncThunk("login/post", async (data, thunkAPI) => {
    try {
        return await homeService.login(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// GET OVERALL DATA
export const getOverallData = createAsyncThunk("getOverallData/get", async (data, thunkAPI) => {
    try {
        return await homeService.getOverallDataAPI(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// GET CAMPAIGN WISE DATA
export const getCampaignWiseData = createAsyncThunk("getCampaignWiseData/get", async (data, thunkAPI) => {
    try {
        return await homeService.getCampaignWiseDataAPI(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// GET REACH DATA
export const getReachData = createAsyncThunk("getReachData/get", async (thunkAPI) => {
    try {
        return await homeService.getReachDataAPI();
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// GET GOAL SUCCESS
export const getGoalSuccess = createAsyncThunk("getGoalSuccess/get", async (hypothesisId, triggerId, thunkAPI) => {
    try {
        return await homeService.getGoalSuccessAPI(hypothesisId, triggerId);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// EXPORT CAMPAIGN WISE DATA
export const exportCampaignWiseData = createAsyncThunk("exportCampaignWiseData/get", async (data, thunkAPI) => {
    try {
        return await homeService.exportCampaignWiseDataAPI(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// GET SCHEDULE FEATURE DATA
export const getScheduleFeature = createAsyncThunk("scheduleFeatureAPI/get", async (data, thunkAPI) => {
    try {
        return await homeService.scheduleFeatureAPI(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
// STOP/RESCIND CAMPAIGN
export const stopRescindCampaign = createAsyncThunk(
    "stopRescindCampaign/delete",
    async (hypothesisId, data, thunkAPI) => {
        try {
            return await homeService.stopRescindCampaignAPI(hypothesisId, data);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// HOME SLICE
export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOverallData.pending, (state) => {
                state.isOverallLoading = true;
            })
            .addCase(getOverallData.fulfilled, (state, action) => {
                state.isOverallLoading = false;
                state.isSuccess = true;
                state.overallData = action.payload;
            })
            .addCase(getOverallData.rejected, (state, action) => {
                state.isOverallLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getCampaignWiseData.pending, (state) => {
                state.isCampaignLoading = true;
            })
            .addCase(getCampaignWiseData.fulfilled, (state, action) => {
                state.isCampaignLoading = false;
                state.isSuccess = true;
                state.campaignWiseData = action.payload;
            })
            .addCase(getCampaignWiseData.rejected, (state, action) => {
                state.isCampaignLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getReachData.pending, (state) => {
                state.isReachLoading = true;
            })
            .addCase(getReachData.fulfilled, (state, action) => {
                state.isReachLoading = false;
                state.isSuccess = true;
                state.reachData = action.payload;
            })
            .addCase(getReachData.rejected, (state, action) => {
                state.isReachLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getGoalSuccess.pending, (state) => {
                state.isReachLoading = true;
            })
            .addCase(getGoalSuccess.fulfilled, (state, action) => {
                state.isReachLoading = false;
                state.isSuccess = true;
                state.goalSuccess = action.payload;
            })
            .addCase(getGoalSuccess.rejected, (state, action) => {
                state.isReachLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(exportCampaignWiseData.pending, (state) => {
                state.isReachLoading = true;
            })
            .addCase(exportCampaignWiseData.fulfilled, (state, action) => {
                state.isReachLoading = false;
                state.isSuccess = true;
                state.exportCampaignWiseData = action.payload;
            })
            .addCase(exportCampaignWiseData.rejected, (state, action) => {
                state.isReachLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getScheduleFeature.pending, (state) => {
                state.isScheduleLoading = true;
            })
            .addCase(getScheduleFeature.fulfilled, (state, action) => {
                state.isScheduleLoading = false;
                state.isSuccess = true;
                state.scheduleFeatureData = action.payload;
            })
            .addCase(getScheduleFeature.rejected, (state, action) => {
                state.isScheduleLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(stopRescindCampaign.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(stopRescindCampaign.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
            })
            .addCase(stopRescindCampaign.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});

export const { reset } = homeSlice.actions;
export default homeSlice.reducer;
