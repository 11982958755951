import { useState, useEffect } from "react";
import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MobilePhoneTemplate from "./MobilePhoneTemplate";
import WebTemplate from "./LaptopTemplate";
import {
    InterstitialPopUp,
    HeaderPopup,
    FooterPopup,
    AlertPopup,
    HalfInterstitialPopup,
    CoverPopUp,
    WebInterstitialPopUp,
    WebImageonlyPopUp,
    WebBannerPopUp,
    WebBoxPopUp,
} from "./PopUpTypeTemplate";
const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 780,
    height: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    // overflow: "scroll",
    display: "flex",
    justifyContent: "center",
};

const PopupTemplatePreview = ({
    open,
    handlePreviewClose,
    imageLink,
    IAPType,
    title,
    IAPBody,
    ctasArray,
    platformType,
    cancelState,
}) => {
    return (
        <div>
            <Modal
                open={open}
                onClose={handlePreviewClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {platformType === "WEB" ? (
                        <WebTemplate>
                            {IAPType === "INTERSTITIAL" ? (
                                <WebInterstitialPopUp
                                    imageLink={imageLink}
                                    title={title}
                                    IAPBody={IAPBody}
                                    ctasArray={ctasArray}
                                    cancelState={cancelState}
                                />
                            ) : IAPType === "IMAGE_ONLY" ? (
                                <WebImageonlyPopUp imageLink={imageLink} cancelState={cancelState} />
                            ) : IAPType === "BANNER" ? (
                                <WebBannerPopUp
                                    imageLink={imageLink}
                                    title={title}
                                    IAPBody={IAPBody}
                                    ctasArray={ctasArray}
                                    cancelState={cancelState}
                                />
                            ) : IAPType === "BOX" ? (
                                <WebBoxPopUp
                                    imageLink={imageLink}
                                    title={title}
                                    IAPBody={IAPBody}
                                    cancelState={cancelState}
                                />
                            ) : (
                                <></>
                            )}
                        </WebTemplate>
                    ) : (
                        <MobilePhoneTemplate>
                            <div
                                style={{
                                    width: "320px",
                                    height: "568px",
                                    backgroundColor: "#202328",
                                    zIndex: "3",
                                    position: "absolute",
                                }}
                            >
                                {IAPType === "INTERSTITIAL" ? (
                                    <InterstitialPopUp
                                        imageLink={imageLink}
                                        title={title}
                                        IAPBody={IAPBody}
                                        ctasArray={ctasArray}
                                        cancelState={cancelState}
                                    />
                                ) : IAPType === "HEADER" ? (
                                    <HeaderPopup
                                        imageLink={imageLink}
                                        title={title}
                                        IAPBody={IAPBody}
                                        ctasArray={ctasArray}
                                        cancelState={cancelState}
                                    />
                                ) : IAPType === "FOOTER" ? (
                                    <FooterPopup
                                        imageLink={imageLink}
                                        title={title}
                                        IAPBody={IAPBody}
                                        ctasArray={ctasArray}
                                        cancelState={cancelState}
                                    />
                                ) : IAPType === "ALERT" ? (
                                    <AlertPopup
                                        imageLink={imageLink}
                                        title={title}
                                        IAPBody={IAPBody}
                                        ctasArray={ctasArray}
                                        cancelState={cancelState}
                                    />
                                ) : IAPType === "HALF_INTERSTITIAL" ? (
                                    <HalfInterstitialPopup
                                        imageLink={imageLink}
                                        title={title}
                                        IAPBody={IAPBody}
                                        ctasArray={ctasArray}
                                        cancelState={cancelState}
                                    />
                                ) : IAPType === "COVER" ? (
                                    <CoverPopUp
                                        imageLink={imageLink}
                                        title={title}
                                        IAPBody={IAPBody}
                                        ctasArray={ctasArray}
                                        cancelState={cancelState}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </MobilePhoneTemplate>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default PopupTemplatePreview;
