import React, { useState, useEffect } from "react";
import InputField from "../../components/InputField";
import CustomDropdown from "../../components/CustomDropdown";
import { templateTypeOptions, localeOptions, inAppPriorityStatus, inAppNotificationType } from "./TemplateConstants";
import FactTypeSelect from "../../components/FactTypeSelect";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTemplate, linkTemplate, reset, editTemplate } from "../../features/searchTemplate/searchTemplateSlice";
import TemplateTextArea from "../../components/TemplateTextArea";
import { toast } from "react-toastify";
import { getTemplates } from "../../features/templatesView/templatesViewSlice";
import { getHypothesis } from "../../features/searchTemplate/searchTemplateSlice";
import FormSubmitBtn from "../../components/SubmitForm";
import CustomDeepLink from "../../components/CustomDeepLink";
import DefaultVariablesTemplate from "./DefaultVariables";
import EditDefaultVars from "./EditDefaultVariables";
import { ImgUpload } from "../../components/ImageUpload";
import MultiSelect from "../../components/MultiSelect";
import appConfig from "../../config/app.config";

const InAppNotification = ({ handleClose, setCreateTempChannel, isTemplateView, templateData }) => {
    const [templateName, setTemplateName] = useState(templateData?.template_name ? templateData?.template_name : "");
    const [selectStatus, setSelectStatus] = useState("APPROVED");
    const [templateType, setTemplateType] = useState("PROMOTIONAL");
    const [locale, setLocale] = useState(templateData?.locale ? templateData?.locale : "");
    const [mentions, setMentions] = useState(templateData?.template_variables ? templateData?.template_variables : []);
    const [titleMentions, setTtitleMentions] = useState(
        templateData?.template_variables ? templateData?.template_variables : []
    );
    const [IABody, setIABody] = useState(
        templateData?.message_details?.body ? templateData?.message_details?.body : ""
    );
    const [imageLink, setImageLink] = useState(
        templateData?.message_details?.image_link ? templateData?.message_details?.image_link : ""
    );
    const [IAType, setIAType] = useState(
        templateData?.message_details?.type ? templateData?.message_details?.type : ""
    );

    const [isFactType, setIsFactType] = useState(false);
    const [IATagsOptions, setIATagsOptions] = useState(templateData?.message_details?.tags ?? []);

    const [IAPriority, setIAPriority] = useState(
        templateData?.message_details?.in_app_priority_status
            ? templateData?.message_details?.in_app_priority_status
            : ""
    );

    // DEFAULT VARIABLES
    const inputArr = [];
    const [customData, setCustomData] = useState(inputArr);
    const [customDataJSON, setCustomDataJSON] = useState(templateData?.default_template_variables_values ?? {});
    const [customVarKey, setCustomVarKey] = useState(inputArr);
    // CUSTOM DEEPLINK
    const [screenName, setScreenName] = useState();
    const [tags, setTags] = useState();
    const [desktopUrl, setDesktopUrl] = useState();
    const [deep_link, setdeep_link] = useState();
    const [customvalue, setCustomValue] = useState("Automatic");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hypothesisID = localStorage.getItem("hypothesisID");
    const editHypothesisID = localStorage.getItem("editHypothesisID");
    const createdTemplateID = useSelector((state) => state?.template?.createdTemplateData?.template_id);
    const isSuccess = useSelector((state) => state?.template?.isCreateTemplateSuccess);
    function removeDuplicates(arr) {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    }
    const templateVariables = removeDuplicates([...mentions, ...titleMentions]);
    const value = useSelector((state) => state.value);
    // TAGS
    const handleTags = (options) => {
        const arr = options.map((item) => item.value);
        setIATagsOptions(arr);
    };

    const handleIATemp = (e) => {
        e.preventDefault();
        // CREATING THE TEMPLATE
        let createData = {
            channel: "IA",
            status: selectStatus,
            template_name: templateName,
            template_type: templateType,
            message_details: {
                body: IABody,
                // title: "Title",
                image_link: imageLink !== undefined ? imageLink : undefined,
                tags: IATagsOptions,
                type: IAType,
                type_params: { typeParams: "EMBIBE" },
                // format: format,
                in_app_priority_status: IAPriority,
            },

            template_variables: [...mentions, ...titleMentions, ...customVarKey],
            default_template_variables_values: customDataJSON,
            locale: locale,
            application: value,
        };

        if (!isFactType && !isTemplateView) {
            toast.error("Please select a fact type ");
        } else if (IABody === "") {
            toast.error("Please fill body field");
        } else if (IATagsOptions?.length === 0) {
            toast.error("Please select a tag");
        } else {
            dispatch(createTemplate(createData));

            if (isTemplateView) {
                dispatch(getTemplates());
                navigate("/template");

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
    };

    // LINKING THE TEMPLATE
    const factType = localStorage.getItem("factTypeSelected");
    const [isCustomFT, setIsCustomFT] = useState(false);

    const linkCreatedTemp = () => {
        const deepLinkData = JSON.parse(localStorage.getItem("deepLinkData"));

        let data = {
            priority: 1,
            weightage: 100,
            template_id: createdTemplateID,
            fact_type: factType,
            custom_deep_link_details: {
                screen_name: deepLinkData?.screenName,
                custom_data: deepLinkData?.customDataJSON,
                tags: deepLinkData?.tags,
                desktop_url: deepLinkData?.desktopUrl,
                deep_link: deepLinkData?.deep_link,
            },
            application: value,
        };

        if (editHypothesisID) {
            const linkTemplateData = {
                hypothesisID: editHypothesisID || hypothesisID,
                data,
            };
            dispatch(linkTemplate(linkTemplateData));
            dispatch(reset());
            handleClose();
            setCreateTempChannel("");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            const linkTemplateData = {
                hypothesisID,
                data,
            };
            dispatch(linkTemplate(linkTemplateData));
            dispatch(reset());
            handleClose();
            setCreateTempChannel("");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    };
    useEffect(() => {
        dispatch(getTemplates());
    }, [value]);
    useEffect(() => {
        if (isSuccess && createdTemplateID !== undefined && createdTemplateID !== "" && !isTemplateView) {
            linkCreatedTemp();
            dispatch(getHypothesis(hypothesisID || editHypothesisID));
        }
    }, [createdTemplateID]);

    // EDIT IA NOTIFICATION TEMPLATE PUT CALL
    let varArr = removeDuplicates(customVarKey);

    const handleEditIA = () => {
        let data = {
            channel: "IA",
            status: selectStatus,
            template_name: templateName,
            template_type: templateType,
            locale: locale,
            message_details: {
                body: IABody,
                // title: title,
                image_link: imageLink !== undefined ? imageLink : templateData?.message_details?.image_link,
                tags: IATagsOptions,
                type: IAType,
                type_params: { typeParams: "EMBIBE" },
                in_app_priority_status: IAPriority,
            },
            template_variables: [...mentions, ...titleMentions, ...varArr],
            default_template_variables_values: customDataJSON,
        };

        let editData = {
            templateId: templateData?.template_id,
            data,
        };
        dispatch(editTemplate(editData));
        navigate("/template");

        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    return (
        <>
            <form onSubmit={templateData?.template_name ? handleEditIA : handleIATemp}>
                <InputField
                    id="outlined-helperText"
                    label="Template Name"
                    variant="outlined"
                    handleChange={(e) => setTemplateName(e.target.value)}
                    value={templateName}
                    isRequired={true}
                />

                <ImgUpload imageLink={imageLink} setImageLink={setImageLink} channelType="IA" />

                <CustomDropdown
                    options={appConfig[value].NOTIFICATION_TYPES}
                    label="Select Notification Type"
                    handleChange={(e) => setIAType(e.target.value)}
                    value={IAType}
                    isRequired={true}
                />
                <MultiSelect
                    editOptions={IATagsOptions}
                    label="Select Tag Options"
                    options={appConfig[value].NOTIFICATION_TAGS}
                    handleMultiSelect={handleTags}
                    selectedOptions={IATagsOptions}
                />

                <CustomDropdown options={templateTypeOptions} label="Select Template Type" value={templateType} />

                <InputField id="outlined-helperText" inputLabel="Select Status" variant="outlined" value="APPROVED" />
                <CustomDropdown
                    options={inAppPriorityStatus}
                    label="Select In App Priority"
                    value={IAPriority}
                    handleChange={(e) => setIAPriority(e.target.value)}
                    isRequired={true}
                />
                <CustomDropdown
                    options={localeOptions}
                    label="Select Locale"
                    value={locale}
                    handleChange={(e) => setLocale(e.target.value)}
                    isRequired={true}
                />
                {!isTemplateView && (
                    <FactTypeSelect
                        templateData={templateData}
                        setIsFactType={setIsFactType}
                        setIsCustomFT={setIsCustomFT}
                    />
                )}

                {isCustomFT && (
                    <CustomDeepLink
                        customvalue={customvalue}
                        setCustomValue={setCustomValue}
                        deep_link={deep_link}
                        setdeep_link={setdeep_link}
                        screenName={screenName}
                        setScreenName={setScreenName}
                        tags={tags}
                        setTags={setTags}
                        desktopUrl={desktopUrl}
                        setDesktopUrl={setDesktopUrl}
                    />
                )}
                <TemplateTextArea setMentions={setMentions} setBody={setIABody} body={IABody} />
                {templateData?.channel === "IA" ? (
                    <EditDefaultVars
                        templateData={templateData}
                        setCustomDataJSON={setCustomDataJSON}
                        setCustomVarKey={setCustomVarKey}
                    />
                ) : (
                    <DefaultVariablesTemplate
                        customData={customData}
                        setCustomData={setCustomData}
                        customDataJSON={customDataJSON}
                        setCustomDataJSON={setCustomDataJSON}
                        setCustomVarKey={setCustomVarKey}
                        templateData={templateData}
                    />
                )}

                {templateData?.template_name ? <></> : <FormSubmitBtn type="submit" />}
            </form>
        </>
    );
};

export default InAppNotification;
