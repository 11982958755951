import axios from "axios";
import { toastError } from "../../utils/helpers/toastError";
import { BASE_URL } from "../../utils/constants/apiBaseURL.constants";
import { toast } from "react-toastify";

// GET OVERALL DATA
const login = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/user-cohort-ms/auth/sign-in`, data);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET OVERALL DATA
const getOverallDataAPI = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/emtap-audit-system/dashboard/channel`, data);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET CAMPAIGN WISE DATA
const getCampaignWiseDataAPI = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/emtap-audit-system/dashboard/hypothesis-channel`, data);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET REACH DATA
const getReachDataAPI = async () => {
    try {
        const response = await axios(`${BASE_URL}/emtap-audit-system/dashboard/reach`);
        // const response = await axios.get(`https://run.mocky.io/v3/773a9b5f-798b-40e5-8e55-dd71ebbcc352`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET GOAL SUCCESS API
const getGoalSuccessAPI = async ({ hypothesisId, triggerId, application }) => {
    try {
        const response = await axios(
            `${BASE_URL}/emtap-audit-system/dashboard/hypothesis-channel/${hypothesisId}/triggerId/${triggerId}`
        );
        if (response?.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// EXPORT CAMPAIGN WISE DATA
const exportCampaignWiseDataAPI = async ({ data }) => {
    try {
        const response = await axios.post(`${BASE_URL}/emtap-audit-system/dashboard/hypothesis-channel/download`, data);
        if (response?.status === 200) {
            toast.success("Report sent");
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// SCHEDULE FEATURE
const scheduleFeatureAPI = async ({ startDate, endDate, page, rowsPerPage, application }) => {
    try {
        const response = await axios(
            `${BASE_URL}/user-cohort-ms/hypothesis/filter?startDate=${startDate}&endDate=${endDate}&pageNo=${page}&application=${application}&pageSize=${rowsPerPage}`
        );
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// STOP/RESCIND CAMPAIGN
const stopRescindCampaignAPI = async ({ hypothesisId, data }) => {
    fetch(`${BASE_URL}/user-cohort-ms/hypothesis/${hypothesisId}/rescind`, {
        method: "DELETE",
        headers: {
            "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response?.status === 200) {
                toast.success("Campaign successfully stopped");
                response.json();
            }
        })
        .catch((error) => {
            toastError(error);
            return Promise.reject(error);
        });

    // try {
    //     const response = await axios.delete(`${BASE_URL}/user-cohort-ms/hypothesis/${hypothesisId}/rescind`, data);
    //     return response.data.payload;
    // } catch (error) {
    //     toastError(error);
    //     return Promise.reject(error);
    // }
};

const homeService = {
    login,
    getOverallDataAPI,
    getCampaignWiseDataAPI,
    getReachDataAPI,
    getGoalSuccessAPI,
    exportCampaignWiseDataAPI,
    scheduleFeatureAPI,
    stopRescindCampaignAPI,
};

export default homeService;
