export const ROUTE = {
    LOGIN: "/",
    HOME: "/home",
    CAMPIAGN: "/campiagn",
    CREATE_CAMPAIGN: "/create-campaign",
    LINKED_TEMPLATE: "/linked-template",
    CAMPAIGN_DETAILS: "/campaign-details",
    TEST_USER_LIST: "/test-user-list",
    TEST_USER: "/test-user/:userId/:name",
    TEMPLATE: "/template",
    CREATE_TEMPLATE: "/create-template",
    USER_VIEW: "/user-view",
    DEEPLINK: "/deeplink_generation",
};
