import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import ScheduleType from "./ScheduleType/index";
import InputField from "../../components/InputField";
import {
    userViewCampaignDetails,
    userListAPI,
    userViewTriggerExecute,
    getAllLinkedHypothesis,
    getUserViews,
    getUserViewName,
} from "../../features/userView/userViewSlice";
import UserListTest from "./UserListTest";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import UserViewDropdown from "./components/UserViewDropdown";
import { Link } from "react-router-dom";
import BackArrow from "@mui/icons-material/ArrowBackIos";
import { toast } from "react-toastify";
import { getHypothesis } from "../../features/searchTemplate/searchTemplateSlice";
import UserViewDDSelect from "./components/UserViewDDSelect";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 450,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
};

const TargetDevice = ({ handleUserViewChange }) => {
    return (
        <div className="user-view-dropdown">
            <div className="target-device-dropdown-title">Target Device</div>
            <div style={{ width: "60%" }}>
                <FormControl fullWidth>
                    <Select id="create-template" name="targetDevice" onChange={(e) => handleUserViewChange(e)}>
                        <MenuItem value="ios">iOS</MenuItem>
                        <MenuItem value="android">Andoid</MenuItem>
                        <MenuItem value="both">Both</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

const AgeOnPlatform = ({ handleUserViewChange }) => {
    return (
        <div className="age-container">
            <div>Age on Platform</div>
            <div> T </div>
            <div>+</div>
            <div>
                <InputField
                    id="outlined-helperText"
                    label="days"
                    variant="outlined"
                    handleChange={(e) => handleUserViewChange(e)}
                    name="ageOnPlatform"
                    type="number"
                    style="50%"
                />
            </div>
        </div>
    );
};

const DNDData = () => {
    return (
        <div className="user-view-dropdown">
            <div className="dnd-data-title">DND Data</div>
            <div>
                <InputField
                    id="outlined-helperText"
                    label="Upload file"
                    variant="outlined"
                    // handleChange={(e) => setAgePlatform(e.target.value)}
                    // value={agePlatform}
                    style="180%"
                />
            </div>
        </div>
    );
};

const UserView = () => {
    // SET THE USER VIEW DATA

    const { errors } = useSelector((state) => state.userView);
    const userViewEditDetails = useSelector((state) => state?.template?.hypohesisData?.campaign);
    const userViewDropdownData = useSelector((state) => state?.userView?.userViews?.content);
    const userViewDataCount = useSelector((state) => state?.userView?.userViews?.totalElements);

    const hypothesisID = localStorage.getItem("hypothesisID");
    const editHypothesisID = localStorage.getItem("editHypothesisID");

    // EDIT THE USER VIEW DATA
    const userViewID = useSelector((state) => state?.template?.hypohesisData?.campaign?.user_view_id);
    const userViewEditID = useSelector((state) => state?.userView?.userViewName?.user_view_id);
    const userViewEditData = useSelector((state) => state?.template?.hypohesisData?.campaign);

    const updateUserViewDD = () => {
        // if (userViewDropdownData) {
        //     return userViewDropdownData?.[0]?.user_view_id;
        // } else if (userViewEditID) {
        //     return userViewEditID;
        // }
        if (userViewEditID && editHypothesisID && userViewEditData !== undefined) {
            return userViewEditData?.user_view_id || "86ab5547-1b3b-46d3-bff6-8be895b7a6d7";
        }
    };

    const [userViewSelected, setUserViewSelected] = useState(updateUserViewDD());
    const [scheduleType, setScheduleType] = useState(userViewEditDetails?.schedule?.schedule_type ?? "");
    const [userViewSelectedData, setUserViewSelectedData] = useState({});
    const [weeklySelection, setWeeklySelection] = useState([]);
    const [monthlySelection, setMonthlySelection] = useState([]);
    const [disableSaveBtn, setSaveDisableBtn] = useState(false);
    const [disableTestExeBtn, setTestExeDisableBtn] = useState(true);
    const [userListTestData, setUserListTestData] = useState();

    const [userListSelected, setUserListSelected] = useState();
    const [linkedTempSelected, setLinkedTempSelected] = useState();

    const dispatch = useDispatch();
    const value = useSelector((state) => state.value);
    // EDIT WEEKLY SELECTION
    let selectedDays = userViewEditDetails?.schedule?.selections;
    const [editWeeklySelection, setEditWeeklySelection] = useState(selectedDays);

    // EDIT MONTHLY SELECTION CHECKBOX
    let selectedDates = userViewEditDetails?.schedule?.selections;
    const [editMonthlySelection, setEditMonthlySelection] = useState(selectedDates);

    // MODAL OPEN/CLOSE FUNCTIONS
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const data = {
            page: 0,
            rowsPerPage: 1500,
            application:value,
        };
        dispatch(getUserViews(data));

        // EDIT USER VIEW FLOW
        if (userViewID) {
            dispatch(getUserViewName(userViewID));
        }
    }, [value]);

    const handleSchduleType = (val) => {
        setScheduleType(val);
        setUserViewSelectedData({});

        setWeeklySelection("");
        setMonthlySelection("");

        if (val === "IMMEDIATE") {
            setSaveDisableBtn(false);
            setTestExeDisableBtn(true);
        }

        if (userViewEditDetails?.schedule?.schedule_type !== val) {
            setScheduleType(val);
        }

        setEditWeeklySelection("");
        setEditMonthlySelection("");
    };

    const handleUserViewChange = (e) => {
        const { name, value } = e.target;
        setUserViewSelectedData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setSaveDisableBtn(false);
        setTestExeDisableBtn(true);
    };

    const handleWeeklyCheckbox = (e) => {
        let weeklyList = [...weeklySelection];
        if (weeklyList.includes(e.target.value)) {
            const listIndex = weeklyList.length !== 0 && weeklyList.indexOf(e.target.value);
            weeklyList.splice(listIndex, 1);
            setWeeklySelection(weeklyList);
        } else {
            weeklyList.push(e.target.value);
            setWeeklySelection(weeklyList);
        }
        setSaveDisableBtn(false);
        setTestExeDisableBtn(true);
    };

    // EDIT WEEKLY SELECTION
    // let selectedDays = userViewEditDetails?.schedule?.selections;
    // const [editWeeklySelection, setEditWeeklySelection] = useState(selectedDays);
    const handleEditWeeklyCheckbox = (e) => {
        let editWeekly = [...editWeeklySelection];
        if (editWeekly.includes(e.target.value)) {
            const listIndex = editWeekly.length !== 0 && editWeekly.indexOf(e.target.value);
            editWeekly.splice(listIndex, 1);
            setEditWeeklySelection(editWeekly);
        } else {
            editWeekly.push(e.target.value);
            setEditWeeklySelection(editWeekly);
        }
        setSaveDisableBtn(false);
        setTestExeDisableBtn(true);
    };

    const handleMonthlyCheckbox = (e) => {
        let monthlyList = [...monthlySelection];
        if (monthlyList.includes(e.target.value)) {
            const listIndex = monthlyList.length !== 0 && monthlyList.indexOf(e.target.value);
            monthlyList.splice(listIndex, 1);
            setMonthlySelection(monthlyList);
        } else {
            monthlyList.push(e.target.value);
            setMonthlySelection(monthlyList);
        }
        setSaveDisableBtn(false);
        setTestExeDisableBtn(true);
    };

    // EDIT MONTHLY SELECTION CHECKBOX
    // let selectedDates = userViewEditDetails?.schedule?.selections;
    // const [editMonthlySelection, setEditMonthlySelection] = useState(selectedDates);

    const handleEditMonthlyCheckbox = (e) => {
        let editMonthly = [...editMonthlySelection];
        if (editMonthly.includes(e.target.value)) {
            const listIndex = editMonthly.length !== 0 && editMonthly.indexOf(e.target.value);
            editMonthly.splice(listIndex, 1);
            setEditMonthlySelection(editMonthly);
        } else {
            editMonthly.push(e.target.value);
            setEditMonthlySelection(editMonthly);
        }
        setSaveDisableBtn(false);
        setTestExeDisableBtn(true);
    };

    //********************************************************** */ HANDLE SAVE
    const handleSave = () => {
        let data;
        if (Date.parse(userViewSelectedData?.endDate) < Date.parse(userViewSelectedData?.startDate)) {
            toast.error("End date should not be past the Start date");
        } else {
            if (scheduleType === "IMMEDIATE") {
                data = {
                    schedule: {
                        schedule_type: scheduleType,
                    },
                    user_view_id: userViewSelected?.value,
                };
            } else if (scheduleType === "WEEKLY") {
                data = {
                    schedule: {
                        selections: weeklySelection ? weeklySelection : [],
                        schedule_type: scheduleType,
                        start_date: userViewSelectedData?.startDate,
                        end_date: userViewSelectedData?.endDate,
                        trigger_time: `${userViewSelectedData?.time}:00`,
                    },
                    user_view_id: userViewSelected?.value,
                };
            } else if (scheduleType === "MONTHLY") {
                data = {
                    schedule: {
                        selections: monthlySelection ? monthlySelection : [],
                        schedule_type: scheduleType,
                        start_date: userViewSelectedData?.startDate,
                        end_date: userViewSelectedData?.endDate,
                        trigger_time: `${userViewSelectedData?.time}:00`,
                    },
                    user_view_id: userViewSelected?.value,
                };
            } else {
                data = {
                    schedule: {
                        selections: [],
                        schedule_type: scheduleType,
                        start_date: userViewSelectedData?.startDate,
                        end_date: userViewSelectedData?.endDate,
                        trigger_time: `${userViewSelectedData?.time}:00`,
                    },
                    user_view_id: userViewSelected?.value,
                };
            }

            if (editHypothesisID) {
                const campaignDetailsData = {
                    hypothesisID: editHypothesisID || hypothesisID,
                    data,
                };

                dispatch(userViewCampaignDetails(campaignDetailsData));
                // dispatch(getHypothesis(hypothesisID || editHypothesisID));

                setSaveDisableBtn(true);
                setTestExeDisableBtn(false);
            } else {
                const campaignDetailsData = {
                    hypothesisID,
                    data,
                };

                dispatch(userViewCampaignDetails(campaignDetailsData));
                // dispatch(getHypothesis(hypothesisID || editHypothesisID));

                setSaveDisableBtn(true);
                setTestExeDisableBtn(false);
            }
        }
    };

    // EDIT VALUES
    let editData = {
        scheduleType: userViewEditData?.schedule?.schedule_type,
        selections: userViewEditData?.schedule?.selections,
        startDate: userViewEditData?.schedule?.start_date,
        endDate: userViewEditData?.schedule?.end_date,
        time: userViewEditData?.schedule?.trigger_time,
        userViewId: userViewEditData?.user_view_id,
    };

    //************************************************************** */ HANDLE EDIT SAVE
    const handleEditSave = () => {
        let data;
        if (
            (Date.parse(userViewSelectedData?.endDate) || Date.parse(editData?.endDate)) <
            (Date.parse(userViewSelectedData?.startDate) || Date.parse(editData?.startDate))
        ) {
            toast.error("End date should not be past the Start date");
        } else if (scheduleType === "IMMEDIATE") {
            data = {
                schedule: {
                    schedule_type: scheduleType ? scheduleType : editData?.scheduleType,
                },
                user_view_id: userViewSelected?.value ? userViewSelected?.value : editData?.userViewId,
            };

            const campaignDetailsData = {
                hypothesisID: editHypothesisID || hypothesisID,
                data,
            };

            dispatch(userViewCampaignDetails(campaignDetailsData));
            setSaveDisableBtn(true);
            setTestExeDisableBtn(false);
        } else {
            data = {
                schedule: {
                    selections:
                        weeklySelection ||
                        monthlySelection ||
                        editMonthlySelection ||
                        editWeeklySelection ||
                        editData?.selections,
                    schedule_type: scheduleType ? scheduleType : editData?.scheduleType,
                    start_date: userViewSelectedData?.startDate ? userViewSelectedData?.startDate : editData?.startDate,
                    end_date: userViewSelectedData?.endDate ? userViewSelectedData?.endDate : editData?.endDate,
                    trigger_time: userViewSelectedData?.time ? `${userViewSelectedData?.time}:00` : `${editData?.time}`,
                },
                user_view_id: userViewSelected?.value ? userViewSelected?.value : editData?.userViewId,
            };

            const campaignDetailsData = {
                hypothesisID: editHypothesisID || hypothesisID,
                data,
            };

            dispatch(userViewCampaignDetails(campaignDetailsData));
            setSaveDisableBtn(true);
            setTestExeDisableBtn(false);
        }
    };

    //********************************************************************* */ HANDLE TEST
    const handleTest = () => {
        if (editHypothesisID) {
            dispatch(userListAPI(value));
            dispatch(getAllLinkedHypothesis({ hypothesisID: editHypothesisID }));
            setOpen(true);
        } else {
            dispatch(userListAPI(value));
            dispatch(getAllLinkedHypothesis({ hypothesisID }));
            setOpen(true);
        }
    };

    //****************************************************************** */ NORMAL TRIGGER EXECUTE
    const handleExecute = () => {
        if (editHypothesisID) {
            const exeData = {
                hypothesis_id: editHypothesisID,
                application:value,
            };
            dispatch(userViewTriggerExecute(exeData));
            // dispatch(getHypothesis(hypothesisID || editHypothesisID));
        } else {
            const exeData = {
                hypothesis_id: hypothesisID,
                application:value,
            };
            dispatch(userViewTriggerExecute(exeData));
            // dispatch(getHypothesis(hypothesisID || editHypothesisID));
        }
    };

    //***************************************************************** */ TEST TRIGGER EXECUTE
    const handleTestExecute = () => {
        if (editHypothesisID) {
            const exeData = {
                hypothesis_id: editHypothesisID,
                operation_type: "TEST",
                users_list_id: userListSelected?.value,
                linked_template_id: linkedTempSelected?.value,
                application:value,
            };
            dispatch(userViewTriggerExecute(exeData));
            setTimeout(() => {
                handleClose();
            }, 500);
        } else {
            const exeData = {
                hypothesis_id: hypothesisID,
                operation_type: "TEST",
                users_list_id: userListSelected?.value,
                linked_template_id: linkedTempSelected?.value,
                application:value,
            };
            dispatch(userViewTriggerExecute(exeData));
            setTimeout(() => {
                handleClose();
            }, 500);
        }
    };
    useEffect  (()=>{
        dispatch(userListAPI(value));
    },[value]);
    return (
        <>
            <Link to="/linked-template" className="user-view-back-arrow">
                <BackArrow />
            </Link>
            <div className="user-view-container">
                {/* <UserViewDropdown
                    userViewSelected={userViewSelected}
                    setUserViewSelected={setUserViewSelected}
                    userViewDropdownData={userViewDropdownData}
                /> */}
                <UserViewDDSelect
                    userViewSelected={userViewSelected}
                    setUserViewSelected={setUserViewSelected}
                    userViewDropdownData={userViewDropdownData}
                />
                <ScheduleType
                    userViewEditDetails={userViewEditDetails}
                    scheduleType={scheduleType}
                    handleSchduleType={handleSchduleType}
                    handleUserViewChange={handleUserViewChange}
                    userViewSelectedData={userViewSelectedData}
                    weeklySelection={weeklySelection}
                    monthlySelection={monthlySelection}
                    handleWeeklyCheckbox={handleWeeklyCheckbox}
                    handleMonthlyCheckbox={handleMonthlyCheckbox}
                    handleSave={handleSave}
                    handleTest={handleTest}
                    handleExecute={handleExecute}
                    disableSaveBtn={disableSaveBtn}
                    disableTestExeBtn={disableTestExeBtn}
                    handleEditWeeklyCheckbox={handleEditWeeklyCheckbox}
                    editWeeklySelection={editWeeklySelection}
                    handleEditSave={handleEditSave}
                    handleEditMonthlyCheckbox={handleEditMonthlyCheckbox}
                    editMonthlySelection={editMonthlySelection}
                />
                {/* <TargetDevice handleUserViewChange={handleUserViewChange} /> */}
                {/* <AgeOnPlatform handleUserViewChange={handleUserViewChange} /> */}
                {/* <DNDData /> */}

                {/* USER LIST TEST MODAL */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="search-modal-header">
                            <span>USER LIST TEST</span>
                            <span onClick={handleClose}>x</span>
                        </div>
                        <UserListTest
                            setUserListTestData={setUserListTestData}
                            setUserListSelected={setUserListSelected}
                            setLinkedTempSelected={setLinkedTempSelected}
                            handleClose={handleClose}
                            handleTestExecute={handleTestExecute}
                        />
                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default UserView;
