import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DateRangePicker from "../DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import { getOverallData } from "../../../../features/home/homeSlice";
import { dateHelperFormat } from "../../../../utils/helpers/dateHelper";
import { toast } from "react-toastify";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Spinner from "../../../../components/Spinner";

const datePickerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const Overall = () => {
    // PAGINATION
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // PAGE NUMBER CHANGE - pages 1,2,...9,10
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // NO OF ROWS PER PAGE COUNT - 5, 10, 25
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    // SETTING INITIAL DATE
    let d = new Date();
    let initEndDate = dateHelperFormat(d);
    // d.setDate(d.getDate() - 3);
    let initStartDate = dateHelperFormat(d);

    const [startDate, setStartDate] = useState(initStartDate ?? "");
    const [endData, setEndDate] = useState(initEndDate ?? "");

    const [updatedStartDate, setUpdatedStartDate] = useState(initStartDate ?? "");
    const [updatedEndDate, setUpdatedEndDate] = useState(initEndDate ?? "");

    const dispatch = useDispatch();
    const value = useSelector((state) => state.value);
    const overallData = useSelector((state) => state?.home?.overallData);
    const isOverallLoading = useSelector((state) => state?.home?.isOverallLoading);

    useEffect(() => {
        let data = {
            start_date: `${initStartDate}T00:00:00`,
            end_date: `${initEndDate}T23:59:59`,
            // trigger_type:triggerType,
            application: value,
        };

        dispatch(getOverallData(data));
    }, [value]);

    // DATE PICKER MODAL OPEN/CLOSE FUNCTIONS
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const handleDatePickerClose = () => {
        setDatePickerOpen(false);
        setStartDate(updatedStartDate);
        setEndDate(updatedEndDate);
    };

    const handleDatePickerOpen = () => {
        setDatePickerOpen(true);
    };

    // OVERALL DATA
    const handleDateSave = () => {
        if (Date.parse(endData) < Date.parse(startDate)) {
            toast.error("End date should not be past the Start date");
        } else {
            let data = {
                start_date: `${startDate}T00:00:00`,
                end_date: `${endData}T23:59:59`,
                application: value,
                // trigger_type:triggerType,
            };

            setUpdatedStartDate(startDate);
            setUpdatedEndDate(endData);

            dispatch(getOverallData(data));
            setDatePickerOpen(false);
        }
    };

    return (
        <>
            <div className="home-reach-header">Overall</div>

            {isOverallLoading ? (
                <div className="spinner-container-overall">
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className="home-overall-btn">
                        <button className="home-buttons" onClick={handleDatePickerOpen}>
                            {Date.parse(endData) < Date.parse(startDate) ? initStartDate : updatedStartDate} |
                            {Date.parse(endData) < Date.parse(startDate) ? initEndDate : updatedEndDate}
                        </button>
                    </div>
                    {/* <div className="boxContainer"> */}
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer>
                            <Table sx={{ minWidth: "100%", maxWidth: "100%" }} aria-label="simple table">
                                <TableHead className="home-overall-table-head">
                                    <TableRow>
                                        <TableCell>Channel</TableCell>
                                        <TableCell align="center">Selected</TableCell>
                                        <TableCell align="center">Sent</TableCell>
                                        <TableCell align="center">Delivered</TableCell>
                                        <TableCell align="center">Delivery Rate</TableCell>
                                        <TableCell align="center">Clicks</TableCell>
                                        <TableCell align="center">Click Rate</TableCell>
                                        <TableCell align="center">App Opens</TableCell>
                                        <TableCell align="center">Failed</TableCell>
                                        <TableCell align="center">Failure Rate</TableCell>
                                    </TableRow>
                                </TableHead>
                                {overallData?.length > 0 ? (
                                    <TableBody>
                                        {overallData
                                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            ?.map((item) => {
                                                // <OverallTableRow key={item?.channel} item={item} />
                                                return (
                                                    <TableRow
                                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                    >
                                                        <TableCell>{item?.channel}</TableCell>
                                                        <TableCell align="center">{item?.selected}</TableCell>
                                                        <TableCell align="center">{item?.sent}</TableCell>
                                                        <TableCell align="center">{item?.delivered}</TableCell>
                                                        <TableCell align="center">
                                                            {item?.delivery_rate === 0
                                                                ? item?.delivery_rate
                                                                : item?.delivery_rate?.toFixed(2)}
                                                            %
                                                        </TableCell>
                                                        <TableCell align="center">{item?.clicks}</TableCell>
                                                        <TableCell align="center">
                                                            {item?.click_rate === 0
                                                                ? item?.click_rate
                                                                : item?.click_rate?.toFixed(2)}
                                                            %
                                                        </TableCell>
                                                        <TableCell align="center">{item?.app_opens}</TableCell>
                                                        <TableCell align="center">{item?.failed}</TableCell>
                                                        <TableCell width="110px" align="center">
                                                            {item?.failure_rate === 0
                                                                ? item?.failure_rate
                                                                : item?.failure_rate?.toFixed(2)}
                                                            %
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                ) : (
                                    <TableBody style={{ position: "relative", height: "84px" }}>
                                        <div className="home-no-data">No data available</div>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={overallData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </>
            )}

            {/* DATE PICKER MODAL */}
            <Modal
                open={datePickerOpen}
                onClose={handleDatePickerClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={datePickerStyle}>
                    <DateRangePicker
                        handleClose={handleDatePickerClose}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endData={endData}
                        setEndDate={setEndDate}
                        handleSave={handleDateSave}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default Overall;
