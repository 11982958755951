import { useState, useEffect } from "react";
import InputField from "../../../../components/InputField";
import { useDispatch } from "react-redux";
import { getStartDate } from "../../../../features/userView/userViewSlice";

const StartDate = ({ userViewEditDetails, handleUserViewChange }) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState();

    useEffect(() => {
        if (userViewEditDetails?.schedule?.start_date) {
            setStartDate(userViewEditDetails?.schedule?.start_date);
        }
    }, []);

    const handleStartDate = (e) => {
        setStartDate(e.target.value);
        handleUserViewChange(e);
    };

    const handleDateChange = (e) => {
        dispatch(getStartDate(e.target.value));
        handleUserViewChange(e);
    };

    const date = new Date();
    const today = date.toISOString().split("T")[0];

    return (
        <div className="user-view-input-wrapper">
            <div>Start Date</div>
            {userViewEditDetails?.schedule?.start_date ? (
                <InputField
                    id="outlined-helperText"
                    variant="outlined"
                    name="startDate"
                    type="date"
                    value={startDate ?? ""}
                    handleChange={(e) => handleStartDate(e)}
                    inputProps={{
                        min: today,
                    }}
                />
            ) : (
                <InputField
                    id="outlined-helperText"
                    variant="outlined"
                    name="startDate"
                    type="date"
                    handleChange={(e) => handleDateChange(e)}
                    inputProps={{
                        min: today,
                    }}
                />
            )}
        </div>
    );
};

export default StartDate;
