import React, { useState, useEffect } from "react";
import InputField from "../../components/InputField";
import CustomDropdown from "../../components/CustomDropdown";
import {
    statusOptions,
    templateTypeOptions,
    localeOptions,
    formatOptions,
    notificationTypeOptions,
    pnNotificationTypes,
} from "./TemplateConstants";
import FactTypeSelect from "../../components/FactTypeSelect";
import FactTypeInput from "../../components/FactTypeInput";
import CustomButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTemplate, linkTemplate, reset, editTemplate } from "../../features/searchTemplate/searchTemplateSlice";
import TemplateTextArea from "../../components/TemplateTextArea";
import { toast } from "react-toastify";
import { getTemplates } from "../../features/templatesView/templatesViewSlice";
import { getHypothesis } from "../../features/searchTemplate/searchTemplateSlice";
import FormSubmitBtn from "../../components/SubmitForm";
import CustomDeepLink from "../../components/CustomDeepLink";
import TemplateTitle from "../../components/TemplateTitle";
import TemplatePreview from "./TemplatePreview";
import DefaultVariablesTemplate from "./DefaultVariables";
import EditDefaultVars from "./EditDefaultVariables";
import { ImgUpload } from "../../components/ImageUpload";

function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}

const PushNotificationTemplate = ({ handleClose, setCreateTempChannel, isTemplateView, templateData }) => {
    const [templateName, setTemplateName] = useState(templateData?.template_name ? templateData?.template_name : "");
    const [notificationType, setNotificationType] = useState(
        templateData?.template_name ? templateData?.template_name : ""
    );
    const [selectStatus, setSelectStatus] = useState("APPROVED");
    const [templateType, setTemplateType] = useState("PROMOTIONAL");
    const [locale, setLocale] = useState(templateData?.locale ? templateData?.locale : "");
    // const [format, setformat] = useState(
    //     templateData?.message_details?.format ? templateData?.message_details?.format : ""
    // );
    const [title, setTitle] = useState(
        templateData?.message_details?.title ? templateData?.message_details?.title : ""
    );

    const [mentions, setMentions] = useState(templateData?.template_variables ? templateData?.template_variables : []);
    const [titleMentions, setTtitleMentions] = useState(
        templateData?.template_variables ? templateData?.template_variables : []
    );
    const [pushNBody, setPushNBody] = useState(
        templateData?.message_details?.body ? templateData?.message_details?.body : ""
    );
    const [imageLink, setImageLink] = useState(
        templateData?.message_details?.image_link ? templateData?.message_details?.image_link : ""
    );
    const value = useSelector((state) => state.value);
    const [isFactType, setIsFactType] = useState(false);
    // const [pnType, setPNType] = useState();

    // const handlePNType = (val) => {
    //     switch (val) {
    //         case "pn_notification_type_app":
    //             return setPNType("APP");
    //         case "pn_notification_type_web":
    //             return setPNType("WEB");
    //         case "pn_notification_type_both":
    //             return setPNType("BOTH");
    //         default:
    //             break;
    //     }
    // };

    // DEFAULT VARIABLES
    const inputArr = [];
    const [customData, setCustomData] = useState(inputArr);
    const [customDataJSON, setCustomDataJSON] = useState(templateData?.default_template_variables_values ?? {});
    const [customVarKey, setCustomVarKey] = useState(inputArr);

    // CUSTOM DEEPLINK
    const [screenName, setScreenName] = useState();
    const [tags, setTags] = useState();
    const [desktopUrl, setDesktopUrl] = useState();
    const [deep_link, setdeep_link] = useState();
    const [customvalue, setCustomValue] = useState("Automatic");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const hypothesisID = localStorage.getItem("hypothesisID");
    const editHypothesisID = localStorage.getItem("editHypothesisID");

    const createdTemplateID = useSelector((state) => state?.template?.createdTemplateData?.template_id);

    const isSuccess = useSelector((state) => state?.template?.isCreateTemplateSuccess);

    function removeDuplicates(arr) {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    }
    const templateVariables = removeDuplicates([...mentions, ...titleMentions]);

    const handlePushNTemp = (e) => {
        e.preventDefault();
        // CREATING THE TEMPLATE
        let createData = {
            channel: "PN",
            status: selectStatus,
            template_name: templateName,
            template_type: templateType,
            message_details: {
                body: pushNBody,
                title: title,
                image_link: imageLink !== undefined ? imageLink : "",
                // format: format,
            },
            external_vendor_ids: {},
            template_variables: [...mentions, ...titleMentions, ...customVarKey],
            default_template_variables_values: customDataJSON,
            locale: locale,
            application: value,
            // push_notification_type: pnType,
        };

        if (!isFactType && !isTemplateView) {
            toast.error("Please select a fact type ");
        } else if (pushNBody === "") {
            toast.error("Please fill body field");
        } else {
            dispatch(createTemplate(createData));

            if (isTemplateView) {
                dispatch(getTemplates());
                navigate("/template");

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
    };

    // LINKING THE TEMPLATE
    const factType = localStorage.getItem("factTypeSelected");
    const [isCustomFT, setIsCustomFT] = useState(false);

    // useEffect(() => {
    //     if (factType === "CUSTOM") {
    //         setIsCustomFT(true);
    //     } else {
    //         setIsCustomFT(false);
    //     }
    // }, [factType]);
    const linkCreatedTemp = () => {
        const deepLinkData = JSON.parse(localStorage.getItem("deepLinkData"));

        let data = {
            priority: 1,
            weightage: 100,
            template_id: createdTemplateID,
            fact_type: factType,
            custom_deep_link_details: {
                screen_name: deepLinkData?.screenName,
                custom_data: deepLinkData?.customDataJSON,
                tags: deepLinkData?.tags,
                desktop_url: deepLinkData?.desktopUrl,
                deep_link: deepLinkData?.deep_link,
            },
            application: value,
        };

        if (editHypothesisID) {
            const linkTemplateData = {
                hypothesisID: editHypothesisID || hypothesisID,
                data,
            };
            dispatch(linkTemplate(linkTemplateData));
            dispatch(reset());
            handleClose();
            setCreateTempChannel("");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            const linkTemplateData = {
                hypothesisID,
                data,
            };
            dispatch(linkTemplate(linkTemplateData));
            dispatch(reset());
            handleClose();
            setCreateTempChannel("");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    };
    useEffect(() => {
        dispatch(getTemplates());
    }, [value]);

    useEffect(() => {
        if (isSuccess && createdTemplateID !== undefined && createdTemplateID !== "" && !isTemplateView) {
            linkCreatedTemp();
            dispatch(getHypothesis(hypothesisID || editHypothesisID));
        }
    }, [createdTemplateID]);

    // EDIT PUSH NOTIFICATION TEMPLATE PUT CALL
    let varArr = removeDuplicates(customVarKey);

    const handleEditPushN = () => {
        // let variablesSelected = JSON.parse(localStorage.getItem("selectedvars"));
        // let emailBody = localStorage.getItem("factTypeValue");

        let data = {
            channel: "PN",
            status: selectStatus,
            template_name: templateName,
            template_type: templateType,
            locale: locale,
            message_details: {
                body: pushNBody,
                title: title,
                image_link: imageLink !== undefined ? imageLink : "",
                // format: format,
            },
            template_variables: [...mentions, ...titleMentions, ...varArr],
            external_vendor_ids: {},
            default_template_variables_values: customDataJSON,
        };

        let editData = {
            templateId: templateData?.template_id,
            data,
        };
        dispatch(editTemplate(editData));
        navigate("/template");

        setTimeout(() => {
            window.location.reload();
        }, 1000);
        // if (!isFactType) {
        //     toast.error("Please select a fact type ");
        // } else {
        //     dispatch(editTemplate(editData));
        //     navigate("/template");
        // }
    };

    // MODAL OPEN/CLOSE FUNCTIONS
    const [open, setOpen] = useState(false);
    const handlePreviewClose = () => setOpen(false);

    return (
        <>
            <form onSubmit={templateData?.template_name ? handleEditPushN : handlePushNTemp}>
                {/* <CustomDropdown
                    options={pnNotificationTypes}
                    label="Select PN Type"
                    value={pnType}
                    handleChange={(e) => {
                        handlePNType(e.target.value);
                    }}
                    // isRequired={true}
                /> */}
                <InputField
                    id="outlined-helperText"
                    label="Template Name"
                    variant="outlined"
                    handleChange={(e) => setTemplateName(e.target.value)}
                    value={templateName}
                    isRequired={true}
                />

                <ImgUpload
                    imageLink={imageLink}
                    setImageLink={setImageLink}
                    channelType="PN"
                    templateData={templateData}
                />
                {/* <CustomDropdown
                options={notificationTypeOptions}
                label="Notification Type"
                value={notificationType}
                handleChange={(e) => setNotificationType(e.target.value)}
            /> */}

                <InputField
                    id="outlined-helperText"
                    inputLabel="Select Template Type"
                    variant="outlined"
                    value="PROMOTIONAL"
                    // isRequired={true}
                />
                {/* <CustomDropdown
                options={templateTypeOptions}
                label="Select Template Type"
                value={templateType}
                handleChange={(e) => setTemplateType(e.target.value)}
                isRequired={true}
            /> */}
                {/* <CustomDropdown
                options={statusOptions}
                label="Select Status"
                value={selectStatus}
                handleChange={(e) => setSelectStatus(e.target.value)}
                isRequired={true}
            /> */}

                <InputField
                    id="outlined-helperText"
                    inputLabel="Select Status"
                    variant="outlined"
                    value="APPROVED"
                    // isRequired={true}
                />
                <CustomDropdown
                    options={localeOptions}
                    label="Select Locale"
                    value={locale}
                    handleChange={(e) => setLocale(e.target.value)}
                    isRequired={true}
                />
                {/* <CustomDropdown
                options={formatOptions}
                label="Select Format"
                value={format}
                handleChange={(e) => setformat(e.target.value)}
            /> */}
                {/* <InputField
                id="outlined-helperText"
                label="Title"
                variant="outlined"
                handleChange={(e) => setTitle(e.target.value)}
                value={title}
                isRequired={true}
            /> */}
                <TemplateTitle setMentions={setTtitleMentions} setTitle={setTitle} title={title} />
                {!isTemplateView && (
                    <FactTypeSelect
                        templateData={templateData}
                        setIsFactType={setIsFactType}
                        setIsCustomFT={setIsCustomFT}
                    />
                )}
                {/* <FactTypeInput label="Body" /> */}
                {isCustomFT && (
                    <CustomDeepLink
                        customvalue={customvalue}
                        setCustomValue={setCustomValue}
                        deep_link={deep_link}
                        setdeep_link={setdeep_link}
                        screenName={screenName}
                        setScreenName={setScreenName}
                        tags={tags}
                        setTags={setTags}
                        desktopUrl={desktopUrl}
                        setDesktopUrl={setDesktopUrl}
                    />
                )}
                <TemplateTextArea setMentions={setMentions} setBody={setPushNBody} body={pushNBody} />
                {templateData?.channel === "PN" ? (
                    <EditDefaultVars
                        templateData={templateData}
                        setCustomDataJSON={setCustomDataJSON}
                        setCustomVarKey={setCustomVarKey}
                    />
                ) : (
                    <DefaultVariablesTemplate
                        customData={customData}
                        setCustomData={setCustomData}
                        customDataJSON={customDataJSON}
                        setCustomDataJSON={setCustomDataJSON}
                        // customVarKey={customVarKey}
                        setCustomVarKey={setCustomVarKey}
                        templateData={templateData}
                    />
                )}

                {/* 
            {templateData?.template_name ? (
                <CustomButton buttonText="Submit Edit" variant="outlined" />
            ) : (
                <CustomButton buttonText="Submit" variant="outlined" />
            )} */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "36px",
                    }}
                >
                    {templateData?.template_name ? <></> : <FormSubmitBtn type="submit" />}
                    <CustomButton
                        buttonText="Preview"
                        variant="contained"
                        handleClick={() => setOpen(true)}
                        // disabled={!intent || !action ? true : false}
                    />
                </div>
            </form>

            <TemplatePreview
                open={open}
                setOpen={setOpen}
                handlePreviewClose={handlePreviewClose}
                templateType="PN"
                pushNBody={pushNBody}
                pushNTitle={title}
                pnImageLink={imageLink}
            />
        </>
    );
};

export default PushNotificationTemplate;
