import CustomCheckbox from "../../../../components/CustomCheckbox";

const MonthlySelection = ({ userViewEditDetails, handleCheckbox, handleEditMonthlyCheckbox, editMonthlySelection }) => {
    const datesList = [
        { id: "1", date: "1", isChecked: false },
        { id: "2", date: "2", isChecked: false },
        { id: "3", date: "3", isChecked: false },
        { id: "4", date: "4", isChecked: false },
        { id: "5", date: "5", isChecked: false },
        { id: "6", date: "6", isChecked: false },
        { id: "7", date: "7", isChecked: false },
        { id: "8", date: "8", isChecked: false },
        { id: "9", date: "9", isChecked: false },
        { id: "10", date: "10", isChecked: false },
        { id: "11", date: "11", isChecked: false },
        { id: "12", date: "12", isChecked: false },
        { id: "13", date: "13", isChecked: false },
        { id: "14", date: "14", isChecked: false },
        { id: "15", date: "15", isChecked: false },
        { id: "16", date: "16", isChecked: false },
        { id: "17", date: "17", isChecked: false },
        { id: "18", date: "18", isChecked: false },
        { id: "19", date: "19", isChecked: false },
        { id: "20", date: "20", isChecked: false },
        { id: "21", date: "21", isChecked: false },
        { id: "22", date: "22", isChecked: false },
        { id: "23", date: "23", isChecked: false },
        { id: "24", date: "24", isChecked: false },
        { id: "25", date: "25", isChecked: false },
        { id: "26", date: "26", isChecked: false },
        { id: "27", date: "27", isChecked: false },
        { id: "28", date: "28", isChecked: false },
        { id: "29", date: "29", isChecked: false },
        { id: "30", date: "30", isChecked: false },
        { id: "31", date: "31", isChecked: false },
    ];

    let selectedDates = userViewEditDetails?.schedule?.selections;
    let tempDates = [];

    datesList.filter((item) => {
        return editMonthlySelection?.some((el) => {
            if (item?.date === el) {
                return tempDates.push({ ...item, isChecked: true });
            }
        });
    });

    const mergedArray = [...datesList, ...tempDates];

    function getUniqueListBy(arr, key) {
        return [...new Map(arr?.map((item) => [item[key], item])).values()];
    }
    const updatedDates = getUniqueListBy(mergedArray, "id");

    return (
        <div className="user-view-input-wrapper">
            <div>Select Days</div>
            <div className="user-view-monthly-checkbox">
                {selectedDates
                    ? updatedDates.map((item) => (
                          <CustomCheckbox
                              key={item.id}
                              label={item.date}
                              value={item.date ?? ""}
                              isChecked={item?.isChecked ? true : false}
                              name="monthlySelection"
                              handleChange={(e) => handleEditMonthlyCheckbox(e)}
                          />
                      ))
                    : datesList.map((item) => (
                          <CustomCheckbox
                              key={item.id}
                              label={item.date}
                              value={item.date}
                              name="monthlySelection"
                              handleChange={(e) => handleCheckbox(e)}
                          />
                      ))}
                {}
            </div>
        </div>
    );
};

export default MonthlySelection;
