import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import InputField from "../../components/InputField";
// import CustomCheckbox from "../../components/CustomCheckbox";
// import UserViewButtons from "./UserViewButtons";
import StartDate from "./components/StartDate";
import EndDate from "./components/EndDate";
import WeeklySelection from "./components/WeeklySelection";
import TriggerTime from "./components/TriggerTime";
import Immediate from "./scheduleTypes/Immediate";
import Once from "./scheduleTypes/Once";
import Daily from "./scheduleTypes/Daily";
import Weekly from "./scheduleTypes/Weekly";
import Monthly from "./scheduleTypes/Monthly";

const ScheduleType = ({
    scheduleType,
    handleSchduleType,
    handleUserViewChange,
    userViewSelectedData,
    weeklySelection,
    monthlySelection,
    handleWeeklyCheckbox,
    handleMonthlyCheckbox,
    handleSave,
    handleTest,
    handleExecute,
    disableSaveBtn,
    disableTestExeBtn,
    userViewEditDetails,
    handleEditWeeklyCheckbox,
    editWeeklySelection,
    handleEditSave,
    handleEditMonthlyCheckbox,
    editMonthlySelection,
    userViewDDChanged,
}) => {
    return (
        <>
            <div className="user-view-input-wrapper">
                <div>Schedule Type</div>
                <div>
                    <FormControl fullWidth>
                        <Select
                            id="create-template"
                            name="scheduleType"
                            value={scheduleType}
                            onChange={(e) => handleSchduleType(e.target.value)}
                        >
                            <MenuItem value="IMMEDIATE">Immediate</MenuItem>
                            <MenuItem value="ONCE">Once</MenuItem>
                            <MenuItem value="DAILY">Daily</MenuItem>
                            <MenuItem value="WEEKLY">Weekly</MenuItem>
                            <MenuItem value="MONTHLY">Monthly</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

            {scheduleType === "IMMEDIATE" ? (
                <Immediate
                    handleSave={handleSave}
                    handleTest={handleTest}
                    disableSaveBtn={disableSaveBtn}
                    disableTestExeBtn={disableTestExeBtn}
                    handleExecute={handleExecute}
                    handleEditSave={handleEditSave}
                />
            ) : (
                ""
            )}
            {scheduleType === "ONCE" ? (
                <Once
                    userViewSelectedData={userViewSelectedData}
                    handleUserViewChange={handleUserViewChange}
                    handleSave={handleSave}
                    handleTest={handleTest}
                    disableSaveBtn={disableSaveBtn}
                    disableTestExeBtn={disableTestExeBtn}
                    handleExecute={handleExecute}
                    handleEditSave={handleEditSave}
                    userViewEditDetails={userViewEditDetails}
                    scheduleType={scheduleType}
                />
            ) : (
                ""
            )}
            {scheduleType === "DAILY" ? (
                <Daily
                    userViewSelectedData={userViewSelectedData}
                    handleUserViewChange={handleUserViewChange}
                    handleSave={handleSave}
                    handleTest={handleTest}
                    disableSaveBtn={disableSaveBtn}
                    disableTestExeBtn={disableTestExeBtn}
                    handleExecute={handleExecute}
                    handleEditSave={handleEditSave}
                    userViewEditDetails={userViewEditDetails}
                    scheduleType={scheduleType}
                />
            ) : (
                ""
            )}
            {scheduleType === "WEEKLY" ? (
                <Weekly
                    userViewSelectedData={userViewSelectedData}
                    handleUserViewChange={handleUserViewChange}
                    handleSave={handleSave}
                    handleTest={handleTest}
                    disableSaveBtn={disableSaveBtn}
                    disableTestExeBtn={disableTestExeBtn}
                    handleExecute={handleExecute}
                    weeklySelection={weeklySelection}
                    handleWeeklyCheckbox={handleWeeklyCheckbox}
                    userViewEditDetails={userViewEditDetails}
                    handleEditWeeklyCheckbox={handleEditWeeklyCheckbox}
                    editWeeklySelection={editWeeklySelection}
                    handleEditSave={handleEditSave}
                    scheduleType={scheduleType}
                />
            ) : (
                ""
            )}
            {scheduleType === "MONTHLY" ? (
                <Monthly
                    userViewSelectedData={userViewSelectedData}
                    handleUserViewChange={handleUserViewChange}
                    handleSave={handleSave}
                    handleTest={handleTest}
                    disableSaveBtn={disableSaveBtn}
                    disableTestExeBtn={disableTestExeBtn}
                    handleExecute={handleExecute}
                    monthlySelection={monthlySelection}
                    handleMonthlyCheckbox={handleMonthlyCheckbox}
                    handleEditSave={handleEditSave}
                    handleEditMonthlyCheckbox={handleEditMonthlyCheckbox}
                    editMonthlySelection={editMonthlySelection}
                    userViewEditDetails={userViewEditDetails}
                    scheduleType={scheduleType}
                />
            ) : (
                ""
            )}
        </>
    );
};

export default ScheduleType;
