import { useState, useEffect } from "react";
import InputField from "../../../../components/InputField";
import { useDispatch, useSelector } from "react-redux";
import { searchTemplateContent } from "../../../../features/searchTemplate/searchTemplateSlice";
import SearchIcon from "../../../../assets/searchIcon.svg";
import SearchTemplateRow from "./SearchTemplateRow";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";

const SearchTemplate = ({ handleAddTemplate }) => {
    const [serachText, setSearchText] = useState();
    const dispatch = useDispatch();

    const searchTemplatesContent = useSelector((state) => state?.template?.searchedTemplateContent?.content);
    const totalElements = useSelector((state) => state?.template?.searchedTemplateContent?.totalElements);
    const value = useSelector((state) => state.value);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    const handleSearch = () => {
        const data = {
            serachText,
            page: page,
            rowsPerPage,
            application:value,
        };
        dispatch(searchTemplateContent(data));

        if (serachText !== undefined) {
            setPage(0);
        }
    };

    const handleSearchKeyPress = (e) => {
        const data = {
            serachText: e.target.value,
            page: page,
            rowsPerPage,
            application:value,
        };
        if (e.key === "Enter") {
            dispatch(searchTemplateContent(data));
        }
        if (serachText !== undefined) {
            setPage(0);
        }
    };

    useEffect(() => {
        handleSearch();
        handleChangePage("", page);
    }, [page, rowsPerPage,value,]);

    return (
        <>
            <div className="search-template-input">
                <InputField
                    id="outlined-search"
                    label="Search templates from content registery"
                    // type="search"
                    variant="outlined"
                    handleChange={(e) => setSearchText(e.target.value)}
                    handleKeyChange={(e) => handleSearchKeyPress(e)}
                    value={serachText ?? ""}
                />

                <span className="search-icon">
                    <img src={SearchIcon} alt="search icon" onClick={handleSearch} />
                </span>
            </div>

            {searchTemplatesContent &&
                searchTemplatesContent?.length > 0 &&
                searchTemplatesContent
                    // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item) => (
                        <SearchTemplateRow key={item?.template_id} handleAddTemplate={handleAddTemplate} item={item} />
                    ))}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default SearchTemplate;
