import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { searchUserView } from "../../../features/userView/userViewSlice";
import useDebounce from "../../../utils/helpers/useDebounce";

const UserViewDDSelect = ({ userViewSelected, userViewDropdownData, setUserViewSelected }) => {
    const dispatch = useDispatch();

    const userviewDDList = userViewDropdownData?.map((item) => ({
        value: item?.user_view_id,
        label: item?.user_view_name,
    }));

    const searchedContent = useSelector((state) => state?.userView?.searchedContent?.content);

    const handleUserViewSelect = (option) => {
        setUserViewSelected(option);
    };

    // SEARCH USER VIEWS
    const [searchTerm, setSearchTerm] = useState();
    const debouncedTerm = useDebounce(searchTerm, 500);
    const value = useSelector((state) => state.value);
    let userViewArr = [];
    userViewArr = searchTerm !== undefined ? searchedContent : userviewDDList;

    let page, rowsPerPage;
    useEffect(() => {
        const data = {
            serachText: debouncedTerm,
            page: 0,
            rowsPerPage: 3000,
            application:value,
        };
        dispatch(searchUserView(data));
    }, [debouncedTerm, page, rowsPerPage,value]);

    const handleInputChange = (inputValue) => {
        setSearchTerm(inputValue);
    };

    return (
        <div className="user-view-input-wrapper">
            <div>User View</div>
            <Select
                closeMenuOnSelect={true}
                onInputChange={handleInputChange}
                //   components={animatedComponents}
                // defaultValue={[colourOptions[4], colourOptions[5]]}
                options={userviewDDList}
                onChange={handleUserViewSelect}
            />
        </div>
    );
};

export default UserViewDDSelect;
