import StartDate from "../components/StartDate";
import TriggerTime from "../components/TriggerTime";
import UserViewButtons from "../../components/UserViewButtons";

const Once = ({
    scheduleType,
    handleSchduleType,
    handleUserViewChange,
    userViewSelectedData,
    weeklySelection,
    monthlySelection,
    handleWeeklyCheckbox,
    handleMonthlyCheckbox,
    handleSave,
    handleTest,
    handleExecute,
    disableSaveBtn,
    disableTestExeBtn,
    userViewEditDetails,
    handleEditSave,
}) => {
    return (
        <>
            {userViewEditDetails && scheduleType === userViewEditDetails?.schedule?.schedule_type ? (
                <>
                    <StartDate userViewEditDetails={userViewEditDetails} handleUserViewChange={handleUserViewChange} />
                    <TriggerTime
                        userViewEditDetails={userViewEditDetails}
                        handleUserViewChange={handleUserViewChange}
                        isOnce={true}
                    />
                    <UserViewButtons
                        handleSave={handleSave}
                        disableSaveBtn={disableSaveBtn}
                        disableTestExeBtn={disableTestExeBtn}
                        handleTest={handleTest}
                        handleExecute={handleExecute}
                        handleEditSave={handleEditSave}
                    />
                </>
            ) : (
                <>
                    <StartDate handleUserViewChange={handleUserViewChange} />
                    {userViewSelectedData?.startDate && (
                        <TriggerTime handleUserViewChange={handleUserViewChange} isOnce={true} />
                    )}
                    {userViewSelectedData?.time && (
                        <UserViewButtons
                            handleSave={handleSave}
                            disableSaveBtn={disableSaveBtn}
                            disableTestExeBtn={disableTestExeBtn}
                            handleTest={handleTest}
                            handleExecute={handleExecute}
                            handleEditSave={handleEditSave}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Once;
