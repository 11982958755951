import { createSlice } from "@reduxjs/toolkit";
// const initialState ={
//     STUDENT
// }
const refreshedValue = sessionStorage.getItem("platformSelected");
const valueSlice = createSlice({
    name: "value",
    initialState: refreshedValue !== null || undefined ? refreshedValue : "STUDENT",
    reducers: {
        setValue: (state, action) => {
            return action.payload;
        },
    },
});
export const { setValue } = valueSlice.actions;
export default valueSlice.reducer;
