import React from "react";
import "./index.css";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import CustomButton from "../../../../components/Button";
import { useSelector } from "react-redux";
import appConfig from "../../../../config/app.config";
import { EMBIBE_COMMON_EMAIL_LIST } from "../../../../config/config.constants";

const closeBtnStyle = {
    cursor: "pointer",
};

const EmailList = ({ deleteEmail, item }) => {
    return (
        <div className="email-select-list-wrapper">
            <p className="email-select-email">{item}</p>
            <span onClick={() => deleteEmail(item)} style={closeBtnStyle}>
                <CloseIcon />
            </span>
        </div>
    );
};

const EmailSelect = ({
    email,
    emailsList,
    deleteEmail,
    handleEmailKeyPress,
    handleEmailInput,
    createNewEmailItem,
    handleCloseEmailSelect,
}) => {
    const value = useSelector((state) => state.value);
    const getEmailList = (value) => {
        const userOrganization = localStorage.getItem("userOrganization");
        return [...appConfig[value].EMAIL_LIST, ...(userOrganization === "EMBIBE" ? EMBIBE_COMMON_EMAIL_LIST : [])];
    };

    const emailList = getEmailList(value);
    const emalOptions = emailList.map((item) => ({ value: item, label: item }));
    const selectedEmails = email.map((item) => ({ value: item, label: item }));

    return (
        <>
            <div className="email-select-wrapper">
                <Select
                    style={{ width: "100%" }}
                    closeMenuOnSelect={false}
                    isMulti
                    options={emalOptions}
                    onChange={handleEmailInput}
                    defaultValue={selectedEmails ?? ""}
                />

                <div className="email-select-apply-btn">
                    <CustomButton buttonText="Apply" variant="outlined" handleClick={handleCloseEmailSelect} />
                </div>
            </div>

            {emailsList?.map((item) => {
                return <EmailList key={item} item={item} deleteEmail={deleteEmail} />;
            })}
        </>
    );
};

export default EmailSelect;
