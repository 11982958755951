import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const UserViewDetails = () => {
    const userViewDetails = useSelector((state) => state?.userView?.userViewName);
    const totalUsersCount = useSelector((state) => state?.userView)?.totalUsersCount?.count;

    return (
        <div>
            <ul style={{ lineHeight: "3" }}>
                <li>
                    <b>User View Name :</b> {userViewDetails?.user_view_name}
                </li>
                <li>
                    <b>User View Desc :</b> {userViewDetails?.description}
                </li>
                <li>
                    <b>User View ID :</b> {userViewDetails?.user_view_id}
                </li>
                <li>
                    <b>User View Query :</b>{" "}
                    {userViewDetails?.queries.map(({ collection_name, query }, index) => (
                        <div key={collection_name}>
                            <b>{`Querie${index + 1}`}</b>
                            <br />
                            <b>Collection Name:</b> {collection_name}
                            <br />
                            <b>Query: </b>
                            {query}
                        </div>
                    ))}
                </li>
                <li>
                    <b>Total Unique Users Count :</b> {totalUsersCount}
                </li>
            </ul>
        </div>
    );
};

export default UserViewDetails;
