import React from "react";
import { useState, useEffect } from "react";
// import CustomButton from "../../components/Button";
import AddIcon from "@mui/icons-material/Add";
import InputField from "../../../components/InputField";
import CloseIcon from "@mui/icons-material/Close";

const closeBtnStyle = {
    marginTop: "55px",
    marginLeft: "44px",
    cursor: "pointer",
};

const DefaultVariablesTemplate = ({
    customData,
    setCustomData,
    customDataJSON,
    setCustomDataJSON,
    setCustomVarKey,
    templateData,
    limit,
    label = "Add Default Variables",
    Namekey = "key",
    Valuekey = "value",
}) => {
    const inputArr = [];
    const [customDataKey, setCustomDataKey] = useState(inputArr);
    const [customDataVal, setCustomDataVal] = useState(inputArr);

    useEffect(() => {
        if (customDataKey?.length >= 0) {
            var newObj = {};
            for (var i = 0; i < customDataKey?.length; i++) {
                newObj[customDataKey[i]] = customDataVal[i];
            }
            setCustomDataJSON(newObj);
        } else {
            setCustomDataJSON({});
        }
    }, [customDataKey, customDataVal]);

    const addRow = (item) => {
        if (limit && customData?.length >= limit) return;
        setCustomData((s) => {
            const lastId = s[s.length - 1]?.id;
            return [...s, ""];
        });
    };

    const removeRow = (index) => {
        const list = [...customData];
        list.splice(index, 1);
        setCustomData(list);

        let result = Object.keys(customDataJSON).map((key) => [key, customDataJSON[key]]);
        const dataList = [...result];
        dataList.splice(index, 1);

        setCustomDataJSON(dataList);
    };

    const handleCustomChange = (e, el) => {
        if (el === "key") {
            let newArr = [];
            const index = e.target.id;
            setCustomDataKey((item) => {
                newArr = item?.slice();
                newArr[index] = e.target.value;
                setCustomVarKey(newArr);
                return newArr;
            });
        } else if (el === "val") {
            let newArr = [];
            const index = e.target.id;
            setCustomDataVal((item) => {
                newArr = item?.slice();
                newArr[index] = e.target.value;
                return newArr;
            });
        }
    };

    return (
        <div>
            {/* CUSTOM DATA */}
            <>
                <div className="custom-deep-custom-header">
                    <p>{label}</p>
                    <span className="custom-data-add-btn" onClick={addRow}>
                        <AddIcon />
                    </span>
                </div>

                {customData?.map((item, i) => {
                    return (
                        <div className="custom-data-input" style={{ marginBottom: "15px" }}>
                            <InputField
                                inputLabel={Namekey}
                                isRequired={true}
                                id={i}
                                variant="outlined"
                                handleChange={(e) => handleCustomChange(e, "key")}
                                // value={customDataKey ?? ""}
                            />
                            <InputField
                                inputLabel={Valuekey}
                                isRequired={true}
                                id={i}
                                variant="outlined"
                                handleChange={(e) => handleCustomChange(e, "val")}
                                // value={customDataVal ?? ""}
                            />

                            <span onClick={() => removeRow(i)} style={closeBtnStyle}>
                                <CloseIcon />
                            </span>
                        </div>
                    );
                })}
            </>
        </div>
    );
};

export default DefaultVariablesTemplate;
