import React, { useState, useEffect } from "react";
import InputField from "../../components/InputField";
import CustomDropdown from "../../components/CustomDropdown";
import { statusOptions, templateTypeOptions, localeOptions, formatOptions } from "./TemplateConstants";
import FactTypeSelect from "../../components/FactTypeSelect";
import FactTypeInput from "../../components/FactTypeInput";
import CustomButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTemplate, linkTemplate, reset, editTemplate } from "../../features/searchTemplate/searchTemplateSlice";
import TemplateTextArea from "../../components/TemplateTextArea";
import { toast } from "react-toastify";
import { getTemplates } from "../../features/templatesView/templatesViewSlice";
import { getHypothesis } from "../../features/searchTemplate/searchTemplateSlice";
import FormSubmitBtn from "../../components/SubmitForm";
import CustomDeepLink from "../../components/CustomDeepLink";
import TemplatePreview from "./TemplatePreview";
import DefaultVariablesTemplate from "./DefaultVariables";
import EditDefaultVars from "./EditDefaultVariables";

function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}

const SMSTemplate = ({ handleClose, setCreateTempChannel, isTemplateView, templateData }) => {
    const [templateName, setTemplateName] = useState(templateData?.template_name ?? "");
    const [selectStatus, setSelectStatus] = useState(templateData?.status ? templateData?.status : "");
    const [templateType, setTemplateType] = useState(templateData?.template_type ? templateData?.template_type : "");
    const [dltTemplateID, setDltTemplateID] = useState(
        templateData?.external_vendor_ids?.dlt_template_id ? templateData?.external_vendor_ids?.dlt_template_id : ""
    );
    const [locale, setLocale] = useState(templateData?.locale ? templateData?.locale : "");
    const [mentions, setMentions] = useState(templateData?.template_variables ? templateData?.template_variables : []);
    const [smsBody, setSmsBody] = useState(
        templateData?.message_details?.body ? templateData?.message_details?.body : ""
    );
    const [isFactType, setIsFactType] = useState(false);

    // DEFAULT VARIABLES
    const inputArr = [];
    const [customData, setCustomData] = useState(inputArr);
    const [customDataJSON, setCustomDataJSON] = useState(templateData?.default_template_variables_values ?? {});
    const [customVarKey, setCustomVarKey] = useState(inputArr);

    // CUSTOM DEEPLINK
    const [screenName, setScreenName] = useState();
    const [tags, setTags] = useState();
    const [desktopUrl, setDesktopUrl] = useState();
    const [deep_link, setdeep_link] = useState();
    const [customvalue, setCustomValue] = useState("Automatic");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hypothesisID = localStorage.getItem("hypothesisID");
    const editHypothesisID = localStorage.getItem("editHypothesisID");

    const createdTemplateID = useSelector((state) => state?.template?.createdTemplateData?.template_id);
    const isSuccess = useSelector((state) => state?.template?.isCreateTemplateSuccess);
    const value = useSelector((state) => state.value);
    const handleSMSTempSubmit = (e) => {
        // let state = JSON.parse(localStorage.getItem("createdTempData"));
        // let smsBody = localStorage.getItem("factTypeValue");
        // let variablesSelected = JSON.parse(localStorage.getItem("selectedvars"));

        e.preventDefault();

        // CREATING THE TEMPLATE
        let createData = {
            channel: "SMS",
            status: selectStatus,
            template_name: templateName,
            template_type: templateType,
            message_details: {
                body: smsBody,
            },
            external_vendor_ids: { dlt_template_id: dltTemplateID },
            template_variables: [...mentions, ...customVarKey],
            default_template_variables_values: customDataJSON,
            locale: locale,
            application: value,
        };

        if (!isFactType && !isTemplateView) {
            toast.error("Please select a fact type ");
        } else if (smsBody === "") {
            toast.error("Please fill body field");
        } else {
            dispatch(createTemplate(createData));

            if (isTemplateView) {
                navigate("/template");
                dispatch(getTemplates());

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
    };

    // LINKING THE TEMPLATE
    const factType = localStorage.getItem("factTypeSelected");
    const [isCustomFT, setIsCustomFT] = useState(false);

    // useEffect(() => {
    //     if (factType === "CUSTOM") {
    //         setIsCustomFT(true);
    //     } else {
    //         setIsCustomFT(false);
    //     }
    // }, [factType]);
    const linkCreatedTemp = () => {
        const deepLinkData = JSON.parse(localStorage.getItem("deepLinkData"));

        let data = {
            priority: 1,
            weightage: 100,
            template_id: createdTemplateID,
            fact_type: factType,
            custom_deep_link_details: {
                screen_name: deepLinkData?.screenName,
                custom_data: deepLinkData?.customDataJSON,
                tags: deepLinkData?.tags,
                desktop_url: deepLinkData?.desktopUrl,
                deep_link: deepLinkData?.deep_link,
            },
            application: value,
        };

        if (editHypothesisID) {
            const linkTemplateData = {
                hypothesisID: editHypothesisID || hypothesisID,
                data,
            };
            dispatch(linkTemplate(linkTemplateData));
            dispatch(reset());
            handleClose();
            setCreateTempChannel("");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            const linkTemplateData = {
                hypothesisID,
                data,
            };
            dispatch(linkTemplate(linkTemplateData));
            dispatch(reset());
            handleClose();
            setCreateTempChannel("");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    };
    useEffect(() => {
        dispatch(getTemplates());
    }, [value]);

    useEffect(() => {
        if (isSuccess && createdTemplateID !== undefined && createdTemplateID !== "" && !isTemplateView) {
            linkCreatedTemp();
            dispatch(getHypothesis(hypothesisID || editHypothesisID));
        }
    }, [createdTemplateID]);

    // EDIT SMS TEMPLATE PUT CALL
    let varArr = removeDuplicates(customVarKey);

    const handleEditSMS = () => {
        // let variablesSelected = JSON.parse(localStorage.getItem("selectedvars"));
        // let SMSBody = localStorage.getItem("factTypeValue");

        let data = {
            channel: "SMS",
            status: selectStatus,
            template_name: templateName,
            template_type: templateType,
            locale: locale,
            message_details: {
                body: smsBody,
                subject: "SMS",
                format: "TEXT",
            },
            external_vendor_ids: { dlt_template_id: dltTemplateID },
            template_variables: [...mentions, ...varArr],
            default_template_variables_values: customDataJSON,
        };

        let editData = {
            templateId: templateData?.template_id,
            data,
        };

        dispatch(editTemplate(editData));
        navigate("/template");

        setTimeout(() => {
            window.location.reload();
        }, 1000);

        // if (!isFactType) {
        //     toast.error("Please select a fact type ");
        // } else {

        // }
    };

    // MODAL OPEN/CLOSE FUNCTIONS
    const [open, setOpen] = useState(false);
    const handlePreviewClose = () => setOpen(false);

    return (
        <>
            <form onSubmit={templateData?.template_name ? handleEditSMS : handleSMSTempSubmit}>
                <InputField
                    id="outlined-helperText"
                    label="Template Name"
                    // inputLabel="Intent"
                    variant="outlined"
                    handleChange={(e) => {
                        setTemplateName(e.target.value);
                    }}
                    value={templateName}
                    isRequired={true}
                />

                <CustomDropdown
                    options={statusOptions}
                    label="Select Status"
                    value={selectStatus}
                    handleChange={(e) => {
                        setSelectStatus(e.target.value);
                    }}
                    isRequired={true}
                />
                <CustomDropdown
                    options={templateTypeOptions}
                    label="Select Template Type"
                    value={templateType}
                    handleChange={(e) => {
                        setTemplateType(e.target.value);
                    }}
                    isRequired={true}
                />
                <InputField
                    id="outlined-helperText"
                    label="DLT Template ID"
                    // inputLabel="Intent"
                    variant="outlined"
                    handleChange={(e) => {
                        setDltTemplateID(e.target.value);
                    }}
                    value={dltTemplateID}
                    isRequired={true}
                />
                <CustomDropdown
                    options={localeOptions}
                    label="Select Locale"
                    value={locale}
                    handleChange={(e) => {
                        setLocale(e.target.value);
                    }}
                    isRequired={true}
                />
                {!isTemplateView && (
                    <FactTypeSelect
                        templateData={templateData}
                        setIsFactType={setIsFactType}
                        setIsCustomFT={setIsCustomFT}
                    />
                )}
                {isCustomFT && (
                    <CustomDeepLink
                        customvalue={customvalue}
                        setCustomValue={setCustomValue}
                        deep_link={deep_link}
                        setdeep_link={setdeep_link}
                        screenName={screenName}
                        setScreenName={setScreenName}
                        tags={tags}
                        setTags={setTags}
                        desktopUrl={desktopUrl}
                        setDesktopUrl={setDesktopUrl}
                    />
                )}
                <TemplateTextArea setMentions={setMentions} setBody={setSmsBody} body={smsBody} />

                {templateData?.channel === "SMS" ? (
                    <EditDefaultVars
                        templateData={templateData}
                        setCustomDataJSON={setCustomDataJSON}
                        setCustomVarKey={setCustomVarKey}
                    />
                ) : (
                    <DefaultVariablesTemplate
                        customData={customData}
                        setCustomData={setCustomData}
                        customDataJSON={customDataJSON}
                        setCustomDataJSON={setCustomDataJSON}
                        // customVarKey={customVarKey}
                        setCustomVarKey={setCustomVarKey}
                        templateData={templateData}
                    />
                )}

                {/* <FactTypeInput label="Body" /> */}
                {/* {templateData?.template_name ? (
                <CustomButton buttonText="Submit Edit" variant="outlined" handleClick={handleEditSMS} />
            ) : (
                <CustomButton buttonText="Submit" variant="outlined" handleClick={handleSMSTempSubmit} />
            )} */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "36px",
                    }}
                >
                    {templateData?.template_name ? <></> : <FormSubmitBtn type="submit" />}
                    <CustomButton
                        buttonText="Preview"
                        variant="contained"
                        handleClick={() => setOpen(true)}
                        // disabled={!intent || !action ? true : false}
                    />
                </div>
            </form>

            <TemplatePreview
                open={open}
                setOpen={setOpen}
                handlePreviewClose={handlePreviewClose}
                templateType="SMS"
                smsBody={smsBody}
            />
        </>
    );
};

export default SMSTemplate;
