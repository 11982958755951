import { useEffect, useState } from "react";
import CustomCheckbox from "../../../../components/CustomCheckbox";

const WeeklySelection = ({ userViewEditDetails, handleCheckbox, editWeeklySelection, handleEditWeeklyCheckbox }) => {
    let daysList = [
        { id: "1", day: "MON", isChecked: false },
        { id: "2", day: "TUE", isChecked: false },
        { id: "3", day: "WED", isChecked: false },
        { id: "4", day: "THU", isChecked: false },
        { id: "5", day: "FRI", isChecked: false },
        { id: "6", day: "SAT", isChecked: false },
        { id: "7", day: "SUN", isChecked: false },
    ];

    let selectedDays = userViewEditDetails?.schedule?.selections;

    // let index = daysList.map((item) => selectedDays.findIndex((el) => el === item.day));
    // daysList.splice(index);

    let tempDays = [];

    daysList.filter((item) => {
        return editWeeklySelection?.some((el) => {
            if (item?.day === el) {
                return tempDays.push({ ...item, isChecked: true });
            }
        });
    });

    const mergedArray = [...daysList, ...tempDays];

    function getUniqueListBy(arr, key) {
        return [...new Map(arr?.map((item) => [item[key], item])).values()];
    }
    const updatedDays = getUniqueListBy(mergedArray, "id");

    return (
        <div className="user-view-input-wrapper">
            <div>Select Days</div>
            <div className="user-view-weekly-checkbox">
                {selectedDays
                    ? updatedDays.map((item) => (
                          <CustomCheckbox
                              key={item.id}
                              label={item.day}
                              value={item.day ?? ""}
                              isChecked={item?.isChecked ? true : false}
                              name="weeklySelection"
                              handleChange={(e) => handleEditWeeklyCheckbox(e)}
                          />
                      ))
                    : daysList.map((item) => (
                          <CustomCheckbox
                              key={item.id}
                              label={item.day}
                              value={item.day}
                              name="weeklySelection"
                              handleChange={(e) => handleCheckbox(e)}
                          />
                      ))}
            </div>
        </div>
    );
};

export default WeeklySelection;
