import axios from "axios";
import { BASE_URL } from "../../utils/constants/apiBaseURL.constants";
import { toastError } from "../../utils/helpers/toastError";

// GET ALL TEMPLATE DATA
const getTemplatesAPI = async ({ page, rowsPerPage, application}) => {
    try {
        const response = await axios(`${BASE_URL}/notification-ms-v2/template?pageNo=${page}&pageSize=${rowsPerPage}&application=${application}`);
        if (response?.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// GET SINGLE TEMPLATE DATA
const getSingleTemplatesAPI = async (templateId) => {
    try {
        const response = await axios(`${BASE_URL}/notification-ms-v2/template/${templateId}`);
        return response.data.payload;
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

// SEARCH TEMPLATE
const searchTemplateAPI = async ({ serachText = "", page, rowsPerPage,application }) => {
    try {
        const response = await axios(
            `${BASE_URL}/user-cohort-ms/template/search?name=${serachText}&pageNo=${page}&pageSize=${rowsPerPage}&application=${application}`
        );
        if (response?.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

const templatesViewService = {
    getTemplatesAPI,
    getSingleTemplatesAPI,
    searchTemplateAPI,
};
export default templatesViewService;
