import Button from "@mui/material/Button";

const CustomButton = ({ buttonText, variant, style, handleClick, disabled, size, color }) => {
    return (
        <>
            <Button variant={variant} onClick={handleClick} style={style} disabled={disabled} size={size} color={color}>
                {buttonText}
            </Button>
        </>
    );
};

export default CustomButton;
