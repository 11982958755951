import React, { useState, useEffect } from "react";
import CustomButton from "../../components/Button";
import InputField from "../../components/InputField";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { createCampaignID, reset, updateIntentAction } from "../../features/createCampaign/createCampaignSlice";
import { resetSearchData, resetLinkedTempData } from "../../features/searchTemplate/searchTemplateSlice";
import { useNavigate } from "react-router-dom";
import BackArrow from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import { getHypothesis } from "../../features/searchTemplate/searchTemplateSlice";
import SuccessCriteriaMultiSelect from "./SuccessCriteriaMultiSelect";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import SingleSelect from "../../components/SingleSelect";
import { toast } from "react-toastify";
import appConfig from "../../config/app.config";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
};

const CreateCampaign = () => {
    // CREATE HYPOTHESIS
    const [intent, setIntent] = useState(localStorage.getItem("intent") ?? "");
    const [action, setAction] = useState(localStorage.getItem("action") ?? "");
    const [campaignName, setCampaignName] = useState(localStorage.getItem("campaignName") ?? "");
    const value = useSelector((state) => state.value);
    const [pnType, setPNType] = useState();
    // SUCCESS CRITERIA
    const editSuccessCriteria = useSelector((state) => state?.template?.hypohesisData?.success_criteria);
    useEffect(() => {
        if (editSuccessCriteria?.length > 0) {
            localStorage.setItem("SCList", JSON.stringify(editSuccessCriteria));
        }
    }, [editSuccessCriteria]);
    const [successCriteriaList, setSuccessCriteriaList] = useState(
        JSON.parse(localStorage.getItem("SCList")) ?? editSuccessCriteria ?? []
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hypothesisData = useSelector((state) => state?.createCampaign?.createdHypothesisData);

    const handleClick = () => {
        if (pnType) {
            const data = {
                intent,
                action,
                success_criteria: successCriteriaList || JSON.parse(localStorage.getItem("SCList")),
                campaign_name: campaignName,
                override_limit: overrideLimit,
                dnd: selectDND,
                push_notification_type: pnType,
                application: value,
            };
            dispatch(createCampaignID(data));
            navigate("/linked-template");
            localStorage.setItem("callType", "PUT");
        } else {
            toast.error("Select the PN Type");
        }
    };

    useEffect(() => {
        dispatch(reset());
        dispatch(resetSearchData());
        dispatch(resetLinkedTempData());
    }, []);

    // EDIT HYPOTHESIS
    let hypothesisId = localStorage.getItem("editHypothesisID");
    const hypohesisData = useSelector((state) => state?.template?.hypohesisData);

    const [editIntent, setEditIntent] = useState(hypothesisData?.intent ? hypothesisData?.intent : intent);
    const [editAction, setEditAction] = useState(hypothesisData?.action ? hypothesisData?.action : action);
    const [editCampaignName, setEditCampaignName] = useState(
        hypothesisData?.campaign_name ? hypothesisData?.campaign_name : campaignName
    );

    useEffect(() => {
        if (hypothesisId) {
            dispatch(getHypothesis(hypothesisId));
        }
    }, []);

    // HANDLE PUSH NOTIFICATION
    const handlePNType = (options) => {
        switch (options?.value) {
            case "APP":
                return setPNType("APP");
            case "WEB":
                return setPNType("WEB");
            case "IOS":
                return setPNType("IOS");
            case "ANDROID_AND_WEB":
                return setPNType("ANDROID_AND_WEB");
            case "BOTH":
                return setPNType("BOTH");
            default:
                break;
        }
    };

    // OVERRIDE LIMIT
    const [overrideLimit, setOverrideLimit] = useState(
        hypohesisData?.override_limit ?? JSON.parse(localStorage.getItem("overrideLimit")) ?? false
    );
    const [selectDND, setSelectDND] = useState(hypohesisData?.dnd ?? JSON.parse(localStorage.getItem("dnd")) ?? false);

    useEffect(() => {
        if (hypohesisData) {
            setEditIntent(hypohesisData?.intent);
            setEditAction(hypohesisData?.action);
            setEditCampaignName(hypohesisData?.campaign_name);
            setOverrideLimit(hypohesisData?.override_limit);
            setPNType(hypohesisData?.push_notification_type);
            setSelectDND(hypohesisData?.dnd);
        }
    }, [hypohesisData]);

    const handleEditClick = () => {
        if (pnType) {
            const data = {
                intent: editIntent,
                action: editAction,
                success_criteria: JSON.parse(localStorage.getItem("SCList")) || successCriteriaList,
                campaign_name: editCampaignName,
                override_limit: overrideLimit,
                push_notification_type: pnType,
                application: value,
                dnd: selectDND,
            };
            let intentActionData = {
                hypothesisId,
                data,
            };
            dispatch(updateIntentAction(intentActionData));
            navigate("/linked-template");

            setEditIntent("");
            setEditAction("");
            setCampaignName("");
        } else {
            toast.error("Select the PN Type");
        }
    };

    // CALL API WHEN SUCCESS CRITERIA CHANGES
    const handleProceedPUT = () => {
        if (pnType) {
            const data = {
                intent,
                action,
                success_criteria: successCriteriaList || JSON.parse(localStorage.getItem("SCList")),
                campaign_name: campaignName,
                override_limit: overrideLimit,
                push_notification_type: pnType,
                application: value,
                dnd: selectDND,
            };
            let intentActionData = {
                hypothesisId: hypothesisData?.hypothesis_id,
                data,
            };
            dispatch(updateIntentAction(intentActionData));
            navigate("/linked-template");
        } else {
            toast.error("Select the PN Type");
        }
    };

    // PROCEED BUTTON DISABLE FUNCTION
    const callType = localStorage.getItem("callType");
    const handleProceedBtn = () => {
        if (hypothesisId) {
            return (
                <CustomButton
                    buttonText="Proceed"
                    variant="outlined"
                    handleClick={handleEditClick}
                    disabled={!editIntent || !editAction ? true : false}
                />
            );
        } else if (callType === "PUT") {
            return <CustomButton buttonText="Proceed" variant="outlined" handleClick={handleProceedPUT} />;
        } else {
            return (
                <CustomButton
                    buttonText="Proceed"
                    variant="outlined"
                    handleClick={handleClick}
                    disabled={!intent || !action ? true : false}
                />
            );
        }
    };

    // OVERRIDE LIMIT
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const handleOverrideChange = (e) => {
        setOverrideLimit(!overrideLimit);
        setOpen(true);
        localStorage.setItem("overrideLimit", JSON.stringify(!overrideLimit));
    };
    const handleSelectDND = (e) => {
        setSelectDND(!selectDND);
        localStorage.setItem("dnd", JSON.stringify(!selectDND));
    };

    const handleOverrideNoChange = () => {
        setOverrideLimit(false);
        let ovLimt = false;
        localStorage.setItem("overrideLimit", JSON.stringify(ovLimt));
        setOpen(false);
    };
    function showSuccessCriteriaSelect() {
        return (
            <SuccessCriteriaMultiSelect
                setSuccessCriteriaList={setSuccessCriteriaList}
                successCriteriaList={successCriteriaList}
            />
        );
    }

    return (
        <>
            <Link to="/campiagn">
                <BackArrow className="create-campaign-back-arrow" />
            </Link>

            {/* <Stepper /> */}

            <div className="create-campaign-wrapper">
                {hypothesisId ? (
                    <InputField
                        id="outlined-helperText"
                        // label="Intent"
                        inputLabel="Campaign Name"
                        variant="outlined"
                        handleChange={(e) => {
                            setEditCampaignName(e.target.value);
                        }}
                        value={editCampaignName ? editCampaignName : ""}
                    />
                ) : (
                    <InputField
                        id="outlined-helperText"
                        // label="Intent"
                        label="Campaign Name"
                        variant="outlined"
                        handleChange={(e) => {
                            setCampaignName(e.target.value);
                            localStorage.setItem("campaignName", e.target.value);
                        }}
                        value={campaignName ?? hypothesisData?.campaign_name}
                    />
                )}
                {hypothesisId ? (
                    <InputField
                        id="outlined-helperText"
                        // label="Intent"
                        inputLabel="Intent"
                        variant="outlined"
                        handleChange={(e) => {
                            setEditIntent(e.target.value);
                        }}
                        value={editIntent ? editIntent : ""}
                    />
                ) : (
                    <InputField
                        id="outlined-helperText"
                        label="Intent"
                        // inputLabel="Intent"
                        variant="outlined"
                        handleChange={(e) => {
                            setIntent(e.target.value);
                            localStorage.setItem("intent", e.target.value);
                        }}
                        value={intent ?? hypothesisData?.intent}
                    />
                )}
                {hypothesisId ? (
                    <InputField
                        id="outlined-helperText"
                        inputLabel="Action"
                        variant="outlined"
                        handleChange={(e) => setEditAction(e.target.value)}
                        value={editAction ?? ""}
                    />
                ) : (
                    <InputField
                        id="outlined-helperText"
                        label="Action"
                        variant="outlined"
                        handleChange={(e) => {
                            setAction(e.target.value);
                            localStorage.setItem("action", e.target.value);
                        }}
                        value={action ?? hypothesisData?.action}
                    />
                )}
                <SingleSelect
                    options={appConfig[value].pnNotificationTypes}
                    handleSingleSelect={handlePNType}
                    defaultValue={hypohesisData?.push_notification_type}
                />
                {/* OVERRIDE LIMIT */}
                <FormGroup style={{ marginTop: "20px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={overrideLimit}
                                onChange={(e) => handleOverrideChange(e)}
                                checked={overrideLimit === true ? true : false}
                            />
                        }
                        label="Override limit"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={selectDND}
                                onChange={(e) => handleSelectDND(e)}
                                checked={selectDND === true ? true : false}
                            />
                        }
                        label="DND Select"
                    />
                </FormGroup>

                {/* SUCCESS CRITERIA */}
                {showSuccessCriteriaSelect()}

                <div className="create-campaign-button">{handleProceedBtn()}</div>
            </div>

            {/* OVERRIDE LIMIT MODAL */}
            {overrideLimit ? (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div>Are you sure you want to override limit?</div>
                        <div className="test-user-delete-btns">
                            <CustomButton
                                buttonText="No"
                                variant="outlined"
                                color="error"
                                handleClick={handleOverrideNoChange}
                            />
                            <CustomButton buttonText="Yes" variant="outlined" handleClick={handleClose} />
                        </div>
                    </Box>
                </Modal>
            ) : (
                <></>
            )}
        </>
    );
};

export default CreateCampaign;
