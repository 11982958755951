import { useState, useEffect } from "react";
import InputField from "../../components/InputField";
import "./index.css";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const CustomDeepLink = ({
    customvalue,
    setCustomValue,
    screenName,
    setScreenName,
    tags,
    setTags,
    desktopUrl,
    deep_link,
    setdeep_link,
    setDesktopUrl,
}) => {
    const inputArr = [""];
    const [customData, setCustomData] = useState(inputArr);
    const [customDataJSON, setCustomDataJSON] = useState();
    const [customDataKey, setCustomDataKey] = useState(inputArr);
    const [customDataVal, setCustomDataVal] = useState(inputArr);

    useEffect(() => {
        if (customDataKey?.length >= 1) {
            var newObj = {};
            for (var i = 0; i < customDataKey?.length; i++) {
                newObj[customDataKey[i]] = customDataVal[i];
            }
            setCustomDataJSON(newObj);
        } else {
            setCustomDataJSON({});
        }
    }, [customDataKey, customDataVal]);

    const addRow = (item) => {
        setCustomData((s) => {
            // const lastId = s[s.length - 1]?.id;
            return [...s, ""];
        });
    };
    const value = useSelector((state) => state.value);
    const removeRow = (index) => {
        const list = [...customData];
        list.splice(index, 1);
        setCustomData(list);

        let result = Object.keys(customDataJSON).map((key) => [key, customDataJSON[key]]);
        const dataList = [...result];
        dataList.splice(index, 1);

        setCustomDataJSON(dataList);
    };

    const handleCustomChange = (e, el) => {
        if (el === "key") {
            let newArr = [];
            const index = e.target.id;
            setCustomDataKey((item) => {
                newArr = item?.slice();
                newArr[index] = e.target.value;
                return newArr;
            });
        } else if (el === "val") {
            let newArr = [];
            const index = e.target.id;
            setCustomDataVal((item) => {
                newArr = item?.slice();
                newArr[index] = e.target.value;
                return newArr;
            });
        }
    };

    useEffect(() => {
        const deepLinkData = {
            screenName,
            tags,
            desktopUrl,
            customDataJSON,
            deep_link,
        };

        localStorage.setItem("deepLinkData", JSON.stringify(deepLinkData));
    }, [customDataJSON, desktopUrl, screenName, tags, deep_link]);

    return (
        <>
            <div className="custom-deep-link-header">Please fill the below details for CUSTOM Fact Type</div>
            <FormControl fullWidth sx={{ paddingBottom: "1rem" }}>
                <Select id="create-template" value={customvalue} onChange={(e) => setCustomValue(e.target.value)}>
                    <MenuItem value="Automatic">Automatic</MenuItem>
                    <MenuItem value="Manual">Manual</MenuItem>
                </Select>
            </FormControl>
            {customvalue === "Automatic" ? (
                <div className="custom-deep-link-wrapper">
                    <InputField
                        id="outlined-search"
                        label="Screen Name"
                        variant="outlined"
                        handleChange={(e) => setScreenName(e.target.value)}
                        value={screenName ?? ""}
                        isRequired={true}
                    />
                    <InputField
                        id="outlined-search"
                        label="Tags"
                        variant="outlined"
                        handleChange={(e) => setTags(e.target.value)}
                        value={tags ?? ""}
                        isRequired={true}
                    />
                    {value === "STUDENT" ? (
                        <InputField
                            id="outlined-search"
                            label="Desktop URL"
                            variant="outlined"
                            handleChange={(e) => setDesktopUrl(e.target.value)}
                            value={desktopUrl ?? ""}
                            isRequired={true}
                        />
                    ) : (
                        <></>
                    )}
                    <>
                        <div className="custom-deep-custom-header">
                            <p>Add Custom Data</p>
                            <span className="custom-data-add-btn" onClick={addRow}>
                                <AddIcon />
                            </span>
                        </div>

                        {customData?.map((item, i) => {
                            return (
                                <div className="custom-data-input">
                                    <InputField
                                        label="Key"
                                        id={i}
                                        variant="outlined"
                                        handleChange={(e) => handleCustomChange(e, "key")}
                                        // value={item}
                                    />
                                    <InputField
                                        label="Value"
                                        id={i}
                                        variant="outlined"
                                        handleChange={(e) => handleCustomChange(e, "val")}
                                        // value={item}
                                    />
                                    {customData?.length !== 1 && (
                                        <span onClick={() => removeRow(i)} className="custom-data-close-btn">
                                            <CloseIcon />
                                        </span>
                                    )}
                                </div>
                            );
                        })}
                    </>
                </div>
            ) : (
                <div className="custom-deep-link-wrapper">
                    <InputField
                        id="outlined-search"
                        label="DeepLink"
                        variant="outlined"
                        handleChange={(e) => setdeep_link(e.target.value)}
                        value={deep_link ?? ""}
                        isRequired={true}
                    />
                </div>
            )}
        </>
    );
};

export default CustomDeepLink;
