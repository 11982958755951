import React from "react";
import Select from "react-select";

const MultiSelect = ({ label, options, handleMultiSelect, editOptions, maxLimit = 1, selectedOptions }) => {
    const optionsList = options?.map((item) => ({ value: item.value, label: item.label }));
    const editOptionList = editOptions?.map((item) => ({ value: item, label: item }));
    return (
        <div style={{ margin: "20px 0" }}>
            <p>{label} *</p>
            <Select
                closeMenuOnSelect={false}
                isMulti
                options={optionsList}
                onChange={handleMultiSelect}
                defaultValue={editOptionList || "Select"}
                isOptionDisabled={(option) => selectedOptions.length >= maxLimit}
            />
        </div>
    );
};

export default MultiSelect;
