export const dateHelper = (timeSec) => {
    if (isNaN(timeSec)) {
        return "--";
    }
    timeSec = Number(timeSec);
    var date = new Date(timeSec);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();

    return day + "-" + month + "-" + year;
    // var today = new Date();
    // var todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    // var dueDateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    // const dayDiff = (dueDateOnly - todayDateOnly) / (60 * 60 * 24 * 1000);
    // let dueText;
    // let duePath;

    // if (progress === 100) {
    //     dueText = "Completed";
    //     duePath = "assignment.completedKey";
    // } else if (dayDiff < 0) {
    //     dueText = "Delayed";
    //     duePath = "assignment.delayedKey";
    // } else if (dayDiff === 0) {
    //     dueText = "Due today";
    //     duePath = "assignment.dueTodayKey";
    // } else if (dayDiff === 1) {
    //     dueText = "Due tomorrow";
    //     duePath = "assignment.dueTomorrowKey";
    // } else {
    //     dueText = day + "-" + month + "-" + year;
    // }
    // return { dueText, duePath };
};

export const dateHelperFormat = (timeSec) => {
    if (isNaN(timeSec)) {
        return "--";
    }
    timeSec = Number(timeSec);
    var date = new Date(timeSec);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();

    if (month < 10) {
        if (day < 10) {
            return year + "-" + "0" + month + "-" + "0" + day;
        } else {
            return year + "-" + "0" + month + "-" + day;
        }
    } else {
        if (day < 10) {
            return year + "-" + month + "-" + "0" + day;
        } else {
            return year + "-" + month + "-" + day;
        }
    }
};
