import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { setValue } from "../../features/apptype/appTypeSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import createEncryptor from "../../pages/Login/encryptions";
function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div>
            <p>An error occurred: {error.message}</p>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
}
const SelectApp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const encryptedApplicationAccess = localStorage.getItem("applicationAccessProvided");
    const applicationAccessString = createEncryptor(`${process.env.REACT_APP_SALTS}`).decrypt(
        encryptedApplicationAccess
    );
    const applicationAccessArray = applicationAccessString ? applicationAccessString.split(",") : [];

    const valueToApplicationName = {
        STUDENT: "Embibe - Student",
        PARENT: "Embibe - Parent",
        SCHOOL: "Embibe - School",
        LENS: "Embibe - Lens",
        EXPERIMENT: "Embibe - VLE",
        CLASSROOM: "EMbibe - Classroom",
        MAHENDRAS: "Mahendras",
        UP_LENS: "Embibe - UP Lens",
        NCERT_LENS: "Embibe - NCERT Lens",
        MTG: "MTG Lens",
    };
    const value = useSelector((state) => state.value);
    const handleChange = (event) => {
        dispatch(setValue(event.target.value));
        navigate(`/home`);
    };
    useEffect(() => {
        sessionStorage.setItem("platformSelected", value);
    }, [value]);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div>
                <FormControl sx={{ m: 2 }}>
                    <Select
                        value={value}
                        onChange={handleChange}
                        sx={{ backgroundColor: "white", width: 210, height: 30, fontSize: ".9rem" }}
                    >
                        {applicationAccessArray.map((appValue) => (
                            <MenuItem value={appValue}>{valueToApplicationName[appValue]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </ErrorBoundary>
    );
};

export default SelectApp;
