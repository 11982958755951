import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { Menu, MenuItem, TextField, Typography } from "@mui/material";

const bodyFooterStyle = {
    margin: "0",
    padding: "0",
    fontSize: "12px",
    color: "#290866",
    fontWeight: "500",
};

const FactTypeInput = ({ label, name }) => {
    const [value, setValue] = useState();
    const [selectedvars, setSelectedvars] = useState([]);

    const factTypeVariables = useSelector((state) => state?.template?.factTypeData?.variables);

    const [selectionEnd, setSelectionEnd] = useState(0);
    const textareaRef = useRef();
    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();

        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const selectedVariables = (text) => {
        let originalArr = [...selectedvars];
        if (originalArr.includes(text)) {
            setSelectedvars(originalArr);
        } else {
            originalArr.push(text);
            setSelectedvars(originalArr);
        }

        localStorage.setItem("selectedvars", JSON.stringify(originalArr));
    };

    const insertText = (text) => () => {
        const selectionStart = textareaRef.current.selectionStart;
        const selectionEnd = textareaRef.current.selectionEnd;
        const insertText = ` {{${text}}} `;

        setSelectionEnd(selectionEnd + insertText.length);

        const newValue = `${value.substring(0, selectionStart)}${insertText}${value.substring(
            selectionEnd,
            value.length
        )}`;

        setValue(newValue);
        handleClose();
        localStorage.setItem("factTypeValue", newValue);

        selectedVariables(text);
    };

    useLayoutEffect(() => {
        //Sets the cursor at the end of inserted text
        textareaRef.current.selectionEnd = selectionEnd;
    }, [selectionEnd]);

    return (
        <>
            <Box onContextMenu={handleContextMenu}>
                <label>{label}</label>
                <TextField
                    multiline
                    minRows={6}
                    inputRef={textareaRef}
                    value={value}
                    onChange={({ target }) => setValue(target.value)}
                    fullWidth
                    style={{ margin: "8px 0" }}
                />
                <p style={bodyFooterStyle}>**Right click to select the variables</p>
            </Box>

            {factTypeVariables && (
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                    }
                >
                    {factTypeVariables?.map((item) => (
                        <MenuItem onClick={insertText(item)}>{item}</MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
};

export default FactTypeInput;
