import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";

const CustomDropdown = ({ label, options, name, value, handleChange, checkboxDisabled }) => {
    return (
        <div className="checkbox-container">
            <div className="checkbox-title">{label} *</div>
            <FormControl required fullWidth disabled={checkboxDisabled ? true : false}>
                <Select id="create-template" name={name} value={value} onChange={handleChange} placeholder={33}>
                    {options.map((item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default CustomDropdown;
