import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalUsersCount, getUserViews, searchUserView } from "../../features/userView/userViewSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { dateHelper } from "../../utils/helpers/dateHelper";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "../../components/Spinner";
import "./index.css";
import SearchIcon from "@mui/icons-material/Search";
import InputField from "../../components/InputField";
import useDebounce from "../../utils/helpers/useDebounce";
import FileUpload from "../../components/FileUpload";
import { Button } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    overflow: "scroll",
};
const FileUploadstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 330,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    overflow: "scroll",
};

const UserViewDetailsModal = ({ handleClose, rowDetails, totalUsersCount }) => {
    return (
        <>
            <div className="test-user-modal-header">
                <span>User View Details</span>
                <span onClick={handleClose} style={{ cursor: "pointer" }}>
                    <CloseIcon />
                </span>
            </div>

            <div>
                <ul style={{ lineHeight: "3" }}>
                    <li>
                        <b>User View Name :</b> {rowDetails?.user_view_name}
                    </li>
                    <li>
                        <b>User View Desc :</b> {rowDetails?.description}
                    </li>
                    <li>
                        <b>User View ID :</b> {rowDetails?.user_view_id}
                    </li>
                    <li id="overflow-query">
                        <b>User View Query :</b>
                        {rowDetails?.queries.map(({ collection_name, query }, index) => (
                            <div key={collection_name}>
                                <b>{`Querie${index + 1}`}</b>
                                <br />
                                <b>Collection Name:</b> {collection_name}
                                <br />
                                <b>Query: </b>
                                {query}
                            </div>
                        ))}
                    </li>
                    <li>
                        <b>Total Unique Users Count :</b> {totalUsersCount}
                    </li>
                </ul>
            </div>
        </>
    );
};

const UserViewTableRow = ({ row, handleUserViewRow, handleTotalUsersCount, totalUsersCount }) => {
    return (
        <TableRow key={row?.created_at} style={{ cursor: "pointer" }}>
            <TableCell onClick={() => handleUserViewRow(row)}>{row?.user_view_name}</TableCell>
            <TableCell onClick={() => handleUserViewRow(row)}>{row?.description}</TableCell>
        </TableRow>
    );
};

const UserViewPage = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();

    // MODAL OPEN/CLOSE FUNCTIONS
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [uploadOpen, setUploadOpen] = useState(false);
    const handleUploadClose = () => setUploadOpen(false);

    // MODAL ROW DETAILS
    const [rowDetails, setRowDetails] = useState();
    const handleUserViewRow = (rowDetails) => {
        setOpen(true);
        setRowDetails(rowDetails);
        dispatch(getTotalUsersCount(rowDetails?.user_view_id));
    };

    const userViewData = useSelector((state) => state?.userView?.userViews?.content);
    const userViewDataCount = useSelector((state) => state?.userView?.userViews?.totalElements);
    const isUserviewLoading = useSelector((state) => state?.userView?.isUserviewLoading);
    const totalUsersCount = useSelector((state) => state?.userView)?.totalUsersCount?.count;
    const searchedContent = useSelector((state) => state?.userView?.searchedContent?.content);
    const searchedCount = useSelector((state) => state?.userView?.searchedContent?.totalElements);

    // useEffect(() => {
    //     const data = {
    //         page: page,
    //         rowsPerPage,
    //     };
    //     dispatch(getUserViews(data));
    // }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // SEARCH USER VIEWS
    const [searchTerm, setSearchTerm] = useState();
    const debouncedTerm = useDebounce(searchTerm, 500);
    const value = useSelector((state) => state.value);
    let userViewArr = [];
    userViewArr = searchTerm !== undefined ? searchedContent : userViewData;

    useEffect(() => {
        if (debouncedTerm === undefined) {
            const data = {
                page: page,
                rowsPerPage,
                application: value,
            };
            dispatch(getUserViews(data));
        } else {
            const data = {
                serachText: debouncedTerm,
                page: page,
                rowsPerPage,
                application: value,
            };
            dispatch(searchUserView(data));
            setPage(0);
        }
    }, [debouncedTerm, page, rowsPerPage, value]);

    useEffect(() => {
        handleChangePage("", page);
    }, [page, rowsPerPage]);

    return (
        <>
            <div className="campaign-container">
                <div className="user-view-list-title">All User Views</div>
                <div className="user-view-option">
                    <div className="user-view-search">
                        <InputField
                            id="outlined-search"
                            label="Search User Views"
                            // type="search"
                            variant="outlined"
                            handleChange={(e) => setSearchTerm(e.target.value)}
                            value={searchTerm ?? ""}
                        />

                        <span className="search-icon">
                            <SearchIcon />
                        </span>
                    </div>
                    <Button
                        variant="outlined"
                        onClick={() => setUploadOpen(true)}
                        sx={{ marginLeft: "2rem", height: "55px", marginTop: "1.1rem" }}
                    >
                        Upload User View CSV <UploadIcon />
                    </Button>
                </div>
                {isUserviewLoading ? (
                    <div className="spinner-container-table">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <TableContainer>
                            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                <TableHead className="template-table-head">
                                    <TableRow>
                                        <TableCell>User View Name</TableCell>
                                        <TableCell>Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                {userViewArr?.length > 0 ? (
                                    <TableBody>
                                        {userViewArr
                                            // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            ?.map((row, index) => (
                                                <UserViewTableRow
                                                    row={row}
                                                    handleUserViewRow={handleUserViewRow}
                                                    totalUsersCount={totalUsersCount}
                                                />
                                            ))}
                                    </TableBody>
                                ) : userViewArr?.length === 0 ? (
                                    <div className="user-view-no-data">No data found</div>
                                ) : (
                                    <div className="spinner-container-table">
                                        <Spinner />
                                    </div>
                                )}
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={debouncedTerm === undefined ? userViewDataCount : searchedCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </div>

            {/* SHOW USER VIEW DETAILS MODAL */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <UserViewDetailsModal
                        handleClose={handleClose}
                        rowDetails={rowDetails}
                        totalUsersCount={totalUsersCount}
                    />
                </Box>
            </Modal>
            {/* MODAL FOR FILE UPLOAD*/}
            <Modal
                open={uploadOpen}
                onClose={handleUploadClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={FileUploadstyle}>
                    <FileUpload onSuccess={() => handleUploadClose()} />
                </Box>
            </Modal>
        </>
    );
};

export default UserViewPage;
