import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getTestUserList,
    addTestUserList,
    deleteTestUserList,
    resetTestUserData,
} from "../../features/testUserList/testUserListSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";
import InputField from "../../components/InputField";
import CustomButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import TestUserTable from "./components/TestUserTable";
import Spinner from "../../components/Spinner";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 360,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const deleteStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 150,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const AddUserListModal = ({
    handleCloseAdd,
    handleTestUserChange,
    handleTestUserListAdd,
    testUserData,
    setTestUserData,
}) => {
    const handleSubmit = async () => {
        await handleTestUserListAdd();

        setTimeout(() => {
            handleCloseAdd();
            setTestUserData({});
            // window.location.reload(true);
        }, 500);
    };
    const { name, desc } = testUserData;

    return (
        <Box sx={style}>
            <div className="test-user-modal-header">
                <span>Add User List</span>
                <span style={{ cursor: "pointer" }} onClick={handleCloseAdd}>
                    <CloseIcon />
                </span>
            </div>
            <InputField label="Name" variant="outlined" name="name" handleChange={(e) => handleTestUserChange(e)} />
            <InputField
                label="Description"
                variant="outlined"
                name="desc"
                handleChange={(e) => handleTestUserChange(e)}
            />

            <div className="test-user-modal-btn">
                <CustomButton
                    buttonText="Submit"
                    disabled={!name || !desc ? true : false}
                    variant="outlined"
                    handleClick={handleSubmit}
                />
            </div>
        </Box>
    );
};

const DeleteUserListModal = ({ handleCloseDelete, handleTestUserListDelete }) => {
    const handleDeleteSubmit = () => {
        handleTestUserListDelete();
        handleCloseDelete();
    };
    return (
        <Box sx={deleteStyle}>
            <div>Are you sure you want to delete the Test User List?</div>

            <div className="test-user-delete-btns">
                <CustomButton buttonText="Cancel" variant="outlined" handleClick={handleCloseDelete} />
                <CustomButton buttonText="Delete" variant="outlined" color="error" handleClick={handleDeleteSubmit} />
            </div>
        </Box>
    );
};

const TestUserList = () => {
    const [testUserData, setTestUserData] = useState({});
    const [deleteID, setDeleteID] = useState();
    const dispatch = useDispatch();
    const value = useSelector((state) => state.value);
    const headerList = [
        { id: "1", title: "Name" },
        { id: "2", title: "Remove" },
    ];

    // CALLBACK FOR ADD TEST USER LIST
    const [isSuccess, setIsSuccess] = useState();
    const successCb = (val) => {
        setIsSuccess(val);
    };

    useEffect(() => {
        if (isSuccess === 201) {
            dispatch(getTestUserList(value));
        } else {
            dispatch(getTestUserList(value));
            dispatch(resetTestUserData());
        }
    }, [isSuccess,value]);

    // ADD TEST USER LIST MODAL
    const [openAdd, setOpenAdd] = useState(false);
    const handleCloseAdd = () => {
        setOpenAdd(false);
        setTestUserData({});
    };

    // DELETE TEST USER LIST MODAL
    const [openDelete, setOpenDelete] = useState(false);
    const handleCloseDelete = () => setOpenDelete(false);

    const testUsersList = useSelector((state) => state?.testUserList?.testUserList?.content);
    const isTestUserLoadingList = useSelector((state) => state?.testUserList?.isTestUserLoadingList);

    const handleTestUserChange = (e) => {
        const { name, value } = e.target;
        setTestUserData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleTestUserListAdd = () => {
        let data = { name: testUserData?.name, description: testUserData?.desc,application:value, };
        let testUserList = { data, successCb };
        dispatch(addTestUserList(testUserList));
        // window.location.reload();
        // dispatch(getTestUserList());
    };

    const handleTestUserListDelete = () => {
        dispatch(deleteTestUserList(deleteID));
        // dispatch(getTestUserList());
    };

    return (
        <>
            <div className="test-user-list-container">
                <div className="test-user-list-title">Test Users List</div>
                {isTestUserLoadingList ? (
                    <div className="spinner-container-table">
                        <Spinner />
                    </div>
                ) : (
                    <TestUserTable
                        tableName="test-user-list"
                        headerList={headerList}
                        bodyList={testUsersList}
                        setDeleteID={setDeleteID}
                        setOpenDelete={setOpenDelete}
                    />
                )}
            </div>
            <div className="test-user-list-add-btn" onClick={() => setOpenAdd(true)}>
                <AddIcon />
            </div>

            {/* ADD TEST USER LIST */}
            <Modal
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddUserListModal
                    handleCloseAdd={handleCloseAdd}
                    handleTestUserChange={handleTestUserChange}
                    handleTestUserListAdd={handleTestUserListAdd}
                    testUserData={testUserData}
                    setTestUserData={setTestUserData}
                />
            </Modal>

            {/* DELETE TEST USER LIST MODAL */}
            <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DeleteUserListModal
                    handleCloseDelete={handleCloseDelete}
                    handleTestUserListDelete={handleTestUserListDelete}
                />
            </Modal>
        </>
    );
};

export default TestUserList;
