import CustomButton from "../../../components/Button";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rescindUserView } from "../../../features/userView/userViewSlice";

const UserViewButtons = ({
    handleSave,
    handleTest,
    handleExecute,
    disableSaveBtn,
    disableTestExeBtn,
    handleEditSave,
    editWeeklySelection,
    editMonthlySelection,
}) => {
    const dispatch = useDispatch();
    const hypothesisDataCreated = useSelector((state) => state?.createCampaign?.createdHypothesisData);
    const hypothesisDataEdited = useSelector((state) => state?.template?.hypohesisData);
    const userViewEditData = useSelector((state) => state?.template?.hypohesisData?.campaign);

    const hypothesisID = localStorage.getItem("hypothesisID");
    const editHypothesisID = localStorage.getItem("editHypothesisID");

    const handleStopClick = () => {
        dispatch(rescindUserView());
    };

    return (
        <div className="user-view-button-wrapper">
            {hypothesisID && (
                <div>
                    <CustomButton
                        buttonText="Save"
                        variant="outlined"
                        disabled={disableSaveBtn}
                        handleClick={handleSave}
                    />
                </div>
            )}
            {editHypothesisID && (
                <div>
                    <CustomButton
                        buttonText="Save Edit"
                        variant="outlined"
                        disabled={disableSaveBtn}
                        handleClick={handleEditSave}
                    />
                </div>
            )}
            <div className="user-view-test-exe-button">
                <CustomButton
                    buttonText="Test"
                    variant="outlined"
                    disabled={disableTestExeBtn}
                    handleClick={handleTest}
                />
                {(hypothesisDataCreated?.status === "DATA_CAPTURING" ||
                    hypothesisDataEdited?.status === "DATA_CAPTURING") && (
                    <CustomButton
                        buttonText="Execute"
                        variant="outlined"
                        disabled={disableTestExeBtn}
                        handleClick={handleExecute}
                    />
                )}
                {(hypothesisDataCreated?.status === "PROCESSING" || hypothesisDataEdited?.status === "PROCESSING") && (
                    <CustomButton
                        buttonText="Stop"
                        variant="outlined"
                        disabled={disableTestExeBtn}
                        handleClick={handleStopClick}
                    />
                )}
            </div>
        </div>
    );
};

export default UserViewButtons;
