import { useState, useEffect } from "react";
import CreateTemplate from "../../Template/components/CreateTemplate";
// import EmailTemplate from "../../Template/components/CreateTemplate/components/Email";
import EmailTemplate from "../../TemplateComponents/EmailTemplate";
// import SMSTemplate from "../../Template/components/CreateTemplate/components/SMS";
import SMSTemplate from "../../TemplateComponents/SMSTemplate";
// import WhatsAppTemplate from "../../Template/components/CreateTemplate/components/WhatsApp";
import WhatsAppTemplate from "../../TemplateComponents/WATemplate";
// import PushNotificationTemplate from "../../Template/components/CreateTemplate/components/m-Push";
import PushNotificationTemplate from "../../TemplateComponents/PushNTemplate";
import { Link, useParams } from "react-router-dom";
import { getSingleTemplate } from "../../../features/templatesView/templatesViewSlice";
import BackArrow from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { getFactType } from "../../../features/searchTemplate/searchTemplateSlice";
import "../index.css";
import InAppNotification from "../../TemplateComponents/InAppNotification";
import InAppPopupNotification from "../../TemplateComponents/InAppPopupNotification";
const CreateTemplateView = () => {
    const [createTempChannel, setCreateTempChannel] = useState(localStorage.getItem("channelSelected") ?? "");

    // CREATE TEMPLATE
    const handleCreateTempChannel = (event) => {
        localStorage.setItem("channelSelected", event.target.value);
        setCreateTempChannel(event.target.value);
    };

    // useEffect(() => {
    //     dispatch(getFactType());
    // }, []);

    // EDIT CREATE TEMPLATE
    const templateData = useSelector((state) => state?.templatesView?.singleTemplateData);
    const [channelSelected, setChannelSelected] = useState("EM");

    const dispatch = useDispatch();
    const editTemplateId = localStorage.getItem("editTemplateId");

    useEffect(() => {
        if (editTemplateId) {
            dispatch(getSingleTemplate(editTemplateId));
        }
    }, []);

    useEffect(() => {
        if (templateData) {
            setChannelSelected(templateData?.channel);
        }
    }, [templateData]);

    return (
        <div>
            <Link to="/template">
                <BackArrow className="create-campaign-back-arrow" />
            </Link>
            <div className="create-temp-container">
                <div className="create-temp-header">Create Template</div>
                <CreateTemplate
                    handleCreateTempChannel={handleCreateTempChannel}
                    createTempChannel={createTempChannel}
                    isTemplateView={true}
                    channel={channelSelected}
                />

                {(createTempChannel === "EM" || channelSelected === "EM") && (
                    <div style={{ marginBottom: "36px" }}>
                        {/* <EmailTemplate
                            templateData={templateData}
                            setCreateTempChannel={setCreateTempChannel}
                            isTemplateView={true}
                        /> */}
                        <EmailTemplate
                            templateData={templateData}
                            setCreateTempChannel={setCreateTempChannel}
                            isTemplateView={true}
                        />
                    </div>
                )}
                {(createTempChannel === "SMS" || channelSelected === "SMS") && (
                    <div style={{ marginBottom: "36px" }}>
                        {/* <SMSTemplate setCreateTempChannel={setCreateTempChannel} isTemplateView={true} /> */}
                        <SMSTemplate
                            templateData={templateData}
                            setCreateTempChannel={setCreateTempChannel}
                            isTemplateView={true}
                        />
                    </div>
                )}
                {(createTempChannel === "WA" || channelSelected === "WA") && (
                    <div style={{ marginBottom: "36px" }}>
                        {/* <WhatsAppTemplate setCreateTempChannel={setCreateTempChannel} isTemplateView={true} /> */}
                        <WhatsAppTemplate
                            templateData={templateData}
                            setCreateTempChannel={setCreateTempChannel}
                            isTemplateView={true}
                        />
                    </div>
                )}
                {(createTempChannel === "PN" || channelSelected === "PN") && (
                    <div style={{ marginBottom: "36px" }}>
                        {/* <PushNotificationTemplate setCreateTempChannel={setCreateTempChannel} isTemplateView={true} /> */}
                        <PushNotificationTemplate
                            templateData={templateData}
                            setCreateTempChannel={setCreateTempChannel}
                            isTemplateView={true}
                        />
                    </div>
                )}
                {(createTempChannel === "IA" || channelSelected === "IA") && (
                    <div style={{ marginBottom: "36px" }}>
                        {/* <PushNotificationTemplate setCreateTempChannel={setCreateTempChannel} isTemplateView={true} /> */}
                        <InAppNotification
                            templateData={templateData}
                            setCreateTempChannel={setCreateTempChannel}
                            isTemplateView={true}
                        />
                    </div>
                )}
                {(createTempChannel === "IA_POPUP" || channelSelected === "IA_POPUP") && (
                    <div style={{ marginBottom: "36px" }}>
                        {/* <PushNotificationTemplate setCreateTempChannel={setCreateTempChannel} isTemplateView={true} /> */}
                        <InAppPopupNotification
                            templateData={templateData}
                            setCreateTempChannel={setCreateTempChannel}
                            isTemplateView={true}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateTemplateView;
