import { useState, useEffect } from "react";
import CustomButton from "../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import CustomSelect from "../../../../components/CustomSelect";
import Tooltip from "@mui/material/Tooltip";
import CustomDropdown from "../../../../components/CustomDropdown";
import chroma from "chroma-js";
import Select from "react-select";
import CustomDeepLink from "../../../../components/CustomDeepLink";
import TemplatePreview from "../../../TemplateComponents/TemplatePreview";

const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? data.color
                : isFocused
                ? color.alpha(0.1).css()
                : undefined,
            color: isDisabled
                ? "#ccc"
                : isSelected
                ? chroma.contrast(color, "white") > 2
                    ? "white"
                    : "black"
                : data.color,
        };
    },
};

const SearchTemplateRow = ({ item, handleAddTemplate }) => {
    const allFactTypes = useSelector((state) => state?.template?.factTypes);
    const applicableFactTypes = item?.applicable_fact_types;

    const [disableButton, setDisableButton] = useState(false);
    const [factTypeSelected, setFactTypeSelected] = useState();

    const [isCustomFT, setIsCustomFT] = useState(false);
    // const platformArray = ["CUSTOM","LENS_CUSTOM","PARENT_CUSTOM"]
    const customRegex = /[Cc][Uu][Ss][Tt][Oo][Mm]/;
    const handleSelectionChange = (option) => {
        if (option) {
            setFactTypeSelected(option?.label);
        }

        // if (platformArray.includes(option?.label)) {
        //     setIsCustomFT(true);
        // } else {
        //     setIsCustomFT(false);
        // }

        if (option?.label.match(customRegex)) {
            setIsCustomFT(true);
        } else {
            setIsCustomFT(false);
        }
    };

    //***************************************** */ ARRAY OF APPLICABLE + REMAINING FACT TYPES
    const formatTrue = (array) => {
        const items = array?.map((display, index) => ({ value: display, label: display, color: "green" }));
        return items;
    };

    // const format = (array) => {
    //     const items = array?.map((display, index) => ({ value: display, label: display, color: "black" }));
    //     return items;
    // };

    const applicableFT = formatTrue(applicableFactTypes);
    // const allFT = format(allFactTypes);
    const allFT = allFactTypes?.map((display, index) => ({ value: display, label: display, color: "black" }));

    const array1 = allFT.filter((val) => {
        return !applicableFT.find((val2) => {
            return val.value === val2.value;
        });
    });

    let finalFTSet = [...applicableFT, ...array1];

    // CALLBACK FUNCTIONS
    const [isSuccess, setIsSuccess] = useState();
    const successCb = (val) => {
        setIsSuccess(val);
    };

    const [isError, setIsError] = useState();
    const errorCb = (val) => {
        setIsError(val);
    };

    // CUSTOM DEEPLINK
    const [screenName, setScreenName] = useState();
    const [tags, setTags] = useState();
    const [desktopUrl, setDesktopUrl] = useState();
    const [deep_link, setdeep_link] = useState();
    const [customvalue, setCustomValue] = useState("Automatic");

    // const deepLinkData = JSON.parse(localStorage.getItem("customDeeplink"));
    const handleAddItem = async (id) => {
        if (factTypeSelected === undefined) {
            toast.error("Please select a Fact Type");
        } else if (
            factTypeSelected.match(customRegex) &&
            (screenName === "" || screenName === undefined) &&
            customvalue === "Automatic"
        ) {
            toast.error("Please provide screen name");
        } else if (
            factTypeSelected.match(customRegex) &&
            (tags === "" || tags === undefined) &&
            customvalue === "Automatic"
        ) {
            toast.error("Please provide tags");
        } else if (
            factTypeSelected === "CUSTOM" &&
            (desktopUrl === "" || desktopUrl === undefined) &&
            customvalue === "Automatic"
        ) {
            toast.error("Please provide desktop url");
        } else {
            await handleAddTemplate(id, factTypeSelected, successCb, errorCb);
            setIsCustomFT(false);
        }
        // setDisableButton(true);
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (isSuccess === 200) {
    //             setDisableButton(true);
    //         } else if (isError === "ET-UC-E-027") {
    //             setDisableButton(false);
    //         }
    //     }, 1000);
    // }, [isSuccess, isError]);

    // const allLinkedTemplates = useSelector((state) => state?.template?.allLinkedTemplates);
    // useEffect(() => {
    //     if (allLinkedTemplates?.length > 0) {
    //         allLinkedTemplates?.some((el) => {
    //             if (item?.template_id === el?.template_id) {
    //                 setDisableButton(true);
    //             }
    //         });
    //     }
    // }, [allLinkedTemplates]);

    // MODAL OPEN/CLOSE FUNCTIONS
    const [open, setOpen] = useState(false);
    const handlePreviewClose = () => setOpen(false);
    const [previewItem, setPreviewItem] = useState();

    const handlePreviewClick = (item) => {
        setOpen(true);
        setPreviewItem(item);
    };

    return (
        <div>
            <div key={item.template_id} className="search-template-content">
                <div className="search-template-channel"> {item.channel}</div>
                <Tooltip title={item.template_name} style={{ fontSize: 14 }} placement="top-start" arrow>
                    <div className="search-template-name">{item.template_name}</div>
                </Tooltip>
                <div className="search-template-select">
                    <Select options={finalFTSet} styles={colourStyles} onChange={handleSelectionChange} />
                    {/* <CustomSelect options={finalFTSet} name="userListSelected" handleChange={handleSelectChange} /> */}
                    {/* <CustomDropdown
                        options={allFactTypes}
                        name="userListSelected"
                        value={factTypeSelected ?? ""}
                        handleChange={handleSelectChange}
                    /> */}
                </div>
                <CustomButton
                    buttonText="Add"
                    variant="outlined"
                    size="small"
                    // disabled={disableButton ? true : false}
                    handleClick={() => handleAddItem(item.template_id)}
                />
                <CustomButton
                    buttonText="Preview"
                    variant="contained"
                    handleClick={() => handlePreviewClick(item)}
                    disabled={item?.channel === "EM" ? true : false}
                />
            </div>
            {isCustomFT && (
                <CustomDeepLink
                    customvalue={customvalue}
                    setCustomValue={setCustomValue}
                    deep_link={deep_link}
                    setdeep_link={setdeep_link}
                    screenName={screenName}
                    setScreenName={setScreenName}
                    tags={tags}
                    setTags={setTags}
                    desktopUrl={desktopUrl}
                    setDesktopUrl={setDesktopUrl}
                />
            )}

            <TemplatePreview
                open={open}
                setOpen={setOpen}
                handlePreviewClose={handlePreviewClose}
                previewItem={previewItem}
            />
        </div>
    );
};

export default SearchTemplateRow;
