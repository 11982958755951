import React from "react";
import "./style.css";
import { Button } from "@mui/material";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";

export const CoverPopUp = ({ imageLink, title, IAPBody, ctasArray, cancelState }) => {
    return (
        <div
            className="cover-popup"
            style={{
                backgroundImage: `url(${imageLink})`,
                color: "white",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
            }}
        >
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <h3>{title}</h3>
            <p className="body-text">{IAPBody}</p>
            <div className="cover-btns">
                {ctasArray.map(({ name, deep_link }) => (
                    <Button variant="contained" key={name} sx={{ margin: "5px 10px 5px 10px" }}>
                        {name}
                    </Button>
                ))}
            </div>
        </div>
    );
};
export const InterstitialPopUp = ({ imageLink, title, IAPBody, ctasArray, cancelState }) => {
    return title.length > 1 || IAPBody.length > 1 || ctasArray.length > 1 ? (
        <div className="interstitial-popup">
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <h3>{title}</h3>
            <img src={imageLink} alt="" />

            <p className="body-text">{IAPBody}</p>
            <div className="interstitial-btns">
                {ctasArray.map(({ name, deep_link }) => (
                    <Button variant="contained" key={name} sx={{ margin: "5px 10px 5px 10px" }}>
                        {name}
                    </Button>
                ))}
            </div>
        </div>
    ) : (
        <div className="interstitial-imageonly">
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <img src={imageLink} alt="" />
        </div>
    );
};
export const HalfInterstitialPopup = ({ imageLink, title, IAPBody, ctasArray, cancelState }) => {
    return (
        <div
            className="half-interstitial-popup"
            style={{
                backgroundImage: `url(${imageLink})`,
            }}
        >
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <h3>{title}</h3>
            <p className="body-text">{IAPBody}</p>
            <div className="half-interstitial-btns">
                {ctasArray.map(({ name, deep_link }) => (
                    <Button variant="contained" key={name} sx={{ margin: "5px 10px 5px 10px" }}>
                        {name}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export const HeaderPopup = ({ imageLink, title, IAPBody, ctasArray, cancelState }) => {
    return (
        <div className="header-popup">
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <div style={{ display: "flex" }}>
                <img src={imageLink} alt="" className="header-popup-img" />
                <div className="header-popup-content">
                    <h3>{title}</h3>
                    <p className="body-text-header">{IAPBody}</p>
                </div>
            </div>
            <div className="header-btns">
                {ctasArray.map(({ name, deep_link }) => (
                    <Button variant="contained" key={name} sx={{ margin: "5px 10px 5px 10px" }}>
                        {name}
                    </Button>
                ))}
            </div>
        </div>
    );
};
export const FooterPopup = ({ imageLink, title, IAPBody, ctasArray, cancelState }) => {
    return (
        <div className="footer-popup">
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <div style={{ display: "flex" }}>
                <img src={imageLink} alt="" className="header-popup-img" />
                <div className="header-popup-content">
                    <h3>{title}</h3>
                    <p className="body-text-header">{IAPBody}</p>
                </div>
            </div>
            <div className="header-btns">
                {ctasArray.map(({ name, deep_link }) => (
                    <Button variant="contained" key={name} sx={{ margin: "5px 10px 5px 10px" }}>
                        {name}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export const AlertPopup = ({ imageLink, title, IAPBody, ctasArray, cancelState }) => {
    return (
        <div className="alert-popup">
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <h3>{title}</h3>
            <p className="body-text">{IAPBody}</p>
            <div className="half-interstitial-btns">
                {ctasArray.map(({ name, deep_link }) => (
                    <Button variant="contained" key={name} sx={{ margin: "5px 10px 5px 10px" }}>
                        {name}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export const WebInterstitialPopUp = ({ imageLink, title, IAPBody, ctasArray, cancelState }) => {
    return (
        <div className="web-interstitial-popup">
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <img src={imageLink} alt="" />
            <h5>{title}</h5>
            <p>{IAPBody}</p>
            <div className="web-interstitial-btns">
                {ctasArray.map(({ name, deep_link }) => (
                    <Button variant="contained" key={name} sx={{ margin: "5px 10px 5px 10px", fontSize: "xx-small" }}>
                        {name}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export const WebImageonlyPopUp = ({ imageLink, cancelState }) => {
    return (
        <div className="web-imageonly-popup">
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <img src={imageLink} alt="" className="web-imageonly-img" />
        </div>
    );
};

export const WebBannerPopUp = ({ imageLink, title, IAPBody, ctasArray, cancelState }) => {
    return (
        <div className="web-banner-popup">
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <img src={imageLink} alt="" />
            <div className="web-banner-popup-content">
                <h5 className="web-banner-popup-heading">{title}</h5>
                <p className="web-banner-popup-text">{IAPBody}</p>
            </div>
            <div className="web-interstitial-btns">
                {ctasArray.map(({ name, deep_link }) => (
                    <Button
                        variant="contained"
                        key={name}
                        sx={{ margin: "5px 10px 5px 10px", fontSize: "xx-small", height: "30px" }}
                    >
                        {name}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export const WebBoxPopUp = ({ imageLink, title, IAPBody, cancelState }) => {
    return (
        <div className="web-box-popup">
            {cancelState === true ? <HighlightOffTwoToneIcon className="close-btn" /> : <></>}
            <img src={imageLink} alt="" />
            <div className="web-box-popup-content">
                <h5 className="web-box-popup-heading">{title}</h5>
                <p className="web-box-popup-text">{IAPBody}</p>
            </div>
        </div>
    );
};
