import { useState, useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getScheduleFeature, stopRescindCampaign } from "../../../../features/home/homeSlice";
import { dateHelperFormat } from "../../../../utils/helpers/dateHelper";
import { toast } from "react-toastify";
import Spinner from "../../../../components/Spinner";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DateRangePicker from "../DateRangePicker";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { getUserViewName } from "../../../../features/userView/userViewSlice";
import ScheduleFeatureTableBody from "./ScheduleFeatureTableBody";
import { getTotalUsersCount } from "../../../../features/userView/userViewSlice";
import StopRescindForm from "./StopRescindForm";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 650,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const datePickerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const stopFormModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 650,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const UserCountAndEvents = ({ events, userViewDetails }) => {
    const totalUsersCount = useSelector((state) => state?.userView)?.totalUsersCount?.count;

    return (
        <>
            <div>User Count : {totalUsersCount}</div>
            <div>User View Name : {userViewDetails?.user_view_name}</div>
            <div style={{ marginTop: "20px" }}> Events :</div>
            <div style={{ maxHeight: "340px", overflowY: "scroll" }}>
                {events?.lenghth > 0 ? (
                    events?.map((item) => (
                        <span>
                            {item} <br />
                        </span>
                    ))
                ) : (
                    <i>No Data found</i>
                )}
            </div>
        </>
    );
};

const ScheduleFeature = () => {
    const dispatch = useDispatch();
    // PAGINATION
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // PAGE NUMBER CHANGE - pages 1,2,...9,10
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // NO OF ROWS PER PAGE COUNT - 5, 10, 25
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const value = useSelector((state) => state.value);
    // SETTING INITIAL DATE
    // START DATE : CURRENT DATE - 3days
    // END DATE : CURRENT DATE

    let initStartDate = new Date().getTime();
    let initEndDate = new Date()?.setUTCHours(23, 59, 59, 999);

    let d = new Date();
    let initDate = dateHelperFormat(d);

    const [startDate, setStartDate] = useState(initDate ?? "");
    const [endData, setEndDate] = useState(initDate ?? "");

    const [updatedStartDate, setUpdatedStartDate] = useState(initDate ?? "");
    const [updatedEndDate, setUpdatedEndDate] = useState(initDate ?? "");

    // DATE PICKER MODAL OPEN/CLOSE FUNCTIONS
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const handleDatePickerClose = () => {
        setDatePickerOpen(false);
        setStartDate(updatedStartDate);
        setEndDate(updatedEndDate);
    };

    const handleDatePickerOpen = () => {
        setDatePickerOpen(true);
    };

    //TOTAL USER COUNT AND EVENTS MODAL OPEN/CLOSE FUNCTIONS
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const handleDateSave = () => {
        if (endData < startDate) {
            toast.error("End date should not be past the Start date");
        } else {
            let data = {
                startDate: new Date(startDate).getTime(),
                endDate: new Date(endData)?.setUTCHours(23, 59, 59, 999),
                page: page,
                rowsPerPage,
                application:value,
            };

            setUpdatedStartDate(startDate);
            setUpdatedEndDate(endData);

            dispatch(getScheduleFeature(data));
            setDatePickerOpen(false);
        }
    };

    useEffect(() => {
        let data = {
            startDate: initStartDate,
            endDate: initEndDate,
            page: page,
            rowsPerPage,
            application:value,
        };

        dispatch(getScheduleFeature(data));
    }, [value]);

    useEffect(() => {
        let data = {
            startDate: new Date(updatedStartDate).getTime(),
            endDate: new Date(updatedEndDate)?.setUTCHours(23, 59, 59, 999),
            page: page,
            rowsPerPage,
            application:value,
        };

        dispatch(getScheduleFeature(data));
    }, [page, rowsPerPage,value]);

    const scheduleFeatureData = useSelector((state) => state?.home?.scheduleFeatureData?.content);
    const scheduleFeatureDeatils = useSelector((state) => state?.home?.scheduleFeatureData);
    const isScheduleLoading = useSelector((state) => state?.home?.isScheduleLoading);
    const userViewDetails = useSelector((state) => state?.userView?.userViewName);

    const [events, setEvents] = useState();

    const handleTotalUsersCount = (item) => {
        dispatch(getTotalUsersCount(item?.user_view_id));
        dispatch(getUserViewName(item?.user_view_id));
        setEvents(item?.success_criteria);
        setOpen(true);
    };

    // STOP/RESCIND CAMPAIGN
    const [stopItem, setStopItem] = useState();
    const [stopEmailID, setStopEmailID] = useState();
    const [stopReason, setStopReason] = useState();

    //STOP ANS RESCID FORM MODAL
    const [openStopModal, setOpenStopModal] = useState(false);
    const handleStopModalClose = () => {
        setOpenStopModal(false);
        setStopEmailID("");
    };

    const handleStopModalOpen = () => {
        setOpenStopModal(true);
    };
    const hanldeCampaignStop = (e) => {
        e.preventDefault();

        if (stopItem?.hypothesis_id) {
            let stopData = { email: stopEmailID, reason: stopReason };
            let data = { hypothesisId: stopItem?.hypothesis_id, data: stopData };

            if (stopEmailID !== "" && stopReason?.length > 60) {
                dispatch(stopRescindCampaign(data));
                setTimeout(() => {
                    let scheduleData = {
                        startDate: new Date(startDate).getTime(),
                        endDate: new Date(endData)?.setUTCHours(23, 59, 59, 999),
                        page: page,
                        rowsPerPage,
                        application:value,
                    };
                    dispatch(getScheduleFeature(scheduleData));
                    handleStopModalClose();
                }, 1000);
            }
        }
        setStopEmailID("");
    };

    return (
        <>
            <div className="home-reach-header">Scheduled Campaign Details</div>
            
            {isScheduleLoading ? (
                <div className="spinner-container-overall">
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className="home-Schedulecampaign-btn">
                        <div></div>
                        <div className="home-campaign-btn-wrapper">
                            <button className="home-buttons" onClick={handleDatePickerOpen}>
                                {endData < startDate
                                    ? dateHelperFormat(new Date(initDate).getTime())
                                    : dateHelperFormat(new Date(updatedStartDate).getTime())}
                                |<span> </span>
                                {endData < startDate
                                    ? dateHelperFormat(new Date(initDate).getTime())
                                    : dateHelperFormat(new Date(updatedEndDate).getTime())}
                            </button>
                        </div>
                    </div>
                    <div className="boxContainer">               
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead className="home-overall-table-head">
                                    <TableRow>
                                        <TableCell align="left">Hypothesis ID</TableCell>
                                        <TableCell align="center">Channel</TableCell>
                                        <TableCell align="center">Campaign Name</TableCell>
                                        <TableCell align="center">Intent</TableCell>
                                        <TableCell align="center">Schedule Date</TableCell>
                                        <TableCell align="center">Schedule Time</TableCell>
                                        <TableCell align="center">Total Target User & Count</TableCell>
                                        <TableCell align="center">Campaign Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                {scheduleFeatureData?.length > 0 ? (
                                    <TableBody>
                                        {scheduleFeatureData
                                            // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            ?.map((item) => {
                                                return (
                                                    <ScheduleFeatureTableBody
                                                        item={item}
                                                        handleTotalUsersCount={handleTotalUsersCount}
                                                        openStopModal={openStopModal}
                                                        setOpenStopModal={setOpenStopModal}
                                                        // showPopover={showPopover}
                                                        // setShowPopover={setShowPopover}
                                                        handleStopModalClose={handleStopModalClose}
                                                        handleStopModalOpen={handleStopModalOpen}
                                                        stopItem={stopItem}
                                                        setStopItem={setStopItem}
                                                        setStopEmailID={setStopEmailID}
                                                        setStopReason={setStopReason}
                                                        hanldeCampaignStop={hanldeCampaignStop}
                                                        stopEmailID={stopEmailID}
                                                        stopReason={stopReason}
                                                    />
                                                );
                                            })}
                                    </TableBody>
                                ) : (
                                    <TableBody style={{ position: "relative", height: "84px" }}>
                                        <div className="home-no-data">No data available</div>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                        
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={scheduleFeatureDeatils?.totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        
                    </Paper>
                    </div>
                </>
            )}

            {/* DATE PICKER MODAL */}
            <Modal
                open={datePickerOpen}
                onClose={handleDatePickerClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={datePickerStyle}>
                    <DateRangePicker
                        handleClose={handleDatePickerClose}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endData={endData}
                        setEndDate={setEndDate}
                        handleSave={handleDateSave}
                    />
                </Box>
            </Modal>

            {/* TOTAL USER COUNT AND EVENTS */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={datePickerStyle}>
                    <UserCountAndEvents events={events} userViewDetails={userViewDetails} />
                </Box>
            </Modal>

            {/* STOP EMAIL AND  REASON FORM */}

            <Modal
                open={openStopModal}
                onClose={handleStopModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={stopFormModalStyle}>
                    <StopRescindForm
                        handleStopModalClose={handleStopModalClose}
                        stopItem={stopItem}
                        setStopEmailID={setStopEmailID}
                        setStopReason={setStopReason}
                        hanldeCampaignStop={hanldeCampaignStop}
                        stopEmailID={stopEmailID}
                        stopReason={stopReason}
                    />
                </Box>
            </Modal>
            
        </>
    );
};

export default ScheduleFeature;
