import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import { getTestUser } from "../../../features/testUserList/testUserListSlice";
import { useDispatch, useSelector } from "react-redux";

const TestUserTableRow = ({ item, setDeleteID, setOpenDelete }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDeleteClick = (id) => {
        setOpenDelete(true);
        setDeleteID(id);
    };

    return (
        <TableRow key={item?.users_list_id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>{item?.first_name}</TableCell>
            <TableCell>{item?.mobile ? item?.mobile : "-"}</TableCell>
            <TableCell>{item?.email ? item?.email : "-"}</TableCell>
            <TableCell>{item?.account_id}</TableCell>
            <TableCell>{item?.profile_id}</TableCell>
            <TableCell>
                <div className="template-table-close-icon" onClick={() => handleDeleteClick(item.profile_id)}>
                    <DeleteIcon />
                </div>
            </TableCell>
        </TableRow>
    );
};

export default TestUserTableRow;
