import StartDate from "../components/StartDate";
import EndDate from "../components/EndDate";
import TriggerTime from "../components/TriggerTime";
import UserViewButtons from "../../components/UserViewButtons";
import WeeklySelection from "../components/WeeklySelection";

const Weekly = ({
    scheduleType,
    handleSchduleType,
    handleUserViewChange,
    userViewSelectedData,
    weeklySelection,
    handleWeeklyCheckbox,
    handleSave,
    handleTest,
    handleExecute,
    disableSaveBtn,
    disableTestExeBtn,
    userViewEditDetails,
    handleEditWeeklyCheckbox,
    editWeeklySelection,
    handleEditSave,
}) => {
    return (
        <>
            {userViewEditDetails && scheduleType === userViewEditDetails?.schedule?.schedule_type ? (
                <>
                    <StartDate userViewEditDetails={userViewEditDetails} handleUserViewChange={handleUserViewChange} />
                    <EndDate userViewEditDetails={userViewEditDetails} handleUserViewChange={handleUserViewChange} />
                    <WeeklySelection
                        userViewEditDetails={userViewEditDetails}
                        handleCheckbox={handleWeeklyCheckbox}
                        handleEditWeeklyCheckbox={handleEditWeeklyCheckbox}
                        editWeeklySelection={editWeeklySelection}
                    />
                    <TriggerTime
                        userViewEditDetails={userViewEditDetails}
                        handleUserViewChange={handleUserViewChange}
                    />
                    <UserViewButtons
                        handleSave={handleSave}
                        disableSaveBtn={disableSaveBtn}
                        disableTestExeBtn={disableTestExeBtn}
                        handleTest={handleTest}
                        handleExecute={handleExecute}
                        handleEditSave={handleEditSave}
                        editWeeklySelection={editWeeklySelection}
                    />
                </>
            ) : (
                <>
                    <StartDate handleUserViewChange={handleUserViewChange} />
                    {userViewSelectedData?.startDate && <EndDate handleUserViewChange={handleUserViewChange} />}
                    {userViewSelectedData?.endDate && <WeeklySelection handleCheckbox={handleWeeklyCheckbox} />}
                    {weeklySelection?.length > 0 && <TriggerTime handleUserViewChange={handleUserViewChange} />}
                    {userViewSelectedData?.time && (
                        <UserViewButtons
                            handleSave={handleSave}
                            disableSaveBtn={disableSaveBtn}
                            disableTestExeBtn={disableTestExeBtn}
                            handleTest={handleTest}
                            handleExecute={handleExecute}
                            handleEditSave={handleEditSave}
                            editWeeklySelection={editWeeklySelection}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Weekly;
