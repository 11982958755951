import React, { useState } from "react";
import axios from "axios";
import "./index.css";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { BASE_URL } from "../../utils/constants/apiBaseURL.constants";
import { Button, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";

const Form = ({ onSuccess }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [errors, setErrors] = useState("");
    const value = useSelector((state) => state.value);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedFile || !name || !description) {
            setErrors("Field Can not be Empty");
            return;
        }
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("application", value);

        try {
            const response = await axios.post(`${BASE_URL}/user-cohort-ms/user-view/upload`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            toast.success("Successfully Uploded");
            onSuccess();
        } catch (error) {
            console.log(error);
            setErrors(error?.response.data.message);
            // toast.error(error.response.data.message);
        }
    };

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <form className="uploadbox">
            <FormControl fullWidth>
                <TextField
                    id="name"
                    label="Name"
                    variant="outlined"
                    sx={{ marginBottom: "1.2rem" }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    id="description"
                    label="Description"
                    variant="outlined"
                    sx={{ marginBottom: "1.5rem" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <input type="file" accept=".csv" onChange={handleFileSelect} />
                <Typography sx={{ color: "red", marginLeft: "11.5rem" }}>{errors}</Typography>
                <Button
                    variant="contained"
                    sx={{ marginTop: "2.5rem", width: "50%", borderRadius: "10px", marginLeft: "25%" }}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </FormControl>
        </form>
    );
};

export default Form;
