import React from "react";
import "./index.css";

const MobilePhoneTemplate = ({ children, templateType, previewItem }) => {
    const getBGColor = () => {
        if (templateType === "WA" || previewItem?.channel === "WA") {
            return "conversation-wa";
        } else if (templateType === "PN" || previewItem?.channel === "PN") {
            return "conversation-pn";
        } else if (templateType === "SMS" || previewItem?.channel === "SMS") {
            return "conversation-sms";
        }
    };
    return (
        <div>
            <div className="page">
                <div className="marvel-device nexus5">
                    <div className="top-bar"></div>
                    <div className="camera"></div>
                    <div className="screen">
                        <div className="screen-container">
                            <div className="chat">
                                <div className="chat-container">
                                    <div className={getBGColor()}>{children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobilePhoneTemplate;
