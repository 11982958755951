import axios from "axios";
import { BASE_URL } from "../../utils/constants/apiBaseURL.constants";
import { toast } from "react-toastify";
import { toastError } from "../../utils/helpers/toastError";

const API_URL = `${BASE_URL}/user-cohort-ms/hypothesis`;

const createCampaignHypothesisID = async (createCampaignData) => {
    try {
        const response = await axios.post(API_URL, createCampaignData);
        localStorage.setItem("hypothesisID", response.data.payload.hypothesis_id);
        return response.data.payload;
    } catch (error) {
        toast.error(error?.message);
        return Promise.reject(error);
    }
};

const getAllHypothesisAPI = async ({ page, rowsPerPage, application}) => {
    try {
        // const response = await axios(`${BASE_URL}/user-cohort-ms/hypothesis?pageNo=0&pageSize=1500`);
        const response = await axios(`${BASE_URL}/user-cohort-ms/hypothesis?pageNo=${page}&pageSize=${rowsPerPage}&application=${application}`);
        return response.data.payload;
    } catch (error) {
        toast.error(error?.message);
        return Promise.reject(error);
    }
};

// UPDATE ACTION AND INTENT
const updateActionIntentAPI = async ({ hypothesisId, data }) => {
    try {
        const response = await axios.put(`${BASE_URL}/user-cohort-ms/hypothesis/${hypothesisId}`, data);
        return response.data.payload;
    } catch (error) {
        toast.error(error?.message);
        return Promise.reject(error);
    }
};

// CLONE HYPOTHESIS
const cloneHypothesisAPI = async (hypothesisId) => {
    try {
        const response = await axios.post(`${BASE_URL}/user-cohort-ms/hypothesis/${hypothesisId}/clone`);
        return response.data.payload;
    } catch (error) {
        toast.error(error?.message);
        return Promise.reject(error);
    }
};

// SEARCH CAMPAIGN
const searchCampaignAPI = async ({ serachText = "", page, rowsPerPage, application}) => {
    try {
        const response = await axios(
            `${BASE_URL}/user-cohort-ms/hypothesis/search?name=${serachText}&pageNo=${page}&pageSize=${rowsPerPage}&application=${application}`
        );
        if (response?.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        toastError(error);
        return Promise.reject(error);
    }
};

const createCampaignService = {
    createCampaignHypothesisID,
    getAllHypothesisAPI,
    updateActionIntentAPI,
    cloneHypothesisAPI,
    searchCampaignAPI,
};
export default createCampaignService;
