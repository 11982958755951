import { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import BackArrow from "@mui/icons-material/ArrowBackIos";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import TestUserTable from "./components/TestUserTable";
import { getTestUser } from "../../features/testUserList/testUserListSlice";
import InputField from "../../components/InputField";
import CustomButton from "../../components/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { addTestUser, deleteTestUser } from "../../features/testUserList/testUserListSlice";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";

const headerList = [
    { id: "1", title: "Name" },
    { id: "2", title: "Phone", align: "left" },
    { id: "3", title: "Email", align: "left" },
    { id: "4", title: "Account ID", align: "left" },
    { id: "5", title: "Profile ID", align: "left" },
    { id: "5", title: "Remove", align: "left" },
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 360,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const deleteStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 150,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const AddUserModal = ({ handleCloseAdd, handleTestUserChange, handleTestUserAdd, testUserData, setTestUserData }) => {
    const testUserMsg = useSelector((state) => state?.testUserList?.testUserMsg);
    const { type, val } = testUserData;

    const handleSubmit = async () => {
        await handleTestUserAdd();

        setTimeout(() => {
            handleCloseAdd();
            setTestUserData({});
        }, 500);
    };

    return (
        <Box sx={style}>
            <div className="test-user-modal-header">
                <span>Add Test Users</span>
                <span style={{ cursor: "pointer" }} onClick={handleCloseAdd}>
                    <CloseIcon />
                </span>
            </div>
            <FormControl fullWidth>
                <span className="test-user-dd-label">Select mobile / email</span>
                <Select id="create-template" name="type" onChange={(e) => handleTestUserChange(e)}>
                    <MenuItem value="mobile">Mobile</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                </Select>
            </FormControl>
            <InputField
                label="Value"
                type={testUserData?.type === "mobile" ? "number" : "text"}
                variant="outlined"
                name="val"
                handleChange={(e) => handleTestUserChange(e)}
            />

            <div className="test-user-modal-btn">
                <CustomButton
                    buttonText="Submit"
                    disabled={!type || !val ? true : false}
                    variant="outlined"
                    handleClick={handleSubmit}
                />
            </div>
        </Box>
    );
};

const DeleteUserModal = ({ handleCloseDelete, handleTestUserDelete }) => {
    const handleDeleteSubmit = () => {
        handleTestUserDelete();
        handleCloseDelete();
    };
    return (
        <Box sx={deleteStyle}>
            <div>Are you sure you want to delete the Test User?</div>

            <div className="test-user-delete-btns">
                <CustomButton buttonText="Cancel" variant="outlined" handleClick={handleCloseDelete} />
                <CustomButton buttonText="Delete" variant="outlined" color="error" handleClick={handleDeleteSubmit} />
            </div>
        </Box>
    );
};

const TestUser = () => {
    const [testUserData, setTestUserData] = useState({});
    const [deleteID, setDeleteID] = useState("");

    let { userId, name } = useParams();
    const testUsers = useSelector((state) => state?.testUserList?.testUserData);
    const isTestUserLoading = useSelector((state) => state?.testUserList?.isTestUserLoading);

    const dispatch = useDispatch();
    // CALLBACK FOR ADD TEST USER
    const [isSuccess, setIsSuccess] = useState();
    const successCb = (val) => {
        setIsSuccess(val);
    };
    useEffect(() => {
        if (isSuccess?.mobile_numbers_not_found?.length > 0 || isSuccess?.email_ids_not_found?.length > 0) {
            toast.error("Email/mobile number is not registered");
        } else {
            dispatch(getTestUser(userId));
        }
    }, [isSuccess]);

    // ADD TEST USER MODAL
    const [openAdd, setOpenAdd] = useState(false);
    const handleCloseAdd = () => {
        setOpenAdd(false);
        setTestUserData({});
    };

    // DELETE TEST USER MODAL
    const [openDelete, setOpenDelete] = useState(false);
    const handleCloseDelete = () => setOpenDelete(false);

    // GET THE TEST USER INPUTS
    const handleTestUserChange = (e) => {
        const { name, value } = e.target;
        setTestUserData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // ADD THE TEST USER TO THE LIST
    const handleTestUserAdd = () => {
        let data;
        if (testUserData?.type === "email") {
            data = {
                emails: [testUserData?.val],
            };
        } else if (testUserData?.type === "mobile") {
            data = {
                mobile_numbers: [testUserData?.val],
            };
        }

        let testUser = { id: userId, data: data, successCb };
        dispatch(addTestUser(testUser));
        // window.location.reload();

        // if (testUserMsg?.length === 0) {
        //     window.location.reload();
        // } else if (testUserMsg?.mobile_numbers_not_found?.length > 0 || testUserMsg?.email_ids_not_found?.length > 0) {
        //     toast.error("Email/mobile number is not registered");
        // }
    };

    // DELETE TEST USER
    const handleTestUserDelete = () => {
        let payload = {
            profile_ids: [deleteID],
        };
        let data = { userId, payload };
        dispatch(deleteTestUser(data));
        window.location.reload();
    };

    return (
        <>
            <div className="test-user-list-container">
                <Link to="/test-user-list">
                    <BackArrow className="test-user-back-arrow" />
                </Link>
                <div className="test-user-title">
                    Test Users for <span className="test-user-title-name">{name}</span>
                </div>
                {isTestUserLoading ? (
                    <div className="spinner-container-table">
                        <Spinner />
                    </div>
                ) : (
                    <TestUserTable
                        tableName="test-user"
                        headerList={headerList}
                        bodyList={testUsers}
                        setDeleteID={setDeleteID}
                        setOpenDelete={setOpenDelete}
                    />
                )}
            </div>
            <div className="test-user-list-add-btn" onClick={() => setOpenAdd(true)}>
                <AddIcon />
            </div>

            {/* ADD TEST USER */}
            <Modal
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddUserModal
                    handleCloseAdd={handleCloseAdd}
                    handleTestUserChange={handleTestUserChange}
                    handleTestUserAdd={handleTestUserAdd}
                    testUserData={testUserData}
                    setTestUserData={setTestUserData}
                    // handleTestUserListAdd={handleTestUserListAdd}
                />
            </Modal>

            {/* DELETE TEST USER MODAL */}
            <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DeleteUserModal handleCloseDelete={handleCloseDelete} handleTestUserDelete={handleTestUserDelete} />
            </Modal>
        </>
    );
};

export default TestUser;
