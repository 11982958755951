import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import DateRangePicker from "../DateRangePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { exportCampaignWiseData, getCampaignWiseData, getGoalSuccess } from "../../../../features/home/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { dateHelperFormat } from "../../../../utils/helpers/dateHelper";
import CustomButton from "../../../../components/Button";
import { toast } from "react-toastify";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Spinner from "../../../../components/Spinner";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import appConfig from "../../../../config/app.config";
import { getHypothesisDataCampaignTbl } from "../../../../features/searchTemplate/searchTemplateSlice";
import { getUserViewName, resetUserViewData } from "../../../../features/userView/userViewSlice";
import EmailSelect from "../EmailSelect";
import InputField from "../../../../components/InputField";
import useDebounce from "../../../../utils/helpers/useDebounce";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadOffOutlinedIcon from "@mui/icons-material/FileDownloadOffOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 650,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
};

const emailListStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 780,
    height: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const datePickerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const ChannelSelect = ({ channelSelect, handlehannelSelect, handleClose, handleDateSave }) => {
    const value = useSelector((state) => state.value);
    return (
        <>
            <div className="test-user-modal-header">
                <span>Select Channel</span>
                <span onClick={handleClose} style={{ cursor: "pointer" }}>
                    <CloseIcon />
                </span>
            </div>
            <div className="create-template-wrapper">
                <FormControl fullWidth>
                    <Select id="create-template" value={channelSelect} onChange={handlehannelSelect}>
                        {appConfig[value].ENABLED_CHANNELS.map((channel) => (
                            <MenuItem value={channel.value} key={channel.value}>
                                {channel.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div className="home-date-btn">
                <CustomButton handleClick={handleDateSave} buttonText="Apply" variant="outlined" />
            </div>
        </>
    );
};

const GoalSuccess = ({ eventDone }) => {
    const goalSuccessData = useSelector((state) => state?.home?.goalSuccess);

    return (
        <>
            {goalSuccessData?.goal_success_criteria?.length > 0 ||
            goalSuccessData?.goal_success_criteria?.highly_engaged_users !== 0 ? (
                <div>
                    <div style={{ margin: "8px 0" }}>
                        <table className="goal-success-table">
                            <tr>
                                <th>Event ID</th>
                                <th>Count</th>
                                <th>Percentage</th>
                            </tr>
                            {goalSuccessData?.goal_success_criteria?.map((item) => (
                                <tr>
                                    <td>{item?.event_id}</td>
                                    <td>{item?.count}</td>
                                    <td>{item?.percentage === 0 ? item?.percentage : item?.percentage.toFixed(2)}</td>
                                </tr>
                            ))}
                        </table>
                    </div>

                    <div style={{ marginTop: "80px" }}>
                        <table className="goal-success-table">
                            <tr>
                                <th>Influenced Engaged Users </th>
                                <th>Highly Engaged Users </th>
                            </tr>
                            <tr>
                                <td>{eventDone}</td>
                                <td>{goalSuccessData?.highly_engaged_users}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            ) : (
                <div>No Data Found</div>
            )}
        </>
    );
};

const closeBtnStyle = {
    // marginTop: "55px",
    // marginLeft: "44px",
    cursor: "pointer",
    float: "right",
};

const ShowUserViewName = ({ setOpenUserView, handleCloseUserView }) => {
    const dispatch = useDispatch();

    const isUserviewNameLoading = useSelector((state) => state?.userView?.isUserviewNameLoading);
    const hypothesisData = useSelector((state) => state?.template?.hypothesisDataCampaignTbl);
    const userViewDetails = useSelector((state) => state?.userView?.userViewName);

    const handleGetUserViewDetails = () => {
        if (hypothesisData?.campaign?.user_view_id) {
            dispatch(getUserViewName(hypothesisData?.campaign?.user_view_id));
        } else {
            toast.warning("no user view found");
        }
    };
    return (
        <>
            <div className="campaign-user-view-header">
                <div>Click here to get User View Details</div>
                <span style={closeBtnStyle} onClick={handleCloseUserView}>
                    <CloseIcon />
                </span>
            </div>

            <CustomButton buttonText="get details" variant="contained" handleClick={handleGetUserViewDetails} />
            {userViewDetails?.user_view_name && (
                <div style={{ marginTop: "20px" }}>
                    <div>User View Name : {userViewDetails?.user_view_name}</div>
                    <div>Description : {userViewDetails?.description}</div>
                </div>
            )}
        </>
    );
};

const CampaignWise = () => {
    // PAGINATION
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // PAGE NUMBER CHANGE - pages 1,2,...9,10
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // NO OF ROWS PER PAGE COUNT - 5, 10, 25
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    let d = new Date();
    let initEndDate = dateHelperFormat(d);
    // d.setDate(d.getDate() - 3);
    let initStartDate = dateHelperFormat(d);

    const [startDate, setStartDate] = useState(initStartDate ?? "");
    const [endData, setEndDate] = useState(initEndDate ?? "");
    // const [triggerType, setTriggerType] = useState("EXECUTED");

    const [updatedStartDate, setUpdatedStartDate] = useState(initStartDate ?? "");
    const [updatedEndDate, setUpdatedEndDate] = useState(initEndDate ?? "");

    const dispatch = useDispatch();
    const campaignWiseData = useSelector((state) => state?.home?.campaignWiseData);
    const isCampaignLoading = useSelector((state) => state?.home?.isCampaignLoading);

    const [searchTerm, setSearchTerm] = useState();
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const value = useSelector((state) => state.value);

    useEffect(() => {
        let data = {
            start_date: `${initStartDate}T00:00:00`,
            end_date: `${initEndDate}T23:59:59`,
            // trigger_type:triggerType,
            application: value,
        };

        dispatch(getCampaignWiseData(data));
    }, [value]);

    // DATE PICKER MODAL OPEN/CLOSE FUNCTIONS
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const handleDatePickerClose = () => {
        setDatePickerOpen(false);
        setStartDate(updatedStartDate);
        setEndDate(updatedEndDate);
    };

    const handleDatePickerOpen = () => {
        setDatePickerOpen(true);
    };

    // CHANNEL SELECT MODAL OPEN/CLOSE FUNCTIONS
    const [channelSelectOpen, setChannelSelectOpen] = useState(false);
    const handleChannelSelectClose = () => setChannelSelectOpen(false);

    // CHANNEL SELECT
    const [channelSelect, setChannelSelect] = useState("");
    const handlehannelSelect = (event) => {
        setChannelSelect(event.target.value);
    };

    // CAMPAIGN WSIE DATA
    const handleDateSave = () => {
        if (Date.parse(endData) < Date.parse(startDate)) {
            toast.error("End date should not be past the Start date");
        } else {
            let data;
            if (channelSelect) {
                data = {
                    channel: channelSelect,
                    // trigger_type:triggerType,
                    start_date: `${startDate}T00:00:00`,
                    end_date: `${endData}T23:59:59`,
                    application: value,
                };
            } else {
                data = {
                    start_date: `${startDate}T00:00:00`,
                    end_date: `${endData}T23:59:59`,
                    application: value,
                };
            }

            setUpdatedStartDate(startDate);
            setUpdatedEndDate(endData);

            dispatch(getCampaignWiseData(data));
            setDatePickerOpen(false);
            handleChannelSelectClose();
        }
    };

    // REMOVE SELECTED CHANNEL
    const handleChannelClose = () => {
        let data;

        data = {
            start_date: `${startDate}T00:00:00`,
            end_date: `${endData}T23:59:59`,
            application: value,
        };

        dispatch(getCampaignWiseData(data));
        setChannelSelect();
    };

    // MODAL OPEN/CLOSE FUNCTIONS
    const [open, setOpen] = useState(false);
    const handleGoalClose = () => setOpen(false);

    // MODAL FOR USER VIEW NAME OPEN/CLOSE FUNCTIONS
    const [openUserView, setOpenUserView] = useState(false);
    const handleCloseUserView = () => {
        setOpenUserView(false);
        dispatch(resetUserViewData());
    };

    // MODAL FOR EMAIL SELECT OPEN/CLOSE FUNCTIONS
    const [openEmailSelect, setEmailSelect] = useState(false);
    const handleCloseEmailSelect = () => setEmailSelect(false);

    // GET GOAL SUCCESS API DATA
    const [eventDone, setEventDone] = useState();

    const getGoalSuccessData = (item) => {
        const goalSuccess = {
            hypothesisId: item?.hypothesis_id,
            triggerId: item?.trigger_id,
            application: value,
        };
        dispatch(getGoalSuccess(goalSuccess));
        setOpen(true);
        setEventDone(item?.event_done);
    };

    const userViewDetails = useSelector((state) => state?.userView?.userViewName);

    const handleUserViewDetails = (item) => {
        if (item?.hypothesis_id) {
            dispatch(getHypothesisDataCampaignTbl(item?.hypothesis_id));
            setOpenUserView(true);
        }
    };

    // EMAIL SELECT
    const [emailsList, setEmailsList] = useState([]);
    const [email, setEmail] = useState([]);

    const handleEmailSelect = () => {
        setEmailSelect(true);
    };

    const handleEmailInput = (options) => {
        const arr = options.map((item) => item.value);
        setEmail(arr);
    };

    const handleExportData = () => {
        var data;
        if (channelSelect) {
            data = {
                start_date: `${startDate}T00:00:00`,
                end_date: `${endData}T23:59:59`,
                channel: channelSelect,
                application: value,
                // trigger_type:triggerType,
                emails: email,
            };
        } else {
            data = {
                start_date: `${startDate}T00:00:00`,
                end_date: `${endData}T23:59:59`,
                application: value,
                emails: email,
            };
        }
        let finalData = { data };

        if (email?.length === 0) {
            toast.error("Please enter email IDs");
        } else {
            dispatch(exportCampaignWiseData(finalData));
            setEmail([]);
        }
    };

    // SEARCH CAMPAIGN

    const searchedResArr = [];
    campaignWiseData?.filter((value) => {
        if (debouncedSearchTerm === " ") {
            return value;
        } else if (value?.campaign_name?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase())) {
            return searchedResArr.push(value);
        } else if (value?.hypothesis_id?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase())) {
            return searchedResArr.push(value);
        } else return value;
    });

    let campaignWiseArray = [];
    campaignWiseArray = debouncedSearchTerm !== undefined ? searchedResArr : campaignWiseData;

    const campaignHeading = [
        "Hypothesis ID",
        "Date",
        "Time Stamp",
        "Trigger ID",
        "Camp Name",
        "Channel",
        "Targeted User Set",
        "Sent",
        "Delivered",
        "Delivery Rate",
        "Click Rate",
        "App Opens",
        "Failed",
        "Event Done",
        "Failure Rate",
        "Engagement Rate % (Overall)",
        "Influenced Engagement Rate % (Overall)",
        "User View Info",
        "Goal Success Data",
    ];
    // console.log(campaignHeading);
    return (
        <>
            <div className="home-reach-header">Campaign Wise</div>

            {isCampaignLoading ? (
                <div className="spinner-container-overall">
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className="home-campaign-btn">
                        {/* <button className="home-buttons">Export as CSV</button> */}

                        <div className="user-view-search">
                            <InputField
                                id="outlined-search"
                                label="Search Campaign"
                                // type="search"
                                variant="outlined"
                                handleChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm ?? ""}
                            />

                            <span className="search-icon">
                                <SearchIcon />
                            </span>
                        </div>
                        <div className="home-campaign-btn-wrapper">
                            {email?.length > 0 ? (
                                <Tooltip title="Download">
                                    <span className="download-icon" onClick={handleExportData}>
                                        <FileDownloadOutlinedIcon />
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Select an Email">
                                    <span className="download-icon noselect" onClick={handleExportData}>
                                        <FileDownloadOffOutlinedIcon />
                                    </span>
                                </Tooltip>
                            )}

                            {/* <Select id="create-template" defaultValue="Executed" value={triggerType} onChange={handleTriggerType}style={{minWidth: 150,maxHeight:40,marginRight:26,marginBottom:26}}>
                                <MenuItem value="Executed">Executed</MenuItem>
                                <MenuItem value="Test">Test</MenuItem>
                            </Select> */}
                            {/* <TriggerSelect
                            triggerType={triggerType}
                            handleTriggerType={handleTriggerType}
                            /> */}

                            <button className="home-buttons home-select-channel-btn" onClick={handleEmailSelect}>
                                Select Email ID’s
                            </button>
                            <button
                                className="home-buttons home-select-channel-btn"
                                onClick={() => setChannelSelectOpen(true)}
                            >
                                {channelSelect ? channelSelect : "Select Channel"}
                            </button>
                            {channelSelect && (
                                <span className="home-camapign-close-btn">
                                    <CloseIcon onClick={handleChannelClose} />
                                </span>
                            )}
                            <button className="home-buttons" onClick={handleDatePickerOpen}>
                                {Date.parse(endData) < Date.parse(startDate) ? initStartDate : updatedStartDate} |
                                {Date.parse(endData) < Date.parse(startDate) ? initEndDate : updatedEndDate}
                            </button>
                        </div>
                    </div>
                    <div className="boxContainer">
                        <Paper sx={{ width: "100%", overflow: "hidden" }}>
                            <TableContainer
                                style={{ margin: "0" }}
                                sx={{
                                    "&::-webkit-scrollbar": {
                                        width: 5,
                                        height: 5,
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "#7DA6E0",
                                        borderRadius: 2,
                                    },
                                }}
                            >
                                <Table aria-label="simple table">
                                    <TableHead className="home-overall-table-head">
                                        <TableRow style={{ padding: "0" }}>
                                            {campaignHeading.map((heading, i) => (
                                                <TableCell
                                                    style={{
                                                        textOverflow: "clip",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                    }}
                                                    align="center"
                                                    key={i}
                                                >
                                                    {heading}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    {campaignWiseArray?.length > 0 ? (
                                        <TableBody>
                                            {campaignWiseArray
                                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                ?.map((item) => {
                                                    // const campaignDate = (item?.date).split("T", 3)[0];
                                                    const campaignDate = item?.trigger_date;
                                                    const time = item?.trigger_time;
                                                    return (
                                                        // <CampaignWiseTableRow key={item?.channel} item={item} />
                                                        <TableRow
                                                            sx={{
                                                                "&:last-child td, &:last-child th": { border: 0 },
                                                            }}
                                                        >
                                                            <TableCell
                                                                sx={{
                                                                    textOverflow: "clip",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                {item?.hypothesis_id}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    textOverflow: "clip",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                }}
                                                                align="center"
                                                            >
                                                                {item?.trigger_date ? campaignDate : "NA"} <br />
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    textOverflow: "clip",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                }}
                                                                align="center"
                                                            >
                                                                {item?.trigger_time ? time : "NA"}
                                                            </TableCell>
                                                            <TableCell align="center">{item?.trigger_id}</TableCell>
                                                            <TableCell align="center">{item?.campaign_name}</TableCell>
                                                            <TableCell align="center">{item?.channel}</TableCell>
                                                            <TableCell align="center">{item?.selected}</TableCell>
                                                            <TableCell align="center">{item?.sent}</TableCell>
                                                            <TableCell align="center">{item?.delivered}</TableCell>
                                                            <TableCell align="center">
                                                                {item?.delivery_rate === 0
                                                                    ? item?.delivery_rate
                                                                    : item?.delivery_rate?.toFixed(2)}
                                                                %
                                                            </TableCell>
                                                            {/* <TableCell align="center">{item?.clicks}</TableCell> */}
                                                            <TableCell align="center">
                                                                {item?.click_rate === 0
                                                                    ? item?.click_rate
                                                                    : item?.click_rate?.toFixed(2)}
                                                                %
                                                            </TableCell>
                                                            <TableCell align="center">{item?.app_opens}</TableCell>
                                                            <TableCell align="center">{item?.failed}</TableCell>
                                                            <TableCell align="center">{item?.event_done}</TableCell>
                                                            <TableCell width="110px" align="center">
                                                                {item?.failure_rate === 0
                                                                    ? item?.failure_rate
                                                                    : item?.failure_rate?.toFixed(2)}
                                                                %
                                                            </TableCell>
                                                            <TableCell width="110px" align="center">
                                                                {item?.engagement_rate === 0
                                                                    ? item?.engagement_rate
                                                                    : item?.engagement_rate?.toFixed(2)}
                                                                %
                                                            </TableCell>
                                                            <TableCell width="110px" align="center">
                                                                {item?.influenced_engagement_rate === 0
                                                                    ? item?.influenced_engagement_rate
                                                                    : item?.influenced_engagement_rate?.toFixed(2)}
                                                                %
                                                            </TableCell>
                                                            <TableCell>
                                                                <InfoIcon
                                                                    className="campaign-info-icon"
                                                                    onClick={() => handleUserViewDetails(item)}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <button
                                                                    className="goal-success-btn"
                                                                    onClick={() => getGoalSuccessData(item)}
                                                                >
                                                                    Get Data
                                                                </button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    ) : (
                                        <TableBody style={{ position: "relative", height: "84px" }}>
                                            <div className="home-no-data">No data available</div>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                // count={campaignWiseData?.length}
                                count={
                                    debouncedSearchTerm === undefined
                                        ? campaignWiseData?.length
                                        : searchedResArr?.length
                                }
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </>
            )}
            {/* DATE PICKER MODAL */}
            <Modal
                open={datePickerOpen}
                onClose={handleDatePickerClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={datePickerStyle}>
                    <DateRangePicker
                        handleClose={handleDatePickerClose}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endData={endData}
                        setEndDate={setEndDate}
                        handleSave={handleDateSave}
                    />
                </Box>
            </Modal>
            {/* CHANNEL MODAL */}
            <Modal
                open={channelSelectOpen}
                onClose={handleChannelSelectClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ChannelSelect
                        handlehannelSelect={handlehannelSelect}
                        channelSelect={channelSelect}
                        handleClose={handleChannelSelectClose}
                        handleDateSave={handleDateSave}
                    />
                </Box>
            </Modal>
            {/* SHOW GOAL SUCCESS DATA MODAL */}
            <Modal
                open={open}
                onClose={handleGoalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <GoalSuccess eventDone={eventDone} />
                </Box>
            </Modal>
            {/* SHOW USER VIEW NAME */}
            <Modal
                open={openUserView}
                onClose={handleCloseUserView}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ShowUserViewName
                        userViewDetails={userViewDetails}
                        setOpenUserView={setOpenUserView}
                        handleCloseUserView={handleCloseUserView}
                    />
                </Box>
            </Modal>
            {/* SHOW EMAIL SELECT */}
            <Modal
                open={openEmailSelect}
                onClose={handleCloseEmailSelect}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={emailListStyle}>
                    <EmailSelect
                        email={email}
                        emailsList={emailsList}
                        handleEmailInput={handleEmailInput}
                        handleCloseEmailSelect={handleCloseEmailSelect}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default CampaignWise;
